import React, { useEffect, useState ,useRef } from 'react';
import { useLocation } from 'react-router-dom';



const KirdReport = ({ filteredData, schoolData, onClose }) => {
  // const location = useLocation();
  // const { filteredData, schoolData } = location.state || {};
  
  const [openingBalanceforFirstPage, setOpeningBalanceforFirstPage] = useState(0);
  const [carryForwardBalances, setCarryForwardBalances] = useState([]);
  
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );


  const calculateTotalCredit = (date) => {
    return filteredData[date].jama.reduce((total, item) => {
      // Exclude entries with Pavati No 0
      if (item.khatePrakar === 'ByCash' && item.pavtiNo !== "0") {
        return total + parseFloat(item.amount);
      }
      return total;
    }, 0);
  };

  const calculateTotalDebit = (date) => {
    return filteredData[date].nave.reduce((total, item) => {
      if (item.khatePrakar === 'ByCash') {
        return total + parseFloat(item.amount);
      }
      return total;
    }, 0);
  };

  const findOpeningBalance = () => {
    for (const date in filteredData) {
      const { jama } = filteredData[date];
      const foundEntry = jama.find(item => item.credit1 === "31-03-Akher shillak");
      if (foundEntry) {
        setOpeningBalanceforFirstPage(parseFloat(foundEntry.amount));
        break;
      }
    }
  };

  useEffect(() => {
    findOpeningBalance();

    const newCarryForwardBalances = [];
    let previousBalance = openingBalanceforFirstPage;

    // Sort dates first
    const sortedDates = Object.keys(filteredData).sort((a, b) => new Date(a) - new Date(b));

    sortedDates.forEach((date) => {
        const totalCredit = calculateTotalCredit(date);
        const totalDebit = calculateTotalDebit(date);
        const lastBalance = previousBalance + totalCredit - totalDebit;

        newCarryForwardBalances.push(lastBalance); // Store the last balance
        previousBalance = lastBalance; // Update previousBalance for the next iteration
    });

    setCarryForwardBalances(newCarryForwardBalances);
}, [filteredData, openingBalanceforFirstPage]);

  const getOpeningBalance = (index) => {
    if (index === 0) {
      return openingBalanceforFirstPage || 0;
    }
    return carryForwardBalances[index - 1] || 0;
  };

 const printRef = useRef();

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=1000');
    printWindow.document.write('<html><head><title>Kird Report</title>');
  
    // Add styles for the print window directly
    printWindow.document.write(`
      <style>
       
      
      </style>
    `);
  
    printWindow.document.write('</head><body>');
    printWindow.document.write(printRef.current.innerHTML); // Get the HTML of the div you want to print
    printWindow.document.write('</body></html>');
  
    printWindow.document.close(); // Close the document to start rendering
    printWindow.print(); // Open the print dialog
  };
  

  return (
    <div style={reportOverlay}>
    <div style={reportContainer}>
    
      <button style={closeButtonStyle} onClick={onClose}>Close</button>
      <button
        onClick={handlePrint}
        style={{
                        margin: '10px 0',
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
      >
        Print Report
      </button>
  
          <div ref={printRef} style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                    <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', textAlign: 'center' }}>
                    {Object.keys(filteredData).sort((a, b) => new Date(a) - new Date(b)).map((date, index) => {
        const totalCredit = calculateTotalCredit(date);
        const totalDebit = calculateTotalDebit(date);
        const openingBalance = getOpeningBalance(index);
        const lastBalance = (openingBalance + totalCredit - totalDebit).toFixed(2);

        let totalAmount = 0;
        let totalBankAmount = 0;

        filteredData[date].jama.forEach(item => {
          if (item.khatePrakar === 'ByCash') {
            totalAmount += parseFloat(item.amount);
          } else {
            totalBankAmount += parseFloat(item.amount);
          }
        });

        const totalCreditAmount = totalAmount + totalBankAmount;
        
        let totalDebitAmount = 0;
        let totalDebitBankAmount = 0;

        filteredData[date].nave.forEach(item => {
          if (item.khatePrakar === 'ByCash') {
            totalDebitAmount += parseFloat(item.amount);
          } else {
            totalDebitBankAmount += parseFloat(item.amount);
          }
        });

        const totalDebitAmountFinal = totalDebitAmount + totalDebitBankAmount;

        return (
         <div key={date} style={{ width: '210mm', height: '297mm', border: '1px solid black', margin: '20px auto', padding: '10mm', boxSizing: 'border-box' }}>
  {/* School Info Section */}
  <div
    style={{
      backgroundColor: "#f0f0f0",
      padding: "20px",
      marginBottom: "20px",
      textAlign: "center",
      border: "1px solid black #000",
    }}
  >
    <h1 style={{ margin: "0", fontSize: "24px" }}>
      {schoolData.schoolName}
    </h1>
    <p style={{ margin: "5px 0", fontSize: "16px" }}>
      Taluka: {schoolData.taluka}, District: {schoolData.jilha}
    </p>
    <p style={{ margin: "5px 0", fontSize: "16px" }}>
      Email: {schoolData.email}, Phone: {schoolData.mobileNumber}
    </p>
  </div>
  <h6><b>{language === "English" ? "Date " : "दिनांक "}</b> {date}</h6>

  <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', marginBottom: '20px' }}>
    <thead>
      <tr>
        <th colSpan={4} style={{ textAlign: 'center', backgroundColor: '#007bff', border: '1px solid #000', color: 'white' }}>
          <b>{language === "English" ? "Credit " : "जमा "}</b>
        </th>
        <th colSpan={4} style={{ textAlign: 'center', backgroundColor: '#007bff', border: '1px solid #000', color: 'white' }}>
          <b>{language === "English" ? "Debit " : "नावे "}</b>
        </th>
      </tr>
      <tr>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Pavti No " : "पावती नंबर "}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Account name and note " : "खात्याचे नाव व टीप "}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Cash amount " : "रोख रक्कम "}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Bank Amount " : "बँक रक्कम"}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Voucher No " : "व्हाउचर नंबर"}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Account name and note " : "खात्याचे नाव व टीप "}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Cash Amount " : "रोख रक्कम "}</b>
        </th>
        <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
          <b>{language === "English" ? "Bank Amount " : "बँक रक्कम"}</b>
        </th>
      </tr>
    </thead>


   <tbody>
  {Array.from({ length: Math.max(filteredData[date].jama.length, filteredData[date].nave.length) }).map((_, idx) => {
    const creditEntry = filteredData[date].jama[idx];
    const debitEntry = filteredData[date].nave[idx];
console.log("creditEntry",creditEntry)
    // Only render the row if creditEntry exists and its pavtiNo is not 0
    if (creditEntry && creditEntry.pavtiNo != 0) {
      return (
        <tr key={`row-${idx}`}>
          {/* Credit Side (जमा) */}
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {creditEntry.pavtiNo || ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {creditEntry.credit1 || ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {creditEntry.khatePrakar === 'ByCash' ? creditEntry.amount || '' : ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {creditEntry.khatePrakar !== 'ByCash' ? creditEntry.amount || '' : ''}
          </td>

          {/* Debit Side (नावे) */}
          {debitEntry ? (
            <>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {debitEntry.pavtiNo || ''}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {debitEntry.debit1 || ''}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {debitEntry.khatePrakar === 'ByCash' ? debitEntry.amount || '' : ''}
              </td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
                {debitEntry.khatePrakar !== 'ByCash' ? debitEntry.amount || '' : ''}
              </td>
            </>
          ) : (
            /* Empty cells for the debit side */
            <>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
              <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
            </>
          )}
        </tr>
      );
    } else if (debitEntry) {
      // If credit entry doesn't exist but debit entry does, render a row with empty cells for credit
      return (
        <tr key={`row-${idx}`}>
          {/* Empty cells for the credit side */}
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}></td>

          {/* Debit Side (नावे) */}
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {debitEntry.pavtiNo || ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {debitEntry.debit1 || ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {debitEntry.khatePrakar === 'ByCash' ? debitEntry.amount || '' : ''}
          </td>
          <td style={{ border: '1px solid #000', padding: '8px', textAlign: 'center' }}>
            {debitEntry.khatePrakar !== 'ByCash' ? debitEntry.amount || '' : ''}
          </td>
        </tr>
      );
    }

    // Return null if neither entry exists
    return null;
  }).filter(row => row !== null)} {/* Ensure to filter out any null entries */}
</tbody>


    <tfoot>
      <tr>
        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Opening Balance" : "आरंभिक शिल्लक"}</b>
        </th>
        <td style={{ textAlign: 'center' }}>{parseFloat(openingBalance).toFixed(2) || '0'}</td>
        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Total Debit" : "एकूण नावे"}</b>
        </th>
        <td style={{ textAlign: 'center', border: '1px solid #000' }}>{totalDebitAmountFinal.toFixed(2)}</td>
      </tr>

      <tr>
        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Total Accumulation" : "एकूण जमा"}</b>
        </th>
        <td style={{ textAlign: 'center', border: '1px solid #000' }}>
      {(
        filteredData[date].jama
          .filter(entry => entry.pavtiNo !== '0') // Exclude pavtiNo '0'
          .reduce((acc, entry) => acc + (parseFloat(entry.amount) || 0), 0) // Ensure amount is a number
      ).toFixed(2)}
    </td>
        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Finally balance" : "अखेर शिल्लक"}</b>
        </th>
        <td style={{ textAlign: 'center', border: '1px solid #000' }}>
  {(
    openingBalance + 
    filteredData[date].jama
      .filter(entry => entry.pavtiNo !== '0') // Exclude pavtiNo '0'
      .reduce((acc, entry) => acc + (parseFloat(entry.amount) || 0), 0) // Total Credit Amount
    - totalDebitAmountFinal
  ).toFixed(2)}
</td>
      </tr>
      <tr>
        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Total Amount" : "एकूण"}</b>
        </th>
        <td style={{ textAlign: 'center', border: '1px solid #000' }}>
  {(
    openingBalance + 
    filteredData[date].jama
      .filter(entry => entry.pavtiNo !== '0') // Exclude pavtiNo '0'
      .reduce((acc, entry) => acc + (parseFloat(entry.amount) || 0), 0) // Sum up the amounts
  ).toFixed(2)}
</td>

        <th colSpan="3" style={{ textAlign: 'center', border: "1px solid #000" }}>
          <b>{language === "English" ? "Total " : "एकूण"}</b>
        </th>
        <td style={{ textAlign: 'center', border: '1px solid #000' }}>
        {((
          (openingBalance + 
            filteredData[date].jama
              .filter(entry => entry.pavtiNo !== '0') // Exclude pavtiNo '0'
              .reduce((acc, entry) => acc + (parseFloat(entry.amount) || 0), 0) // Total Credit Amount
          ) - totalDebitAmountFinal 
        ) + totalDebitAmountFinal ).toFixed(2)
        }  
      </td>
      </tr>
    </tfoot>
  </table>
</div>

        );
      })}
    </div>
    </div>
    </div>
    </div>
  );
};

export default KirdReport;

const reportOverlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)', // Blur effect
  zIndex: 1000, 
  marginTop:'20px'
};

const reportContainer = {
  background: 'white', // White background for the report
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  maxWidth: '900px', // Set a maximum width
  width: '100%', // Full width
  position: 'relative', // Position for the close button
  color:'black',
  overflowY: 'hidden'
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color:'Red'
};


