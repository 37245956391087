import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../AlertMessage';


                 
function DainandinNondvahi() {
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [day, setDay] = useState('');
  const [menu, setMenu] = useState({});
  const [praman, setPraman] = useState({});
  const [error, setError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [labharthiValue, setLabharthiValue] = useState('');
  const [labharthiInput, setLabharthiInput] = useState('');
  const udiseNumber = localStorage.getItem("udiseNumber");
  const language = localStorage.getItem("language");

  const [alertMessage, setAlertMessage] = useState("");
const [showAlert, setShowAlert] = useState(false);
useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);


  const navigate = useNavigate();
  const aaharMenuOptions = [
    { value: 'Varan Bhat', label: language === "English" ? 'Varan Bhat' : 'वरण भात' },
    { value: 'Dal Bhat', label: language === "English" ? 'Dal Bhat' : 'डाळ भात' },
    { value: 'Usal Bhat', label: language === "English" ? 'Usal Bhat' : 'उसळ भात' },
    { value: 'Khichadi', label: language === "English" ? 'Khichadi' : 'खिचडी' },
  ];
  const dayMapping = {
    'Sunday': 'रविवार',
    'Monday': 'सोमवार',
    'Tuesday': 'मंगळवार',
    'Wednesday': 'बुधवार',
    'Thursday': 'गुरुवार',
    'Friday': 'शुक्रवार',
    'Saturday': 'शनिवार'
  };
  const [fetchedMenuName, setFetchedMenuName] = useState(''); // New state for fetched menu name


  const classOptions = [
    { value: '1 To 5', label: language === "English" ? '1 to 5' : '१ ते ५' },
  { value: '6 To 8', label: language === "English" ? '6 to 8' : '६ ते ८' },
  ];
useEffect(() => {
  if (selectedClass && selectedDate && udiseNumber) {  
    const fetchData = async () => {
      try {
        // Parse the selected date
        const dateObj = new Date(selectedDate);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const dayOfWeek = dateObj.toLocaleString('en-US', { weekday: 'long' });
        const dayOfMonth = dateObj.getDate().toString().padStart(2, "0");
    
        // Update the URLs with the correct structure
        const menuUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/menu/${selectedClass}/${dayOfWeek}.json`;
        const pramanUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;
        const labharthiUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${year}/${month}/${dayOfWeek}/${dayOfMonth}.json`;
    
        const pramanResponse = await axios.get(pramanUrl);
        const pramanData = pramanResponse.data || {};
        const pramanValues = pramanData[selectedClass] || {};
    
        const menuResponse = await axios.get(menuUrl);
        const menuData = menuResponse.data || {};
        
        // Set the fetched menu name
        const fetchedMenuKey = Object.keys(menuData)[0]; // Get the first menu key
        if (fetchedMenuKey) {
          setFetchedMenuName(fetchedMenuKey); // Set the fetched menu name
        } else {
          setFetchedMenuName(''); // Reset if no menu found
        }
    
        const labharthiResponse = await axios.get(labharthiUrl);
        const labharthiData = labharthiResponse.data || {};
    
        setPraman(pramanValues);
        setLabharthiValue(labharthiData); // Set labharthi value from database
    
        if (menuData) {
          setMenu(menuData);
          setError('');
    
          // Prepare table data
          const newTableData = Object.entries(menuData).flatMap(([menuName, content]) =>
            Object.entries(content).map(([menuContent, isSelected]) => {
              const pramanValue = parseFloat(pramanValues[menuContent]) || 0;
              const labharthiValue = labharthiData.labharthi;
              const totalValue = labharthiData[menuContent] || 0;
              return {
                menuContent,
                praman: pramanValue,
                labharthi: labharthiValue,
                total:  totalValue > 0 ? (totalValue % 1 === 0 ? totalValue.toString() : parseFloat(totalValue).toFixed(6)) : totalValue, 
              };
            })
          );
          setTableData(newTableData);
          console.log("newTableData", newTableData);
    
        } else {
          setMenu({});
          setError('No data available');
          setTableData([]); // Clear table data if no menu
        }
      } catch (error) {
        setMenu({});
        setPraman({});
        setError('Failed to fetch data. Please check your input or try again later.');
        setTableData([]); // Clear table data on error
      }
    };

    fetchData();
  }
}, [selectedClass, selectedDate, udiseNumber]);

  useEffect(() => {
    // Update table data when labharthiInput changes
    if (tableData.length > 0) {
      const updatedTableData = tableData.map(row => ({
        ...row,
        labharthi: parseFloat(labharthiInput) || row.labharthi,
        total: parseFloat(labharthiInput) * row.praman || 0
      }));
      setTableData(updatedTableData);
    }
  }, [labharthiInput]);
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setSelectedDate(dateValue);
    const dateObj = new Date(dateValue);
    const dayOfWeek = dateObj.toLocaleDateString('en-US', { weekday: 'long' });

    const dayInMarathi = language === "English" ? dayOfWeek : dayMapping[dayOfWeek];
    setDay(dayInMarathi);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleLabharthiChange = (e) => {
    setLabharthiInput(e.target.value); // Update input value
  };
  const handleSave = async () => {
    if(!selectedDate || !selectedClass  || !labharthiInput || !menu){
      setAlertMessage("Please fill all fields");
      return ;
    }

    try {
      // Parse the selectedDate to get year, month, and day
      const dateObj = new Date(selectedDate);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
      const dayOfMonth = dateObj.getDate().toString().padStart(2, "0");
      const dayOfWeek = dateObj.toLocaleString('en-US', { weekday: 'long' }); // Get the day of the week
  
      // Construct the save URL with the updated path format
      const saveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${year}/${month}/${dayOfWeek}/${dayOfMonth}.json`;
  
      // Fetch existing data
      const existingResponse = await axios.get(saveUrl);
      const existingData = existingResponse.data || {};
  
      // Create a new object to store the data in the desired structure
      const newData = {};
      tableData.forEach((row) => {
        newData[row.menuContent] = row.total > 0 ? (row.total % 1 === 0 ? row.total.toString() : parseFloat(row.total).toFixed(6)) : row.total; 
      });
      newData.labharthi = labharthiInput;
      const updatedData = { ...existingData, ...newData };
  
      await axios.put(saveUrl, updatedData);
      console.log('Data saved successfully:', updatedData);
      setAlertMessage("Saved Successfully")
    } catch (error) {
      console.error('Failed to save data:', error);
    }
  };
const handleReport = () => {
  if (!selectedDate || !selectedClass) {
    setAlertMessage("Please select both date and class before generating the report.");
    return;
  }

  navigate('/dainandinNondreport', {
    state: { selectedDate, selectedClass }
  });
};


 const handleDelete = async () => {
  if (!selectedClass || !selectedDate || !udiseNumber) {
    console.error('Class, selected date, or UDISE number is missing.');
    return;
  }

  try {
    // Parse the selected date
    const dateObj = new Date(selectedDate);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const dayOfWeek = dateObj.toLocaleString('en-US', { weekday: 'long' });
    const dayOfMonth = dateObj.getDate().toString().padStart(2, "0");

// Construct the URL for the specific node you want to delete
const deleteUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${year}/${month}/${dayOfWeek}/${dayOfMonth}.json`;

// Send DELETE request to remove the data for the selected class and day
await axios.delete(deleteUrl);

// Clear relevant state after deletion
setLabharthiValue('');
setLabharthiInput('');
setTableData([]);
console.log('Data deleted successfully');

// Show success alert after deletion
setAlertMessage('Data deleted successfully!');
} catch (error) {
  console.error('Failed to delete data:', error);
}

};

  
  const containerStyle = {
    maxWidth: '800px',
    margin: '1% auto',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: '#fff',
    border:'1px solid gray'
  };

  const inputStyle = {
    display: 'block',
    marginTop: '10px',
    width: '130px',
    maxWidth: '300px',
    height:'35px', 
    marginRight: '20px', 
    fontSize:'14px', 
    padding:'8px',
    borderRadius:'5px',
    border:'1px solid gray'
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontSize:'14px'
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '8px 20px',
    fontSize: '14px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
  };
  const buttonStyleDelete = {
    marginTop: '20px',
    padding: '8px 16px',
    fontSize: '14px',
    backgroundColor: 'red',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft:'2%',
  };
  const buttonStyleReport= {
    marginTop: '20px',
    padding: '8px 16px',
    fontSize: '14px',
    backgroundColor: '#d4a017',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft:'20px',
    right:'0',
    float:'right'
  }

  const tableStyle = {
    marginTop: '20px',
    width: '100%',
    borderCollapse: 'collapse'
  };

  const tableHeaderStyle = {
    backgroundColor: '#f2f2f2'
  };

  const tableCellStyle = {
    padding: '8px',
    border: '1px solid black'
  };

  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '30px',
  };

  return (
    <div>
      <Sidebar />
      
 <AlertMessage message={alertMessage} show={showAlert}/>

 
      <div className='main-content-of-page'>
        <div style={containerStyle}>
          <h3 className='text-center mb-5' style={{ color:'#03365e', fontWeight:'600'}}> {language === "English" ? "Daily register  " : "दैनंदिन नोंदवही"}</h3>

          <div style={rowStyle}>
            <div>
              <label htmlFor="date" style={labelStyle}>{language === "English" ? " Date " : "दिनांक"} :</label>
              <input
                type="date"
                id="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
                style={inputStyle}
              />
            </div>

            {/* Class Dropdown */}
            <div>
              <label htmlFor="class-select" style={labelStyle}>{language === "English" ? " Class " : "वर्ग "} :</label>
              <select
                id="class-select"
                value={selectedClass}
                onChange={handleClassChange}
                style={inputStyle}
              >
                <option value="" disabled>{language === "English" ? "Select Class " : "वर्ग निवडा"}</option>
                {classOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div style={rowStyle}>
            
            <div>
              <label htmlFor="day" style={labelStyle}> {language === "English" ? " Day " : "वार "} :</label>
              <input
                type="text"
                id="day"
                name="day"
                value={day}
                readOnly
                style={inputStyle}
              />
            </div>

            <div>
              <label htmlFor="menu" style={labelStyle}> {language === "English" ? " Menu " : "मेनू "}:</label>
              <input
  type="text"
  id="menu"
  name="menu"
  value={
    aaharMenuOptions.find(option => option.value === fetchedMenuName)?.label || fetchedMenuName
  }
  readOnly
  style={inputStyle}
/>
            </div>
          
            <div>
  <label htmlFor="labharthiInput" style={labelStyle}>{language === "English" ? " Beneficiary " : "लाभार्थी "}:</label>
  <input
  type="number"
  id="labharthiInput"
  name="labharthiInput"
  value={labharthiInput || (tableData[0] && tableData[0].labharthi)}
  onChange={handleLabharthiChange}
  onInput={(e) => {
    if (e.target.value.length > 8) {
      e.target.value = e.target.value.slice(0, 8);
    }
  }}
  style={inputStyle}
/>

</div>
          </div>
          <hr/>


          <button onClick={handleSave} style={buttonStyle}>Save</button>
          {tableData.length > 0 && (tableData[0] && tableData[0].labharthi) > 0 && ( 
            <button onClick={handleDelete} style={buttonStyleDelete}>Delete</button>
          )}
         
          <button style={buttonStyleReport} onClick={handleReport}>Report</button>

        
          <table style={tableStyle}>
            <thead style={tableHeaderStyle}>
              <tr>
                <th className='text-center fw-bold '  style={tableCellStyle}>{language === "English" ? " Menu Content " : "मेन्यू मधील धान्य"}</th>
                <th className='text-center fw-bold ' style={tableCellStyle}>{language === "English" ? " Quantity (In Gram)" : "प्रमाण (ग्रॅम मध्ये)"}</th>
                <th className='text-center fw-bold ' style={tableCellStyle}>{language === "English" ? " Beneficiary " : "लाभार्थी "}</th>
                <th className='text-center fw-bold' style={tableCellStyle}>{language === "English" ? " Total (In Gram)" : "एकूण (ग्रॅम मध्ये)"}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{row.menuContent}</td>
                  <td style={tableCellStyle}>{row.praman}</td>
                  <td style={tableCellStyle}>{labharthiInput || row.labharthi}</td>
                  <td style={tableCellStyle}>
        {row.total > 0 
          ? (row.total % 1 === 0 ? row.total.toString() : parseFloat(row.total).toFixed(6)) 
          : row.total}
      </td>

                </tr>
              ))}
              <tr >
                <td style={{ padding: '18px' }}></td><td></td><td></td><td></td>
              </tr> <tr >
                <td style={{ padding: '18px' }}></td><td></td><td></td><td></td>
              </tr> <tr >
                <td style={{ padding: '18px' }}></td><td></td><td></td><td></td>
              </tr>
            </tbody>
          </table>

          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default DainandinNondvahi;
