

import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import './Bonafide.css'
import Leavdoc from './Leavdoc';
import AlertMessage from "../../AlertMessage";
const Leaving = () => {
  const [formData, setFormData] = useState({
    stdName: '',
    stdMother: '',
    religion: '',
    class: '',
    educationalYear: '',
    dob: '',
    birthPlace: '',
    registerno: '',
    reason: '',
    selectedBookNo: '',
    progress: '',
    conduct: '',
    remark: '',
    schoolLogo: '',
    documentNo: '', // Initial document number
    bookno: '',
    printdate:'',
    date:''
  });

  const [submitted, setSubmitted] = useState(false);
  const [walletPoints, setWalletPoints] = useState(0);
  const [udiseNumber, setUdiseNumber] = useState('');
  const [lastLeavDocNo, setlastLeavDocNo] = useState(0); 
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    setFormData({
      stdName: '',
      stdMother: '',
      religion: '',
      class: '',
      educationalYear: '',
      dob: '',
      birthPlace: '',
      registerno: '',
      reason: language === 'English' ? 'Going Elsewhere' : 'इतरत्र जात आहे',
      selectedBookNo: '',
      progress: language === 'English' ? 'Excellent' : 'उत्कृष्ट',
      conduct: language === 'English' ? 'Excellent' : 'उत्कृष्ट',
      remark: language === 'English' ? 'Going Elsewhere' : 'इतरत्र जात आहे',
      schoolLogo: '',
      documentNo: '', // Initial document number
      bookno: '',
      printdate:'',
      date:''

    });
  }, [language]);

  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        const data = await response.json();
        // Update wallet points state
        console.log("Wallet points data:", data);
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]); // Make useEffect re-run when udiseNumber changes


  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber)
    }
  }, [])

  useEffect(() => {
    console.log("formData:", formData); 
  }, [formData]);


  const [duplicateStudents, setDuplicateStudents] = useState([]);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);

  const handleSelectStudent = (selectedStudent) => {
    setFormData((prevData) => ({
      ...prevData,
      stdName: selectedStudent.stdName || '',
      stdMother: selectedStudent.stdMother || '',
      religion: selectedStudent.religion || '',
      class: selectedStudent.currentClass || '',
      educationalYear: selectedStudent.educationalYear || '',
      dob: selectedStudent.dob || '',
      birthPlace: selectedStudent.birthPlace || '',
      dobInWords: selectedStudent.dobInWords || '',
      studentId: selectedStudent.studentId || '',
      stdSurname: selectedStudent.stdSurname || '',
      stdFather: selectedStudent.stdFather || '',
      stdAdharNo: selectedStudent.stdAdharNo || '',
      division: selectedStudent.division || '',
      bookno: selectedStudent.bookno || '',

    }));
    setShowDuplicatePopup(false);
  };

  const DuplicateStudentPopup = ({ duplicateStudents, onSelectStudent }) => {
    return (
      <div className="popup">
        <h4>Select Student</h4>
        <table className="student-table">
        <thead>
          <tr>
            <th> Full Name</th>
            <th>Class</th>
          </tr>
        </thead>
        <tbody>
          {duplicateStudents.map((student, index) => (
            <tr key={index} onClick={() => onSelectStudent(student)}>
              <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
              <td>{student.currentClass}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    );
  };

  const handleWalletUpdate = async (updatedPoints) => {
    try {
      // Update only the walletPoints field in the database
      const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
        method: 'PUT', // Use PUT method to replace existing data
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPoints),
      });
      if (!updateResponse.ok) {
        throw new Error('Failed to update wallet points');
      }
      // Update local state with new points
      console.log("Updated wallet points in state:", updatedPoints);
      setWalletPoints(updatedPoints);

    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };

  useEffect(() => {
    const fetchlastLeavDocNo = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/lastLeavDocNo.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch last document number');
        }
        const data = await response.json();
        setlastLeavDocNo(data || 0);
      } catch (error) {
        console.error('Error fetching last document number:', error);
      }
    };
    fetchlastLeavDocNo();
  }, [udiseNumber]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    // Deduct points from wallet
    const updatedPoints = walletPoints - 2; // Assuming 2 points deduction
    if (updatedPoints < 0) {
      setAlertMessage('Insufficient points! Please recharge your wallet.');
      return;
    }

    try {
      // Fetch the serialNo using the formData.registerno
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();

      // Check if data is not null and is an object
      if (data && typeof data === 'object') {
        let serialNo = null;

        // Iterate over each serial number
        Object.entries(data).forEach(([key, serialData]) => {
          // Check if the register number matches
          if (serialData && serialData.registerNo === formData.registerno) {
            serialNo = key; // Set the serialNo
          }
        });

        // Fetch the documentNo
        const lastLeavDocNoResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/lastLeavDocNo.json`);
        if (!lastLeavDocNoResponse.ok) {
          throw new Error('Failed to fetch lastLeavDocNo');
        }
        const documentNo = await lastLeavDocNoResponse.json();

        if (serialNo) {
          // Update the leavingCertificate node with serialNo
          await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/leavingCertificate/${serialNo}/${documentNo}.json`, {
            method: 'PATCH', // Use PATCH method to update specific fields
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: new Date().toLocaleDateString() }), // Update serialNo

          });
        } else {
          console.error('No matching serialNo found for the registerNo:', formData.registerno);
        }
      } else {
        console.error('Data fetched is not valid:', data);
      }

      // Increment the documentNo
      const newDocumentNo = formData.documentNo + 1;
      // Update formData with newDocumentNo
      setFormData(prevData => ({ ...prevData, documentNo: newDocumentNo }));

      // Update lastLeavDocNo in Firebase
      const lastSerialUpdateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}.json`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ lastLeavDocNo: newDocumentNo }),
      });
      if (!lastSerialUpdateResponse.ok) {
        throw new Error('Failed to update lastLeavDocNo');
      }

      // Update wallet points in Firebase
      await handleWalletUpdate(updatedPoints);
      setSubmitted(true);
    } catch (error) {
      console.error('Error handling submit:', error);
    }
  };
  useEffect(() => {
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`; // Format date as dd-mm-yyyy
    setFormData(prevData => ({ ...prevData, printdate: formattedDate, date: formattedDate }));
  }, []);
  
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === 'registerno') {
      setDuplicateStudents([]);
      setShowDuplicatePopup(false);
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        console.log('Fetched data:', data); // Log fetched data to inspect structure and content

        // Check if data is not null and is an object
        if (data && typeof data === 'object') {
          const matchingStudents = [];

          // Iterate over each serial number
          Object.values(data).forEach((serialData) => {
            // Check if the register number matches
            if (serialData && serialData.registerNo === value) {
              matchingStudents.push(serialData);
            }
          });

          if (matchingStudents.length > 1) {
            console.log('Duplicate students found:', matchingStudents); // Log matched student data

            // Update state to show duplicate popup
            setDuplicateStudents(matchingStudents);
            setShowDuplicatePopup(true);
          } else if (matchingStudents.length === 1) {
            // Only one student found, update form data
            const studentData = matchingStudents[0];
            setFormData((prevData) => ({
              ...prevData,
              stdName: studentData.stdName || '',
              stdMother: studentData.stdMother || '',
              religion: studentData.religion || '',
              class: studentData.currentClass || '',
              educationalYear: studentData.educationalYear || '',
              dob: studentData.dob || '',
              birthPlace: studentData.birthPlace || '',
              dobInWords: studentData.dobInWords || '',
              studentId: studentData.studentId || '',
              stdSurname: studentData.stdSurname || '',
              stdFather: studentData.stdFather || '',
              stdAdharNo: studentData.stdAdharNo || '',
              caste: studentData.caste || '',
              prevSchool: studentData.prevSchool || '',
              dateOfAdmission: studentData.dateOfAdmission || '',
              nationality: studentData.nationality || '',
              serialNo: studentData.serialNo || '',
              bookno: studentData.bookno || '',

            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              stdName: '',
              stdMother: '',
              religion: '',
              class: '',
              educationalYear: '',
              dob: '',
              birthPlace: '',
              dobInWords: '',
              studentId: '',
              stdSurname: '',
              stdFather: '',
              stdAdharNo: '',
              caste: '',
              prevSchool: '',
              dateOfAdmission: '',
              nationality: '',
              serialNo: '',
              bookno : ' ',
            }));
          }
        } else {
          console.error('Data fetched is not valid:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };


 

  const [isProgressOther, setIsProgressOther] = useState(formData.progress === (language === "English" ? "Other" : "इतर"));
  const [isConductOther, setIsConductOther] = useState(formData.conduct === (language === "English" ? "Other" : "इतर"));
  const [isReasonOther, setIsReasonOther] = useState(formData.reason === (language === "English" ? "Other" : "इतर"));
  const [isRemarkOther, setIsRemarkOther] = useState(formData.remark === (language === "English" ? "Other" : "इतर"));

  // Handle select change for each field
  const handleSelectChange = (event, field) => {
    const { name, value } = event.target;
    if (name === "progress") {
      setIsProgressOther(value === (language === "English" ? "Other" : "इतर"));
    } else if (name === "conduct") {
      setIsConductOther(value === (language === "English" ? "Other" : "इतर"));
    } else if (name === "reason") {
      setIsReasonOther(value === (language === "English" ? "Other" : "इतर"));
    } else if (name === "remark") {
      setIsRemarkOther(value === (language === "English" ? "Other" : "इतर"));
    }
    handleChange(event);
  };


  

  return (
    <div>
      <Sidebar />
    <AlertMessage message={alertMessage} show={showAlert}/>
      <div className='main-content-of-page leavingcer-hor-width Bonafide-Css' style={{ width: "90%", overflowX: "auto" }}>
        <div className='bonafidecontent ' style={{ width: "57%" }} >
          <h2 className="title">{language === "English" ? "Leaving Certificate " : "शाळा सोडल्याचा दाखला"} </h2>
          <div className="form-output">
            <form onSubmit={handleSubmit}>
              <br />

              <div className="form-group l3">
                <label>{language === "English" ? "Register No " : "रजिस्टर क्र."}</label>
                <input style={{marginRight:'42px'}} className="input-field" type="text" name="registerno" value={formData.registerno} onChange={handleChange} required />
              </div>
              <br />
              <div className="form-group l3">
                <label>{language === "English" ? "Name " : "नाव"}</label>
                <input style={{marginRight:'42px'}}  className="input-field" type="text" name="stdName" value={formData.stdName} onChange={handleChange} required />
              </div>
              <br />

              <div className="form-group l3">
                <label>{language === "English" ? "Class " : "वर्ग"}</label>
                <input style={{marginRight:'42px'}}  className="input-field" type="text" name="class" value={formData.class} onChange={handleChange} required />
              </div>
              <br />

              <div className="form-group l1">
        <label>{language === "English" ? "Progress" : "प्रगती"}</label>
        <select className="input-field" name="progress" value={formData.progress} onChange={(e) => handleSelectChange(e, "progress")} required>
          <option value={language === "English" ? "Excellent" : "उत्कृष्ट"}>{language === "English" ? "Excellent" : "उत्कृष्ट"}</option>
          <option value={language === "English" ? "Good" : "चांगले"}>{language === "English" ? "Good" : "चांगले"}</option>
          <option value={language === "English" ? "Satisfactory" : "समाधानकारक"}>{language === "English" ? "Satisfactory" : "समाधानकारक"}</option>
          <option value={language === "English" ? "Other" : "इतर"}>{language === "English" ? "Other" : "इतर"}</option>
        </select>
        {isProgressOther && (
          <input className="input-field" name="progress" value={formData.progress} onChange={handleChange} />
        )}
      </div>
      <br />

      <div className="form-group l1">
        <label>{language === "English" ? "Conduct" : "वर्तणूक"}</label>
        <select className="input-field" name="conduct" value={formData.conduct} onChange={(e) => handleSelectChange(e, "conduct")} required>
          <option value={language === "English" ? "Excellent" : "उत्कृष्ट"}>{language === "English" ? "Excellent" : "उत्कृष्ट"}</option>
          <option value={language === "English" ? "Good" : "चांगले"}>{language === "English" ? "Good" : "चांगले"}</option>
          <option value={language === "English" ? "Satisfactory" : "समाधानकारक"}>{language === "English" ? "Satisfactory" : "समाधानकारक"}</option>
          <option value={language === "English" ? "Other" : "इतर"}>{language === "English" ? "Other" : "इतर"}</option>
        </select>
        {isConductOther && (
          <input className="input-field" name="conduct" value={formData.conduct} onChange={handleChange} />
        )}
      </div>
      <br />

      <div className="form-group l1">
        <label>{language === "English" ? "Reason" : "कारण"}</label>
        <select className="input-field" name="reason" value={formData.reason} onChange={(e) => handleSelectChange(e, "reason")} required>
          <option value={language === "English" ? "Graduated" : "पदवी प्राप्त केली"}>{language === "English" ? "Graduated" : "पदवी प्राप्त केली"}</option>
          <option value={language === "English" ? "Transferred" : "हस्तांतरित"}>{language === "English" ? "Transferred" : "हस्तांतरित"}</option>
          <option value={language === "English" ? "Expelled" : "निष्कासित"}>{language === "English" ? "Expelled" : "निष्कासित"}</option>
          <option value={language === "English" ? "Other" : "इतर"}>{language === "English" ? "Other" : "इतर"}</option>
        </select>
        {isReasonOther && (
          <input className="input-field" name="reason" value={formData.reason} onChange={handleChange} />
        )}
      </div>
      <br />

      <div className="form-group l1">
        <label>{language === "English" ? "Remark" : "शेरा"}</label>
        <select className="input-field" name="remark" value={formData.remark} onChange={(e) => handleSelectChange(e, "remark")} required>
          <option value={language === "English" ? "Going Elsewhere" : "इतरत्र जात आहे"}>{language === "English" ? "Going Elsewhere" : "इतरत्र जात आहे"}</option>
          <option value={language === "English" ? "Transferred" : "हस्तांतरित"}>{language === "English" ? "Transferred" : "हस्तांतरित"}</option>
          <option value={language === "English" ? "Expelled" : "निष्कासित"}>{language === "English" ? "Expelled" : "निष्कासित"}</option>
          <option value={language === "English" ? "Other" : "इतर"}>{language === "English" ? "Other" : "इतर"}</option>
        </select>
        {isRemarkOther && (
          <input className="input-field" name="remark" value={formData.remark} onChange={handleChange} />
        )}
      </div>
              <br />

              <div className="form-group mb-3">
  <label style={{flex: 'none'}}>{language === "English" ? "Date of leaving school " : "शाळा सोडल्याची तारीख"}</label>
  <input  className="input-field dt-lc" style={{width:'200px', marginLeft:'80px'}}  type="date" name="date"  value={formData.date}  onChange={handleChange}  required
  />
</div>
<div className="form-group">
      <label style={{ flex: 'none' }}>
        {language === "English" ? "Certificate Print Date " : "दाखला प्रिंट तारीख"}
      </label>
      <input
        className="input-field dt-lc"
        style={{ width: '200px', marginLeft: '90px' }}
        type="date"
        name="printdate"
        value={formData.printdate}
        onChange={handleChange}
        required
      />
    </div>
              <div className="wallet-points">
                {language === "English" ? " Wallet Points For Each Leaving Certificate  = 2" : "प्रत्येक दाखल्यासाठी वॉलेट पॉइंट्स = 2"}
              </div>
            </form>
          </div>

          {showDuplicatePopup && (
            <div className="popup-container">             
                <DuplicateStudentPopup
                  duplicateStudents={duplicateStudents}
                  onSelectStudent={handleSelectStudent}
                />
            </div>
          )}
        </div>
        <div className=' leavedoc-doic' style={{ width: "30%", marginBottom:'115px' }}>
          <Leavdoc 
            handleSubmit={handleSubmit}
            submitted={submitted}
            formData={formData}
          />
        </div>
      </div>
    </div>
  );
};

export default Leaving;