import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import './Bonafide.css'
import IdDoc from './IdDoc';
import AlertMessage from '../../AlertMessage';

const IdCard = () => {
  const [formData, setFormData] = useState({
    stdName: '',
    stdMother: '',
    religion: '',  
    class: '',
    educationalYear: '',
    dob: '',
    birthPlace: '',
    registerno: '',
    reason: '',
    selectedBookNo: '',
    schoolName: "",
    udiseNumber: "",
    password: "",
    mobileNumber: "",
    language: "",
    telephoneNumber: "",
    address: "",
    taluka: "",
    jilha: "",
    email: "",
    generalRegisterNumber: "",
    boardName: "",
    indexNumber: "",
    sanlagnataKramank: "",
    manyataKramank: "",
    schoolLogo: "",
    stdPhoto: ""
  });

  const [udiseNumber, setUdiseNumber] = useState('');
  const [walletPoints, setWalletPoints] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("language") || "English");
  const [submitted, setSubmitted] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [duplicateStudents, setDuplicateStudents] = useState([]);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
      if (alertMessage) {
        setShowAlert(true);
        const timeoutId = setTimeout(() => {
          setShowAlert(false);
          setAlertMessage(""); 
        }, 3000);
        return () => clearTimeout(timeoutId);
      }
    }, [alertMessage]);

  const romanToInt = {
    I: 1,
    II: 2,
    III: 3,
    IV: 4,
    V: 5,
    VI: 6,
    VII: 7,
    VIII: 8,
    IX: 9,
    X: 10,
    XI: 11,
    XII: 12,
  };
  
  const marathiToInt = {
    'पहिली': 1,
    'दुसरी': 2,
    'तिसरी': 3,
    'चौथी': 4,
    'पाचवी': 5,
    'सहावी': 6,
    'सातवी': 7,
    'आठवी': 8,
    'नववी': 9,
    'दहावी': 10,
    'अकरावी':11,
    'बारावी': 12
  };


  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        const data = await response.json();
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "English";
    setLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  useEffect(() => {
    console.log("formData:", formData);
  }, [formData]);

  useEffect(() => {
    setFormData({
      stdName: '',
      stdMother: '',
      religion: '',
      class: '',
      educationalYear: '',
      dob: '',
      birthPlace: '',
      registerno: '',
      reason: language === "English" ? "To avail of travel benefits" : "प्रवासाचे फायदे मिळावेत",
      selectedBookNo: '',
      division: '',
      stdPhoto: '',
    });
  }, [language]);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch classes');
        }
        let data = await response.json();
        console.log("data:", data);
  
        // Filter out null values
        data = Object.values(data).filter(student => student !== null);
  
        // If data is still empty after filtering, handle the case
        if (data.length === 0) {
          throw new Error('No valid student data available');
        }
  
        const classSet = new Set();
        data.forEach((student) => {
          if (student.currentClass) {
            classSet.add(student.currentClass);
          }
        });
        setClasses([...classSet]);
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    fetchClasses();
  }, [udiseNumber]);
  
 const handleSelectStudent = (selectedStudents) => {
  if (Array.isArray(selectedStudents) && selectedStudents.length > 0) {
    // Update selected students state
    setSelectedStudents(selectedStudents);

    // Prepare updated form data
    const updatedFormData = {
      stdName: '',
      stdMother: '',
      religion: '',
      class: '',
      educationalYear: '',
      dob: '',
      birthPlace: '',
      dobInWords: '',
      studentId: '',
      stdSurname: '',
      stdFather: '',
      stdAdharNo: '',
      division: '',
      stdPhoto: '',
    };

    // Combine data from all selected students into the updatedFormData
    selectedStudents.forEach((student, index) => {
      updatedFormData.stdName += (index > 0 ? ", " : "") + (student.stdName || '');
      updatedFormData.stdMother += (index > 0 ? ", " : "") + (student.stdMother || '');
      updatedFormData.religion += (index > 0 ? ", " : "") + (student.religion || '');
      updatedFormData.class += (index > 0 ? ", " : "") + (student.currentClass || '');
      updatedFormData.educationalYear += (index > 0 ? ", " : "") + (student.educationalYear || '');
      updatedFormData.dob += (index > 0 ? ", " : "") + (student.dob || '');
      updatedFormData.birthPlace += (index > 0 ? ", " : "") + (student.birthPlace || '');
      updatedFormData.dobInWords += (index > 0 ? ", " : "") + (student.dobInWords || '');
      updatedFormData.studentId += (index > 0 ? ", " : "") + (student.studentId || '');
      updatedFormData.stdSurname += (index > 0 ? ", " : "") + (student.stdSurname || '');
      updatedFormData.stdFather += (index > 0 ? ", " : "") + (student.stdFather || '');
      updatedFormData.stdAdharNo += (index > 0 ? ", " : "") + (student.stdAdharNo || '');
      updatedFormData.division += (index > 0 ? ", " : "") + (student.division || '');
      updatedFormData.stdPhoto += (index > 0 ? ", " : "") + (student.stdPhoto || '');

    });

    // Update form data state
    setFormData((prevData) => ({
      ...prevData,
      ...updatedFormData,
    }));
  } else {
    console.error('Invalid input: selectedStudents should be a non-empty array');
  }
};

  const handleWalletUpdate = async (updatedPoints) => {
    try {
      const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPoints),
      });
      if (!updateResponse.ok) {
        throw new Error('Failed to update wallet points');
      }
      setWalletPoints(updatedPoints);
    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  
    if (name === 'class') {
      setSelectedClass(value);
      setDuplicateStudents([]);
      setShowDuplicatePopup(false);
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        let data = await response.json();
        console.log("data:", data);
  
        // Filter out null values
        data = Object.values(data).filter(student => student !== null);
  
        const matchingStudents = [];
        data.forEach((student) => {
          if (student.currentClass === value) {
            matchingStudents.push(student);
          }
        });
        
        if (matchingStudents.length > 0) {
          setDuplicateStudents(matchingStudents);
          setShowDuplicatePopup(true);
        } else {
          console.log('No matching students found');
          setFormData((prevData) => ({
            ...prevData,
            stdName: '',
            stdMother: '',
            religion: '',
            class: '',
            educationalYear: '',
            dob: '',
            birthPlace: '',
            dobInWords: '',
            studentId: '',
            stdSurname: '',
            stdFather: '',
            stdAdharNo: '',
            division: '',
            stdPhoto: '',
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const pointsToDeduct = selectedStudents.length; // 1 point per student
    const updatedPoints = walletPoints - pointsToDeduct;
    
    if (updatedPoints < 0) {
      setAlertMessage('Insufficient points! Please recharge your wallet.');
      return;
    }
  
    await handleWalletUpdate(updatedPoints);
    setSubmitted(true);
  };

  const handleReset = () => {
    setFormData({
      stdName: '',
      stdMother: '',
      religion: '',
      class: '',
      educationalYear: '',
      dob: '',
      birthPlace: '',
      registerno: '',
      reason: '',
      selectedBookNo: '',
      division: '',
      stdPhoto: '',
    });
    setSubmitted(false);
    window.location.reload();
  };

  const DuplicateStudentPopup = ({ duplicateStudents, onSelectStudent }) => {
    const [selectedStudents, setSelectedStudents] = useState([]);
  
    const handleCheckboxChange = (student) => {
      const updatedSelectedStudents = selectedStudents.includes(student)
        ? selectedStudents.filter((s) => s !== student)
        : [...selectedStudents, student];
  
      setSelectedStudents(updatedSelectedStudents);
    };
  
    const handleSelectAllChange = (event) => {
      if (event.target.checked) {
        setSelectedStudents(duplicateStudents);
      } else {
        setSelectedStudents([]);
      }
    };
  
    const handleConfirmSelection = () => {
      if (selectedStudents.length > 0) {
        onSelectStudent(selectedStudents);
      }
      console.log('Confirm button clicked');
      setShowDuplicatePopup(false); // Close the popup
    };
    const isAllSelected = selectedStudents.length === duplicateStudents.length;
    return (
      <div className="popup">
        <h3>Select Students</h3>
        <label>
          <input
            type="checkbox"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          Select All
        </label>
        <ul className="student-list">
          {duplicateStudents.map((student, index) => (
            <li key={index} className="student-item">
              <label>
                <input
                  type="checkbox"
                  checked={selectedStudents.includes(student)}
                  onChange={() => handleCheckboxChange(student)}
                />
                {student.stdName}
              </label>
            </li>
          ))}
        </ul>
        <button className="confirm-button" onClick={handleConfirmSelection}>Confirm</button>
        <style jsx>{`
          .popup {
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 20px;
            width: 80%;
            max-width: 500px;
            margin: 20px auto;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease-in-out;
          }
  
          h3 {
            font-size: 24px;
            margin-bottom: 10px;
            text-align: center;
          }
  
          .student-list {
            max-height: 200px;
            overflow-y: auto;
            margin-bottom: 10px;
            transition: max-height 0.3s ease;
          }
  
          .student-item {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            transition: background-color 0.2s ease, transform 0.2s ease;
          }
  
          .student-item:hover {
            background-color: #f9f9f9;
            transform: translateX(5px);
          }
  
          .confirm-button {
            display: block;
            width: 100%;
            padding: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
  
          .confirm-button:hover {
            background-color: #0056b3;
          }
  
          @media (max-width: 600px) {
            .popup {
              width: 90%;
              padding: 10px;
            }
  
            h3 {
              font-size: 18px;
            }
  
            .student-list {
              max-height: 150px;
            }
  
            .student-item {
              padding: 8px;
            }
  
            .confirm-button {
              padding: 8px;
            }
          }
        `}</style>
      </div>
    );
  };
return (
<div className="home">
  <Sidebar />
    
 <AlertMessage message={alertMessage} show={showAlert}/>
      {/* Your existing form and other UI */}
      {showDuplicatePopup && (
        <div className="popup-container">
          <div className="popup">
            <DuplicateStudentPopup
              duplicateStudents={duplicateStudents}
              onSelectStudent={handleSelectStudent}
            />
          </div>
        </div>
      )}

  <div className="id-container" style={{ float:'left', left:'0'}}>
    <h2 className="title">{language === "English" ? "Identity Card" : "ओळख पत्र"}</h2>
    <div className="form-output">
      <form onSubmit={handleSubmit}>  
      <br/>
       <div>
              <label>{language === "English" ? "Class" : "वर्ग"}</label>
              <select style={{ width: '400px',}} name="class" value={formData.class} onChange={handleChange}>
                <option value="">{language === "English" ? "Select Class" : "वर्ग निवडा"}</option>
                {classes
    .sort((a, b) => {
      const classA = a.split(' ')[1];
      const classB = b.split(' ')[1];
      let numA, numB;

      if (language === "English") {
        numA = romanToInt[classA] !== undefined ? romanToInt[classA] : 0; // Default to 0 if not found
        numB = romanToInt[classB] !== undefined ? romanToInt[classB] : 0; // Default to 0 if not found
      } else {
        numA = marathiToInt[classA] !== undefined ? marathiToInt[classA] : 0; // Default to 0 if not found
        numB = marathiToInt[classB] !== undefined ? marathiToInt[classB] : 0; // Default to 0 if not found
      }

      return numA - numB; // Sort based on the numerical value
    })
    .map((cls, index) => (
      <option key={index} value={cls}>
        {cls}
      </option>
    ))}
              </select>
       </div>
    
        <br/>
        <div className="form-buttons">
          <button className="reset-btn" type="button" onClick={handleReset} style={{ marginLeft: '30px', marginTop:'0px' }}>{language === "English" ? "Reset" : "रीसेट करा"}</button>
          
        </div>
        <div className="wallet-points">
                {language === "English" ? " Wallet Points For Each Identity Card  = 1" : "प्रत्येक ओळखपत्रासाठी वॉलेट पॉइंट्स = 1"}
              </div>
        <div>
       
      </div>

      </form>       
    </div>  
  </div>
  <div style={{width:'70%'}}>
      <IdDoc 
        handleSubmit={handleSubmit}
        submitted={submitted}
        handleSelectStudent={handleSelectStudent}
        formData={formData}
        selectedStudents={selectedStudents}
      />
   </div>
    </div>
  );
};

export default IdCard;
