import { React, useState } from 'react';

function Downloadexe() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');
  const [showThankYou, setShowThankYou] = useState(false);

  const handleDownloadClick = () => {
    setShowThankYou(true);
    setTimeout(() => {
      setShowThankYou(false);
    }, 6000);
  };

  return (
    <div className='container'>
       <p>{language === "English" ? "To use e-guruji software you need to download the following exe file." : "ई-गुरुजी सॉफ्टवेअर वापरण्यासाठी तुम्हाला खालील exe फाईल डाउनलोड करावी लागेल."}</p>
      <h1 className="heading pt-2">{language === 'English' ? 'Download Your File' : 'तुमची फाईल डाउनलोड करा'}</h1>
      <div className="button-container">
        <a href="path_to_exe_file" className="btn btn-exe" download onClick={handleDownloadClick}>
          Download EXE <i className="fa-solid fa-download"></i>
        </a>
      </div>

      {showThankYou && (
        <div className="thank-you-message">
          <h2>Thank you for downloading! 🙏</h2>
        </div>
      )}


      <style jsx>{`
        body {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #f0f0f0;
        }

        .container {
          padding: 4rem;
          text-align: center;
          background: #f9f9f9;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-top: 0;
        }

        .heading {
          margin-bottom: 5rem;
          color: #333;
          font-size: 2rem;
          font-weight: bold;
        }

        .button-container {
          display: flex;
          justify-content: center;
          gap: 10rem;
        }

        .btn {
          display: inline-block;
          padding: 1rem 1rem;
          font-size: 1.3rem;
          font-weight: bold;
          color: #fff;
          text-decoration: none;
          border-radius: 4px;
          transition: transform 0.3s, box-shadow 0.3s;
        }

        .btn-apk {
          background: #007bff;
          border: none;
        }

        .btn-apk:hover {
          background: #0056b3;
          transform: scale(1.05);
          color: white;
        }

        .btn-exe {
          background: green;
          border: none;
        }

        .btn-exe:hover {
          background: #009E60;
          transform: scale(1.05);
          color: white;
        }

        .btn:active {
          transform: scale(0.95);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        .thank-you-message {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 10vh;
          text-align: center;
        }

        .thank-you-message h2 {
          font-size: 1.5rem;
          color: #4caf50;
          animation: fadeIn 1s ease-in-out;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
}

export default Downloadexe;
