
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import printJS from 'print-js'; // If using npm
   import moment from 'moment';
import AlertMessage from '../../AlertMessage';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeRzK6wzqfH5zO7icoCKwkaE7Du998dKw",
  authDomain: "digitalschool-6ba79.firebaseapp.com",
  databaseURL: "https://digitalschool-6ba79-default-rtdb.firebaseio.com",
  projectId: "digitalschool-6ba79",
  storageBucket: "digitalschool-6ba79.appspot.com",
  messagingSenderId: "407448102746",
  appId: "1:407448102746:web:22ed42062e3f32aa3cb563",
  measurementId: "G-ZV6YZNL451",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function Leavdoc({ submitted, formData }) {
  const [schoolData, setSchoolData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');
  const [logoURL, setLogoURL] = useState('');
  const [loading, setLoading] = useState(true);
  const [walletPoints, setWalletPoints] = useState(0);
  const [udiseNumber, setUdiseNumber]= useState('');


  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);
  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      fetchSchoolData(schoolUdiseNumber);
    }
  }, []);
  useEffect(()=>{
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if(schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber)
    }
  }, [])

  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        console.log("response11",response);
        const data = await response.json();
        console.log("Wallet points data 11:", data);
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]);
  const handleWalletUpdate = async (updatedPoints) => {
    try {
      const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPoints),
      });
      if (!updateResponse.ok) {
        throw new Error('Failed to update wallet points');
      }
      console.log("Updated wallet points in state:", updatedPoints);
      setWalletPoints(updatedPoints);
    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };



  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize(); // Check initial screen size
    window.addEventListener('resize', handleResize); // Add event listener for window resize
    return () => window.removeEventListener('resize', handleResize); // Clean up event listener
  }, []);

  const fetchSchoolData = async (udiseNumber) => {
    try {
      console.log('Fetching school data...');
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('School data:', data);
      setSchoolData(data);
      if (data && data.schoolLogo) {
        const storageRef = firebase.storage().refFromURL(data.schoolLogo);
        const url = await storageRef.getDownloadURL();
        console.log('Logo URL:', url);
        setLogoURL(url);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setLoading(false);
    }
  };

  const renderContent = () => {
  if (!schoolData || !formData) return null;
  console.log('Rendering content...');
  console.log('Logo URL in renderContent:', logoURL);


  return (
    <>
    <div
    style={{
      marginBottom:'300px ',
      position: 'absolute',
      top: '0.80in',
      bottom:'0in',
      right: '0.35in', // Aligns the right edge of the box with the right edge of the page
      width: '7.6in',  // 8.5in - 0.4in (0.2in on each side)
      height: '11in', // 11in - 0.4in (0.2in on each side)
      fontFamily: "'NotoSansDevanagari', sans-serif",
      border: '0.03in solid black',
      boxSizing: 'border-box',
      padding: '0.2in', // Add padding to create margin inside the border
      // Responsive adjustments
      '@media (max-width: 1200px)': {  // For screens narrower than 1200px
        right: '0in',
        width: '8in',
        height: '11in',
      },
      '@media (max-width: 992px)': {  // For screens narrower than 992px
        right: '0in',
        width: '8.5in',
        height: '11.5in',
      },
      '@media (max-width: 768px)': {  // For screens narrower than 768px
        top: '0.2in',
        right: '0in',
        width: '100%',
        height: 'auto',
        padding: '0.1in',
      },
    }}
  >

      <div
  style={{
    position: 'absolute',
    top: '0.2in',
    left: '0in',
    right: '0in',
    textAlign: 'center',
  }}
>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {schoolData.sansthaName && (
    <div
      style={{
        fontSize: '15px',
        fontWeight: 'bold',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginBottom: '-10px', // add this
      }}
    >
      {schoolData.sansthaName}
    </div>
  )}
  <div
    style={{
      fontSize: '26px',
      fontWeight: 'bold',
      wordWrap: 'break-word',
      textAlign: 'center',
      marginTop: schoolData.sansthaName ? '20px' : '0px',
    }}
  >
    {schoolData.schoolName}
  </div>
  <div
        style={{
          position: 'absolute',
          top: '0.5in',
          left: '15%',
          right: '15%',
          boxSizing: 'border-box',
          padding: '0',
        }}
      >
        <div
          style={{
            margin: '0 auto',
            width: '100%',
            maxWidth: 'calc(100% - 0.6in)',
            boxSizing: 'border-box',
            textAlign: 'center',
          }}
        >
        <p  style={{
           
           fontSize: '18px',
           fontWeight: 'bold',
           wordWrap: 'break-word',
           display: 'inline-block',
         }}>

        </p>
        <p style={{ fontSize: '14px', margin: '2px 0', padding: '0' }}>
  {language === 'English' ? (
    <>
    <strong>Taluka: </strong>{schoolData.taluka},<strong> District:</strong> {schoolData.jilha}
    </>
  ) : (
    <>
    <strong> तालुका: </strong>{schoolData.taluka}, <strong>जिल्हा:</strong> {schoolData.jilha}
    </>
  )}
</p>

<p style={{ fontSize: '14px', margin: '2px 0', padding: '0' }}>
  {language === 'English' ? (
    <>
      {/* <strong>UDISE No:</strong> {schoolData.udiseNumber}, <strong>Board:</strong> {schoolData.boardName} */}
      <strong style={{marginLeft:'-87px'}} >UDISE No:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
    </>
  ) : (
    <>
      <strong >UDISE क्र:</strong> {schoolData.udiseNumber}, <strong>बोर्ड:</strong> {schoolData.boardName}
    </>
  )}
</p>

<p style={{ fontSize: '14px', margin: '2px 0', padding: '0' }}>
  {language === 'English' ? (
    <>
      {/* <strong>Phone No:</strong> {schoolData.mobileNumber}, <strong>Email:</strong> {schoolData.email} */}
      <strong className='me-5 pe-5'>Phone No:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>  <strong  className='me-5 pe-4 ms-1'>Email:</strong> {schoolData.email}
    </>
  ) : (
    <>
      <strong>दूरध्वनी क्र:</strong> {schoolData.mobileNumber}, <strong>ई-मेल:</strong> {schoolData.email}
    </>
  )}
</p>



        </div>
      </div>
</div>
  <div
    style={{
      marginTop: '1.4in',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    }}
  >
    {logoURL && (
      <img
        src={logoURL}
        alt="School Logo"
        style={{ position: 'absolute',
    top: '0.55in',
    left: '0.3in', width: '100px', height: '100px', marginRight: '10px' }}
      />
    )}
   
  </div>
 
</div>

      <div
  style={{
    position: 'absolute',
    top: '1.65in',
    left: '2.6in',
    width: '2.5in',
    height: '0.35in',
    textAlign: 'center',
    border: '0.025in solid black',
    margin: '10px 0',
    backgroundColor: 'black', // add this
    color: 'white', // add this
    borderRadius: '0.1in', // add this

  }}
>
  <h3 style={{ color: 'white', fontSize: '16px', margin: '5px 0', fontWeight: 'bold' }}></h3>
  <p>
    {language === "English"
      ? "School Leaving Certificate"
      : "शाळा सोडल्याचे प्रमाणपत्र"}
  </p>
</div>

<p style={{ position: 'absolute', top: '1.75in', left: '5.6in', fontSize: '13px'}}>
  {language === "English" ? (
    <>
      <strong>Book No:</strong> {formData.bookno}
    </>
  ) : (
    <>
      <strong>बूक क्रं:</strong> {formData.bookno}
    </>
  )}
</p>

<p style={{ position: 'absolute', top: '1.95in', left: '5.6in', fontSize: '13px' }}>
  {language === "English" ? (
    <>
      <strong>Medium:</strong> {schoolData.madhyam}
    </>
  ) : (
    <>
      <strong>माध्यम:</strong> {schoolData.madhyam}
    </>
  )}
</p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '2.7in', left: '0in', right: '0in' }}></div>
      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '2.2in', left: '0in', right: '0in' }}></div>
      <div style={{ borderLeft: '0.02in solid black',position: 'absolute',top: '3.5in',left: '3.38in',height: '5.9in', }}></div>

      {/* <p style={{ position: 'absolute', top: '1.9in', left: '5.8in', fontSize: '12px' }}>
        Document No.: {formData.documentNo}
      </p> */}

      <p style={{ position: 'absolute', top: '2.3in', left: '0.8in', fontSize: '12px', }}>
      {language === "English"
              ? "  No changes are to be made in any entries, only issuance of leaving certificate by the authorized officer."
              : "या दाखल्यात शाळेच्या मुख्याध्यापकाशिवाय कोणत्याही प्रकारचा फेरबदल करू नये .तसे केल्यास तो गंभीर प्रकारचा गुन्हा"}</p>
    
      <p style={{ position: 'absolute', top: '2.5in', left: '2.6in', fontSize: '12px' }}>
      {language === "English"
              ? "  Teaching certificate with education will be taken."
              : "समजण्यात येऊन अपराधी शिक्षेस पत्र होईल."}</p> 
    
<p style={{ position: 'absolute', top: '2.8in', left: '1in', fontSize: '13px' }}>
  {language === "English" ? (
    <>
      <strong>Register No:</strong> {formData.registerno}
    </>
  ) : (
    <>
      <strong>विद्यार्थ्याची रजिस्टर क्र:</strong> {formData.registerno}
    </>
  )}
</p>

<p style={{ position: 'absolute', top: '3.1in', left: '1in', fontSize: '13px' }}>
  {language === "English" ? (
    <>
      <strong>Student ID:</strong> {formData.studentId}
    </>
  ) : (
    <>
      <strong>स्टुडंट आयडी:</strong> {formData.studentId}
    </>
  )}
</p>

<p style={{ position: 'absolute', top: '2.8in', left: '5.2in', fontSize: '13px' }}>
  {language === "English" ? (
    <>
      <strong>Educational Year:</strong> {formData.educationalYear}
    </>
  ) : (
    <>
      <strong>शैक्षणिक वर्ष:</strong> {formData.educationalYear}
    </>
  )}
</p>

<p style={{ position: 'absolute', top: '3.1in', left: '5.2in', fontSize: '13px' }}>
  {language === "English" ? (
    <>
      <strong>Aadhar Card No:</strong> {formData.stdAdharNo}
    </>
  ) : (
    <>
      <strong>विद्यार्थी आधार क्र:</strong> {formData.stdAdharNo}
    </>
  )}
</p>



      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '3.5in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '3.6in', left: '0.5in' }}>
        <b>{language === "English"
              ? " Full Name of Student"
              : "विद्यार्थ्याचे संपूर्ण नाव"}</b>
      </p>
      <p style={{ position: 'absolute', top: '3.6in', left: '3.5in' }}>
        {formData.stdName} {formData.stdFather} {formData.stdSurname}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '3.9in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '4in', left: '0.5in' }}>
       <b> {language === "English"
              ? "  Mother's Name"
              : "आईचे नाव"}</b>
      </p>
      <p style={{ position: 'absolute', top: '4in', left: '3.5in'}}>
        {formData.stdMother}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '4.3in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '4.4in', left: '0.5in' }}>
         <b>{language === "English"
              ? "  Class & Division"
              : "धर्म "}</b> 
      </p>
      <p style={{ position: 'absolute', top: '4.4in', left: '3.5in'}}>
      {formData.religion}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '4.7in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '4.8in', left: '0.5in' }}>
      <b> {language === "English"
              ? "Religion and Caste "
              : "जात-पोटजात"}</b> 
      </p>
      <p style={{ position: 'absolute', top: '4.8in', left: '3.5in'}}>
       {/* {formData.caste}-{formData.subCaste} */}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '5.1in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '5.2in', left: '0.5in' }}>
      <b>{language === "English"
              ? " Nationality  "
              : "राष्ट्रीयत्व"}</b> 
      </p>
      <p style={{ position: 'absolute', top: '5.2in', left: '3.5in' }}>
      {formData.nationality}
      </p>

   

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '5.5in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '5.55in', left: '0.5in' }}>
       <b> {language === "English"
              ? " Date of Birth "
              : "इ. सनप्रमाणे जन्म दिनांक"}</b>
      </p>
      <p style={{ position: 'absolute', top: '5.8in', left: '0.55in' }}>
      <b>{language === "English"
              ? "(Numerical and in words) "
              : "(अंकी व अक्षरी)"}</b>
      </p>
      <p style={{ position: 'absolute', top: '5.55in', left: '3.5in' }}>
      {/* {moment(formData.dob).format('DD-MM-YYYY')} </p> */}
       </p>
   
      <p style={{ position: 'absolute', top: '5.8in', left: '3.5in' }}>
        {formData.dobInWords}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '6.1in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '6.2in', left: '0.5in' }}>
      <b>{language === "English"
              ? "Place of Birth  "
              : "जन्म स्थळ"}</b>
      </p>
      <p style={{ position: 'absolute', top: '6.2in', left: '3.5in'}}>
        {formData.birthPlace}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '6.5in', left: '0in', right: '0in' }}></div>

    
      <p style={{ position: 'absolute', top: '6.56in', left: '0.5in', fontSize:'0.99em' }}>
      <b> {language === "English"
              ? "Previous School Name "
              : "या शाळेत येण्यापूर्वी शिकत असलेली शाळा"}</b>
      </p>
      <p style={{ position: 'absolute', top: '6.74in', left: '0.5in', fontSize:'0.99em' }}>
      <b> {language === "English"
              ? "and Class"
              : "व इय्यता"}</b>
      </p>
      <p style={{ position: 'absolute', top: '6.56in', left: '3.5in', fontSize:'0.8em'}}>
        {formData.prevSchool}
      </p> 
      <p style={{ position: 'absolute', top: '6.74in', left: '3.5in', fontSize:'0.8em'}}>
        {formData.admissionClass}
      </p> 
      {/**/}


      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '7in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '7.1in', left: '0.5in' }}>
      <b> {language === "English"
              ? "    Last School Day   "
              : "शाळा सोडल्याची तारीख(माहे सन)"}</b>
      </p>
      {/* <p style={{ position: 'absolute', top: '7.1in', left: '3.5in' }}>
        {formData.date}
      </p> */}

      <p style={{ position: 'absolute', top: '7.1in', left: '3.5in' }}>
        
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '7.4in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '7.5in', left: '0.5in' }}>
      <b>{language === "English"
              ? "Student's Progress"
              : "अभ्यासातील प्रगती"}</b>
      </p>
      <p style={{ position: 'absolute', top: '7.5in', left: '3.5in' }}>
        {/* {formData.progress} */}
      </p>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '7.8in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '7.9in', left: '0.5in' }}>
      <b> {language === "English"
              ? "Student's Conduct "
              : "शाळेतील वर्तणूक"}</b>
      </p>
      <p style={{ position: 'absolute', top: '7.9in', left: '3.5in' }}>
        {/* {formData.conduct} */}
      </p>


      <p style={{ position: 'absolute', top: '8.3in', left: '0.5in' }}>
      <b>{language === "English"
              ? "class "
              : "कोणत्या इयत्तेत शिकत होता व केव्हा पासून"}</b>
      </p>
      <p style={{ position: 'absolute', top: '8.3in', left: '3.5in' }}>
        {/* {formData.class} */}
      </p>


      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '8.2in', left: '0in', right: '0in' }}></div>
      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '9.4in', left: '0in', right: '0in' }}></div>

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '8.6in', left: '0in', right: '0in' }}></div>

      <p style={{ position: 'absolute', top: '9.1in', left: '0.5in' }}>
      <b>{language === "English"
              ? " Remarks "
              : "शेरा"}</b>
      </p>
      <p style={{ position: 'absolute', top: '9.1in', left: '3.5in' }}>
      {/* {formData.remark} */}
      </p>
      <p style={{ position: 'absolute', top: '8.7in', left: '0.5in' }}>
      <b>{language === "English"
              ? "Reason for Leaving"
              : "शाळा सोडल्याचे कारण"}</b>
      </p>
      <p style={{ position: 'absolute', top: '8.7in', left: '3.5in' }}>
        {/* {formData.reason} */}
      </p> 

      <div style={{ borderTop: '0.02in solid black', position: 'absolute', top: '9in', left: '0in', right: '0in' }}></div>
      <p style={{ position: 'absolute', top: '9.48in', left: '0.5in' }}>
      {language === "English"
              ? "  This is to certify that the above information is as per the records of the institution."
              : "दाखला देण्यात येतो की, वरील माहिती शाळेतील जनरल रजिस्टर नंबर १ प्रमाणे आहे."}
      </p>
      <p style={{ position: 'absolute', top: '10.6in', left: '0.3in' }}>
        {/* Date: {formData.printdate} */}
        Date: 
      </p>
      <p style={{ position: 'absolute', top: '10.6in', right: '0.6in' }}>
       {language === "English"
              ? "HeadMaster's Signature"
              : "मुख्याध्यापक/ प्राचार्य सही"}
      </p>

      <p style={{ position: 'absolute', top: '10.6in', right: '3in' }}>
       {language === "English"
              ? "Class Teacher Signature"
              : "लिपिक/ वर्गशिक्षक सही"}
      </p>
    </div>
    
    <div style={{height:'200px'}}>
    </div>
    </>
  );
};

const printCertificate = async () => {
  const updatedPoints = walletPoints - 2;
  if (updatedPoints < 0) {
    setAlertMessage('Insufficient points! Please recharge your wallet.');
    return;
  }
  await handleWalletUpdate(updatedPoints);

  const printContent = document.getElementById("print-content");
  if (!printContent) {
    setAlertMessage("Print content not found.");
    return;
  }

  // Clone the print content and apply styles
  const clone = printContent.cloneNode(true);
  clone.style.padding = "50px 381px";
  clone.style.transform = "translate(0, -70px) scale(1.08)";
  
  // Apply specific inline styles to fix alignment issues
  clone.querySelectorAll('p').forEach(p => {
    p.style.margin = "0";
    p.style.padding = "0";
    p.style.lineHeight = "1.4";
  });

  // Create a temporary container for the clone
  const printContainer = document.createElement("div");
  printContainer.appendChild(clone);

  // Use printJS to print the content
  printJS({
    printable: printContainer.innerHTML,
    type: 'raw-html',
    style: `
      @media print {
        body { margin: 0; }
        p { margin: 0; padding: 0; line-height: 1.4; }
        /* Add more specific styles to ensure correct layout */
      }
    `
  });
};


return (
  <>
    <AlertMessage message={alertMessage} show={showAlert}/>
  <div
  style={{
    display: "flex",
    justifyContent: "flex-end",
    padding: "1em",
  }}
>
  <div id="print-content">
    {loading ? <p>Loading...</p> : renderContent()}
  </div>
  <div style={{ marginTop: "1em" }}>
  <button
    onClick={printCertificate}
    style={{
      position: "absolute",
      top: "54.5em",
      left: "26.7em",
    }}
  >
    Print
  </button>

  </div>
</div>
</>
);
}

export default Leavdoc;