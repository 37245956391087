
import { fontSize } from '@mui/system';
import React, { useState } from 'react';

const Receipt1 = ({
  isDebitSelected,
  selectedPavtiNos,
  pavtiDataArray,
  sansthaName,
  schoolName,
  taluka,
  jilha,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );


  if (!isDebitSelected || !selectedPavtiNos || selectedPavtiNos.length === 0) {
    return <p> {language === "English" ? "Select For Pavti Numbers  " : "पावती क्रमांक निवडा "} </p>;
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const printReceipt = () => {
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    const receiptContent = pavtiDataArray.map((pavtiData, index) => `
      <div class="receipt" style="border: 1px solid #000; padding: 10px; margin: 20px 0; width: 800px; background-color: white; page-break-inside: avoid;">
      <div id="voucher" style="background-color: #E8E8E8; padding: 10px; border-radius: 5px;">
  <div class="header" style="text-align: center; margin-bottom: 10px;">
    <h3 class="centerText">${sansthaName}</h3>
    <h4 class="centerText">${schoolName}</h4>
    <p class="centerText">Taluka: ${taluka}, Dist: ${jilha}</p>
  </div>
</div>

        <table class="table" style="width: 100%; border-collapse: collapse;">
          <tbody>
            <tr>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;"><strong>Pavti No:</strong></td>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;">${pavtiData.pavtiNo}</td>
            </tr>
            <tr>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;"><strong>Date:</strong></td>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;">${pavtiData.date}</td>
            </tr>
            <tr>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;"><strong>Aaj Mitis Shree:</strong></td>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;">${pavtiData.khatePrakar}</td>
            </tr>
            <tr>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;"><strong>Yanchyakdun Rakkam:</strong></td>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;">Rs. ${pavtiData.Amount} (IN WORDS - Rs. ${pavtiData.Amount} ONLY)</td>
            </tr>
            <tr>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;"><strong>Amount:</strong></td>
              <td class="tableCell" style="border: 1px solid #ddd; padding: 8px;">Rs. ${parseFloat(pavtiData.Amount).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
        <div class="signatureSection" style="display: flex; justify-content: space-between; margin-top: 20px;">
          <div class="signatureBox" style="width: 40%; border: 1px solid #000; padding: 5px; text-align: center;">
            <p>Signature</p>
          </div>
          <div class="signatureBox" style="width: 40%; border: 1px solid #000; padding: 5px; text-align: center;">
            <p>Accountant</p>
          </div>
        </div>
      </div>
    `).join('');
    
    printWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            .receiptContainer {
              border: 1px solid #000;
              padding: 10px;
              margin: 20px 0;
              width: 800px;
              background-color: white;
            }
            .header {
              text-align: center;
              margin-bottom: 10px;
            }
            .centerText {
              text-align: center;
            }
            .table {
              width: 100%;
              border-collapse: collapse;
            }
            .tableCell {
              border: 1px solid #ddd;
              padding: 8px;
            }
            .signatureSection {
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
            }
            .signatureBox {
              width: 40%;
              border: 1px solid #000;
              padding: 5px;
              text-align: center;
            }
            .printButton {
              background-color: #87CEFA;
              color: white;
              padding: 10px 20px;
              font-size: 16px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              transition: background-color 0.3s ease;
              margin-top: 20px;
            }
            .modalOverlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: white;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .modalContent {
              background-color: white;
              padding: 20px;
              border-radius: 5px;
              max-width: 100%;
              max-height: 100%;
              background-color: white;
            }
            .closeButton {
              background-color: red;
              color: white;
              border: none;
              padding: 10px;
              border-radius: 50%;
              cursor: pointer;
              float: right;
              font-size: 16px;
            }
              
            .viewButton {
              background-color: #87CEFA;
              color: white;
              padding: 10px 20px;
              font-size: 16px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              transition: background-color 0.3s ease;
              margin-bottom: 20px;
            }
            .receipt {
              page-break-inside: avoid;
            }
          </style>
        </head>
        <body>
          ${receiptContent}
          <script>
            window.print();
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };
  

  return (
    <div>
      {/* View Receipt Button */}
      <button
        onClick={openModal}
        style={styles.viewButton}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#6CA6CD')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#87CEFA')}
      >
        View Receipt
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
             <button
              onClick={printReceipt}
              style={styles.printButton}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#6CA6CD')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#87CEFA')}
            >
              Print Voucher
            </button>
            <button style={styles.closeButton} onClick={closeModal}>
              X
            </button>

            {/* Receipt Content */}
            {pavtiDataArray.map((pavtiData, index) => (
              
              <div className="receipt" key={index} style={styles.receiptContainer}>
              <div style={{ backgroundColor: '		#E8E8E8', padding: '10px', borderRadius: '5px' }}>
  <div style={styles.header}>
    <h6 style={styles.centerText}>{sansthaName}</h6>
    <h5 style={styles.centerText}>{schoolName}</h5>
    <p style={styles.centerText}>
      {language === "English" ? "Taluka: " : "तालुका: "} {taluka}, 
      {language === "English" ? " District: " : " जिल्हा: "} {jilha}
    </p>
  </div>
</div>


                <table style={styles.table}>
                  <tbody>
                    <tr>
                      <td style={styles.tableCell}><strong> {language === "English" ? "Voucher No. " : "व्हा. नं. "} </strong></td>
                      <td style={styles.tableCell}>{pavtiData.pavtiNo}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCell}><strong>{language === "English" ? "Date " : "दिनांक "}</strong></td>
                      <td style={styles.tableCell}>{pavtiData.date}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCell}><strong>{language === "English" ? "Account Type " : "खाते प्रकार "}</strong></td>
                      <td style={styles.tableCell}>{pavtiData.khatePrakar}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCell}><strong>{language === "English" ? "Amount in Words: " : "रक्कम अक्षरी "}</strong></td>
                      <td style={styles.tableCell}>Rs. {pavtiData.Amount} (IN WORDS - Rs. {pavtiData.Amount} ONLY)</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCell}><strong>{language === "English" ? "Amount  " : "रक्कम  "}</strong></td>
                      <td style={styles.tableCell}>Rs. {parseFloat(pavtiData.Amount).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>

                <div style={styles.signatureSection}>
                  <div style={styles.signatureBox}>
                    <p> <b>{language === "English" ? "Sign " : "सही "}</b></p>
                  </div>
                  <div style={styles.signatureBox}>
                    <p><b>{language === "English" ? "Accountant " : "लेखापाल "}</b></p>
                  </div>
                </div>
              </div>
            ))}

           
          </div>
        </div>
      )}
    </div>
  );
};

// Styles for the component
const styles = {
  receiptContainer: {
    border: '1px solid #000',
    padding: '10px',
    margin: '20px 0',
    width: '800px',
    backgroundColor: 'white',

  },
  header: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  centerText: {
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '7px',
    fontSize:'15px'
  },
  signatureSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    textAlign: 'center',
  },
  signatureBox: {
    width: '30%',
    border: '1px solid #000',
    padding: '5px',
    textAlign: 'center',
  },
  printButton: {
    position: 'absolute',
    top: 3,
    right: 100,
    backgroundColor: '#87CEFA',
    color: 'white',
    padding: '5px 5px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  },
  modalOverlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'white',

  },
  closeButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    padding: '3px',
    borderRadius: '50%',
    cursor: 'pointer',
    float: 'right',
    right:'0',
    fontSize: '17px',
    marginTop: '13px',
    width:'28px',
    height:'28px',
    marginRight:'-20px'
  },
  viewButton: {
    backgroundColor: '#87CEFA',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
    marginBottom: '20px',
  },
};

export default Receipt1;