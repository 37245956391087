import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';


const Avaksatha = () => {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [vastuNames, setVastuNames] = useState([]);
  const [quantities, setQuantities] = useState({});
  const udiseNumber = localStorage.getItem("udiseNumber");
  const language = localStorage.getItem("language");
  const [dataFetched, setDataFetched] = useState(false); // New state variable
 
  const [alertMessage, setAlertMessage] = useState("");
const [showAlert, setShowAlert] = useState(false);
useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    const vastuNames = language === 'English' 
    ? ['Rice', 'Mugdal', 'Turdal', 'Masurdal', 'Matki', 'Moong', 'Cowpea', 'Gram', 'Pease', 'Cumin', 'Mustard', 'Turmeric', 'Chili', 'Oil', 'Salt', 'Garam Masala', 'Vegetables'] 
    : ['तांदूळ', 'मुगडाळ', 'तुरडाळ', 'मसुरडाळ', 'मटकी', 'मूग', 'चवळी', 'हरभरा', 'वाटाणा', 'जिरे', 'मोहरी', 'हळद', 'मिरची', 'तेल', 'मीठ', 'गरम मसाला', 'भाजी पाला'];
    setVastuNames(vastuNames);
  }, []);

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedClass) {
      const fetchData = async () => {
        const firebaseBaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha/${selectedClass}/${selectedYear}/${selectedMonth}.json`;
        try {
          const response = await fetch(firebaseBaseUrl);
          const data = await response.json();
          if (data && data.vastuDetails) {
            setQuantities(data.vastuDetails);
            setDataFetched(true); // Set dataFetched to true
          } else {
            setQuantities({});
            setDataFetched(false); // Reset dataFetched if no data
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          setQuantities({});
          setDataFetched(false); // Reset dataFetched on error
        }
      };
      fetchData();
    } else {
      setQuantities({});
      setDataFetched(false); // Reset dataFetched when selections are empty
    }
  }, [selectedYear, selectedMonth, selectedClass, udiseNumber]);



  const handleYearChange = (e) => {
    console.log(e);
    
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleQuantityChange = (e, vastuName) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,  
      [vastuName]: e.target.value,
    }));
  };

  const handleSaveData = () => {
    // Check if any quantity is greater than 0
    const hasInvalidQuantities = Object.values(quantities).some(value => Number(value) > 0);
  
    if (!hasInvalidQuantities) {
      setAlertMessage('Please enter valid quantities greater than 0 for at least one item.');
      return; // Prevent saving if no valid quantities
    }
  
    const firebaseBaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha/${selectedClass}/${selectedYear}/${selectedMonth}.json`;
    const dataToSave = {
      vastuDetails: quantities,
    };
  
    fetch(firebaseBaseUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        setAlertMessage('Data saved successfully:');
        // Deselect the selected class after saving
        setSelectedClass(''); // Reset selectedClass to deselect
      })
      .catch((error) => setAlertMessage('Error saving data:', error));
  };

const handleDeleteData = () => {
  // Check if a specific year, month, and class are selected
  if (!selectedYear || !selectedMonth || !selectedClass) {
    console.log('Please select Year, Month, and Class');
    return; // Skip delete operation if any selection is missing
  }

  const firebaseBaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha/${selectedClass}/${selectedYear}/${selectedMonth}.json`;

  fetch(firebaseBaseUrl, {
    method: 'DELETE',
  })
    .then((response) => {
      if (response.ok) {
        setAlertMessage('Data deleted successfully');
        setQuantities({}); 
        setSelectedYear('')
        setSelectedMonth('')
        setSelectedClass('')
      } else {
        throw new Error('Failed to delete data');
      }
    })
    .catch((error) => console.error('Error deleting data:', error));
};

  return (
    <div>
    <Sidebar/>
    <AlertMessage message={alertMessage} show={showAlert}/>
    <div className="main-content-of-page mb-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

  <div style={{ width: '60%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', border: '1px solid gray', padding: '30px', borderRadius: '5px' }}>
  <h2  className='text-center mb-5' style={{ color:'#03365e', fontWeight:'600'}}>{language === "English" ? "Incoming entry  " : "आवक नोंद"}</h2>
     
    <form>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label style={{marginLeft:'-30px', paddingLeft:'20px',fontSize:'14px'}}> {language === "English" ? "Year " : "वर्ष "}: </label>
          <select value={selectedYear} onChange={handleYearChange} style={{ width: '130px', height:'35px', marginRight: '20px', fontSize:'14px', padding:'8px' }}>
            <option value="">{language === "English" ? "Select Year " : "वर्ष निवडा"}</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>

          <label style={{ marginLeft: '20px',fontSize:'14px' }}>  {language === "English" ? "Month " : "महिना"} : </label>
          <select value={selectedMonth} onChange={handleMonthChange} style={{ width: '130px', height:'35px', marginRight: '20px', fontSize:'14px', padding:'8px' }}>
          <option value="">{language === "English" ? "Select Month" : "महिना निवडा"}</option>
          <option value="January">{language === "English" ? "January" : "जानेवारी"}</option>
          <option value="February">{language === "English" ? "February" : "फेब्रुवारी"}</option>
          <option value="March">{language === "English" ? "March" : "मार्च"}</option>
          <option value="April">{language === "English" ? "April" : "एप्रिल"}</option>
          <option value="May">{language === "English" ? "May" : "मे"}</option>
          <option value="June">{language === "English" ? "June" : "जून"}</option>
          <option value="July">{language === "English" ? "July" : "जुलै"}</option>
          <option value="August">{language === "English" ? "August" : "ऑगस्ट"}</option>
          <option value="September">{language === "English" ? "September" : "सप्टेंबर"}</option>
          <option value="October">{language === "English" ? "October" : "ऑक्टोबर"}</option>
          <option value="November">{language === "English" ? "November" : "नोव्हेंबर"}</option>
          <option value="December">{language === "English" ? "December" : "डिसेंबर"}</option>
          </select>

          <label style={{ marginLeft: '20px',fontSize:'14px' }}>{language === "English" ? " Class " : "वर्ग "} : </label>
          <select value={selectedClass} onChange={handleClassChange}  style={{ width: '130px', height:'35px', marginLeft: '0px', fontSize:'14px', padding:'8px' }}>
          <option value="">{language === "English" ? "Select Class" : "वर्ग निवडा"}</option>
          <option value="1 To 5">{language === "English" ? "1 To 5" : "१ ते ५"}</option>
          <option value="6 To 8">{language === "English" ? "6 To 8" : "६ ते ८"}</option>
          </select>
        </div>
      </div>
    </form>
<hr/>
    <table style={{ width: '100%', margin: '30px 0', borderCollapse: 'collapse', marginTop:'50px', fontSize:'13px'}}>
      <thead>
        <tr >
          <th className='text-center fw-bold border border-dark'> {language === "English" ? " Items " : "साहित्य"}</th>
          <th className='text-center fw-bold  border border-dark'> {language === "English" ? " Quantity(1gram = 0.001 kilo)" : "प्रमाण(1 ग्रॅम = 0.001 किलो) "}</th>
          <th className='text-center fw-bold  border border-dark'> {language === "English" ? " Items " : "साहित्य"}</th>
          <th className='text-center fw-bold border border-dark'> {language === "English" ? " Quantity(1gram = 0.001 kilo)" : "प्रमाण(1 ग्रॅम = 0.001 किलो) "}</th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: Math.ceil(vastuNames.length / 2) }, (_, index) => (
          <tr key={index} >
            <td style={{ padding:'7px'}}>{vastuNames[index]}</td>
            <td style={{ padding:'7px'}}>
            <input type="number" value={quantities[vastuNames[index]] || ''} onChange={(e) => e.target.value.length <= 7 && handleQuantityChange(e, vastuNames[index])} style={{ width: '60px', padding: '0px', border: 'none', borderBottom: '1px solid gray', outline: 'none', textAlign: 'center' }}/>

            </td>
            <td style={{ padding:'7px'}}>{vastuNames[index + Math.ceil(vastuNames.length / 2)]}</td>
            <td style={{ padding:'7px'}}>
              {vastuNames[index + Math.ceil(vastuNames.length / 2)] && (
                <input
                  type="number"
                  value={quantities[vastuNames[index + Math.ceil(vastuNames.length / 2)]] || ''}
                  onChange={(e) =>  e.target.value.length <= 7 && handleQuantityChange(e, vastuNames[index + Math.ceil(vastuNames.length / 2)])}
                  style={{ width: '60px', padding: '0px',  border: 'none', borderBottom: '1px solid gray', outline: 'none', textAlign:'center'}}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    <div style={{display:'flex'}}>
    {dataFetched ? ( 
      <button
        onClick={handleSaveData} // You can rename this function to handleUpdateData if needed
        style={{
          display: 'block',
          margin: '0 10px',
          padding: '7px 14px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom:'10px'
        }}
      >
        Update 
      </button>
    ) : (
      <button
        onClick={handleSaveData}
        style={{
          display: 'block',
          margin: '0 10px',
          padding: '7px 14px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom:'10px'
        }}
      >
        Save 
      </button>
    )}

    {dataFetched && ( // Conditionally render the delete button
      <button
        onClick={handleDeleteData}
        style={{
          display: 'block',
          margin: '0 10px',
          padding: '7px 14px',
          backgroundColor: '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom: '10px'
        }}
      >
        Delete 
      </button>
    )}
</div>
  </div>
</div>

    </div>
  );
};

export default Avaksatha;