import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';


const language = localStorage.getItem("language");
const classOptions = [
  { value: '1 To 5', label: language === "English" ? '1 to 5' : '१ ते ५' },
  { value: '6 To 8', label: language === "English" ? '6 to 8' : '६ ते ८' },
];

const dayOptions = [
  { value: 'Monday', label: language === "English" ? 'Monday' : 'सोमवार' },
  { value: 'Tuesday', label: language === "English" ? 'Tuesday' : 'मंगळवार' },
  { value: 'Wednesday', label: language === "English" ? 'Wednesday' : 'बुधवार' },
  { value: 'Thursday', label: language === "English" ? 'Thursday' : 'गुरुवार' },
  { value: 'Friday', label: language === "English" ? 'Friday' : 'शुक्रवार' },
  { value: 'Saturday', label: language === "English" ? 'Saturday' : 'शनिवार' },
];
const getFoodItemLabel = (foodItem) => {
    const option = aaharMenuOptions.find(option => option.value === foodItem);
    return option ? option.label : foodItem; // Return the Marathi label or the original if not found
};

const aaharMenuOptions = [
  { value: 'Varan Bhat', label: language === "English" ? 'Varan Bhat' : 'वरण भात' },
  { value: 'Dal Bhat', label: language === "English" ? 'Dal Bhat' : 'डाळ भात' },
  { value: 'Usal Bhat', label: language === "English" ? 'Usal Bhat' : 'उसळ भात' },
  { value: 'Khichadi', label: language === "English" ? 'Khichadi' : 'खिचडी' },
  { value: 'Other', label: language === "English" ? 'Other' : 'इतर' },
];

const foodItems = [
  { name: language === "English" ? 'Rice' : 'तांदूळ' },
  { name: language === "English" ? 'Mugdal' : 'मुगडाळ' },
  { name: language === "English" ? 'Turdal' : 'तूरडाळ' },
  { name: language === "English" ? 'Masurdal' : 'मसूरडाळ' },
  { name: language === "English" ? 'Matki' : 'मटकी' },
  { name: language === "English" ? 'Moong' : 'मूग' },
  { name: language === "English" ? 'Cowpea' : 'चवळी' },
  { name: language === "English" ? 'Gram' : 'हरभरा' },
  { name: language === "English" ? 'Pease' : 'वाटाणा' },
  { name: language === "English" ? 'Mustard' : 'मोहरी' },
  { name: language === "English" ? 'Cumin' : 'जिरे' },
  { name: language === "English" ? 'Turmeric' : 'हळद' },
  { name: language === "English" ? 'Oil' : 'तेल' },
  { name: language === "English" ? 'Salt' : 'मीठ' },
  { name: language === "English" ? 'Garam Masala' : 'गरम मसाला' },
  { name: language === "English" ? 'Chili' : 'मिरची' },
  { name: language === "English" ? 'Vegetables' : 'भाजी पाला' },
];

const daysOfWeek = [
  { key: 'Monday', label: language === "English" ? "Monday" : "सोमवार" },
  { key: 'Tuesday', label: language === "English" ? "Tuesday" : "मंगळवार" },
  { key: 'Wednesday', label: language === "English" ? "Wednesday" : "बुधवार" },
  { key: 'Thursday', label: language === "English" ? "Thursday" : "गुरुवार" },
  { key: 'Friday', label: language === "English" ? "Friday" : "शुक्रवार" },
  { key: 'Saturday', label: language === "English" ? "Saturday" : "शनिवार" },
];
const DropdownExample = () => {
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedAaharMenu, setSelectedAaharMenu] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [isMenuSaved, setIsMenuSaved] = useState(false);
  const [isOtherOption, setIsOtherOption] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  
  const udiseNumber = localStorage.getItem("udiseNumber");
  const [schoolData, setSchoolData] = useState(null); 

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
      if (alertMessage) {
        setShowAlert(true);
        const timeoutId = setTimeout(() => {
          setShowAlert(false);
          setAlertMessage(""); 
        }, 3000);
        return () => clearTimeout(timeoutId);
      }
    }, [alertMessage]);

  useEffect(() => {
    if (selectedClass && selectedDay) {
        const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/menu/${selectedClass}/${selectedDay}.json`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    const savedMenuKey = Object.keys(data)[0];
                    if (savedMenuKey) {
                        setSelectedAaharMenu(savedMenuKey);
                        setSelectedFoodItems(Object.keys(data[savedMenuKey]));
                        setIsMenuSaved(true);

                        // Check if the saved menu is not in the predefined options
                        if (!aaharMenuOptions.some(option => option.value === savedMenuKey)) {
                          setSelectedAaharMenu('Other')
                          setIsOtherOption(true)
                          setInputValue(savedMenuKey); // Set the inputValue to the custom menu
                        } else {
                            setInputValue(''); // Reset if it's a predefined option
                        }
                    } else {
                        setSelectedAaharMenu('');
                        setSelectedFoodItems([]);
                        setIsMenuSaved(false);
                    }
                } else {
                    setSelectedAaharMenu('');
                    setSelectedFoodItems([]);
                    setIsMenuSaved(false);
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }
}, [selectedClass, selectedDay, udiseNumber]);
  const handleSaveOrUpdate = () => {
    if (selectedClass && selectedDay && selectedFoodItems.length > 0) {
        // Determine the menu to save
        const menuToSave = selectedAaharMenu === 'Other' ? inputValue : selectedAaharMenu;

        const dataToSave = {
            [menuToSave]: selectedFoodItems.reduce((acc, food) => {
                acc[food] = true;
                return acc;
            }, {}),
        };

        const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/menu/${selectedClass}/${selectedDay}.json`;

        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSave),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Data saved/updated successfully!', data);
            setIsMenuSaved(true);
            setAlertMessage('Data saved successfully!');
            window.location.reload();
        })
        .catch(error => {
            console.error('Error saving/updating data: ', error);
        });
    } else {
      setAlertMessage('Please select all fields and food items.');
    }
};

  
  const fetchSchoolData = () => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        setSchoolData(data); 
      })
      .catch(error => {
        console.error('Error fetching school data from Firebase:', error);
      });
  };

  const handleAaharMenuChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAaharMenu(selectedValue);
    setIsOtherOption(selectedValue === 'Other');
    
    // Reset inputValue if the user changes the menu back from 'Other'
    if (selectedValue !== 'Other') {
        setInputValue('');
    }
};
  const handleFoodItemChange = (foodName) => {
    setSelectedFoodItems((prevSelectedItems) =>
      prevSelectedItems.includes(foodName)
        ? prevSelectedItems.filter((item) => item !== foodName)
        : [...prevSelectedItems, foodName]
    );
  };
  useEffect(() => {
    fetchSchoolData(); 
  }, []);
  const handleReport = async() => {
    const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/menu/${selectedClass}.json`);
    console.log("response",response);
    
    const data = await response.json();
    console.log("data",data);
    setMenuData(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false); 
  };

  const handlePrint = () => {
    const printContent = document.querySelector('.modal-content');
  
    if (printContent) {
      const cloneContent = printContent.cloneNode(true);
  
      const schoolName = cloneContent.querySelector('p'); 
      const otherDetails = cloneContent.querySelectorAll('p'); 
  
      otherDetails.forEach((p, index) => {
        if (index !== 0) { 
          p.style.fontWeight = 'normal'; 
        }
      });
      
      if (schoolName) {
        schoolName.innerHTML = `<h5 style="font-size: 16px; margin-top: 10px;">${schoolData.schoolName}</h5>`; // Increase the font size and set top margin
      }
  
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Modal Content</title>
            <style>
              @page {
                size: A4 portrait;
                margin-left: 5mm; /* Set left margin */
                margin-right: 5mm; /* Set right margin */
                margin-top: 10mm; /* Optional: Set top margin */
                margin-bottom: 10mm; /* Optional: Set bottom margin */
              }
              body {
                font-family: Arial, sans-serif;
                margin: 0; /* Remove body margin */
              }
              .modal-content {
                margin: 20px; /* Add margin to the modal content */
              }
              thead {
                            background-color: #F0F0F0; /* Set very light gray background color for <thead> */

              }
            </style>
          </head>
          <body>
            ${cloneContent.outerHTML} <!-- Include the modified outer HTML of the modal content -->
          </body>
        </html>
      `);
      printWindow.document.close(); 
      printWindow.focus(); // Focus the new window
      printWindow.print(); // Trigger print
      printWindow.close(); // Close the print window after printing
    } else {
      console.error('Print content not found');
    }
  };
  const classOptions2 = [
    { 
        value: '1 To 5', 
        label: language === "English" ? <span>1<sup>st</sup> to 5<sup>th</sup> </span> : '१ ली ते ५ वी ' 
    },
    { 
        value: '6 To 8', 
        label: language === "English" ? <span>6<sup>th</sup> to 8<sup>th</sup> </span> : '६ वी ते ८ वी ' 
    },
];
const selectedClassLabel = classOptions2.find(option => option.value === selectedClass)?.label || selectedClass;

const handleDeleteMenu = () => {
  if (selectedClass && selectedDay && selectedAaharMenu) {
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/menu/${selectedClass}/${selectedDay}/${selectedAaharMenu}.json`;
    
    fetch(url, {
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        console.log('Menu deleted successfully!', data);

        // Show an alert for successful deletion
        setAlertMessage('Menu deleted successfully!');

        // Clear selections after deletion
        setSelectedAaharMenu('');
        setSelectedFoodItems([]);
        setIsMenuSaved(false);
      })
      .catch(error => {
        console.error('Error deleting menu: ', error);
      });
  } else {
    console.error('Please select a class, day, and menu to delete.');
  }
};


const halfway = Math.ceil(foodItems.length / 2);
const firstColumnItems = foodItems.slice(0, halfway);
const secondColumnItems = foodItems.slice(halfway);
const inputStyle = {
  marginTop: '10px',
  width: '130px',
  maxWidth: '300px',
  height:'35px', 
  marginRight: '20px', 
  fontSize:'14px', 
  padding:'8px',
  borderRadius:'5px',
  border:'1px solid gray'
};

const buttonStyle = {
  marginTop: '20px',
  padding: '8px 20px',
  fontSize: '14px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer'
};
const buttonStyleReport = {
  marginTop: '20px',
  padding: '8px 20px',
  fontSize: '14px',
  backgroundColor: '#d4a017',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer'
};
const buttonStyleDelete = {
  marginTop: '20px',
  padding: '8px 16px',
  fontSize: '14px',
  backgroundColor: 'red',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};
  return (
    <div>
    <Sidebar />
    
 <AlertMessage message={alertMessage} show={showAlert}/>
    <div className="main-content-of-page" >
    <div style={{ maxWidth: "800px", margin: "1% auto", padding: "30px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",  borderRadius: "5px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid gray"}}>



      <h2  className='text-center mb-5' style={{ color:'#03365e', fontWeight:'600'}}>  {language === "English" ? " Food  Menu  " : " आहार मेनू "}</h2>
      
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div>
          <label style={{  marginBottom: '5px', fontSize:'14px'}} >{language === "English" ? " Class  " : "वर्ग  "}:</label>
          <select style={inputStyle} value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
            <option value="">{language === "English" ? " Select Class  " : "वर्ग निवडा "}</option>
            {classOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div >
          <label style={{marginBottom: '5px',fontSize:'14px'}}>{language === "English" ? " Day  " : "वार "}:</label>
          <select style={inputStyle} value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)}>
            <option value="">  {language === "English" ? " Select Day  " : "वार निवडा"}</option>
            {dayOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ marginBottom: '5px', fontSize:'14px'}}>{language === "English" ? " Menu  " : " मेनू "}:</label>
          <select style={inputStyle} value={selectedAaharMenu} onChange={handleAaharMenuChange}>
            <option value="">{language === "English" ? " Select Menu   " : "मेनू निवडा"}</option>
            {aaharMenuOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isOtherOption && (
        <div style={{ marginBottom: '25px', alignItems:'center', display: 'flex', justifyContent: 'center',}}>
          <label style={{  fontSize:'14px'}}>{language === "English" ? " Other Menu content  " : "इतर मेनू सामग्री "}:</label>
          <input  style={inputStyle} type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
      )}
      <hr/>

      <div style={{
  display: 'flex',
  justifyContent: 'center',
  gap: '10%',
  padding: '35px',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: 'rgb(242, 242, 242)',
  border: '1px solid black', // Add border here
  borderRadius: '5px',      // Optional: Add border radius for rounded corners
  transition: 'transform 0.3s ease', // Smooth transition for scale effect
  ':hover': {
    transform: 'scale(1.02)', // Slightly increase size on hover
  }
}}>
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }}>
    {firstColumnItems.map((item) => (
      <div key={item.name} style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}>
        <input
          type="checkbox"
          checked={selectedFoodItems.includes(item.name)}
          onChange={() => handleFoodItemChange(item.name)}
          style={{ width: '18px', height: '18px', marginRight: '10px' }}
        />
        <label style={{ marginLeft: '5px', fontSize: '15px' }}>{item.name}</label>
      </div>
    ))}
  </div>

  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }}>
    {secondColumnItems.map((item) => (
      <div key={item.name} style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
      }}>
        <input
          type="checkbox"
          checked={selectedFoodItems.includes(item.name)}
          onChange={() => handleFoodItemChange(item.name)}
          style={{ width: '18px', height: '18px', marginRight: '10px' }}
        />
        <label style={{ marginLeft: '5px', fontSize: '15px' }}>{item.name}</label>
      </div>
    ))}
  </div>
</div>



  <div style={{ display: 'flex',justifyContent: 'space-between', margin:'auto 180px' }}>
    <button style={buttonStyle} onClick={handleSaveOrUpdate}>
      {isMenuSaved ? 'Update' : 'Save'}
    </button>
    {isMenuSaved && ( 
    <button style={buttonStyleDelete} onClick={handleDeleteMenu}>
      Delete
    </button>
  )}
    <button
      type="button"
      onClick={handleReport}
      style={buttonStyleReport}
    >
      Report
    </button>

   
  </div>

  

      </div>

      {showModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '80%',
              maxWidth: '800px', // Set a max width for better fitting
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
              maxHeight: '90%',
              overflowY: 'auto',
              position: 'relative',
            }}>

              <div className="modal-content">
            
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '30px',
                  fontFamily: 'Arial, sans-serif',
                  border: '1px solid black',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                  {/* Header as part of the Table */}
                  <thead>
                    {schoolData && (
                      <tr>
                        <th colSpan="3" style={{
                          color: 'black',
                          padding: '5px',
                          borderRadius: '10px 10px 0 0',
                          textAlign: 'center',
                          lineHeight: '0.4',
                          borderBottom: '1px solid black',
                          borderLeft: '1px solid black',
                          borderRight: '1px solid black',
                          borderTop: '1px solid black',
                          fontSize: '13px'
                        }}>
                          <p><strong><h5> {schoolData.schoolName}</h5></strong></p>
                          <p>{schoolData.sansthaName}</p>
                          <p>{language === "English" ? "Taluka:" : "तालुका:"} {schoolData.taluka} , {language === "English" ? "Jilha:" : "जिल्हा:"} {schoolData.jilha} </p>
                          <p>{language === "English" ? "Mobile Number:" : "मोबाइल नंबर:"} {schoolData.mobileNumber} , {language === "English" ? "Email:" : "ईमेल:"} {schoolData.email}</p>

                          <p style={{ display: 'inline-block', backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px', fontSize: '18px' }}>
                            {selectedClassLabel} {language === "English" ? "Food Menu" : "पोषण मेनू प्रमाण"}
                          </p>
                        </th>
                      </tr>
                    )}
                    <tr style={{ color: 'black' }}>
                      <th style={{borderLeft: '1px solid black',...tdstylemy}}>{language === "English" ? "Day" : "वार"}</th>
                      <th style={tdstylemy}>{language === "English" ? "Food" : "आहार"}</th>
                      <th style={{...tdstylemy, width: '40%'}}>{language === "English" ? "Details" : "तपशील"}</th>
                    </tr>
                  </thead>

                  <tbody>
  {daysOfWeek
    .filter(day => menuData[day.key]) // Filter out days without data
    .map((day) => {
      const foods = menuData[day.key];
      const mainFoodItem = foods ? Object.keys(foods)[0] : null;
      const details = foods && mainFoodItem
        ? Object.keys(foods[mainFoodItem])
            .filter(foodDetail => foods[mainFoodItem][foodDetail])
            .join(', ')
        : '';

      // Get the label in the correct language
      const displayedFoodItem = language === "Marathi" 
        ? getFoodItemLabel(mainFoodItem) 
        : mainFoodItem || '-';

      // Prepare details translation if necessary
      const displayedDetails = details || '-'; // You can add translation logic for details if needed

      return (
        <tr key={day.key}>
          <td style={{...thstylemy, borderLeft: '1px solid black'}}>
            {day.label}
          </td>
          <td style={thstylemy}>
            {displayedFoodItem} 
          </td>
          <td style={thstylemy}>
            {displayedDetails}
          </td>
        </tr>
      );
    })}
</tbody>
                </table>
              </div>

              <div style={{ textAlign: 'right', marginTop: '20px' }}>

                <button onClick={handlePrint} style={{
          padding: '12px 25px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.2s ease',
          marginRight: '10px',
        }}>
         {language === "English" ? "Print" : "प्रिंट करा"} 
        </button>

                <button onClick={closeModal} style={{
                  padding: '12px 25px',
                  backgroundColor: '#FF4136',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.2s ease',
                }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#E04A3F'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#FF4136'}
                >
               {language === "English" ? "Close" : " बंद करा"}                          
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};


const thstylemy={
  padding: '12px',
  borderBottom: '1px solid black',
  textAlign: 'center',
  borderRight: '1px solid black',
}
const tdstylemy={
  padding: '10px',
  fontWeight: 'bold',
  borderBottom: '1px solid black',
  textAlign: 'center',
  borderRight: '1px solid black',
}
export default DropdownExample;