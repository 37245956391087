import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import schoolImage from "../../assets/images/school.jpg";
// import './School.css'
import AlertMessage from "../../AlertMessage";
import CreatePramanNode from "../Poshanaahar/CreatePramanNode";

function SchoolRegister() {
  const [schoolName, setSchoolName] = useState("");
  const [udiseNumber, setUdiseNumber] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [sentOtp, setSentOtp] = useState("");
  const [serverOtp, setServerOtp] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [showEnterOtp, setShowEnterOtp] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [invalidMobileNumber, setInvalidMobileNumber] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const [showLanguageOverlay, setShowLanguageOverlay] = useState(!localStorage.getItem("language"));
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [email, setEmail] = useState("");
  const [sansthaName, setsansthaName] = useState("");
  const [bridvakya, setbridvakya] = useState("");
  const [boardName, setBoardName] = useState("");
  const [indexNumber, setIndexNumber] = useState("");
  const [sanlagnataKramank, setSanlagnataKramank] = useState("");
  const [manyataKramank, setManyataKramank] = useState("");
  const [madhyam, setMadhyam] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [walletPoints, setwalletPoints] = useState(100);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setIsFormFilled(!!schoolName && !!udiseNumber && !!password && !!mobileNumber);
  }, [schoolName, udiseNumber, password, mobileNumber]);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); // clear the alert message
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    if (!language) {
      setShowLanguageOverlay(true);
    }
  }, [language]);

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", language);
    }
  }, [language]);
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    setShowLanguageOverlay(false);
  };


  const handleRegistration = (e) => {
    e.preventDefault();
    if (!isPhoneNumberVerified) {
      setAlertMessage(language === "English" ? "Please verify your phone number first." : "कृपया आपला मोबाइल नंबर सत्यापित करा.")
      return;
    }
    if (serverOtp.trim() !== sentOtp.toString()) {
      setAlertMessage(language === "English" ? "Please verify your OTP." : "कृपया आपला OTP सत्यापित करा.");
      return;
    }

    const schoolData = {
      schoolName,
      password,
      mobileNumber,
      language,
      telephoneNumber,
      address,
      taluka,
      jilha,
      email,
      sansthaName,
      bridvakya,
      boardName,
      indexNumber,
      sanlagnataKramank,
      manyataKramank,
      madhyam,
      schoolLogo,
      walletPoints
    };

    const schoolRegisterData = {
      schoolData,
      studentData: {},
    };

    fetch(
      `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}.json`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(schoolRegisterData),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("School registered successfully:", data);
        setAlertMessage(language === "English" ? "Registered successfully" : "यशस्वीरित्या नोंदणी केली");

        localStorage.setItem("udiseNumber", udiseNumber);
    CreatePramanNode(udiseNumber, language)
        window.location.href = "/school-data";
      })
      .catch((error) => {
        console.error("Error registering school:", error);
      });
  };

  function generateRandomCode() {
    const min = 100000;
    const max = 999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const sendMessage = (e) => {
    e.preventDefault();
    const username = "Experts";
    const authkey = "ba9dcdcdfcXX";
    const mobiles = "+91" + mobileNumber.trim();
    const senderId = "EXTSKL";

    // Check if UDISE number already exists
    fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data !== null) {
          setAlertMessage(language === "English" ? "UDISE number already registered." : `UDISE क्रमांक ${udiseNumber} आधीच नोंदणी केला गेला आहे.`);
          console.log("error occured");
          
          return;
        }

        // Proceed to send OTP if UDISE number is not already registered
        const otp = generateRandomCode();
        setSentOtp(otp);
        setShowEnterOtp(true);
        const message = `Your Verification Code is ${otp}. - Expertskill Technology.`;
        const accusage = "1";
        const encodedMessage = encodeURIComponent(message);

        // Send SMS API
        const mainUrl = "https://mobicomm.dove-sms.com/submitsms.jsp?";
        const url = `${mainUrl}user=${username}&key=${authkey}&mobile=${mobiles}&message=${encodedMessage}&accusage=${accusage}&senderid=${senderId}`;

        fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text(); // Parse response as text
          })
          .then((data) => {
            console.log("Message sent successfully:", data);
            const responseArray = data.split(",");
            if (responseArray[1] === "InvalidMobileNumber") {
              setInvalidMobileNumber(true);
            } else {
              setInvalidMobileNumber(false);
            }
          })
          .catch((error) => {
            console.error("Error sending message:", error);
          });
      })
      .catch((error) => {
        console.error("Error checking UDISE number:", error);
      });
  };


  const verifyOtp = (e) => {
    e.preventDefault();
    if (serverOtp.trim() === sentOtp.toString()) {
      setVerificationStatus(language === "English" ? "Your OTP Verified Successfully." : "आपला OTP यशस्वीरित्या सत्यापित झाला आहे.");
      setIsPhoneNumberVerified(true);
    } else {
      setVerificationStatus(language === "English" ? "OTP did not match." : " OTP जुळला नाही.");
    }
  };

  
  return (
    <div className="p-5 mt-2">
  {showLanguageOverlay && (
    <div className="overlay" style={{ display: 'flex', width: '100%' }}>
      <div
        className="sidebar-template"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '88vh',
          background: 'linear-gradient(135deg, #eaeff3, #3acad1)',
          padding: '15px 10px',
          borderRadius: '20px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',
          animation: 'fadeIn 1s ease-in-out',
          marginTop: '35px',
        }}
      >
        <div
          className="card"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            animation: 'slideIn 1s ease-in-out',
            background: '#fff',
            border: '1px solid #e0e0e0',
            borderRadius: '20px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
              marginTop: '40px',
              maxHeight: '85vh',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
          }}
        >
          <div
            className="column english"
            style={{
              flex: 1,
              padding: '10px',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              animation: 'bounceIn 1.5s ease-in-out'
            }}
          >
            <h3
              style={{
                color: '#007bff',
                marginBottom: '15px',
                fontFamily: "'Poppins', sans-serif",
                fontSize: '1.8em',
                textAlign: 'center',
                borderBottom:'2px solid  #ff7043',
                paddingBottom:'10px'
              }}
            >
            <b>E-Gurji Software</b>
              
            </h3>
            <p
              style={{
                fontSize: '14px',
                lineHeight: '1.6',
                fontFamily: "'Roboto', sans-serif",
                color: '#333',
                textAlign: 'center'
              }}
            >
              With this software, you can register your school, manage school information, and more. <br/><b>Please select your language to continue. You will be able to use the software in the selected language.</b>
            </p>
            <ol
              style={{
                paddingLeft: '20px',
                marginTop: '20px',
                fontFamily: "'Roboto', sans-serif",
                 color: '#007bff'
              }}
            >
              <li style={{ marginBottom: '10px' }}>Manage your school's information</li>
              <li style={{ marginBottom: '10px' }}>Register students, fill in or update information</li>
              <li style={{ marginBottom: '10px' }}>Bonafide</li>
              <li style={{ marginBottom: '10px' }}>School leaving certificate</li>
              <li style={{ marginBottom: '10px' }}>Student identification card</li>
              <li style={{ marginBottom: '10px' }}>Various types of reports and much more</li>
              <li style={{ marginBottom: '10px' }}>Available in Marathi and English Language</li>
            </ol>
          </div>
          <div
            className="column marathi"
            style={{
              flex: 1,
              padding: '25px 17px',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              animation: 'bounceIn 1.5s ease-in-out',
              width:'98%',
              marginTop:'-20px'
            }}
          >
            <h3
              style={{
                color: '#007bff',
                marginBottom: '15px',
                fontFamily: "'Poppins', sans-serif",
                fontSize: '1.8em',
                textAlign: 'center',
                marginTop: '-15px',
                borderBottom:'2px solid  #ff7043',
                paddingBottom:'10px'
              }}
            >
            <b>ई-गुरुजी सॉफ्टवेअर</b>
              
            </h3>
            <p
              style={{
                fontSize: '14px',
                lineHeight: '1.6',
                fontFamily: "'Roboto', sans-serif",
                color: '#333',
                textAlign: 'center'
              }}
            >
              या सॉफ्टवेअरद्वारे आपण आपल्या शाळेची नोंदणी करू शकता, शाळेची माहिती व्यवस्थापित करू शकता आणि अधिक.<br/> <b>कृपया पुढे चालू ठेवण्यासाठी आपली भाषा निवडा. निवडलेल्या भाषेत तुम्हाला हे सॉफ्टवेअर वापरण्यास मिळेल.</b>
            </p>
            <ol
              style={{
                paddingLeft: '20px',
                marginTop: '20px',
                fontFamily: "'Roboto', sans-serif",
                color: '#007bff',
              }}
            >
              <li style={{ marginBottom: '10px' }}>आपल्या शाळेची माहिती व्यवस्थापित करा</li>
              <li style={{ marginBottom: '10px' }}>विद्यार्थी नोंदणी करा, माहिती भरा किंवा अपडेट करा.</li>
              <li style={{ marginBottom: '10px' }}>बोनफाईड</li>
              <li style={{ marginBottom: '10px' }}>शाळा सोडल्याचा दाखला</li>
              <li style={{ marginBottom: '10px' }}>विद्यार्थी ओळख पत्र</li>
              <li style={{ marginBottom: '10px' }}>विविध प्रकारचे रीपोर्ट आणि बरेच काही</li>
              <li style={{ marginBottom: '10px' }}>मराठी आणि इंग्रजी भाषेत उपलब्ध</li>
            </ol>
          </div>
        </div>
      </div>
      <div
        className="center-content"
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          marginLeft: 'auto'
        }}
      >
        <div
          className="language-box"
          style={{
            textAlign: 'center',
            backgroundColor: 'rgb(243, 238, 238)',
            width: '300px !important',
            padding: '40px 35px',
            borderRadius: '5px',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)'
          }}
        >
          <h4 style={{ marginTop: '-30px' }}>Select Language</h4>
          <select
            className="form-select"
            style={{ width: '100%' }}
            value={language || ''}
            onChange={handleLanguageChange}
          >
            <option value="">Select Language</option>
            <option value="English">English</option>
            <option value="Marathi">मराठी</option>
          </select>
        </div>
      </div>
    </div>
  )}

      <div className={`schoolRegisterForm col-md-5 col-sm-12 mb-4 p-md-4 ${showLanguageOverlay ? 'blur-background' : ''}`}>
        <h1 className="my-2">
          {language === "English" ? " Register Your School" : "शाळा नोंदणी करा"}
        </h1>
        <AlertMessage message={alertMessage} show={showAlert} />
        <div className="row my-3 sc-form">
          <div className="col-md-4 col-sm-12 mt-md-5">
            <img className="img-fluid mt-5" src={schoolImage} alt="School" />
          </div>

          <div className="col-md-8 col-sm-12">
            <form className="h-100 text-start p-4 mb-4">
              <div>
                <label htmlFor="schoolName">
                  {language === "English" ? "School Name" : "शाळेचे नाव"}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language === "English" ? "Enter school name" : "शाळेचे नाव प्रविष्ट करा"}
                  id="schoolName"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="udiseNumber">
                  {language === "English" ? "UDISE Number" : "UDISE क्रमांक"}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder={language === "English" ? "Enter UDISE number" : "UDISE क्रमांक प्रविष्ट करा"}
                  id="udiseNumber"
                  value={udiseNumber}
                  onChange={(e) => setUdiseNumber(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="password">
                  {language === "English" ? "Password" : "पासवर्ड"}
                </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={language === "English" ? "Enter password" : "पासवर्ड प्रविष्ट करा"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="mobileNumber">
                  {language === "English" ? "Mobile Number" : "मोबाइल क्रमांक"}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language === "English" ? "Enter mobile number" : "मोबाइल क्रमांक प्रविष्ट करा"}
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  required
                />
                {invalidMobileNumber && (
                  <div className="invalid-feedback">
                    {language === "English" ? "Invalid mobile number" : "अवैध मोबाइल क्रमांक"}
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-between">
                {!isPhoneNumberVerified ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendMessage}
                    disabled={!mobileNumber.trim()}
                  >
                    {language === "English" ? "Send OTP" : "OTP पाठवा"}
                  </button>
                ) : (
                  <span className="text-success">
                    {language === "English" ? "Phone number verified" : "मोबाइल क्रमांक सत्यापित केला"}
                  </span>
                )}
              </div>

              {showEnterOtp && (
                <div className="mt-3">
                  <label htmlFor="serverOtp">
                    {language === "English" ? "Enter OTP" : "OTP प्रविष्ट करा"}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={language === "English" ? "Enter OTP" : "OTP प्रविष्ट करा"}
                    id="serverOtp"
                    value={serverOtp}
                    onChange={(e) => setServerOtp(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={verifyOtp}
                  >
                    {language === "English" ? "Verify OTP" : "OTP सत्यापित करा"}
                  </button>
                  {verificationStatus && (
                    <div className="mt-2 text-success">
                      {verificationStatus}
                    </div>
                  )}
                </div>
              )}

              <button
                type="submit"
                className="btn btn-primary mt-4"
                onClick={handleRegistration}
                disabled={!isFormFilled || !isPhoneNumberVerified}
              >
                {language === "English" ? "Register" : "नोंदणी करा"}
              </button>
              <span className="loginLink mt-5">
                {language === "English"
                  ? "Already Registered ?"
                  : "आधीच नोंदणी केली आहे?"}
                <Link className="text-decoration-none ms-1" to="/">
                  {language === "English" ? "Just Login" : "लॉगिन करा"}
                </Link>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolRegister;


