import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
// import { Tooltip } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// import TableViewIcon from '@mui/icons-material/TableView';
// import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import "../components/Sidebar.css";
import ReferalCompo from "../pages/PaymentIntegration/ReferalCompo";

const Sidebar = () => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');
  const [showStudentDropdown, setShowStudentDropdown] = useState(false);
  const [showSchoolDropdown, setShowSchoolDropdown] = useState(false);
  const [showExamDropdown, setShowExamDropdown] = useState(false);
  // const [showPoshanDropdown, setShowPoshanDropdown] = useState(false);

  const currentPath = window.location.pathname;

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);

  const getLabel = (englishLabel, marathiLabel) => {
    return language === "English" ? englishLabel : marathiLabel;
  };

  const handleStudentMenuClick = () => {
    setShowStudentDropdown(prevState => !prevState);
    setShowSchoolDropdown(false); 
    setShowExamDropdown(false)
    // setShowPoshanDropdown(false);

  };

  // const handleSchoolMenuClick = () => {
  //   setShowSchoolDropdown(prevState => !prevState);
  //   setShowStudentDropdown(false); 
  //   setShowExamDropdown(false);
  //   setShowPoshanDropdown(false);

  // };

  
  const handleExamMenuClick = () => {
    setShowExamDropdown(prevState => !prevState);
    setShowStudentDropdown(false); 
    setShowSchoolDropdown(false);  
    // setShowPoshanDropdown(false);
  };

  
  // const handlePoshanMenuClick = () => {
  //   setShowPoshanDropdown(prevState => !prevState);
  //   setShowStudentDropdown(false); 
  //   setShowExamDropdown(false) 
  //   setShowSchoolDropdown(false)
  // };

  return (
    <div className="sidebar">
    <Tooltip style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}}  >
        <Link className="main-menus" to="/home">
          <HomeIcon />
          <span><b>{getLabel("Home", "होम")}</b></span>
        </Link>
      </Tooltip>

     

      
      <Tooltip  style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}}  className="main-menus" onClick={handleStudentMenuClick}>
        <p className="main-menus">
          <GroupIcon />
          <span><b>{getLabel("Student", "विद्यार्थी")}</b></span>
        </p>
      </Tooltip>
      {showStudentDropdown && (
        <div className="submenu">
          <Tooltip >
            <Link to="/student-register">
              <GroupIcon />
              <span>{getLabel("New students", "नवीन विद्यार्थी")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/rollNo">
              <BarChartIcon />
              <span>{getLabel("Roll Number", "हजेरी क्रमांक")}</span>
            </Link>
          </Tooltip>

          <Tooltip >
            <Link to="/all-student-report">
              <SummarizeIcon />
              <span>{getLabel("Student Report", "विद्यार्थी अहवाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/leaving-certificate">
              <ReceiptIcon />
              <span>{getLabel("Leaving Certificate", "शाळा सोडल्याचा दाखला")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/bonafide">
              <ReceiptIcon />
              <span>{getLabel("Bonafide", "बोनाफाईड")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/caste-report">
              <AssessmentIcon />
              <span>{getLabel("Caste Report", "जात अहवाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/minority-report">
              <BarChartIcon />
              <span>{getLabel("Minority Report", "अल्पसंख्याक अहवाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/agewisereport">
              <WaterfallChartIcon />
              <span>{getLabel("Age Report", "वय अहवाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/IdCard">
              <ReceiptIcon />
              <span>{getLabel("IdCard", "ओळखपत्र")}</span>
            </Link>
          </Tooltip>

          

          <Tooltip>
            <Link to="/generalregister">
              <ReceiptIcon />
              <span>{getLabel("General Register 1", "जनरल रजिस्टर 1")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/importexport">
              <WaterfallChartIcon />
              <span>{getLabel("Import/Export Data", "डेटा आयात/निर्यात डेटा")}</span>
            </Link>
          </Tooltip>
        </div>
      )}

      
 
      <Tooltip style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}} >
        <Link to="/kird">
        <MenuBookIcon />
          <span><b>{getLabel("Kird", "किर्द")}</b></span>
        </Link>
      </Tooltip>
      
      {/* <Tooltip style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}} >
        <Link to="/#">
        <ReceiptIcon />
          <span><b>{getLabel("Student Fee", "विद्यार्थ्यांची फी")}</b></span>
        </Link>
      </Tooltip> */}
     


    
       {/* <Tooltip  style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}}  className="main-menus"  onClick={handlePoshanMenuClick}>
        <p className="main-menus">
          <GroupIcon />
          <span><b>{getLabel("Poshan Aahar", "पोषण आहार")}</b></span>
        </p>
      </Tooltip>
      {showPoshanDropdown && (
        <div className="submenu">
        <Tooltip >
            <Link to="/dainandin">
              <GroupIcon />
              <span>{getLabel("Daily register ", "दैनंदिन नोंदवही ")}</span>
            </Link>
          </Tooltip>

          <Tooltip >
            <Link to="/sadhyachasatha">
              <GroupIcon />
              <span>{getLabel("Current stock", "सध्याचा साठा ")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/aavaksatha">
              <SummarizeIcon />
              <span>{getLabel("incoming entry", "आवक नोंद")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/menu">
              <SummarizeIcon />
              <span>{getLabel("Menu", "आहार मेनू")}</span>
            </Link>
          </Tooltip>
          
          <Tooltip >
            <Link to="/magani">
              <ReceiptIcon />
              <span>{getLabel("demand", "मागणी")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/praman">
              <ReceiptIcon />
              <span>{getLabel("quantity", "आहार प्रमाण")}</span>
            </Link>
          </Tooltip>
        </div>
      )}  */}

     
      
 {/*     <Tooltip  style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px'}}  className="main-menus"  onClick={handleExamMenuClick}>
        <p className="main-menus">
          <GroupIcon />
          <span><b>{getLabel("Result", "निकालपत्र")}</b></span>
        </p>
      </Tooltip>
      {showExamDropdown && (
        <div className="submenu">
          <Tooltip >
            <Link to="/GunaNeendani">
              <GroupIcon />
              <span>{getLabel("Mark Registration", "गुण नोंदणी ")}</span>
            </Link>
          </Tooltip>

          <Tooltip >
            <Link to="/progresssheet">
              <SummarizeIcon />
              <span>{getLabel("Progress sheet", "प्रगती पत्रक")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/collectout">
              <ReceiptIcon />
              <span>{getLabel("combined result", "एकत्रित निकाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/subjectwise">
              <ReceiptIcon />
              <span>{getLabel("Subject wise results", "विषयनिहाय निकाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/gradewise">
              <AssessmentIcon />
              <span>{getLabel("Grade Wise Results", "श्रेणी निहाय निकाल")}</span>
            </Link>
          </Tooltip>
          <Tooltip >
            <Link to="/dailyregister">
              <BarChartIcon />
              <span>{getLabel("Daily register", "दैनंदिन नोंदवही")}</span>
            </Link>
          </Tooltip>
          <Tooltip>
            <Link to="/boardresult">
              <BarChartIcon />
              <span>{getLabel("5th & 8th Result", "पाचवी आणि आठवीचा निकाल")}</span>
            </Link>
          </Tooltip>
          
        </div>
      )}
    */}
      <Tooltip className=" main-menus" style={{background:'#84c2fc',  fontSize:'16px', paddingLeft:'9px', }}  >
      <Link to="/school-data">
              <AccountBalanceIcon />
              <span><b>{getLabel("School Detail", "शाळेचा तपशील")}</b></span>
            </Link>
      </Tooltip>
      <div  style={{paddingBottom: '29px',  bottom:0 }} >
        <ReferalCompo />
      </div>

    </div>
  );
};

export default Sidebar;
