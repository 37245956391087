import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';
import loading from '../../assets/images/loading.jpg.gif';
function Sadhyachasatha() {
  const [data, setData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const udiseNumber = localStorage.getItem("udiseNumber");
  const language = localStorage.getItem("language");
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [schoolData, setSchoolData] = useState(null);
   const [isYearDisabled, setIsYearDisabled] = useState(true); 
  const [isMonthDisabled, setIsMonthDisabled] = useState(true);
  const [isClassDisabled, setIsClassDisabled] = useState(true);
  const [noavakordaindin, setnoavakordaindin] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);
  const classMapping = {
    '1 To 5': {
      English: "1 To 5",
      Marathi: "१ ते ५"
    },
    '6 To 8': {
      English: "6 To 8",
      Marathi: "६ ते ८"
    }
  };
  const predefinedVastuNames = language === 'English' 
    ? ['Rice', 'Mugdal', 'Turdal', 'Masurdal', 'Matki', 'Moong', 'Cowpea', 'Gram', 'Pease', 'Cumin', 'Mustard', 'Turmeric', 'Chili', 'Oil', 'Salt', 'Garam Masala', 'Vegetables'] 
    : ['तांदूळ', 'मुगडाळ', 'तुरडाळ', 'मसुरडाळ', 'मटकी', 'मूग', 'चवळी', 'हरभरा', 'वाटाणा', 'जिरे', 'मोहरी', 'हळद', 'मिरची', 'तेल', 'मीठ', 'गरम मसाला', 'भाजी पाला'];
  
  const [monthData, setMonthData] = useState({});

  useEffect(() => {
    const fetchAvailableYearsAndMonths = async () => {
        try {

          const yearsUrl0 = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha.json`;
          const yearsResponse = await axios.get(yearsUrl0);
          const yearsData0 = yearsResponse.data || {};

          // Fetch classes from dainandinnondani
          const dainandinnondaniUrl0 = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani.json`;
          const dainandinnondani0Response = await axios.get(dainandinnondaniUrl0);
          const dainandinnondaniData0 = dainandinnondani0Response.data || {};

          // Log the fetched data
          console.log("Avaksatha Classes Data:", yearsData0);
          console.log("Dainandinnondani Classes Data:", dainandinnondaniData0);

            const availableClasses = new Set();

            // Add classes from avaksatha
            if (yearsData0) {
                Object.keys(yearsData0).forEach(classKey => {
                    availableClasses.add(classKey);
                });
            }

            // Add classes from dainandinnondani
            if (dainandinnondaniData0) {
                Object.keys(dainandinnondaniData0).forEach(classKey => {
                    availableClasses.add(classKey);
                });
            }
  // Convert Set to Array
  const sortedClasses = Array.from(availableClasses);
  console.log("Available Classes:", sortedClasses);

  // Set default class based on available classes
  let defaultClass = '';
  if (sortedClasses.includes('1 To 5')) {
      defaultClass = '1 To 5';
  } else if (sortedClasses.includes('6 To 8')) {
      defaultClass = '6 To 8';
  }

  setSelectedClass(defaultClass);
  
  const yearsUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha/${defaultClass}.json`;

  const response = await axios.get(yearsUrl);
  const yearsData = response.data || {};
  

  const dainandinnondaniUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${defaultClass}.json`;
  const dainandinnondaniResponse = await axios.get(dainandinnondaniUrl);
  const dainandinnondaniData = dainandinnondaniResponse.data || {};
  
  // Log the fetched data
  console.log("Years Data:", yearsData);
  console.log("Dainandinnondani Data:", dainandinnondaniData);

            if (!yearsData && !dainandinnondaniData) {
              setnoavakordaindin(false)
                setIsYearDisabled(true);
                setIsMonthDisabled(true);
                setIsClassDisabled(true);
                setAlertMessage('No data available.');
                
                return;
            }
            setnoavakordaindin(true)
            // Determine the earliest year directly from the available data
            let earliestYear = null;
            let earliestYearSource = null;

            if (Object.keys(yearsData).length > 0) {
                const years = Object.keys(yearsData).map(Number).sort((a, b) => a - b);
                earliestYear = years[0]; // Earliest year from avaksatha
                earliestYearSource = 'avaksatha'; // Track source
            }

            if (Object.keys(dainandinnondaniData).length > 0) {
                const years2 = Object.keys(dainandinnondaniData).map(Number).sort((a, b) => a - b);
                const earliestYear2 = years2[0]; // Earliest year from dainandinnondani

                // If avaksatha has no data, take the year from dainandinnondani
                if (earliestYear === null) {
                    earliestYear = earliestYear2;
                    earliestYearSource = 'dainandinnondani'; // Update source
                } else if (earliestYear2 < earliestYear) {
                    earliestYear = earliestYear2;
                    earliestYearSource = 'dainandinnondani'; // Update source
                }
            }

            // Log the final selected year and source
            console.log("Final Earliest Year:", earliestYear);
            console.log("Source of the Earliest Year:", earliestYearSource);

            // Set the selected year to the earliest year found
            setSelectedYear(earliestYear.toString());
            setIsYearDisabled(false);
console.log("earliestYearSource",earliestYearSource);

            // Now fetch months based on the source of the earliest year
            let monthsData = {};
            if (earliestYearSource === 'avaksatha') {
                monthsData = yearsData[earliestYear] || {};
            } else if (earliestYearSource === 'dainandinnondani') {
                monthsData = dainandinnondaniData[earliestYear] || {};
            }

            // Log the months data
            console.log("Months Data for Selected Year:", monthsData);

            // Create a mapping of month numbers to month names
            const monthNames = [
                "January", "February", "March", "April", "May", "June", 
                "July", "August", "September", "October", "November", "December"
            ];

            // Extract months and convert to month names
// Extract months and convert to month names
const months = Object.keys(monthsData)
    .map(month => {
        // Check if the month is a number
        const monthNum = Number(month);
        if (!isNaN(monthNum) && monthNum >= 1 && monthNum <= 12) {
            return monthNum; // Keep the numeric month for sorting
        }
        return null; // If it's not a number, ignore it
    })
    .filter(month => month !== null) // Filter out any null values
    .sort((a, b) => a - b) // Sort numerically
    .map(monthNum => monthNames[monthNum - 1]); // Convert to month name

console.log("Sorted Months:", months);

const earliestMonth = months[0] || ''; 
setSelectedMonth(earliestMonth);
setIsMonthDisabled(false);
setIsClassDisabled(false);
            
        } catch (error) {
            console.error('Failed to fetch years and months:', error);
            setAlertMessage(' First save data in avaksatha or dainandinnondani');
            setIsLoading(false);
        }
    };

    fetchAvailableYearsAndMonths();
    fetchSchoolData(); 

}, [udiseNumber]); 


  useEffect(() => {
    console.log("noavakordaindin",noavakordaindin);
    if(noavakordaindin){
      fetchData();
    }
    
  }, [selectedYear, selectedMonth, selectedClass]);

  const fetchSchoolData = () => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        setSchoolData(data); 
      })
      .catch(error => {
        console.error('Error fetching school data from Firebase:', error);
      });
  };

  const saveShillakVastu = async (monthData) => {
    try {
      // Fetch existing data
      const existingDataUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/sadhyachasatha/${selectedClass}/${selectedYear}/${selectedMonth}.json`;
      const existingDataResponse = await axios.get(existingDataUrl);
      const existingData = existingDataResponse.data || {};

      // Prepare new data to merge
      const newShillakData = {};
      monthData.forEach(item => {
        if (item.shillakVastu !== 0) {
          newShillakData[item.vastuName] = item.shillakVastu;
        }
      });

      // Merge existing data with new data
      const mergedData = { ...existingData, ...newShillakData };

      // Save merged data
      await axios.put(existingDataUrl, mergedData);
      console.log('ShillakVastu values saved successfully      .');
    } catch (error) {
      console.error('Failed to save shillakVastu values:', error);
      setAlertMessage('Failed to save shillakVastu values.');
    }
  };
  const fetchPreviousMonthShillak = async (year, month) => { 
    let currentYear = year; 
    let currentMonthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();

    const yearsBack = 3;
    // Check from the current month backwards
    for (let i = 0; i <= yearsBack; i++) {
        for (let j = currentMonthIndex-1; j >= 0; j--) {
          console.log("currentMonthIndex",currentMonthIndex);
          
            const previousMonthDate = new Date(currentYear, j);
            const previousMonthName = previousMonthDate.toLocaleString('default', { month: 'long' });
            const previousMonthYear = previousMonthDate.getFullYear();
    
            const previousMonthShillakUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/sadhyachasatha/${selectedClass}/${previousMonthYear}/${previousMonthName}.json`;
            console.log("previousMonthName",previousMonthName);
            
    
            try {
                const response = await axios.get(previousMonthShillakUrl);
                const previousMonthData = response.data || {};
    
                if (Object.keys(previousMonthData).length > 0) {
                    return previousMonthData; // Return the first found data
                }
            } catch (error) {
                console.error(`Failed to fetch data for ${previousMonthName} ${previousMonthYear}:`, error);
            }
        }
    
        // Move to the previous year
        currentYear--;
        currentMonthIndex = 11; // Reset to December for the new year
    }
    
    return {}; // Return an empty object if no data is found
    };


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const pramanUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/praman.json`;
      const praptVastuUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/avaksatha/${selectedClass}/${selectedYear}/${selectedMonth}/vastuDetails.json`;

      const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const monthNumber = new Date(`${selectedMonth} 1, ${selectedYear}`).getMonth() + 1;
      const formattedMonth = String(monthNumber).padStart(2, '0');

      let useVastuData = {};
      let totalUseVastu = {};

      for (let day of daysOfWeek) {
        const useVastuUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${selectedYear}/${formattedMonth}/${day}.json`;

        const response = await axios.get(useVastuUrl);
        const dayData = response.data || {};

        if (Object.keys(dayData).length > 0) {
          useVastuData = { ...useVastuData, ...dayData };

          Object.keys(dayData).forEach((date) => {
            const dayVastuData = dayData[date];
            Object.keys(dayVastuData).forEach((vastuName) => {
              if (!totalUseVastu[vastuName]) {
                totalUseVastu[vastuName] = 0;
              }
              totalUseVastu[vastuName] += parseInt(dayVastuData[vastuName], 10);
            });
          });
        }
      }

      const previousMonthShillak = await fetchPreviousMonthShillak(selectedYear, selectedMonth);
// console.log("previousMonthShillak",previousMonthShillak);

      const praptVastuResponse = await axios.get(praptVastuUrl);
      const praptVastuData = praptVastuResponse.data || {};

      const newData = predefinedVastuNames.map((vastuName) => {
        const praptValue = parseInt(praptVastuData[vastuName] || '0', 10);
        const useValue = totalUseVastu[vastuName] || 0;
        const magilSillak = previousMonthShillak[vastuName] || 0;
// console.log("magilSillak",magilSillak);

        const totalVastu = praptValue + magilSillak;
        const shillakVastu = totalVastu - useValue;

        return {
          vastuName,
          magilSillak,
          praptVastu: praptValue,
          totalVastu,
          useVastu: useValue,
          shillakVastu
        };
      });

      // Update the month data state
      setMonthData((prevMonthData) => ({ ...prevMonthData, [selectedMonth]: newData }));

      // Set the calculated data
      setData(newData);

      setIsTableVisible(true);
      await saveShillakVastu(newData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setAlertMessage('Failed to fetch data.');          
    }
    finally{
      setIsLoading(false); // Stop loading
    }
  };

  const handleArrowClick = () => {
    if (!selectedYear || !selectedMonth || !selectedClass) {
      setAlertMessage('Please select year, month, and class first.');
      return;
    }

    fetchData();
    setIsTableVisible(prevState => !prevState);
  };
  const fetchDemandData = async (year, month) => {
    const demandData = {}; // Object to store aggregated data
    const monthIndex = new Date(`${month} 1, ${year}`).getMonth(); // Get the month index (0-11)
  
    // Calculate the next two months and handle year transition
    const monthsToCheck = [
      (monthIndex + 1) % 12, // Next month
      (monthIndex + 2) % 12  // Month after next
    ];
    const yearForMonths = [
      year,
      (monthIndex + 1 > 11 ? parseInt(year) + 1 : year), // Year for next month
      (monthIndex + 2 > 11 ? parseInt(year) + 1 : year)  // Year for month after next
    ];
  
    // Fetch all data from the magani node
    const demandUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/magani.json`;
    let allData = {};
    try {
      const response = await axios.get(demandUrl);
      allData = response.data || {}; // Get all data from magani
    } catch (error) {
      console.error("Error fetching data from magani:", error);
      return demandData; // Return empty object on error
    }
  
    // Filter and aggregate the data for the next two months
    Object.keys(allData).forEach(date => {
      console.log("allData",allData);
      
      const dateParts = date.split('-'); // Split date into [year, month, day]
      const dataYear = parseInt(dateParts[0], 10);
      console.log("dataYear",dataYear);

      const dataMonth = parseInt(dateParts[1], 10); // Month is already 1-based
      console.log("dataMonth",dataMonth);
  console.log("yearForMonths",yearForMonths);

  
      // Check if the date falls within the next two months
      if (
        (dataYear == yearForMonths[1] && dataMonth == monthsToCheck[0] + 1) || // Next month
        (dataYear == yearForMonths[2] && dataMonth == monthsToCheck[1] + 1)    // Month after next
      ) {
        const dailyData = allData[date]; // Access daily data for each date
  console.log("dailyData",dailyData);
  
        // Aggregate the values for each item
        Object.keys(dailyData).forEach(item => {
          const itemValue = parseInt(dailyData[item], 10); // Convert to integer
          if (!demandData[item]) {
            demandData[item] = 0; // Initialize if it doesn't exist
          }
          demandData[item] += itemValue; // Sum the values for each item
        });
      }
    });
  
    return demandData; // Return the aggregated demand data
  };
  
  const [userInputs, setUserInputs] = useState({}); // State to hold user inputs

  const handleInputChange = (index, value) => {
    setUserInputs((prev) => ({
      ...prev,
      [index]: value, 
    }));
  };
  const handleReport = async () => {
    if (!data.length) {
      setAlertMessage('No data available to report.');
      return;
    }

    const demandData = await fetchDemandData(selectedYear, selectedMonth);
    console.log("demandData", demandData);

    const modalDataToShow = data.map((item, index) => ({
      content: item.vastuName,
      previousStock: item.magilSillak,
      receivedGoods: item.praptVastu,
      totalGoods: item.totalVastu,
      itemsUsed: item.useVastu,
      remainingStock: item.shillakVastu,
      totalDemand: (demandData[item.vastuName] || 0),
      userInput: userInputs[index] || '' // Initialize user input for the row
    }));

    setModalData(modalDataToShow);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false); 
  };
  
  const handlePrint = () => {
    const printContent = document.querySelector('.modal-content');
  
    if (printContent) {
      const cloneContent = printContent.cloneNode(true);
  
      const schoolName = cloneContent.querySelector('p'); 
      const otherDetails = cloneContent.querySelectorAll('p'); 
  
      otherDetails.forEach((p, index) => {
        if (index !== 0) { 
          p.style.fontWeight = 'normal'; 
        }
      });
      
      if (schoolName) {
        schoolName.innerHTML = `<h5 style="font-size: 16px; margin-top: 10px;">${schoolData.schoolName}</h5>`; // Increase the font size and set top margin
      }
  
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Modal Content</title>
            <style>
              @page {
                size: A4 landscape; /* Set to landscape */
                margin: 0; /* Remove default margins */
              }
              body {
                font-family: Arial, sans-serif;
                margin: 0; /* Remove body margin */
                padding: 10mm; /* Add padding to body */
              }
              .modal-content {
                margin: 0; /* Remove margin */
                width: 100%; /* Full width */
              }
              table {
                width: 100%; /* Full width for the table */
                border-collapse: collapse; /* Collapse borders */
              }
              th, td {
                border: 1px solid black; /* Add border */
                padding: 8px; /* Padding for cells */
                text-align: center; /* Center align text */
              }
              thead {
                background-color: #F0F0F0; /* Light gray background for thead */
              }

              
            </style>
          </head>
          <body>
            ${cloneContent.outerHTML} <!-- Include the modified outer HTML of the modal content -->
          </body>
        </html>
      `);
      printWindow.document.close(); 
      printWindow.focus(); // Focus the new window
      printWindow.print(); // Trigger print
      printWindow.close(); // Close the print window after printing
    } else {
      console.error('Print content not found');
    }
  };
  return (
    <div>
      <Sidebar />
      <AlertMessage message={alertMessage} show={showAlert} />
     <div className="main-content-of-page">

     <div>
        <h2 className='text-center mb-5' style={{ color: '#03365e', fontWeight: '600' }}>
          {language === "English" ? "Current Stock" : "सध्याचा साठा"}
        </h2>
        <form style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '20px' }}>
          <div className="form-group" style={{ flex: '1', minWidth: '10px' }}>
            <label htmlFor="year" style={{ display: 'block', marginBottom: '5px', fontSize: '1.1em' }}>
              {language === "English" ? "Year" : "वर्ष"}:
            </label>
            <select style={{ width: '100%', padding: '10px', fontSize: '1em' }} id="year" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} disabled={isYearDisabled}>
              <option value="">{language === "English" ? "Select Year" : "वर्ष निवडा"}</option>
              {[...Array(8)].map((_, index) => {
                const year = 2023 + index;
                return <option key={year} value={year}>{year}</option>;
              })}
            </select>
          </div>
          <div className="form-group" style={{ flex: '1', minWidth: '120px' }}>
            <label htmlFor="month" style={{ display: 'block', marginBottom: '5px', fontSize: '1.1em' }}>
              {language === "English" ? "Month" : "महिना"}:
            </label>
            <select style={{ width: '100%', padding: '10px', fontSize: '1em' }} id="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} disabled={isMonthDisabled} >
              <option value="">{language === "English" ? "Select Month" : "महिना निवडा"}</option>
              {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((month) => (
                <option key={month} value={month}>{language === "English" ? month : month}</option>
              ))}
            </select>
          </div>
          <div className="form-group" style={{ flex: '1', minWidth: '120px' }}>
            <label htmlFor="class" style={{ display: 'block', marginBottom: '5px', fontSize: '1.1em' }}>
              {language === "English" ? "Class" : "वर्ग"}:
            </label>
            <select style={{ width: '100%', padding: '10px', fontSize: '1em' }} id="class" value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}  disabled={isClassDisabled} >
              <option value="">{language === "English" ? "Select Class" : "वर्ग निवडा"}</option>
              <option value="1 To 5">{language === "English" ? "1 To 5" : "१ ते ५"}</option>
              <option value="6 To 8">{language === "English" ? "6 To 8" : "६ ते ८"}</option>
            </select>
          </div>
        </form>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap:'70px' ,margin
        : '30px auto' }}>
    <button 
        onClick={handleArrowClick} 
        style={{ 
            padding: '10px 20px', 
            backgroundColor: '#4CAF50', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: 'pointer', 
            marginRight: '10px' // Space between buttons
        }}
    >
        {language === "English" ? "View Data" : "डेटा पहा"}
    </button>

    <button 
        onClick={handleReport} 
        style={{ 
            padding: '10px 20px', 
            backgroundColor: '#d4a017', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: 'pointer' 
        }}
    >
        Report
    </button>
</div>
        {isLoading && (
          <div style={loadingoverlay}>
            <img src={loading} alt="Loading..."   width={'70px'}  height= {'70px'} />
          </div>
        )}
        {isTableVisible && (
          <div>
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', marginBottom:'20px' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px' }}>{language === "English" ? "Goods" : "वस्तू"}</th>
                <th style={{ border: '1px solid black',                padding: '8px' }}>{language === "English" ? "Previous Stock" : "मागील शिल्लक"}</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>{language === "English" ? "Received Goods" : "प्राप्त वस्तू"}</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>{language === "English" ? "Total Goods" : "एकूण वस्तू"}</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>{language === "English" ? "Items used for cooking food" : "अन्न शिजवण्यासाठी वापरलेल्या वस्तू"}</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>{language === "English" ? "Remaining Goods" : "शिल्लक वस्तू"}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.vastuName}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.magilSillak}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.praptVastu}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.totalVastu}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.useVastu}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.shillakVastu}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        )}
</div>


{showModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '80%',
              maxWidth: 'fit-content', // Set a max width for better fitting
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
              maxHeight: '90%',
              overflowY: 'auto',
              position: 'relative',
            }}>



              <div className="modal-content">
                {/* Table for Report Data */}
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '30px',
                  fontFamily: 'Arial, sans-serif',
                  border: '1px solid black',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                  {/* Header as part of the Table */}
                  <thead>
                    {schoolData && (
                      <tr>
                        <th colSpan="9" style={{

                          color: 'black',
                          padding: '5px',
                          borderRadius: '10px 10px 0 0',
                          textAlign: 'center',
                          lineHeight: '0.4',
                          borderBottom: '1px solid black',
                          borderLeft: '1px solid black',
                          borderRight: '1px solid black',
                          borderTop: '1px solid black',
                          fontSize: '13px'
                        }}>
                          <p><strong><h5> {schoolData.schoolName}</h5></strong></p>
                          <p>{schoolData.sansthaName}</p>
                          <p>{language === "English" ? "Taluka:" : "तालुका:"} {schoolData.taluka} , {language === "English" ? "Jilha:" : "जिल्हा:"} {schoolData.jilha} </p>
                          <p>{language === "English" ? "Mobile Number:" : "मोबाइल नंबर:"} {schoolData.mobileNumber} , {language === "English" ? "Email:" : "ईमेल:"} {schoolData.email}</p>

                          <p style={{ display: 'inline-block', backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px', fontSize: '18px' }}>
  {language === "English" ? 
    `School Nutrition Scheme - Form B (Class ${classMapping[selectedClass].English})` : 
    `शालेय पोषण आहार योजना- प्रपत्र ब (इ. ${classMapping[selectedClass].Marathi})`}
</p>
                        </th>
                      </tr>
                    )}
                    {/* Column Headers */}
                    <tr style={{ color: 'black' }}>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Sr.No" : "क्रमांक"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Items Name" : "वस्तूचे नाव"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Previous Item Stocks (Kilograms)" : "मागील शिल्लक वस्तू(किलोग्रॅम)"}</th>
                       <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Goods Received In Current Month (Kilograms)" : "चालू महिन्यात प्राप्त वस्तू (किलोग्रॅम)"}</th>
                       <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Total Goods (Kilograms)" : "एकूण वस्तू (किलोग्रॅम)"}</th>
                       <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Items Used For Cooking Food (Kilograms)" : "अन्न शिजवण्यासाठी वापरलेल्या वस्तू (किलोग्रॅम)"}</th>
                       <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Remaining Stock (Kilograms)" : "शिल्लक वस्तू (किलोग्रॅम)"}</th>

<th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Demand For TheNext 2 Months(Kilograms)" : "पुढील 2 महिन्यांसाठी मागणी (किलोग्रॅम)"}</th>
                       <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                        width:'100px',
                      }}>{language === "English" ? "Remark" : "शेरा"}</th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
  {modalData.map((row, index) => (
    <tr key={index}>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', fontWeight: '500', borderRight: '1px solid black', borderLeft: '1px solid black' }}>{index + 1}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.content}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.previousStock}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.receivedGoods}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.totalGoods}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.itemsUsed}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>{row.remainingStock}</td>
      <td style={{ padding: '7px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black' }}>
      {row.totalDemand}
      </td>
      <td style={{ padding: '3px', borderBottom: '1px solid black', textAlign: 'center', color: 'black', borderRight: '1px solid black',width:'100px' }}>
                          <textarea 
                            type="text" 
                            value={userInputs[index] || ''} 
                            onChange={(e) => handleInputChange(index, e.target.value)} 
                            style={{ width: '140px', height:'100%', border: 'none',outline: 'none', fontSize:'10px',overflow: 'hidden', resize: 'none'}} 
                          />
                        </td>
    </tr>
  ))}
</tbody>

                </table>
              </div>


              <div style={{ textAlign: 'right', marginTop: '20px' }}>

                <button onClick={handlePrint} style={{
          padding: '12px 25px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.2s ease',
          marginRight: '10px',
        }}>
         {language === "English" ? "Print" : "प्रिंट करा"} 
        </button>

                <button onClick={closeModal} style={{
                  padding: '12px 25px',
                  backgroundColor: '#FF4136',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.2s ease',
                }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#E04A3F'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#FF4136'}
                >
               {language === "English" ? "Close" : " बंद करा"}                          
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Sadhyachasatha;

const loadingoverlay = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
}