import React, { useState, useEffect,useRef  } from 'react';
import './Age.css'; 
import Sidebar from '../../components/Sidebar';

function AgewiseReport() {
  const [students, setStudents] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [ageCountsByClass, setAgeCountsByClass] = useState({});
  const [selectedCell, setSelectedCell] = useState({ ageGroup: '', className: '', students: [], position: { x: 0, y: 0 } });

  const [udiseNumber, setUdiseNumber] = useState("");
  const detailRef = useRef(null);

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!udiseNumber) {
        console.error('UDISE number is not set');
        return;
      }
  
      try {
        const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`;
        console.log("url", url);
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data && typeof data === 'object') {
            const allStudents = [];
            const classSet = new Set();
  
            Object.values(data).forEach(student => {
              if (student && student.currentClass && student.dob) {
                const classNumber = student.currentClass;
                const dob = student.dob;
  
                // Validate class number
                if (classNumber) {
                  allStudents.push(student);
                  classSet.add(classNumber);
                }
              }
            });
  
            const classOrder = [
              language === 'English' ? 'Class I' : 'इयत्ता पहिली',
              language === 'English' ? 'Class II' : 'इयत्ता दुसरी',
              language === 'English' ? 'Class III' : 'इयत्ता तिसरी',
              language === 'English' ? 'Class IV' : 'इयत्ता चौथी',
              language === 'English' ? 'Class V' : 'इयत्ता पाचवी',
              language === 'English' ? 'Class VI' : 'इयत्ता सहावी',
              language === 'English' ? 'Class VII' : 'इयत्ता सातवी',
              language === 'English' ? 'Class VIII' : 'इयत्ता आठवी',
              language === 'English' ? 'Class IX' : 'इयत्ता नववी',
              language === 'English' ? 'Class X' : 'इयत्ता दहावी',
              language === 'English' ? 'Class XI' : 'इयत्ता अकरावी',
              language === 'English' ? 'Class XII' : 'इयत्ता बारावी'
            ];
            const sortedClassOptions = Array.from(classSet).sort((a, b) => {
              return classOrder.indexOf(a) - classOrder.indexOf(b);
            });

            setStudents(allStudents);
            setClassOptions(sortedClassOptions);
          }
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [udiseNumber]);
  
  useEffect(() => {
    if (students.length > 0) {
      const ageCountsObj = {};

      students.forEach(student => {
        const age = calculateAge(student.dob);
        const studentClass = student.currentClass;
        const ageGroup = `${age}-${age + 1}`;

        if (!ageCountsObj[ageGroup]) {
          ageCountsObj[ageGroup] = {};
        }

        if (!ageCountsObj[ageGroup][studentClass]) {
          ageCountsObj[ageGroup][studentClass] = 0;
        }

        ageCountsObj[ageGroup][studentClass]++;
      });

      // Sort age groups
      const sortedAgeGroups = Object.keys(ageCountsObj).sort((a, b) => {
        const [aMin] = a.split('-').map(Number);
        const [bMin] = b.split('-').map(Number);
        return aMin - bMin;
      });

      const sortedAgeCountsByClass = {};
      sortedAgeGroups.forEach(ageGroup => {
        sortedAgeCountsByClass[ageGroup] = ageCountsObj[ageGroup];
      });

      setAgeCountsByClass(sortedAgeCountsByClass);
    }
  }, [students]);

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const ageDiffMs = Date.now() - dobDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleCellClick = (ageGroup, className, event) => {
    const selectedStudents = students.filter(student => {
      const age = calculateAge(student.dob);
      const studentAgeGroup = `${age}-${age + 1}`;
      return studentAgeGroup === ageGroup && student.currentClass === className;
    });

    const rect = event.target.getBoundingClientRect();
    const xPos = rect.left;
    const yPos = rect.top + rect.height;

    setSelectedCell({ ageGroup, className, students: selectedStudents, position: { x: xPos, y: yPos } });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (detailRef.current && !detailRef.current.contains(event.target)) {
        setSelectedCell({ ageGroup: '', className: '', students: [], position: { x: 0, y: 0 } });
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [detailRef]);

  
  const handlePrint = () => {
    const printTitleElement = document.getElementById('print-title');
    if (printTitleElement) {
      let logoHTML = '';
      if (logoURL) {
        logoHTML = `<img src="${logoURL}" alt="School Logo" style="width: 40px; height: 40px; margin-right: 10px;" />`;
      }
  
      let schoolDataHTML = `${schoolName}<br />Taluka: ${taluka}&nbsp;&nbsp;&nbsp;District: ${jilha}`;
  
      printTitleElement.innerHTML = `
        <div style="display: flex; align-items: center; padding-top: -30px;">
          <div style="flex: 1; text-align: left; margin-left: 20px;">
            ${logoHTML}
          </div>
          <div style="flex: 2; text-align: center;">
            <div style="font-size: 8px; font-weight: 300; margin-bottom: 10px; margin-top: -5px;">${sansthaName}</div>
            <div style="font-size: 9px; font-weight: 400; margin-top: 0px;">${schoolDataHTML}</div>
          </div>
          <div style="flex: 1;"></div>
        </div>
      `;
      
      // Delay the print call to allow content rendering
      setTimeout(() => {
        window.print();
        
        // Clean up the print title element after printing
        if (printTitleElement) {
          printTitleElement.innerHTML = ''; 
          printTitleElement.style.fontSize = ''; 
          printTitleElement.style.fontWeight = ''; 
          printTitleElement.style.paddingBottom = '14px'; 
        }
      }, 1000); // 1000 ms delay
    }
  };

  const [schoolName, setSchoolName] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [sansthaName, setsansthaName] = useState("");
  const [logoURL, setLogoURL] = useState(null);
  
  const fetchSchoolName = async () => {
    if (udiseNumber) {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        const schoolData = await response.json();
        if (schoolData) {
          if (schoolData.schoolName) {
            setSchoolName(schoolData.schoolName);
          }
          if (schoolData.taluka) {
            setTaluka(schoolData.taluka);
          }
          if (schoolData.jilha) {
            setJilha(schoolData.jilha);
          }
          if (schoolData.sansthaName) {
            setsansthaName(schoolData.sansthaName);
          }
          if (schoolData.schoolLogo) {
            setLogoURL(schoolData.schoolLogo);
          }
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    }
  };
  useEffect(() => {
  fetchSchoolName();
}, [udiseNumber]);
  return (
    <div>
    <Sidebar/>
    <div className='main-content-of-page'>
    <style>
          {`
            @media print {
              .table th,
              .table td {
                background-color: transparent !important;
                -webkit-print-color-adjust: exact;
              }
              .bg-secondary {
                background-color: transparent !important;
              }
              .print-button {
                display: none;
              }
              .custom-heading-text{
                margin: -5px auto;
              }
            
          `}
        </style>

      <button onClick={handlePrint} className="print-button btn btn-primary" style={{ position: "absolute", right: 20, top: 90 }}>Print</button>
      <center style={{borderBottom : '0.5px solid gray', padding:'-10px', marginBottom:'20px' }}>
          <div id="print-title" className="text-center" style={{ marginTop: '10px', fontWeight: 'bold' }}></div>
          <div className="custom-heading"  style={{paddingBottom:'-20px', marginTop:'-10px'}}>
            <h2 className="custom-heading-text text-center" style={{paddingBottom:'-20px', marginBottom:'-10px'}}>{language === "English" ? "Age Wise Report" : "वयानुसार अहवाल"}</h2>
            
          </div>
        </center>
      
      <div >
        <table className="styled-table ">
          <thead>
            <tr>
              <th style={{backgroundColor:'#bcdcff' ,}}className="age-group-header"><center>{language === "English" ? "Age Group" : "वयोगट"}</center></th>
              <th  style={{backgroundColor:'#bcdcff' }} colSpan={classOptions.length}><center>{language === "English" ? "Class" : "वर्ग"}</center></th>
            </tr>
            <tr>
  <th style={{ backgroundColor: '#bcdcff', border: '1px solid black' }}></th>
  {classOptions.map((classNumber, index) => (
    <th style={{ backgroundColor: '#bcdcff', border: '1px solid black' }} key={index}>
      {classNumber}
    </th>
  ))}
</tr>

          </thead>
          <tbody>
            {Object.keys(ageCountsByClass).map((ageGroup, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className="age-group-header">{ageGroup}</td>
                  {classOptions.map((className, idx) => {
                    const count = ageCountsByClass[ageGroup] && ageCountsByClass[ageGroup][className]
                      ? ageCountsByClass[ageGroup][className]
                      : 0;
                    return (
                      <td
                        key={idx}
                        className="class-column"
                        onClick={(event) => handleCellClick(ageGroup, className, event)}
                        style={{ color: count === 0 ? 'black' : 'green', position: 'relative' }}
                      >
                        {count}
                      </td>
                    );
                  })}
                </tr>
                {selectedCell.ageGroup === ageGroup && selectedCell.className === classOptions.find(cls => cls === selectedCell.className) && selectedCell.students.length > 0 && (
                  <tr className="student-detail-row"  ref={detailRef}  style={{ left: selectedCell.position.x - 140, top: selectedCell.position.y + 10 }}>
                    <td colSpan={classOptions.length + 1}>
                      <table className="student-info-table">
                        <thead>
                          <tr>
                            <th className='text-center'>{language === "English" ? "Name" : "नाव"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedCell.students.map((student, idx) => (
                            <tr key={idx}>
                              <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      </div>
    </div>
  );
}

export default AgewiseReport;