import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import './NewMinority.css'

const Minority = () => {
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedCaste, setSelectedCaste] = useState('');
  const [selectedMinority, setSelectedMinority] = useState('');
  const [selectedRegister, setSelectedRegister] = useState('');
  const [students, setStudents] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [registerOptions, setRegisterOptions] = useState([]);

  const [udiseNumber, setUdiseNumber] = useState("");

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  const romanToInt = {
    I: 1,
    II: 2,
    III: 3,
    IV: 4,
    V: 5,
    VI: 6,
    VII: 7,
    VIII: 8,
    IX: 9,
    X: 10,
    XI: 11,
    XII: 12,
  };
  
  const marathiToInt = {
    'पहिली': 1,
    'दुसरी': 2,
    'तिसरी': 3,
    'चौथी': 4,
    'पाचवी': 5,
    'सहावी': 6,
    'सातवी': 7,
    'आठवी': 8,
    'नववी': 9,
    'दहावी': 10,
    'अकरावी':11,
    'बारावी': 12
  };

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    const fetchAllCaste = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (response.ok) {
          const data = await response.json();

          if (data && typeof data === 'object') {
            const castesSet = new Set(
              Object.values(data)
                .filter(student => student !== null && typeof student === 'object')
                .map(student => student.caste)
            );
            setCasteOptions(Array.from(castesSet));
          } else {
            throw new Error('Invalid data format');
          }
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching castes:', error);
      }
    };
    fetchAllCaste();
  }, [udiseNumber]);

  useEffect(() => {
    const fetchAllClasses = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          if (data && typeof data === 'object') {
            const classesSet = new Set(
              Object.values(data)
                .filter(student => student !== null && typeof student === 'object')
                .map(student => student.currentClass)
            );
            setClassOptions(Array.from(classesSet));
          } else {
            throw new Error('Invalid data format');
          }
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };

    fetchAllClasses();
  }, [udiseNumber]);
  const fetchDataByFilters = async () => {
    try {
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (response.ok) {
        const data = await response.json();
        if (data && typeof data === 'object') {
          let filteredData = Object.values(data)
            .filter(student => student !== null && typeof student === 'object' && student.isMinority === true);
          
          // Apply additional filters if needed
          if (selectedGender !== '') {
            filteredData = filteredData.filter(student => student.gender === selectedGender);
          }
          if (selectedCaste !== '') {
            filteredData = filteredData.filter(student => student.caste === selectedCaste);
          }
          if (selectedClass !== '') {
            filteredData = filteredData.filter(student => student.currentClass === selectedClass);
          }
          if (selectedRegister !== '') {
            filteredData = filteredData.filter(student => student.registerNo === selectedRegister);
          }
          
          setStudents(filteredData);
        } else {
          throw new Error('Invalid data format');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    fetchDataByFilters();
  }, [selectedGender, selectedCaste, selectedClass, selectedRegister, udiseNumber, selectedMinority]);

  useEffect(() => {
    const fetchRegisterOptions = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          if (data && typeof data === 'object') {
            const filteredData = Object.values(data).filter(student => student !== null);
            const registers = filteredData.map(student => student.registerNo);
            setRegisterOptions(registers);
          } else {
            throw new Error('Invalid data format');
          }
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching registers:', error);
      }
    };

    fetchRegisterOptions();
  }, [udiseNumber]);

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleClearData = () => {
    window.location.reload();
    setSelectedGender('');
    setSelectedMinority('');
    setSelectedClass('');
    setSelectedCaste('');
    setSelectedRegister('');
    setStudents([]);
  };
  
  const [schoolName, setSchoolName] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [sansthaName, setsansthaName] = useState("");
  const [logoURL, setLogoURL] = useState(null);
  const fetchSchoolName = async () => {
    if (udiseNumber) {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        const schoolData = await response.json();
        if (schoolData) {
          if (schoolData.schoolName) {
            setSchoolName(schoolData.schoolName);
          }
          if (schoolData.taluka) {
            setTaluka(schoolData.taluka);
          }
          if (schoolData.jilha) {
            setJilha(schoolData.jilha);
          }
          if (schoolData.sansthaName) {
            setsansthaName(schoolData.sansthaName);
          }
          if (schoolData.schoolLogo) {
            setLogoURL(schoolData.schoolLogo);
          }
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    }
  };
  useEffect(() => {
  fetchSchoolName();
}, [udiseNumber]);

const handlePrint = () => {
  const printTitleElement = document.getElementById('print-title');
  if (printTitleElement) {
    let logoHTML = '';
    if (logoURL) {
      logoHTML = `<img src="${logoURL}" alt="School Logo" style="width: 40px; height: 40px; margin-right: 10px;" />`;
    }

    let schoolDataHTML = `${schoolName}<br />Taluka: ${taluka}&nbsp;&nbsp;&nbsp;District: ${jilha}`;

    printTitleElement.innerHTML = `
      <div style="display: flex; align-items: center; padding-top: -20px;">
        <div style="flex: 1; text-align: left; margin-left: 20px;">
          ${logoHTML}
        </div>
        <div style="flex: 2; text-align: center;">
          <div style="font-size: 8px; font-weight: 300; margin-bottom: 10px; margin-top: -5px;">${sansthaName}</div>
          <div style="font-size: 9px; font-weight: 400; margin-top: 0px;">${schoolDataHTML}</div>
        </div>
        <div style="flex: 1;"></div>
      </div>
    `;
    
    // Delay the print call to allow content rendering
    setTimeout(() => {
      window.print();
      
      // Clean up the print title element after printing
      if (printTitleElement) {
        printTitleElement.innerHTML = ''; 
        printTitleElement.style.fontSize = ''; 
        printTitleElement.style.fontWeight = ''; 
        printTitleElement.style.paddingBottom = '14px'; 
      }
    }, 1000); // 1000 ms delay
  }
};
  return (
    <div >
      <Sidebar />
      <div className='main-content-of-page'>
      <style>
          {`
            @media print {
              .table th,
              .table td {
                background-color: transparent !important;
                -webkit-print-color-adjust: exact;
              }
              .bg-secondary {
                background-color: transparent !important;
              }
              .print-button {
                display: none;
              }
              .custom-heading-text{
                margin: -5px auto;
              }
            
            }
          `}
        </style>
        
        <center style={{borderBottom : '0.5px solid gray', padding:'-10px', marginBottom:'20px' }}>
          <div id="print-title" className="text-center" style={{ marginTop: '10px', fontWeight: 'bold' }}></div>
          <div className="custom-heading"  style={{paddingBottom:'-20px', marginTop:'-10px'}}>
            <h2 className="custom-heading-text text-center" style={{paddingBottom:'-20px', marginBottom:'-10px'}}>{language === "English" ? "Minority Report" : "अल्पसंख्यांक अहवाल"}</h2>
            
          </div>
        </center>
        <div>
          <center>
            <label className='genderOption' htmlFor="gender" style={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '15px', marginRight: '10px' }}>{language === "English" ? "Select Gender" : "लिंग निवडा"}</label>
            <select id="gender" value={selectedGender} onChange={handleGenderChange} className="brown-dropdown dropdown" style={{ marginRight: '10px', width:'90px' }}>
              <option value="">{language === "English" ? "Select" : "निवडा"}</option>
              <option value="male">{language === "English" ? "Male" : "मुलगा"}</option>
              <option value="female">{language === "English" ? "Female" : "मुलगी"}</option>
            </select>

            <label htmlFor="classDropdown" className="dropdown-label" style={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '15px' }}>{language === "English" ? "Select Class" : "वर्ग निवडा"}</label>
            <select id="classDropdown" value={selectedClass} onChange={handleClassChange} className="brown-dropdown dropdown" style={{ width:'90px' }}>
              <option value="">{language === "English" ? "Select" : "निवडा"}</option>
              

              {classOptions
  .filter(cls => cls !== null && cls !== undefined && cls !== '') // Filter out null and undefined classes
  .sort((a, b) => {
    const classA = a.split(' ')[1];
    const classB = b.split(' ')[1];
    let numA, numB;

    if (language === "English") {
      numA = romanToInt[classA] !== undefined ? romanToInt[classA] : 0; // Default to 0 if not found
      numB = romanToInt[classB] !== undefined ? romanToInt[classB] : 0; // Default to 0 if not found
    } else {
      numA = marathiToInt[classA] !== undefined ? marathiToInt[classA] : 0; // Default to 0 if not found
      numB = marathiToInt[classB] !== undefined ? marathiToInt[classB] : 0; // Default to 0 if not found
    }

    return numA - numB; // Sort based on the numerical value
  })
  .map((cls, index) => (
    <option key={index} value={cls}>
      {cls}
    </option>
  ))}
            </select>

          </center>
          <div>
            <button className='btn btn-primary' onClick={handleClearData} style={{ marginLeft: '10px' }}>Clear</button>
            <button onClick={handlePrint} className="btn btn-primary float-end">Print</button>
          </div>
        </div>

        <br />

        <div className='table-responsive'>
          <table className="table table-bordered mb-5 ">
            <thead>
              <tr>
                <th>{language === "English" ? "Sr. No" : "अनु क्रमांक "}</th>
                <th>{language === "English" ? "Student ID" : "विद्यार्थी क्रमांक"}</th>
                <th>{language === "English" ? "Name" : "नाव"}</th>
                <th>{language === "English" ? "Adhar No" : "आधार क्रमांक"}</th>
                <th>{language === "English" ? "Is Minority" : "अल्पसंख्याक"}</th>
                <th>{language === "English" ? "Gender" : "लिंग"}</th>
                <th>{language === "English" ? "Country" : "देश"}</th>
                <th>{language === "English" ? "Caste" : "जात"}</th>
                <th>{language === "English" ? "Class" : "वर्ग"}</th>
                <th>{language === "English" ? "District" : "जिल्हा"}</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(students) && students.length > 0 ? (
                students.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{student.studentId}</td>
                    <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
                    <td>{student.stdAdharNo}</td>
                    <td>
  {language === 'English'
    ? (student.isMinority === true ? 'Yes' : 'No')
    : student.isMinority === true
    ? 'होय'
    : 'नाही'}
</td>

                    <td>
          {language === 'English'
            ? student.gender
            : student.gender === 'female'
            ? 'मुलगी'
            : student.gender === 'male'
            ? 'मुलगा'
            : student.gender}
        </td>
                    <td>{student.country}</td>
                    <td>{student.caste}</td>
                    <td>{student.currentClass}</td>
                    <td>{student.district}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11">{language === "English" ? "No data available" : " माहिती उपलब्ध नाही "}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Minority;