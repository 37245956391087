import React, { useState, useEffect, useMemo  } from 'react';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';

function Magani() {
  const today = new Date();

// Add one month to today's date
const oneMonthLater = new Date(today);
oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

// Format both dates to YYYY-MM-DD format
const formattedToday = today.toISOString().split('T')[0];
const formattedOneMonthLater = oneMonthLater.toISOString().split('T')[0];

const [formData, setFormData] = useState({
  fromDate: formattedToday,
  toDate: formattedOneMonthLater,  // Set default to one month after today
  vastuName: '',
  customVastuName: '',
  magani: '',
});

  const formatDate = (dateString) => {
    const date = new Date(dateString); 
    const options = { day: '2-digit', month: 'short', year: 'numeric' }; 
    const formattedDate = date.toLocaleDateString('en-GB', options); 
    const [day, month, year] = formattedDate.split(' ');
    return `${day}-${month}-${year}`;
};

const formattedFromDate = useMemo(() => formatDate(formData.fromDate), [formData.fromDate]);
const formattedToDate = useMemo(() => formatDate(formData.toDate), [formData.toDate]);

  const [dataList, setDataList] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const udiseNumber = localStorage.getItem("udiseNumber");
  const language = localStorage.getItem("language");

  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [schoolData, setSchoolData] = useState(null); 


  const [alertMessage, setAlertMessage] = useState("");
const [showAlert, setShowAlert] = useState(false);
useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  const vastuOptions = language === 'English' 
  ? ['Rice', 'Mugdal', 'Turdal', 'Masurdal', 'Matki', 'Moong', 'Cowpea', 'Gram', 'Pease', 'Cumin', 'Mustard', 'Turmeric', 'Chili', 'Oil', 'Salt', 'Garam Masala', 'Vegetables', 'Other'] 
  : ['तांदूळ', 'मुगडाळ', 'तुरडाळ', 'मसुरडाळ', 'मटकी', 'मूग', 'चवळी', 'हरभरा', 'वाटाणा', 'जिरे', 'मोहरी', 'हळद', 'मिरची', 'तेल', 'मीठ', 'गरम मसाला', 'भाजी पाला', 'Other'];

  useEffect(() => {
    fetchData();
  }, [formData.fromDate, formData.toDate]); 


  const fetchData = async () => {
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/magani.json`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (!data) {
        setDataList([]);
        return;
      }
  
      const formattedData = [];
  
      Object.entries(data).forEach(([dateKey, contentData]) => {
        Object.entries(contentData).forEach(([vastuName, maganiValue]) => {
          formattedData.push({
            fromDate: dateKey,
            vastuName: vastuName,
            magani: maganiValue,
          });
        });
      });

      let filteredData = formattedData;
  
      // Filter data between selected fromDate and toDate
      const startDate = new Date(formData.fromDate);
      const endDate = formData.toDate ? new Date(formData.toDate) : null;

      if (formData.fromDate) {
        filteredData = filteredData.filter(item => {
          const itemDate = new Date(item.fromDate);
          if (endDate) {
            // Filter by both fromDate and toDate
            return itemDate >= startDate && itemDate <= endDate;
          } else {
            // Filter by only fromDate
            return itemDate >= startDate;
          }
        });
      }
  console.log("datalist",dataList);
  
      setDataList(filteredData); // Update the table with filtered data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'vastuName') {
        const selectedData = dataList.find(item => item.vastuName === value);
        if (selectedData) {
            setFormData(prevState => ({ ...prevState, magani: selectedData.magani })); // Set the magani value
            setEditIndex(dataList.indexOf(selectedData)); // Set editIndex to the index of the selected data
        } else {
            setFormData(prevState => ({ ...prevState, magani: '' })); // Clear magani if not found
            setEditIndex(null); // Reset editIndex if no valid selection
        }
    }
};
  const handleMaganiChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setFormData({ ...formData, magani: value });
  };

  const saveToDatabase = async (nameToSave, maganiValue) => {
    const { fromDate } = formData;
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/magani/${fromDate}.json`;

    const dataToSave = { [nameToSave]: maganiValue };

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSave),
      });

      if (response.ok) {
        console.log('Data saved successfully');
        fetchData(); 
      } else {
        console.error('Failed to save data');
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  const deleteFromDatabase = async (fromDate, vastuName) => {
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/magani/${fromDate}/${vastuName}.json`;
  
    try {
      const response = await fetch(url, { method: 'DELETE' });
  
      if (response.ok) {
        console.log(`${vastuName} deleted successfully from ${fromDate}`);
        fetchData();  // Refresh data after deletion
      } else {
        console.error('Failed to delete data');
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  const handleSave = () => {
 console.log("clicked");
 
  
    const { fromDate, customVastuName, vastuName, magani } = formData;
    const nameToSave = vastuName === 'Other' && customVastuName ? customVastuName : vastuName;

    console.log({ fromDate, nameToSave, magani }); // Debugging log


    if (!fromDate || !nameToSave || !magani) {
      setAlertMessage('Please fill out all fields');
      return;
  }
 
    saveToDatabase(nameToSave, magani);
  
    const newData = { ...formData, vastuName: nameToSave, customVastuName: '' };
  
    if (editIndex !== null) {
      const updatedDataList = [...dataList];
      updatedDataList[editIndex] = newData;
      console.log("updatedDataList", updatedDataList);
      
      setDataList(updatedDataList);
      setEditIndex(null);
    } else {
      setDataList([...dataList, newData]);
    }
  
    setFormData({ fromDate: formattedToday, vastuName: '', customVastuName: '', magani: '' });
  
    // Show success alert after saving
    setAlertMessage('Data saved successfully!');
  };
  

  const handleDelete = (index) => {
    const { fromDate, vastuName } = dataList[index];  // Get the fromDate and vastuName (content)
    deleteFromDatabase(fromDate, vastuName);  // Delete only that content and value
  
    const updatedDataList = dataList.filter((_, i) => i !== index);  // Update the local state by removing the deleted item
    setDataList(updatedDataList);
  
    // Show success alert after deletion
    setAlertMessage('Data deleted successfully!');
  };
  

  const handleEdit = (index) => {
    const selectedData = dataList[index]; // Get the selected entry
    console.log("selectedData",selectedData);
    
    if (selectedData) {
      setFormData({ 
        fromDate: selectedData.fromDate, // Set the fromDate from the selected entry
        vastuName: selectedData.vastuName,
        customVastuName: '', // Reset customVastuName
        magani: selectedData.magani,
      });
      setEditIndex(index); // Set the edit index to indicate which entry is being edited
    } else {
      console.error('No data found for the selected index');
    }
  };
  
  
  useEffect(() => {
    fetchSchoolData(); 
  });
  const fetchSchoolData = () => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        setSchoolData(data); 
      })
      .catch(error => {
        console.error('Error fetching school data from Firebase:', error);
      });
  };
  const handleReport = () => {

    setModalData(dataList);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false); 
  };
  const handlePrint = () => {
    const printContent = document.querySelector('.modal-content');
  
    if (printContent) {
      const cloneContent = printContent.cloneNode(true);
  
      const schoolName = cloneContent.querySelector('p'); 
      const otherDetails = cloneContent.querySelectorAll('p'); 
  
      otherDetails.forEach((p, index) => {
        if (index !== 0) { 
          p.style.fontWeight = 'normal'; 
        }
      });
      
      if (schoolName) {
        schoolName.innerHTML = `<h5 style="font-size: 16px; margin-top: 10px;">${schoolData.schoolName}</h5>`; // Increase the font size and set top margin
      }
  
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Modal Content</title>
            <style>
              @page {
                size: A4 portrait;
                margin-left: 5mm; /* Set left margin */
                margin-right: 5mm; /* Set right margin */
                margin-top: 10mm; /* Optional: Set top margin */
                margin-bottom: 10mm; /* Optional: Set bottom margin */
              }
              body {
                font-family: Arial, sans-serif;
                margin: 0; /* Remove body margin */
              }
              .modal-content {
                margin: 20px; /* Add margin to the modal content */
              }
              thead {
                            background-color: #F0F0F0; /* Set very light gray background color for <thead> */

              }
            </style>
          </head>
          <body>
            ${cloneContent.outerHTML} <!-- Include the modified outer HTML of the modal content -->
          </body>
        </html>
      `);
      printWindow.document.close(); 
      printWindow.focus(); // Focus the new window
      printWindow.print(); // Trigger print
      printWindow.close(); // Close the print window after printing
    } else {
      console.error('Print content not found');
    }
  };
  const inputStyle = {
    marginTop: '10px',
    width: '130px',
    maxWidth: '300px',
    height:'35px', 
    marginRight: '20px', 
    fontSize:'14px', 
    padding:'8px',
    borderRadius:'5px',
    border:'1px solid gray'
  };
  const tableCellStyle = {
    padding: '8px',
    border: '1px solid black',
    fontWeight:'600',
    textAlign: "center",
  };
  return (
    <div>
      <Sidebar />
 <AlertMessage message={alertMessage} show={showAlert}/>

      <div className="main-content-of-page">
      <div style={{
  maxWidth: "800px",
  margin: "1% auto",
  padding: "30px",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
  borderRadius: "5px",
  backgroundColor: "rgb(255, 255, 255)",
  border: "1px solid gray"
}}>
        <h2 className='text-center mb-5' style={{ color: '#03365e', fontWeight: '600' }}>{language === "English" ? " Demand " : "मागणी "}</h2>

        <div style={{ display: 'flex', justifyContent: 'center'}}>
          
            <div>
              <div>
                <label style={{  marginBottom: '5px', fontSize:'14px'}}>{language === "English" ? " From Date " : "या तारखेपासून "}:</label>
                <input type="date" name="fromDate" value={formData.fromDate} onChange={handleInputChange} style={inputStyle}/>
              </div>
            </div>
            <div style={{ marginBottom: '10px', position: 'relative' }}>
  
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div>
      <label style={{ marginBottom: '5px', fontSize: '14px' }}>
        {language === "English" ? "Menu content" : "मेनू सामग्री"}:
      </label>
      <select name="vastuName" value={formData.vastuName} onChange={handleInputChange} style={inputStyle}>
        <option value="">{language === "English" ? "Select content" : "सामग्री निवडा"}</option>
        {vastuOptions.map((name, index) => (
          <option key={index} value={name}>{name}</option>
        ))}
      </select>
    </div>
  </div>

  {formData.vastuName === 'Other' && (
    <div style={{ position: 'absolute', top: '100%', left: '0', zIndex: '1', backgroundColor: 'white', paddingTop: '10px' }}>
      <label style={{ marginBottom: '5px', fontSize: '14px' }}>
        {language === "English" ? "Other content" : "इतर सामग्री"}:
      </label>
      <input 
        type="text" 
        name="customVastuName" 
        value={formData.customVastuName} 
        onChange={handleInputChange} 
        placeholder="Enter content" 
        style={{...inputStyle, width:'130px',marginRight: '0px',marginTop: '25px'}} 
      />
    </div>
  )}
</div>

            <div style={{ marginBottom: '10px' }}>
              <label style={{  marginBottom: '5px', fontSize:'14px'}}>{language === "English" ? " Demand " : "मागणी "}:</label>
              <input type="number" name="magani" value={formData.magani}  onChange={(e) => {if (e.target.value.length <= 7) {handleMaganiChange(e);}}}  style={inputStyle} />
              
            </div>
           
          
        </div>
        <div className='mt-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <label style={{ fontSize:'14px', marginLeft:'10px'}}>
      {language === "English" ? "To Date" : "या तारखेपर्यंत"}:
    </label>
    <input 
      type="date" 
      name="toDate" 
      value={formData.toDate} 
      onChange={handleInputChange} 
      style={language === "English" ? { ...inputStyle, marginLeft: '10px' } : { ...inputStyle, marginLeft: '7px' }} 
    />
  </div>

  {/* Buttons: Save and Report */}
  <div style={{ display: 'flex', gap: '20px', marginRight: '25px' }}>
    <button 
        onClick={handleSave}  
        style={{ padding: '7px 12px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '4px'}}
       
    >
        {editIndex !== null ? 'Update' : 'Save'}
    </button>
    <button 
        type="button" 
        onClick={handleReport} 
        style={{ padding: '8px 10px', backgroundColor: '#d4a017', color: '#fff', border: 'none', borderRadius: '4px' }}
    >
        Report
    </button>
</div>
</div>
     

  
<hr/>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
  <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px', backgroundColor: '#fff', overflow: 'hidden', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
    <thead>
      <tr style={{ background: '#4CAF50', color: 'black' }}>
        <th style={tableCellStyle}>{language === "English" ? " Date " : "तारीख "}</th>
        <th style={tableCellStyle}>{language === "English" ? " Menu content " : "मेनू सामग्री "}</th>
        <th style={tableCellStyle}>{language === "English" ? " Demand (In Kilo Gram)" : "मागणी (In Kilo Gram)"}</th>
        <th style={tableCellStyle}>Actions</th>
      </tr>
    </thead>
    <tbody>
              {dataList.length > 0 ? (
                dataList.map((data, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                    <td>{formatDate(data.fromDate)}</td>
                    <td>{data.vastuName}</td>
                    <td>{data.magani}</td>
                    <td>
                      <button 
                        onClick={() => handleEdit(index)} 
                        style={{ marginRight: '5px', background: '#2196F3', color: '#fff', border: 'none', borderRadius: '3px', padding: '5px' }}>
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button 
                        onClick={() => handleDelete(index)} 
                        style={{ background: '#f44336', color: '#fff', border: 'none', borderRadius: '3px', padding: '5px' }}>
                       <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>
                    {language === 'English' ? 'No records found within selected dates.' : 'निवडलेल्या तारखेमध्ये कोणतेही नोंदी सापडल्या नाहीत.'}
                  </td>
                </tr>
              )}
            </tbody>
  </table>
</div>

</div>


{showModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '80%',
              maxWidth: '800px', // Set a max width for better fitting
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
              maxHeight: '90%',
              overflowY: 'auto',
              position: 'relative',
            }}>



              <div className="modal-content">
                {/* Table for Report Data */}
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '30px',
                  fontFamily: 'Arial, sans-serif',
                  border: '1px solid black',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                 
                  <thead>
                    {schoolData && (
                      <tr>
                        <th colSpan="3" style={{

                          color: 'black',
                          padding: '5px',
                          borderRadius: '10px 10px 0 0',
                          textAlign: 'center',
                          lineHeight: '0.4',
                          borderBottom: '1px solid black',
                          borderLeft: '1px solid black',
                          borderRight: '1px solid black',
                          borderTop: '1px solid black',
                          fontSize: '13px'
                        }}>
                          <p><strong><h5> {schoolData.schoolName}</h5></strong></p>
                          <p>{schoolData.sansthaName}</p>
                          <p>{language === "English" ? "Taluka:" : "तालुका:"} {schoolData.taluka} , {language === "English" ? "Jilha:" : "जिल्हा:"} {schoolData.jilha} </p>
                          <p>{language === "English" ? "Mobile Number:" : "मोबाइल नंबर:"} {schoolData.mobileNumber} , {language === "English" ? "Email:" : "ईमेल:"} {schoolData.email}</p>

                          <p style={{ display: 'inline-block', backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px', fontSize: '18px' }}>
                            {language === "English" ? "School Nutrition (B) Demand Sheet" : " शालेय  पोषण आहार (ब) मागणी पत्रक"}
                          </p>
                          <p> ({language === "English" ? "Rice and other grains" : "तांदूळ व धान्यादी माल"}) </p>
                          <p> {formattedFromDate}  {language === "English" ? "To " : "ते "} {formattedToDate} </p>
                          
                        </th>
                      </tr>
                    )}
                    <tr style={{ color: 'black' }}>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Sr.No" : "क्रमांक"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "List Of Grains" : "धान्याची यादी"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Quantity (in Kilo gram)" : "प्रमाण (किलो ग्रॅम मध्ये)"}</th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
    {dataList.map((row, index) => (
        <tr key={index}>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                fontWeight: '500',
                borderRight: '1px solid black',
                borderLeft: '1px solid black',
            }}>{index + 1}</td>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                color: 'black',
                borderRight: '1px solid black',
            }}>{row.vastuName}</td>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                color: 'black',
                borderRight: '1px solid black',
            }}>{row.magani}</td>
        </tr>
    ))}
</tbody>

                </table>
              </div>


              <div style={{ textAlign: 'right', marginTop: '20px' }}>

                <button onClick={handlePrint} style={{
          padding: '12px 25px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.2s ease',
          marginRight: '10px',
        }}>
         {language === "English" ? "Print" : "प्रिंट करा"} 
        </button>

                <button onClick={closeModal} style={{
                  padding: '12px 25px',
                  backgroundColor: '#FF4136',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.2s ease',
                }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#E04A3F'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#FF4136'}
                >
               {language === "English" ? "Close" : " बंद करा"}                          
                </button>
              </div>
            </div>
          </div>
        )}


      </div>
    </div>
  );
}

export default Magani;
