// withAuth.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const WithAuth = (WrappedComponent) => {
    const AuthenticatedComponent = () => {
      const udiseNumber = localStorage.getItem('udiseNumber');
      const language = localStorage.getItem('language');
      const location = useLocation();
  
      if (udiseNumber  && language) {
        return <WrappedComponent />;
      } else {
        return <Navigate to="/" state={{ from: location }} replace />;
      }
    };
  
    return AuthenticatedComponent;
  };

export default WithAuth;