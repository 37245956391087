import React, { useState, useEffect } from "react";
import "../result/result.css";
import Sidebar from "../../components/Sidebar";

const SubjectWiseResult = () => {
    const [academicYear, setAcademicYear] = useState("");
    const [classValue, setClassValue] = useState("");
    const [subject, setSubject] = useState("");
    const [selectedExamName, setSelectedExamName] = useState("");
    const [studentData, setStudentData] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [marksData, setMarksData] = useState({});
    const [classes, setClasses] = useState([]);
    const [subjects, setSubjects] = useState({});
    const examNames = ["First Semester", "Second Semester"];
    
    const udiseNumber = localStorage.getItem("udiseNumber");
  
    useEffect(() => {
        if (udiseNumber) {
            fetchStudentData();
        }
    }, [udiseNumber]);

    useEffect(() => {
        if (subject && selectedExamName && classValue && academicYear) {
            fetchMarksForSelectedSubject();
        }
    }, [subject, selectedExamName, classValue, academicYear]);
    
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

    useEffect(() => {
      const storedLanguage = localStorage.getItem('language') || 'English';
      setLanguage(storedLanguage);
    }, []);

    const handleAcademicYearChange = (e) => setAcademicYear(e.target.value);
    const handleClassChange = (e) => {
        setClassValue(e.target.value);
        fetchSubjectsForClass(e.target.value);
    };
    const handleSubjectChange = (e) => setSubject(e.target.value);
    const handleExamNameChange = (e) => setSelectedExamName(e.target.value);

    const fetchStudentData = async () => {
        try {
            const response = await fetch(
                `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
    
            if (!data) {
                console.error("No data received from the server");
                return;
            }
    
            const studentsArray = Object.keys(data).map((key) => ({
                srNo: key,
                ...data[key]
            }));
    
            setStudentData(studentsArray);
    
            const classSet = new Set();
            studentsArray.forEach((student) => {
                if (student && student.currentClass) {
                    classSet.add(student.currentClass);
                }
            });
    
            setClasses([...classSet]);
        } catch (error) {
            console.error("Error fetching student data:", error);
        }
    };


    const fetchSubjectsForClass = async (classValue) => {
        try {
          if (!academicYear) {
            console.error("Academic year is not set");
            return;
          }
      
          const subjects = {};
          for (const student of studentData) {
            if (student.currentClass === classValue) {
              for (const examName of examNames) {
                const response = await fetch(
                  `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${examName}.json`
                );
                if (!response.ok) {
                  throw new Error(
                    `Network response was not ok for student ${student.srNo}`
                  );
                }
                const studentResult = await response.json();
                if (studentResult) {
                  Object.keys(studentResult).forEach((subject) => {
                    // Exclude the 'nondi' key
                    if (subject !== "nondi") {
                      subjects[subject] = true;
                    }
                  });
                }
              }
            }
          }
      
          setSubjects(subjects);
        } catch (error) {
          console.error(
            `Error fetching subjects for class ${classValue} and academic year ${academicYear}:`,
            error
          );
        }
      };
      
    

    const fetchMarksForSelectedSubject = async () => {
        try {
            console.log("Fetching marks for selected subject...");

            const selectedStudents = studentData.filter(
                (student) => student && student.currentClass === classValue
            );
            console.log("Selected students:", selectedStudents);
            setSelectedStudents(selectedStudents);

            const marksDataPromises = selectedStudents.map(async (student) => {
                const studentMarks = await fetchMarksData(
                    student.srNo,
                    academicYear,
                    selectedExamName,
                    subject
                );
                return { srNo: student.srNo, marks: studentMarks };
            });

            const marksDataArray = await Promise.all(marksDataPromises);
            console.log("Marks data array:", marksDataArray);

            const marksData = marksDataArray.reduce((acc, { srNo, marks }) => {
                acc[srNo] = marks;
                return acc;
            }, {});

            console.log("Final marks data:", marksData);
            setMarksData(marksData);
        } catch (error) {
            console.error("Error fetching marks data:", error);
        }
    };

    const fetchMarksData = async (srNo, academicYear, examName, subject) => {
        const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${srNo}/result/${academicYear}/${examName}/${subject}.json`;

        try {
            console.log(`Fetching data from URL: ${url}`);
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const data = await response.json();
            console.log(`Data fetched for student ${srNo}:`, data);
            return data || {};
        } catch (error) {
            console.error("Error fetching marks data:", error);
            return {}; 
        }
    }; 

    const submit = () => {
        console.log("Submit button clicked");
        fetchMarksForSelectedSubject();
    };

    const printTable = () => {
    const tableElement = document.getElementById("printableTable");
    if (tableElement) {
        const tableContent = tableElement.outerHTML;
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><head><title>Print</title>");
        
        // Include your styles here
        printWindow.document.write(`
            <style>
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ccc;
                    padding: 5px;
                    text-align: left;
                    width: calc(100% / 7); /* Adjust width to fit the number of columns */
                }
                thead th {
                    background-color: #f4f4f4;
                    font-weight: normal; /* Removes bold styling */
                }
                tbody td {
                    text-align: center;
                    font-weight: normal; /* Removes bold styling */
                }
                .grade-table table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .grade-table th, .grade-table td {
                    border: 1px solid #ccc;
                    padding: 5px;
                    text-align: center;
                    width: calc(100% / 10); /* Adjust based on the number of columns */
                }
                .grade-table thead th {
                    background-color: #f4f4f4;
                }
                .grade-table tbody td {
                    text-align: center;
                }
            </style>
        `);
        
        printWindow.document.write("</head><body>");
        printWindow.document.write(tableContent);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
    } else {
        console.error("Table element with ID 'printableTable' not found.");
    }
};


    return (
        <div>
            <Sidebar />
            <div className="p-3 main-content-of-page">
            <h1 className="title"> {language === "English" ? "Subject Wise" : "विषय निहाय निकाल"}</h1>

                <table className="table table-striped table-bordered">
                    <tbody>
                        <tr>
                            <th>{language === "English" ? "Academic Year " : "शैक्षणिक वर्ष"}</th>
                            <td>
                                <select
                                    id="academicYear"
                                    value={academicYear}
                                    onChange={handleAcademicYearChange}
                                    className="form-control custom-select"
                                >
                                    <option value="2023-2024" selected>2023-2024</option>
                                    <option value="2024-2025">2024-2025</option>
                                    <option value="2025-2026">2025-2026</option>
                                    <option value="2026-2027">2026-2027</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>{language === "English" ? "Class" : "वर्ग"}</th>
                            <td>
                                <select
                                    id="class"
                                    value={classValue}
                                    onChange={handleClassChange}
                                    className="form-control custom-select"
                                >
                                <option value="">{language === "English" ? "Select Class" : "वर्ग निवडा"}</option>
                                    {classes.map((cls, index) => (
                                        <option key={index} value={cls}>
                                            {cls}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>{language === "English" ? "Subject" : "विषय"}</th>
                            <td>
                                <select
                                    id="subject"
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    className="form-control custom-select"
                                >
                                  <option value="">{language === "English" ? "Select Subject" : "विषय निवडा"}</option>
                                    {Object.keys(subjects).map((sub, index) => (
                                        <option key={index} value={sub}>
                                            {sub}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>{language === "English" ? "Exam Name" : "परीक्षेचे नाव"}</th>
                            <td>
                                <select
                                    id="examName"
                                    value={selectedExamName}
                                    onChange={handleExamNameChange}
                                    className="form-control custom-select"
                                >
                                <option value="">{language === "English" ? "Select Exam" : "परीक्षा निवडा"}</option>
                                    {examNames.map((examName, index) => (
                                        <option key={index} value={examName}>
                                            {examName}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <button onClick={submit} className="btn btn-primary btn-block">
                                    {language === "English" ? "Submit" : "सबमिट करा"}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button onClick={printTable} className="btn btn-primary">
                    {language === "English" ? "Print" : "Print करा"}
                </button>
                <br/>
                <table className="table table-striped table-bordered" id="printableTable">
    <thead>
        <tr>
            <th>{language === "English" ? "Register No" : "रजिस्टर नंबर"}</th>
            <th>{language === "English" ? "Student Name" : "विद्यार्थ्याचे नाव"}</th>
            <th colSpan="9" className="text-center">{language === "English" ? "Akarik" : "आकारिक"}</th>
            <th colSpan="4" className="text-center">{language === "English" ? "Sanklit" : "संकलित"}</th>
        </tr>
        <tr>
            <th colSpan="2" className="text-center"></th>
            <th>{language === "English" ? "Activity" : "उपक्रम/कृती"}</th>
            <th>{language === "English" ? "Daily Monitoring" : "दैनंदिन निरीक्षण"}</th> {/* Added this field */}
            <th>{language === "English" ? "Demonstration" : "प्रात्यक्षिके"}</th>
            <th>{language === "English" ? "Homework" : "गृहपाठ"}</th>
            <th>{language === "English" ? "Oral Work" : "तोंडी काम"}</th>
            <th>{language === "English" ? "Others" : "इतर"}</th>
            <th>{language === "English" ? "Project" : "प्रकल्प"}</th>
            <th>{language === "English" ? "Test" : "चाचणी"}</th>
            <th>{language === "English" ? "Total" : "एकूण"}</th>
            <th>{language === "English" ? "Orally" : "तोंडी"}</th>
            <th>{language === "English" ? "Demonstration" : "प्रात्यक्षिके"}</th>
            <th>{language === "English" ? "Writing" : "लेखन"}</th>
            <th>{language === "English" ? "Total" : "एकूण"}</th>
        </tr>
    </thead>
    <tbody>
        {selectedStudents.map((student) => (
            <tr key={student.srNo}>
                <td>{student.srNo}</td>
                <td>{student.stdName}</td>
                <td>{marksData[student.srNo]?.Akarik?.Activity ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.['Daily Monitoring'] ?? 'N/A'}</td> {/* Added this field */}
                <td>{marksData[student.srNo]?.Akarik?.Demonstration ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.Homework ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.['Oral Work'] ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.Others ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.Project ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.Test ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Akarik?.Total ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Sanklik?.Orally ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Sanklik?.Demonstration ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Sanklik?.Writing ?? 'N/A'}</td>
                <td>{marksData[student.srNo]?.Sanklik?.Total ?? 'N/A'}</td>
            </tr>
        ))}
    </tbody>
</table>

            </div>
        </div>
    );
};

export default SubjectWiseResult;
