import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';


function CustomForm() {
  const [class1T05vastuValues, setClass1T05vastuValues] = useState({});
  const [class6T08vastuValues, setClass6T08vastuValues] = useState({});

  const language = localStorage.getItem("language");
  const udiseNumber = localStorage.getItem("udiseNumber");
  const [selectedClass, setSelectedClass] = useState('1 To 5');
const [selectedContent, setSelectedContent] = useState('');
const [inputValue, setInputValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [dropdown2Options, setDropdown2Options] = useState([]);
  const [isQuantityChanged, setIsQuantityChanged] = useState(false);

  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [schoolData, setSchoolData] = useState(null); 
  const classMapping = {
    '1 To 5': {
      English: "1 To 5",
      Marathi: "१ ते ५"
    },
    '6 To 8': {
      English: "6 To 8",
      Marathi: "६ ते ८"
    }
  };
  const [alertMessage, setAlertMessage] = useState("");
const [showAlert, setShowAlert] = useState(false);
useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    // Fetch contents and table data when component mounts
    fetchContents(selectedClass);
  }, []);
  useEffect(() => {
    if (selectedClass !== '') {
      fetchTableData();
    }
    fetchSchoolData(); 
  }, [selectedClass]);
  
  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    fetchContents(event.target.value);
    setSelectedContent(''); // Reset selected content
    setInputValue('');
    fetchTableData();
  };
  
  const handleContentChange = (event) => {
    setSelectedContent(event.target.value);
    console.log(event.target.value);
    setTimeout(() => {
      if (selectedClass !== '') {
        fetchValue(selectedClass, event.target.value);
      }
    }, 100);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsQuantityChanged(true); // Mark quantity as changed
  };
  const handleInputKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // Prevent the default form submission
  }
};
  const fetchContents = (selectedClass) => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;
  
    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        const dropdown2Options = [];
        let expensesOption = null; // Variable to hold the expenses option
  console.log("selectedClass",selectedClass);
  
        if (selectedClass === '1 To 5') {
          Object.keys(data['1 To 5']).forEach((content) => {
            if (content === 'Expenses' ||  content === 'खर्च') {
              expensesOption = content; 
            } else {
              dropdown2Options.push(content);
            }
          });
        } else if (selectedClass === '6 To 8') {
          Object.keys(data['6 To 8']).forEach((content) => {
            if (content === 'Expenses' ||  content === 'खर्च') {
              expensesOption = content; 
            } else {
              dropdown2Options.push(content);
            }
          });
        }
        
        // Add expenses option at the end
        if (expensesOption) {
          dropdown2Options.push(expensesOption);
        }
        setDropdown2Options(dropdown2Options);
      })
      .catch(error => {
        console.error('Error fetching data from Firebase:', error);
      });
  };
  
const fetchValue = (selectedClass, selectedContent) => {
  console.log("selectedClass",selectedClass,selectedContent);
  
  if (selectedClass === '' || selectedContent === '') {
    setInputValue('');
    setIsQuantityChanged(false);
    return;
  }

  const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;

  fetch(firebaseDatabaseUrl)
    .then(response => response.json())
    .then(data => {
      let value;
      if (selectedClass === '1 To 5') {
        value = data['1 To 5'][selectedContent];
      } else if (selectedClass === '6 To 8') {
        value = data['6 To 8'][selectedContent];
      }
      setInputValue(value);
      setIsQuantityChanged(false);
    })
    .catch(error => {
      console.error('Error fetching data from Firebase:', error);
    });
};
const fetchTableData = () => {
  const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;

  fetch(firebaseDatabaseUrl)
    .then(response => response.json())
    .then(data => {
      const tableData = [];
      let expensesEntry = null; 

      if (selectedClass === '1 To 5') {
        Object.keys(data['1 To 5']).forEach((content) => {
          if (content === 'Expenses' ||  content === 'खर्च') {
            expensesEntry = {
              class: '1 To 5',
              content,
              quantity: data['1 To 5'][content],
            }; 
          } else {
            tableData.push({
              class: '1 To 5',
              content,
              quantity: data['1 To 5'][content],
            });
          }
        });
      } else if (selectedClass === '6 To 8') {
        Object.keys(data['6 To 8']).forEach((content) => {
          if (content === 'Expenses' ||  content === 'खर्च') {
            expensesEntry = {
              class: '6 To 8',
              content,
              quantity: data['6 To 8'][content],
            }; 
          } else {
            tableData.push({
              class: '6 To 8',
              content,
              quantity: data['6 To 8'][content],
            });
          }
        });
      }

      if (expensesEntry) {
        tableData.push(expensesEntry);
      }

      console.log("tableData", tableData);

      setTableData(tableData);
    })
    .catch(error => {
      console.error('Error fetching data from Firebase:', error);
    });
};


  const fetchSchoolData = () => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        setSchoolData(data); 
      })
      .catch(error => {
        console.error('Error fetching school data from Firebase:', error);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;
  
    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        if (selectedClass === '1 To 5') {
          // Update the selected content's quantity
          data['1 To 5'][selectedContent] = inputValue;
        } else if (selectedClass === '6 To 8') {
          // Update the selected content's quantity
          data['6 To 8'][selectedContent] = inputValue;
        }
  
        // Send updated data back to Firebase
        return fetch(firebaseDatabaseUrl, {
          method: 'PUT',
          body: JSON.stringify(data),
        });
      })
      .then(response => response.json())
      .then(updatedData => {
        console.log('Data updated successfully:', updatedData);
        // Optionally, you may want to reset form fields here after a successful update
        setInputValue('');
        setSelectedContent('');
        fetchTableData(); // Refresh the table data to reflect changes
  
        // Show success alert after saving the data
        setAlertMessage('Data saved successfully!');
      })
      .catch(error => {
        console.error('Error updating data:', error);
      });
  };
  
  
  const handleReport = () => {
    const filteredData = tableData.filter(row => row.class === selectedClass);    
    const defaultQuantities = selectedClass === '1 To 5' ? class1T05vastuValues : class6T08vastuValues;
    const filteredDataWithDefaults = filteredData.map(row => {
      const defaultQuantity = defaultQuantities[row.content];
      return {
        ...row,
        quantity: defaultQuantity || row.quantity,
      };
    });
    setModalData(filteredDataWithDefaults);
    setShowModal(true);
  };



  const closeModal = () => {
    setShowModal(false); 
  };


  const handlePrint = () => {
    const printContent = document.querySelector('.modal-content');
  
    if (printContent) {
      const cloneContent = printContent.cloneNode(true);
  
      const schoolName = cloneContent.querySelector('p'); 
      const otherDetails = cloneContent.querySelectorAll('p'); 
  
      otherDetails.forEach((p, index) => {
        if (index !== 0) { 
          p.style.fontWeight = 'normal'; 
        }
      });
      
      if (schoolName) {
        schoolName.innerHTML = `<h5 style="font-size: 16px; margin-top: 10px;">${schoolData.schoolName}</h5>`; // Increase the font size and set top margin
      }
  
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Modal Content</title>
            <style>
              @page {
                size: A4 portrait;
                margin-left: 5mm; /* Set left margin */
                margin-right: 5mm; /* Set right margin */
                margin-top: 10mm; /* Optional: Set top margin */
                margin-bottom: 10mm; /* Optional: Set bottom margin */
              }
              body {
                font-family: Arial, sans-serif;
                margin: 0; /* Remove body margin */
              }
              .modal-content {
                margin: 20px; /* Add margin to the modal content */
              }
              thead {
                            background-color: #F0F0F0; /* Set very light gray background color for <thead> */

              }
            </style>
          </head>
          <body>
            ${cloneContent.outerHTML} <!-- Include the modified outer HTML of the modal content -->
          </body>
        </html>
      `);
      printWindow.document.close(); 
      printWindow.focus(); // Focus the new window
      printWindow.print(); // Trigger print
      printWindow.close(); // Close the print window after printing
    } else {
      console.error('Print content not found');
    }
  };
  const containerStyle = {
    maxWidth: '800px',
    margin: ' auto',
    padding: '30px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: '#fff',
    border:'1px solid gray',
    marginBottom:'2%'
  };  
  const labelStyle = {
    marginBottom: '5px',
    fontSize:'14px'
  };

  const inputStyle = {
    marginTop: '10px',
    width: '130px',
    maxWidth: '300px',
    height:'35px', 
    marginRight: '20px', 
    fontSize:'14px', 
    padding:'8px',
    borderRadius:'5px',
    border:'1px solid gray'
  };
  const tableStyle = {
    marginTop: '5px',
    width: '100%',
    borderCollapse: 'collapse'
  };

  return (
    <div>
      <Sidebar />
      <AlertMessage message={alertMessage} show={showAlert}/>
      <div className='main-content-of-page '>
      <div style={containerStyle}>
        <h3 className='text-center mb-5' style={{ color:'#03365e', fontWeight:'600'}}>  {language === "English" ? " Quantity  " : " प्रमाण "}</h3>

     <form onSubmit={handleSubmit}  style={{display: 'flex',flexWrap: 'wrap', gap:'10px', marginBottom: '20px'}}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px', gap: '10px' }}>
          <div >
            <label htmlFor="dropdown1" style={labelStyle}>{language === "English" ? " Class  " : " वर्ग "} : </label>
            <select id="class" name="class" value={selectedClass} onChange={handleClassChange}
 style={inputStyle}>
  <option value="">{language === "English" ? " Select Class  " : " वर्ग निवडा"} </option>
  <option value="1 To 5">{language === "English" ? " 1 To 5  " : " १ ते ५ "}</option>
  <option value="6 To 8">{language === "English" ? " 6 To 8  " : " ६ ते ८ "}</option>
</select>

          </div>
          
          <div>
            <label htmlFor="dropdown2" style={labelStyle}> {language === "English" ? " Contents  " : " सामग्री "} :</label>
            <select id="content" name="content"  value={selectedContent} onChange={handleContentChange} style={inputStyle}>
  <option value=""> {language === "English" ? " Select Content  " : " सामग्री निवडा"} </option>
  {dropdown2Options.map((option, index) => (
    <option key={index} value={option}>{option}</option>
  ))}
</select>
          </div>

          <div>
            <label htmlFor="inputText" style={labelStyle}> {language === "English" ? " Quantity(In Gram) (1 gram = 0.001 kg) " : " प्रमाण (ग्रॅम मध्ये) (1 gram = 0.001 kg) "} : </label>
            <input type="text"  id="inputValue"  name="inputValue"  value={inputValue}  onChange={handleInputChange}  onKeyDown={handleInputKeyDown} style={inputStyle}/>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%'}}>
    {selectedContent && isQuantityChanged && (
    <button type="submit" style={{ marginLeft:'20px', padding: '8px 15px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '4px'}}>
      Update
    </button>
  )}
    <button type="button" onClick={handleReport} style={{ padding: '8px 10px', backgroundColor: '#d4a017', color: '#fff', border: 'none', borderRadius: '4px',marginLeft:'20px' }}>
        Report
    </button>
</div>
      </form>

<hr style={{marginTop:'30px'}}/>
       <div style={{ marginTop: '30px'}}>
       <p className='text-center' style={{fontSize:'12px'}}>{language === "English" ? "The Following Quantities Are Per Student" : "खालील प्रमाण प्रति विद्यार्थी आहे"}</p>
    

<table style={tableStyle}>
  <thead>
    <tr>
      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: '600' }}>{language === "English" ? "Class" : "वर्ग"}</th>
      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: '600' }}>{language === "English" ? "Contents" : "सामग्री"}</th>
      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'center', fontWeight: '600' }}>{language === "English" ? "Quantity (in grams)" : "प्रमाण (ग्रॅम मध्ये)"}</th>
    </tr>
  </thead>
  <tbody>
    {tableData.map((row, index) => (
      <tr key={index}>
        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}> {language === "English" ? 
    `${classMapping[row.class].English}` : 
    `${classMapping[row.class].Marathi}`}
    </td>
        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.content}</td>
        <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{row.quantity}</td>
      </tr>
    ))}
  </tbody>
</table>
      </div>
</div>
        {showModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '80%',
              maxWidth: '800px', // Set a max width for better fitting
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
              maxHeight: '90%',
              overflowY: 'auto',
              position: 'relative',
            }}>



              <div className="modal-content">
                {/* Table for Report Data */}
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '30px',
                  fontFamily: 'Arial, sans-serif',
                  border: '1px solid black',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                  {/* Header as part of the Table */}
                  <thead>
                    {schoolData && (
                      <tr>
                        <th colSpan="3" style={{

                          color: 'black',
                          padding: '5px',
                          borderRadius: '10px 10px 0 0',
                          textAlign: 'center',
                          lineHeight: '0.4',
                          borderBottom: '1px solid black',
                          borderLeft: '1px solid black',
                          borderRight: '1px solid black',
                          borderTop: '1px solid black',
                          fontSize: '13px'
                        }}>
                          <p><strong><h5> {schoolData.schoolName}</h5></strong></p>
                          <p>{schoolData.sansthaName}</p>
                          <p>{language === "English" ? "Taluka:" : "तालुका:"} {schoolData.taluka} , {language === "English" ? "Jilha:" : "जिल्हा:"} {schoolData.jilha} </p>
                          <p>{language === "English" ? "Mobile Number:" : "मोबाइल नंबर:"} {schoolData.mobileNumber} , {language === "English" ? "Email:" : "ईमेल:"} {schoolData.email}</p>

                          <p style={{ display: 'inline-block', backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '5px', fontSize: '18px' }}>
                            {language === "English" ? "Nutritional intake" : "पोषण आहार प्रमाण"}  {language === "English" ? 
    `( Class ${classMapping[selectedClass].English})` : 
    `( वर्ग ${classMapping[selectedClass].Marathi})`}
                          </p>
                        </th>
                      </tr>
                    )}
                    {/* Column Headers */}
                    <tr style={{ color: 'black' }}>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Sr.No" : "क्रमांक"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Grain content" : "धान्याची सामग्री"}</th>
                      <th style={{
                        padding: '10px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid black',
                        textAlign: 'center',
                        borderRight: '1px solid black',
                      }}>{language === "English" ? "Amount (in grams)" : "प्रमाण (ग्रॅम मध्ये)"}</th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
    {modalData.map((row, index) => (
        <tr key={index}>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                fontWeight: '500',
                borderRight: '1px solid black',
                borderLeft: '1px solid black',
            }}>{index + 1}</td>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                color: 'black',
                borderRight: '1px solid black',
            }}>{row.content}</td>
            <td style={{
                padding: '7px',
                borderBottom: '1px solid black',
                textAlign: 'center',
                color: 'black',
                borderRight: '1px solid black',
            }}>{row.quantity}</td>
        </tr>
    ))}
</tbody>

                </table>
              </div>


              <div style={{ textAlign: 'right', marginTop: '20px' }}>

                <button onClick={handlePrint} style={{
          padding: '12px 25px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.2s ease',
          marginRight: '10px',
        }}>
         {language === "English" ? "Print" : "प्रिंट करा"} 
        </button>

                <button onClick={closeModal} style={{
                  padding: '12px 25px',
                  backgroundColor: '#FF4136',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.2s ease',
                }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#E04A3F'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#FF4136'}
                >
               {language === "English" ? "Close" : " बंद करा"}                          
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomForm;
