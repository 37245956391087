import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import i1 from '../../assets/images/SCHOOL DISE.jpg';
import i2 from '../../assets/images/SCHOOL DISE2.jpg';
import i3 from '../../assets/images/SCHOOL DISE3.jpg';
import i4 from '../../assets/images/SCHOOL DISE4.jpg';
import i5 from '../../assets/images/SCHOOL DISE5.jpg';
import i6 from '../../assets/images/SCHOOL DISE6.jpg';
import i7 from '../../assets/images/SCHOOL DISE7.jpg';
import i8 from '../../assets/images/SCHOOL DISE8.jpg';

function LandingPage() {
  const location = useLocation();
  const [referralCode, setReferralCode] = useState('');
  

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referral = params.get('referralCode');
    if (referral) {
      setReferralCode(referral);
    }
  }, [location]);

  const navigate = useNavigate();
  const handleSchoolRegisterClick = () => {
    const url = referralCode ? `/school-register?referralCode=${referralCode}` : '/school-register';
    navigate(url);
  };

  return (
    <div style={{
      textAlign: 'center',
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: '#f0f4f8',
      padding: '40px',
      animation: 'fadeIn 2s',
    }}>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes slideUp {
            from { transform: translateY(50px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }
          .carousel img {
            border-radius: 15px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
          }
          .hoverEffect:hover {
            background-color: #c0392b !important;
            transform: scale(1.05);
          }
        `}
      </style>

      <h1 style={{
        fontSize: '3rem',
        fontWeight: 'bold',
        color: '#2c3e50',
        marginBottom: '20px',
        animation: 'slideUp 1s',
      }}>E-Guruji Software</h1>

      <p style={{
        fontSize: '1.2rem',
        color: '#34495e',
        lineHeight: '1.6',
        maxWidth: '800px',
        margin: '0 auto 20px',
        animation: 'fadeIn 1.5s',
      }}>
        Welcome to our School Management Application <span style={{ color: '#e74c3c', fontWeight: 'bold' }}>E-Guruji</span>.
        This application helps you manage all aspects of school administration with ease.
      </p>

      <p style={{
        fontSize: '1.2rem',
        color: '#34495e',
        lineHeight: '1.6',
        maxWidth: '800px',
        margin: '0 auto 20px',
        animation: 'fadeIn 1.5s',
      }}>
        आमच्या स्कूल मॅनेजमेंट ऍप्लिकेशन <span style={{ color: '#e74c3c', fontWeight: 'bold' }}>E-Guruji</span> मध्ये आपले स्वागत आहे!
        हे ऍप्लिकेशन आपल्याला स्कूल प्रशासनाचे सर्व पैलू सहजपणे व्यवस्थापित करण्यात मदत करते.
      </p>

      <div style={{
        width: '60%',
        margin: '0 auto 30px',
        animation: 'fadeIn 2s',
      }}>
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          interval={3000}
          showStatus={false}
          centerMode={false}  
          showIndicators={true} 
        >
          {[i1, i2, i3, i4, i5, i6, i7, i8].map((image, index) => (
            <div key={index} style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}>
              <img src={image} alt={`Image ${index + 1}`} style={{
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                borderRadius: '15px',
                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.4)',
              }} />
            </div>
          ))}
        </Carousel>
      </div>

      <button
        style={{
          backgroundColor: '#e74c3c',
          color: '#fff',
          fontSize: '1.2rem',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          animation: 'slideUp 1.5s',
        }}
        className="hoverEffect"
        onClick={handleSchoolRegisterClick}
      >
        Free Register Your School
      </button>

      <div style={{
        marginTop: '20px',
        fontSize: '1.2rem',
        color: '#7f8c8d',
        animation: 'fadeIn 2s',
      }}>Referral Code: {referralCode}</div>
    </div>
  );
}

export default LandingPage;
