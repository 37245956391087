
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import AlertMessage from "../../AlertMessage";
import moment from 'moment';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeRzK6wzqfH5zO7icoCKwkaE7Du998dKw",
  authDomain: "digitalschool-6ba79.firebaseapp.com",
  databaseURL: "https://digitalschool-6ba79-default-rtdb.firebaseio.com",
  projectId: "digitalschool-6ba79",
  storageBucket: "digitalschool-6ba79.appspot.com",
  messagingSenderId: "407448102746",
  appId: "1:407448102746:web:22ed42062e3f32aa3cb563",
  measurementId: "G-ZV6YZNL451",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function Bonadoc({ submitted, formData }) {
  const [schoolData, setSchoolData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [walletPoints, setWalletPoints] = useState(0);

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );
  const [logoURL, setLogoURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [udiseNumber, setUdiseNumber]= useState('');


  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      fetchSchoolData(schoolUdiseNumber);
    }
  }, []);
  useEffect(()=>{
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if(schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber)
    }
  }, [])

  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        console.log("response11",response);
        const data = await response.json();
        console.log("Wallet points data 11:", data);
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]);

  const handleWalletUpdate = async (updatedPoints) => {
    try {
      const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPoints),
      });
      if (!updateResponse.ok) {
        throw new Error('Failed to update wallet points');
      }
      console.log("Updated wallet points in state:", updatedPoints);
      setWalletPoints(updatedPoints);
    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize(); 
    window.addEventListener("resize", handleResize); 
    return () => window.removeEventListener("resize", handleResize); // Clean up event listener
  }, []);

  const fetchSchoolData = async (udiseNumber) => {
    try {
      console.log("Fetching school data...");
      const response = await fetch(
        `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("School data:", data);
      setSchoolData(data);
      if (data && data.schoolLogo) {
        const storageRef = firebase.storage().refFromURL(data.schoolLogo);
        const url = await storageRef.getDownloadURL();
        console.log("Logo URL:", url);
        setLogoURL(url);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching school data:", error);
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (!schoolData || !formData) return null;

    console.log("Rendering content...");
    console.log("Logo URL in renderContent:", logoURL);

    return (
      <div
        style={{
  position: "absolute",
  top: "0.9in", // Move the box to the top of the page
  right:"0.30in",
  width: "7.6in", // 8.5in - 0.4in (0.2in on each side)
  height: "5.35in", // Adjust the height as needed
  padding: "0.2in", // Adjust padding to create the margin inside the border
  fontFamily: "'NotoSansDevanagari', sans-serif",
  border: "0.03in solid black",
  boxSizing: "border-box",
 
  
 '@media (max-width: 1200px)': {
    width: '7in', // Adjust width for medium screens
    height: '5in', // Adjust height for medium screens
  },
  '@media (max-width: 992px)': {
    width: '6.5in', // Adjust width for smaller screens
    height: '4.8in', // Adjust height for smaller screens
  },
  '@media (max-width: 768px)': {
    width: '6in', 
    height: '4.6in', 
  },
  '@media (max-width: 576px)': {
    width: '100%', 
    left: '0.2in', 
    height: 'auto',
  }
}}
      >
        <div
          style={{
            position: "absolute",
            top: "0.7in",
            left: "0.4in",
          }}
        >
          {logoURL && (
            <img
              src={logoURL}
              alt="School Logo"
              style={{ width: "80px", height: "80px" }}
            />
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {schoolData.sansthaName && (
    <div
      style={{
        fontSize: '11px',
        fontWeight: 'bold',
        wordWrap: 'break-word',
        textAlign: 'center',
        marginBottom: '-10px', // add this
      }}
    >
      {schoolData.sansthaName}
    </div>
  )}
  <div
    style={{
      fontSize: '18px',
      fontWeight: 'bold',
      wordWrap: 'break-word',
      textAlign: 'center',
      marginTop: schoolData.sansthaName ? '10px' : '0px',
    }}
  >
    {schoolData.schoolName}
  </div>

  </div>
        <div
      style={{
        textAlign: "center",
        marginBottom: "10px",
        fontSize: "13.5px"
      }}
    >
      <p style={{ margin: "2px 0" }}>
        <b>{language === "English" ? "Taluka: " : "तालुका: "}</b>
        {schoolData.taluka}, 
        <b>{language === "English" ? " District: " : " जिल्हा: "}</b>
        {schoolData.jilha}
      </p>
      <p style={{ margin: "2px 0" }}>
      <b> {language === "English" ? "UDISE No.: " : "UDISE क्र.: "}</b>
        {schoolData.udiseNumber}, 
        <b> {language === "English" ? " Board: " : " बोर्ड: "}</b>
        {schoolData.boardName}
      </p>
   
      <p style={{ margin: "2px 0" }}>
      <b>  {language === "English" ? "Affiliation No.: " : "संलग्नता क्र.: "}</b>
        {schoolData.sanlagnataKramank}, 
        <b> {language === "English" ? " Recognition No.: " : " मान्यता क्र.: "}</b>
        {schoolData.manyataKramank}
      </p>
    </div>
        <div
          style={{
            position: "absolute",
            top: "1.6in",
            left: "0in",
            width: "7.55in",
            height: "0in",
            textAlign: "center",
            border: "0.01in solid black",
            margin: "10px 0",
          }}
        ></div>
        <div
          style={{
              position: "absolute",
              top: "1.45in",
              left: "2.9in",
            width: "2in",
            height: "0.3in",
            textAlign: "center",
            border: "0.02in solid black",
            margin: "10px 0",
            backgroundColor: 'black', // add this
    color: 'white', // add this
    borderRadius: '0.1in', // add this
          }}
        >
          <h3
      style={{
        color: "white",
        fontSize: "14px",
        margin: "5px 0",
        fontWeight: "bold",
       
      }}
    >
      {language === "English" ? "Bonafide Certificate" : "बोनाफाईड प्रमाणपत्र"}
    </h3>
        </div>
        <div
          style={{
            position: "absolute",
            top: "1.8in",
            left: "0.1in"
          }}
        >
          <p>
          <b> {language === "English" ? "Registration Number:  " : "रजिस्टर क्र:  "}</b> 
         {formData.registerno}
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            top: "1.8in",
            left: "5.2in",
          }}
        >
          <p>
          
          <b> {language === "English" ? "Aadhar No: " : " आधार क्र: "}</b> 
          {formData.stdAdharNo}
     
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            top: "2.2in",
          }}
        >
          <p
            style={{
              margin: "5px 15px",
              position: "absolute",
              top: "-0.3in",
              left: "2.75in",
            }}
          >
            {" "}
            {language === "English"
              ? "It is certified that"
              : "प्रमाणित करण्यात येते की"}
          </p>

          <p style={{ margin: "5px 20px" }}>
          <strong> {language === "English"
              ? "Full name of student: "
              : "विद्यार्थ्याचे पूर्ण नाव: "}
              </strong>
              {formData.stdName} {formData.stdFather} {formData.stdSurname}
            
          </p>

          <p style={{ margin: "5px 20px" }}>
          <strong>{language === "English" ? "Mother's name: " : "आईचे नाव: "}</strong>
            {formData.stdMother}
          </p>
          <p
        style={{
          margin: '5px 20px',
          position: 'absolute',
          top: '0.6in',
          left: '4.8in',
        }}
      >
       <strong> {language === "English" ? "Class: " : "वर्ग: "}</strong>
        {formData.class} , &nbsp;
        <strong>{language === "English" ? " Division: " : " तुकडी: "}</strong>
        {formData.division}
      </p>

      <p style={{ margin: '5px 20px' }}>
      <strong>{language === "English" ? "Academic Year: " : "शैक्षणिक वर्ष: "}</strong>
        {formData.educationalYear}
      </p>

      <p
  style={{
    margin: '5px 20px',
    position: 'absolute',
    top: '0.3in',
    left: '4.8in',
  }}
>
  <strong>{language === "English" ? "Date of Birth: " : "जन्मतारीख: "}</strong>
  {moment(formData.dob).format('DD-MM-YYYY')}
</p>

      <p style={{ margin: "5px 20px" }}>
      <strong> {language === "English"
              ? "caste and religion: "
              : "जात व धर्म: "}</strong>
            {formData.religion}, {formData.caste}
          </p>

          <p style={{ margin: "5px 20px" }}>
          <strong> {language === "English"
              ? "Reason : "
              : "कारण: "}</strong>
            {formData.reason}
          </p>

          <p>
            {language === "English"
              ? "This student belongs to our school and all the information given above is correct. According to our information, his/her behavior is satisfactory."
              : "वरील विद्यार्थी आमच्या शाळेत शिकत आहे आणि वर दिलेली सर्व माहिती योग्य आहे. आमच्या माहितीप्रमाणे त्याचे/तिचे वर्तन समाधानकारक आहे."}
          </p>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            position: "absolute",
            top: "4.3in",
            left: "5.8in",
          }}
        >
          <p style={{ margin: "0" }}>
            {language === "English" ? "College/School" : " कॉलेज/शाळा"} <br />
            {language === "English" ? "(Official Seal)" : " (अधिकृत शिक्का)"}
          </p>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            position: "absolute", 
            top: "4.3in",
            left: "0.5in",
          }}
        >
          <p style={{ margin: "0"}}>
            {" "}
          <br/>
            {language === "English" ? "Principal / Dean" : "प्राचार्य /मुख्याध्यापक"}
          </p>
        </div>
      </div>
    );
  };


  const printCertificate = async () => {
    const updatedPoints = walletPoints - 2;
    if (updatedPoints < 0) {
      setAlertMessage('Insufficient points! Please recharge your wallet.');
      return;
    }
    await handleWalletUpdate(updatedPoints);
  
    const printContent = document.getElementById("print-content");
    const originalContent = document.body.innerHTML;
  
    // Apply CSS styles directly to the print content for positioning
    printContent.style.padding ="50px 381px"; // Shift content to the right and up
    printContent.style.transform ="translate(0, -90px) scale(1.08)"; // Slightly move content upwards
  
    document.body.innerHTML = printContent.outerHTML; // Replace body with print content
  
    window.print(); // Print the current document
  
    document.body.innerHTML = originalContent; // Restore the original content
    window.location.reload()
  };

  return (
    <>
      <AlertMessage message={alertMessage} show={showAlert}/>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "1em",
      }}
    >
      <div id="print-content">
        {loading ? <p>Loading...</p> : renderContent()}
      </div>
      <div style={{ marginTop: "1em" }}>
      <button
        onClick={printCertificate}
        style={{
          position: "absolute",
          top: "50.8em",
          left: "28.7em",
        }}
      >
        Print
      </button>
    
      </div>
    </div></>
  );
}

export default Bonadoc;
