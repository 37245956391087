import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Sidebar from "../components/Sidebar";
import  "../components/style.css";
import AlertMessage from "../AlertMessage";

const firebaseConfig = {
  apiKey: "AIzaSyAT2U6IrC02AxYeDj90V3fsHWVBS0jBlqM",
  authDomain: "digischoolweb.firebaseapp.com",
  databaseURL: "https://digischoolweb-default-rtdb.firebaseio.com",
  projectId: "digischoolweb",
  storageBucket: "digischoolweb.appspot.com",
  messagingSenderId: "687893308637",
  appId: "1:687893308637:web:d38d65ee5ea14cb909a734",
  measurementId: "G-HL5VXCSCPJ"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function SchoolData() {
  const [formData, setFormData] = useState({
    schoolName: "",
    udiseNumber: "",
    password: "",
    mobileNumber: "",
    language: "",
    telephoneNumber: "",
    address: "",
    taluka: "",
    jilha: "",
    email: "",
    sansthaName: "",
    bridvakya: "",
    boardName: "",
    indexNumber: "",
    sanlagnataKramank: "",
    manyataKramank: "",
    madhyam : "",
    schoolLogo: "",
    walletPoints: 100,
  });
  const [logoFile, setLogoFile] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);
  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    const udiseNumber = localStorage.getItem("udiseNumber");
    fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("All data fetched successfully:", data);
        const schoolData = data || {};
        setFormData({
          ...schoolData,
          udiseNumber: udiseNumber
        });
        setLogoURL(schoolData.schoolLogo);
      })
      .catch((error) => {
        console.error("Error fetching all data:", error);
      });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const udiseNumber = localStorage.getItem("udiseNumber");

    // Check if a new file is selected
    if (!logoFile) {
        // No new file selected, save form data with the existing logoURL
        saveFormData(logoURL);
    } else {
        // Upload the new file
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(`logos/${udiseNumber}logo`).put(logoFile);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                console.log(progress);
            },
            (error) => {
                console.error(error);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    saveFormData(downloadURL);
                });
            }
        );
    }
};

  const saveFormData = (logoURL) => {
    const udiseNumber = localStorage.getItem("udiseNumber");
    fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formData,
        schoolLogo: logoURL
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("School data saved successfully:", data);
        setAlertMessage("Data Saved Successfully")
        
      window.location.href = '/home';
      })
      .catch((error) => {
        console.error("Error updating school data:", error);
      });
  };

  const districtsData = {
    Maharashtra: [
      language === 'English' ? 'Ahmednagar' : 'अहमदनगर',
      language === 'English' ? 'Akola' : 'अकोला',
      language === 'English' ? 'Amravati' : 'अमरावती',
      language === 'English' ? 'Aurangabad' : 'औरंगाबाद',
      language === 'English' ? 'Beed' : 'बीड',
      language === 'English' ? 'Bhandara' : 'भंडारा',
      language === 'English' ? 'Buldhana' : 'बुलढाणा',
      language === 'English' ? 'Chandrapur' : 'चंद्रपूर',
      language === 'English' ? 'Dhule' : 'धुळे',
      language === 'English' ? 'Gadchiroli' : 'गडचिरोली',
      language === 'English' ? 'Gondia' : 'गोंदिया',
      language === 'English' ? 'Hingoli' : 'हिंगोली',
      language === 'English' ? 'Jalgaon' : 'जळगाव',
      language === 'English' ? 'Jalna' : 'जालना',
      language === 'English' ? 'Kolhapur' : 'कोल्हापूर',
      language === 'English' ? 'Latur' : 'लातूर',
      language === 'English' ? 'Mumbai City' : 'मुंबई शहर',
      language === 'English' ? 'Nagpur' : 'नागपूर',
      language === 'English' ? 'Nanded' : 'नांदेड',
      language === 'English' ? 'Nandurbar' : 'नंदुरबार',
      language === 'English' ? 'Nashik' : 'नाशिक',
      language === 'English' ? 'Osmanabad' : 'उस्मानाबाद',
      language === 'English' ? 'Palghar' : 'पालघर',
      language === 'English' ? 'Parbhani' : 'परभणी',
      language === 'English' ? 'Pune' : 'पुणे',
      language === 'English' ? 'Raigad' : 'रायगड',
      language === 'English' ? 'Ratnagiri' : 'रत्नागिरी',
      language === 'English' ? 'Sangli' : 'सांगली',
      language === 'English' ? 'Satara' : 'सातारा',
      language === 'English' ? 'Sindhudurg' : 'सिंधुदुर्ग',
      language === 'English' ? 'Solapur' : 'सोलापूर',
      language === 'English' ? 'Thane' : 'ठाणे',
      language === 'English' ? 'Wardha' : 'वर्धा',
      language === 'English' ? 'Washim' : 'वाशीम',
      language === 'English' ? 'Yavatmal' : 'यवतमाळ',
    ],
  };
  const talukasData = {
    English: {
      "Kolhapur": ["Karvir", "Panhala", "Shahuwadi", "Kagal", "Hatkanangale", "Shirol", "Radhanagari", "Gaganbawada", "Bhudargad", "Gadhinglaj", "Chandgad", "Ajra"],
      "Sangli": ["Miraj", "Kavathe-Mahankal", "Tasgaon", "Jat", "Walwa (Islampur)", "Shirala", "Khanapur-Vita", "Atpadi", "Palus", "Kadegaon"],
      "Satara": ["Satara", "Jaoli", "Koregaon", "Wai", "Mahabaleshwar", "Khandala", "Phaltan", "Maan (Dahiwadi)", "Khatav (Vaduj)", "Patan", "Karad"],
      "Pune": ["Pune City", "Haveli", "Khed (Rajgurunagar)", "Junnar", "Ambegaon (Ghodegaon)", "Maval (Vadgaon)", "Mulshi (Paud)", "Shirur", "Purandhar (Saswad)", "Velhe", "Bhor", "Baramati", "Indapur", "Daund"],
      "Ahmednagar": ["Nagar", "Shevgaon", "Pathardi", "Parner", "Sangamner", "Kopargaon", "Akole", "Shrirampur", "Nevasa", "Rahata", "Rahuri", "Shrigonda", "Karjat", "Jamkhed"],
      "Solapur": ["Solapur North", "Barshi", "Solapur South", "Akkalkot", "Madha", "Karmala", "Pandharpur", "Mohol", "Malshiras", "Sangole", "Mangalvedhe"],
      "Osmanabad": ["Osmanabad", "Tuljapur", "Bhum", "Paranda", "Washi", "Kalamb", "Lohara", "Umarga"],
      "Latur": ["Latur", "Renapur", "Ahmedpur", "Jalkot", "Chakur", "Shirur Anantpal", "Ausa", "Nilanga", "Deoni", "Udgir"],
      "Beed": ["Beed", "Ashti", "Patoda", "Shirur-Kasar", "Georai", "Majalgaon", "Wadwani", "Kaij", "Dharur", "Parli", "Ambejogai"],
      "Aurangabad": ["Aurangabad", "Kannad", "Soegaon", "Sillod", "Phulambri", "Khuldabad", "Vaijapur", "Gangapur", "Paithan"],
      "Jalna": ["Jalna", "Bhokardan", "Jafrabad", "Badnapur", "Ambad", "Ghansawangi", "Partur", "Mantha"],
      "Parbhani": ["Parbhani", "Sonpeth", "Gangakhed", "Palam", "Purna", "Sailu", "Jintur", "Manwath", "Pathri"],
      "Hingoli": ["Hingoli", "Sengaon", "Kalamnuri", "Basmath", "Aundha Nagnath"],
      "Nanded": ["Nanded", "Ardhapur", "Mudkhed", "Bhokar", "Umri", "Loha", "Kandhar", "Kinwat", "Himayatnagar", "Hadgaon", "Mahur", "Deglur", "Mukhed", "Dharmabad", "Biloli", "Naigaon (Khairgaon)"],
      "Yavatmal": ["Yavatmal", "Arni", "Babhulgaon", "Kalamb", "Darwha", "Digras", "Ner", "Pusad", "Umarkhed", "Mahagaon", "Kelapur (Pandharkawada)", "Ralegaon", "Ghatanji", "Wani", "Maregaon", "Zari Jamani"],
      "Chandrapur": ["Chandrapur", "Saoli", "Mul", "Ballarpur", "Pombhurna", "Gondpimpri", "Warora", "Chimur", "Bhadravati", "Bramhapuri", "Nagbhid", "Sindewahi", "Rajura", "Korpana", "Jivati"],
      "Gadchiroli": ["Gadchiroli", "Dhanora", "Chamorshi", "Mulchera", "Desaiganj (Vadasa)", "Armori", "Kurkheda", "Korchi", "Aheri", "Bhamragad", "Sironcha"],
      "Gondia": ["Gondia", "Tirora", "Goregaon", "Arjuni Morgaon", "Amgaon", "Salekasa", "Sadak Arjuni", "Deori"],
      "Bhandara": ["Bhandara", "Tumsar", "Pauni", "Mohadi", "Sakoli", "Lakhni", "Lakhandur"],
      "Nagpur": ["Nagpur (Urban)", "Nagpur (Rural)", "Kamptee", "Hingna", "Katol", "Narkhed", "Savner", "Kalameshwar", "Ramtek", "Mouda", "Parseoni", "Umred", "Kuhi", "Bhiwapur"],
      "Wardha": ["Wardha", "Deoli", "Seloo", "Arvi", "Ashti", "Karanja", "Hinganghat", "Samudrapur"],
      "Amravati": ["Amravati", "Bhatukali", "Nandgaon Khandeshwar", "Dharni (Amravati)", "Chikhaldara", "Achalpur", "Chandurbazar", "Morshi", "Warud", "Daryapur", "Anjangaon-Surji", "Chandur", "Dhamangaon", "Tiosa"],
      "Washim": ["Washim", "Malegaon", "Risod", "Mangrulpir", "Karanja", "Manora"],
      "Akola": ["Akola", "Akot", "Telhara", "Balapur", "Patur", "Murtajapur", "Barshitakli"],
      "Buldhana": ["Buldhana", "Chikhli", "Deulgaon Raja", "Jalgaon Jamod", "Sangrampur", "Malkapur", "Motala", "Nandura", "Khamgaon", "Shegaon", "Mehkar", "Sindkhed Raja", "Lonar"],
      "Jalgaon": ["Jalgaon", "Jamner", "Erandol", "Dharangaon", "Bhusawal", "Raver", "Muktainagar (Edalabad)", "Bodwad", "Yawal", "Amalner", "Parola", "Chopda", "Pachora", "Bhadgaon", "Chalisgaon"],
      "Dhule": ["Dhule", "Sakri", "Sindkheda", "Shirpur"],
      "Nandurbar": ["Nandurbar", "Navapur", "Shahada", "Taloda", "Akkalkuwa", "Akrani (Dhadgaon)"],
      "Nashik": ["Nashik", "Igatpuri", "Dindori", "Peth", "Trimbakeshwar", "Kalwan", "Deola", "Surgana", "Baglan (Satana)", "Malegaon", "Nandgaon", "Chandwad", "Niphad", "Sinnar", "Yeola"],
      "Palghar": ["Palghar", "Vasai", "Dahanu", "Talasari", "Jawhar", "Mokhada", "Vada", "Vikramgad"],
      "Thane": ["Thane", "Kalyan", "Murbad", "Bhiwandi", "Shahapur", "Ulhasnagar", "Ambarnath"],
      "Mumbai City": ["Kurla", "Andheri", "Borivali"],
      "Raigad": ["Pen", "Alibaug", "Murud", "Panvel", "Uran", "Karjat (Matheran)", "Khalapur", "Mangaon", "Tala", "Roha", "Sudhagad (Pali)", "Mahad", "Poladpur", "Shrivardhan", "Mhasla"],
      "Ratnagiri": ["Ratnagiri", "Sangameshwar (Deorukh)", "Lanja", "Rajapur", "Chiplun", "Guhagar", "Dapoli", "Mandangad", "Khed"],
      "Sindhudurg": ["Kankavli", "Vaibhavwadi", "Devgad", "Malwan", "Sawantwadi", "Kudal", "Vengurla", "Dodamarg (Kasal)"]
    },
    Marathi: {
      "कोल्हापूर": ["करवीर", "पन्हाळा", "शाहूवाडी", "कागल ", "हातकणंगले", "शिरोळ", "राधानगरी", "गगनबावडा", "भुदरगड", "गढिंगलज", "चंदगड", "आजरा"],
      "सांगली": ["मिरज", "कवठे-महांकळ", "तासगाव", "जत", "वाळवा (इस्लामपुर)", "शिराळा", "खानापूर-विटा", "आटपाडी", "पळूस", "कडेगाव"],
      "सातारा": ["सातारा", "जावळी", "कोरेगाव", "वाई", "महाबळेश्वर", "खंडाळा", "फलटण", "माण (दहिवडी)", "खताव (वडुज)", "पाटण", "कराड"],
      "पुणे": ["पुणे शहर", "हवेली", "खेड (राजगुरुनगर)", "जुन्नर", "अंबेगाव (घोडेगाव)", "मावळ (वडगाव)", "मुळशी (पाऊड)", "शिरूर", "पुरंधर (सस्वद)", "वेल्हे", "भोर", "बारामती", "इंदापूर", "दौंड"],
      "अहमदनगर": ["नगर", "शेवगाव", "पाठार्डी", "परनेर", "संगमनेर", "कोपरगाव", "अकोले", "श्रीरामपुर", "नेवासा", "राहता", "राहुरी", "श्रीगोंडा", "कर्जत", "जामखेड"],
      "सोलापूर": ["सोलापूर उत्तर", "बार्शी", "सोलापूर दक्षिण", "अक्कलकोट", "मधा", "करमाळा", "पंढरपूर", "मोहोळ", "माळशिरस", "सांगोळ", "मंगळवेढे"],
      "उस्मानाबाद": ["उस्मानाबाद", "तुळजापूर", "भूम", "परंदा", "वाशी", "कलंब", "लोहारा", "उमरगा"],
      "लातूर": ["लातूर", "रेणापूर", "अहमदपूर", "जळकोट", "चाकूर", "शिरूर अनंतपाळ", "औसा", "निळंगा", "देवनी", "उदगीर"],
      "बीड": ["बीड", "अश्ती", "पाटोदा", "शिरूर-कासर", "गेवराई", "मजलगाव", "वडवाणी", "कईज", "धरूर", "पारळी", "आंबेजोगाई"],
      "औरंगाबाद": ["औरंगाबाद", "कानंद", "सोईगाव", "सिल्लोड", "फुलंब्री", "खुलदाबाद", "वैजापूर", "गंगापूर", "पैठण"],
      "जालना": ["जालना", "भोकरदान", "जाफराबाद", "बडनापूर", "अंबाड", "घांसावंगी", "परटूर", "मंथा"],
      "परभणी": ["परभणी", "सोनपेठ", "गंगाखेड", "पाळम", "पूर्णा", "साईलू", "जिंतूर", "मनवठ", "पथरी"],
      "हिंगोली": ["हिंगोली", "सेंगाव", "कळमनुरी", "बासमत", "औंधा नागनाथ"],
      "नांदेड": ["नांदेड", "अर्धापूर", "मुदखेड", "भोकर", "उमरी", "लोहा", "कांधार", "किंवट", "हिमायतनगर", "हडगाव", "महूर", "देगळूर", "मुखेड", "धर्माबाद", "बिलोळी", "नागांव (खैरगांव)"],
      "यवतमाळ": ["यवतमाळ", "अर्णी", "बभुलगाव", "कळम्ब", "दारव्हा", "दिग्रास", "नेर", "पुसड", "उमरखेड", "महागाव", "केळापूर (पांढरकवाडा)", "राळेगाव", "घटांजी", "वाणी", "मारेगाव", "जारी जामानी"],
      "चंद्रपूर": ["चंद्रपूर", "सावली", "मुळ", "बल्लारपूर", "पोंभूर्णा", "गोंदपिंपरी", "वरोरा", "चिमूर", "भद्रावती", "ब्रह्मपूरी", "नागभिड", "सिंडेवाही", "राजूरा", "कोरपाणा", "जिवती"],
      "गडचिरोली": ["गडचिरोली", "धनोरा", "चामोर्शी", "मुलचेरा", "देसैगंज (वडसा)", "आरमोरी", "कुरखेडा", "कोरची", "आहेरी", "भामरगाव", "सिरोंचा"],
      "गोंदिया": ["गोंदिया", "तिरोरा", "गोरेगाव", "अर्जुनी मोरगाव", "अमगाव", "साळेकासा", "सडक अर्जुनी", "देओरी"],
      "भंडारा": ["भंडारा", "तुमसार", "पाऊनी", "मोहडी", "साकोळी", "लाखनी", "लाखांदुर"],
      "नागपूर": ["नागपूर (शहरी)", "नागपूर (ग्रामीण)", "कामठी", "हिंगणा", "कटोल", "नारखेड", "सावनेर", "काळामेश्वर", "रामटेक", "मौडा", "परसेवनी", "उमरेड", "कुही", "भिवापूर"],
      "वर्धा": ["वर्धा", "देवोली", "सेलू", "अरवी", "अश्ती", "कारंजा", "हिंगणघाट", "समुद्रापूर"],
      "अमरावती": ["अमरावती", "भाटुकली", "नंदगाव कांदेश्वर", "धरणी (अमरावती)", "चिखलदारा", "आचलपूर", "चंदूरबाजार", "मोरशी", "वारुड", "दर्यापूर", "अंजनगाव-सुर्जी", "चंदूर", "धामांगाव", "तिओसा"],
      "वाशिम": ["वाशिम", "माळेगाव", "रिसोड", "मांगरुलपीर", "करंजा", "मनोरा"],
      "अकोला": ["अकोला", "अकोट", "टेलहारा", "बालापूर", "पाटूर", "मुर्ताजापूर", "बर्शितकली"],
      "बुलढाणा": ["बुलढाणा", "चिखली", "देऊलगाव राजा", "जळगाव जामोद", "संग्रामपुर", "माळकापूर", "मोतला", "नांदुरा", "खामगाव", "शेगाव", "मेहकर", "सिंधखेड राजा", "लोणार"],
      "जळगाव": ["जळगाव", "जामनेर", "इरंडोल", "धारणगाव", "भुसावळ", "रावेर", "मुक्तैनगर (एडलाबाद)", "बोडवाड", "यावळ", "अमळनेर", "परोळा", "चोपडा", "पाचोरा", "भाडगाव", "चाळीसगाव"],
      "धुळे": ["धुळे", "साकरी", "सिंधखेडा", "शिरपूर"],
      "नंदुरबार": ["नंदुरबार", "नवापूर", "शहादा", "तालोदा", "अक्कलकुवा", "अकरणी (धडगाव)"],
      "नाशिक": ["नाशिक", "इगतपुरी", "दिंडोरी", "पेठ", "त्रिंबकेश्वर", "कलवाण", "देवळा", "सुरगाणा", "बागलाण (साताना)", "माळेगाव", "नांदगाव", "चांदवड", "निफड", "सिन्नार", "येवोळा"],
      "पालघर": ["पालघर", "वसई", "दहाणू", "तळसारी", "जावहर", "मोखाडा", "वडा", "विक्रमगड"],
      "ठाणे": ["ठाणे", "कल्याण", "मुरबाड", "भिवंडी", "शहापूर", "उल्हासनगर", "अंबरनाथ"],
      "मुंबई शहर": ["कुर्ला", "अंधेरी", "बोरिवली"],
      "रायगड": ["पेण", "आळीबाग", "मुरुड", "पनवेल", "उरण", "कर्जत (माथेराण)", "खालापूर", "मंगाव", "ताळा", "रोहा", "सुधागड (पाळी)", "महाड", "पोलडपूर", "श्रीवर्धन", "म्हासळा"],
      "रत्नागिरी": ["रत्नागिरी", "संगमेश्वर (देवरुख)", "लांजा", "राजापूर", "चिपळूण", "गुहागर", "दापोळी", "मंडणगड", "खेड"],
      "सिंधुदुर्ग": ["कणकवली", "वैभववाडी", "देवगड", "मालवण", "सावंतवाडी", "कुडाळ", "वेंगुर्ला", "दोडमर्ग (कसाळ)"]
    }
  };
  return (
    <div>
    <Sidebar/>
    <AlertMessage message={alertMessage} show={showAlert}/>
    <div className="main-content-of-page overflow-auto mt-5 pt-sm-5 mb-5" style={{ flex: "1" }}>
    <h1 className="mt-3 mb-4 text-center">{language === "English" ? " Fill School Information" : "शाळेची माहिती भरा"}</h1>
    <form onSubmit={handleSubmit}>
      <div className="mb-3 ">
        <label htmlFor="schoolName" className="form-label">{language === "English" ? "School Name" : "शाळेचे नाव"}:</label>
        <input
          autoComplete="off"
          type="text"
          className="form-control"
          id="schoolName"
          name="schoolName"
          value={formData.schoolName}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="udiseNumber" className="form-label">{language === "English" ? "UDISE Number" : "UDISE क्र"}:</label>
        <input
          type="text"
          className="form-control"
          id="udiseNumber"
          name="udiseNumber"
          value={formData.udiseNumber}
          readOnly
        />
      </div>
      <div className="mb-3">
        <label htmlFor="mobileNumber" className="form-label">{language === "English" ? "Mobile Number" : "मोबाईल नंबर"}:</label>
        <input
          autoComplete="off"
            lang="en"
          type="text"
          className="form-control"
          id="mobileNumber"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleInputChange}
          inputMode="numeric"
        />
      </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">{language === "English" ? "Village" : "गाव"}:</label>
          <input
          autoComplete="off"
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="jilha" className="form-label">{language === "English" ? "District" : "जिल्हा"}:</label>
        
          <select
              id="jilha"
              name="jilha"
              value={formData.jilha}
              onChange={handleInputChange}
              className="form-control"
              required
            >
              <option value="">{language === "English" ? "Select District" : "जिल्हा निवडा"}</option>
              {districtsData.Maharashtra.map((district) => (
                <option key={district} value={district}>
                  {district}
                </option>
              ))}
            </select>
        </div>
        <div className="mb-3">
          <label htmlFor="taluka" className="form-label">{language === "English" ? "Taluka" : "तालुका"}:</label>
          
          <select
              id="taluka"
              name="taluka"
              value={formData.taluka}
              onChange={handleInputChange}
              className="form-control"
              required
            >
              <option value="">{language === "English" ? "Select Taluka" : "तालुका निवडा"}</option>
              {formData.jilha && talukasData[language][formData.jilha]?.map((taluka) => (
                <option key={taluka} value={taluka}>
                  {taluka}
                </option>
              ))}
            </select>
        </div>
        <div className="mb-3">
  <label htmlFor="email" className="form-label">
    {language === "English" ? "Email Id" : "ई - मेल आयडी"}:
  </label>
  <input
    autoComplete="off"
    type="email"
    className="form-control"
    id="email"
    name="email"
    value={formData.email}
    onChange={handleInputChange}
    lang="en"
    placeholder= {language === "English" ? "" : "कृपया तुमचा ईमेल आयडी इंग्रजीमध्ये प्रविष्ट करा."}
  />
</div>
        <div className="mb-3">
          <label htmlFor="telephoneNumber" className="form-label">{language === "English" ? "Telephone Number" : "दूरध्वनी क्र"}:</label>
          <input
          autoComplete="off"
            lang="en"
            type="text"
            className="form-control"
            id="telephoneNumber"
            name="telephoneNumber"
            value={formData.telephoneNumber}
            onChange={handleInputChange}
          inputMode="numeric"
          />
        </div>
       
        <div className="mb-3">
          <label htmlFor="boardName" className="form-label">{language === "English" ? "Board Name" : "बोर्डचे नाव"}:</label>
          <input
          autoComplete="off"
            type="text"
            className="form-control"
            id="boardName"
            name="boardName"
            value={formData.boardName}
            onChange={handleInputChange}
          />
        </div>
         <div className="mb-3">
          <label htmlFor="sansthaName" className="form-label">{language === "English" ? "organization's Name" : "व्यवस्थापनाचे नाव (संस्था)"}:</label>
          <input
          autoComplete="off"
            lang="en"
            type="text"
            className="form-control"
            id="sansthaName"
            name="sansthaName"
            value={formData.sansthaName}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="indexNumber" className="form-label">{language === "English" ? "Index Number" : "अनु क्र"}:</label>
          <input
          autoComplete="off"
           lang="en"
            type="text"
            className="form-control"
            id="indexNumber"
            name="indexNumber"
            value={formData.indexNumber}
            onChange={handleInputChange}
          inputMode="numeric"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="sanlagnataKramank" className="form-label">{language === "English" ? "Affiliation no" : "संलग्नता क्र"}:</label>
          <input
          autoComplete="off"
            lang="en"
            type="text"
            className="form-control"
            id="sanlagnataKramank"
            name="sanlagnataKramank"
            value={formData.sanlagnataKramank}
            onChange={handleInputChange}
            inputMode="numeric"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="manyataKramank" className="form-label">{language === "English" ? "Approval Number" : "मान्यता क्र"}:</label>
          <input
          autoComplete="off"
            lang="en"
            type="text"
            className="form-control"
            id="manyataKramank"
            name="manyataKramank"
            value={formData.manyataKramank}
            onChange={handleInputChange}
            inputMode="numeric"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="madhyam" className="form-label">{language === "English" ? "Medium" : "माध्यम"}:</label>
          <input
          autoComplete="off"
            type="text"
            className="form-control"
            id="madhyam"
            name="madhyam"
            value={formData.madhyam}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="bridvakya" className="form-label">{language === "English" ? "Slogan/Mission Statement (if required for Bonafide or Leaving Certificate)" : "ब्रीद वाक्य (दाखला किंवा बोनफाईड वरती हवं असल्यास)"}:</label>
          <input
          autoComplete="off"
            lang="en"
            type="text"
            className="form-control"
            id="bridvakya"
            name="bridvakya"
            value={formData.bridvakya}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-3">
            <label htmlFor="schoolLogo" className="form-label">{language === "English" ? "School Logo" : "शाळेचा लोगो"}:</label>
            {logoURL && <img src={logoURL} alt="School Logo" style={{ width: "100px", height: "100px" }} />}
            <input
              type="file"
              className="form-control"
              id="schoolLogo"
              name="schoolLogo"
              onChange={handleLogoChange}
            />
          </div>
    <button type="submit" className="btn btn-primary">{language === "English" ? "Save" : "जतन करा"}</button>
  </form>
  </div>
</div>
  );
}

export default SchoolData;
