  import React, { useState, useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import Logo from "../assets/images/e-guruji-logo.png";
  import "./style.css";

  function Navbar() {
    const location = useLocation();
    const [language, setLanguage] = useState(localStorage.getItem("language") || "English");
    const [walletPoints, setWalletPoints] = useState(0);
    const udiseNumber = localStorage.getItem("udiseNumber");

    useEffect(() => {
      const storedLanguage = localStorage.getItem("language") || "English";
      setLanguage(storedLanguage);
    }, []);

    useEffect(() => {
      const fetchWalletPoints = async () => {
        try {
          const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
          if (!response.ok) {
            throw new Error("Failed to fetch wallet points");
          }
          const data = await response.json();
          if (data && data.walletPoints !== undefined) {
            setWalletPoints(data.walletPoints);
          } else {
            console.error("Invalid wallet points data:", data);
          }
        } catch (error) {
          console.error("Error fetching wallet points:", error);
        }
      };

      fetchWalletPoints();
      const interval = setInterval(fetchWalletPoints, 1000); // Fetch every second

      return () => clearInterval(interval); // Cleanup on unmount
    }, [udiseNumber]);



    const handleLogout = () => {
      // Clear relevant items from local storage
      localStorage.removeItem("udiseNumber");
      localStorage.removeItem("language");
    };

    const openPayment = ()=>{
      window.location.href = "/paymentint";
    }

    return (
      <div className="Navbar fixed-top">
        <div className="leftSide">
          <img className="rounded-circle" src={Logo} alt="School Logo" /> <span className="eguruji-heading">E-Guruji</span>
        </div>

        <div className="rightSide">
        
          {location.pathname !== "/" && location.pathname !== "/school-register" && location.pathname !== "/eguruji-landingpage" && location.pathname !== "/downloadexe-landingpage"&& (
            <>  
            <div onClick={openPayment} className="wallet-points1" style={{height:'33px', marginTop:'5px', marginRight:'-20px'}}>
          💰{language === "English" ? " Wallet Points: " : "वॉलेट पॉइंट्स: "} 
            <span className="points">{walletPoints}</span>
          </div>
            <a href="/" onClick={handleLogout} style={{fontSize:'12px', height:'33px', marginTop:'6px', background:'red'}}>
              {language === "English" ? "Log Out" : "लॉग आउट"}
            </a>
            </> 
          )}
        </div>
      </div>
    );
  }

  export default Navbar;
