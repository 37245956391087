import React, { useState, useEffect } from 'react';
import { MdOutlineFilterList } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import './New.css';
import Sidebar from '../../components/Sidebar';


const CustomMenu = ({ checkboxes, isOpen }) => {
  return (
    <div className={`custom-menu1 ${isOpen ? 'open1' : ''}`}>
      <div className="row">
        <div className="col">
          <div className="collapse1 multi-collapse1" id="multiCollapseExample2">
            <div style={{marginBottom:'0'}} className="card card-body open-card1">
              {checkboxes.map((checkbox, index) => (
                <label key={index}>
                  <input className='me-2'
                    type="checkbox"
                    checked={checkbox.checked}
                    onChange={checkbox.onChange}
                    style={{width:'auto'}}
                  />
                  {checkbox.label}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const StudentAllData = () => {
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedCaste, setSelectedCaste] = useState('');
  const [students, setStudents] = useState([]);
  const [srNo, setSrNo] = useState(1);
  const [classOptions, setClassOptions] = useState([]);
  const [casteOptions, setCasteOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showAdharNo, setShowAdharNo] = useState(false);
  const [showCountry, setShowCountry] = useState(false);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showReligion, setShowReligion] = useState(false);
  const [showMobileNo, setShowMobileNo] = useState(false);
  const [showTaluka, setShowTaluka] = useState(false);
  const [showDob, setShowDob] = useState(false);
  const [showBookNo, setShowBookNo] = useState(false);
  const [showSARALID, setShowSARALID] = useState(false);
  const [showRegisterNo, setShowRegisterNo] = useState(false);
  const [showPrevSchool, setShowPrevSchool] = useState(false);
  const [showState, setShowState] = useState(false);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState('');
  const [filterByDivision, setFilterByDivision] = useState(false);
  const [filterByGender, setFilterByGender] = useState(false);
  const [filterByClass, setFilterByClass] = useState(false);
  const [filterByCaste, setFilterByCaste] = useState(false);
  const [expandCheckboxes, setExpandCheckboxes] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [udiseNumber, setUdiseNumber] = useState("");


  
  const romanToInt = {
    I: 1,
    II: 2,
    III: 3,
    IV: 4,
    V: 5,
    VI: 6,
    VII: 7,
    VIII: 8,
    IX: 9,
    X: 10,
    XI: 11,
    XII: 12,
  };
  
  const marathiToInt = {
    'पहिली': 1,
    'दुसरी': 2,
    'तिसरी': 3,
    'चौथी': 4,
    'पाचवी': 5,
    'सहावी': 6,
    'सातवी': 7,
    'आठवी': 8,
    'नववी': 9,
    'दहावी': 10,
    'अकरावी':11,
    'बारावी': 12
  };

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);


  const handleEllipsisClick = () => {
    setExpandCheckboxes(!expandCheckboxes);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (udiseNumber) {
      fetchAllRegisters();
      fetchAllClasses();
      fetchAllCaste();
    }

  }, [udiseNumber]);

  useEffect(() => {
    fetchDataByFilters();
  }, [filterByGender, filterByCaste, filterByClass,filterByDivision, selectedGender, selectedCaste, selectedClass, selectedDivision, udiseNumber]);

  const fetchAllRegisters = async () => {
    try {
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        if (data && typeof data === 'object') {
          const filteredData = Object.values(data).filter(student => student !== null);
          const divisions = filteredData.map(student => student.division);
          setDivisionOptions(divisions);
        } else {
          throw new Error('Invalid data format');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching registers:', error);
    }
  };

  const fetchAllClasses = async () => {
    try {
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        if (data && typeof data === 'object') {
          const classesSet = new Set(
            Object.values(data)
              .filter(student => student !== null && typeof student === 'object')
              .map(student => student.currentClass)
          );

          setClassOptions(Array.from(classesSet));
        } else {
          throw new Error('Invalid data format');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchAllCaste = async () => {
    try {
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (response.ok) {
        const data = await response.json();

        if (data && typeof data === 'object') {
          const castesSet = new Set(
            Object.values(data)
              .filter(student => student !== null && typeof student === 'object')
              .map(student => student.caste)
          );
          setCasteOptions(Array.from(castesSet));
        } else {
          throw new Error('Invalid data format');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching castes:', error);
    }
  };

  const fetchDataByFilters = async () => {
    try {
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
      if (response.ok) {
        const data = await response.json();
        if (data && typeof data === 'object') {
          let filteredData = Object.values(data)
            .filter(student => student !== null && typeof student === 'object');

          if (filterByGender && selectedGender !== '') {
            filteredData = filteredData.filter(student => student.gender === selectedGender);
          }
          if (filterByCaste && selectedCaste !== '') {
            filteredData = filteredData.filter(student => student.caste === selectedCaste);
          }
          if (selectedClass && selectedClass !== '') {
            filteredData = filteredData.filter(student => student.currentClass === selectedClass);
          }
          if (selectedDivision !== '') {
            filteredData = filteredData.filter(student => student.division === selectedDivision);
          }


          setStudents(filteredData);
        } else {
          throw new Error('Invalid data format');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleCasteChange = (event) => {
    setSelectedCaste(event.target.value);
  };

  const handleDivisionChange = (event) => {
    setSelectedDivision(event.target.value);
  };

  const handleClearData = () => {
    window.location.reload();
  };
  
  const handlePrint = () => {
    const printTitleElement = document.getElementById('print-title');
    if (printTitleElement) {
      let logoHTML = '';
      if (logoURL) {
        logoHTML = `<img src="${logoURL}" alt="School Logo" style="width: 40px; height: 40px; margin-right: 10px;" />`;
      }
  
      let schoolDataHTML = `${schoolName}<br />Taluka: ${taluka}&nbsp;&nbsp;&nbsp;District: ${jilha}`;
  
      printTitleElement.innerHTML = `
        <div style="display: flex; align-items: center; padding-top: -20px;">
          <div style="flex: 1; text-align: left; margin-left: 20px;">
            ${logoHTML}
          </div>
          <div style="flex: 2; text-align: center;">
            <div style="font-size: 8px; font-weight: 300; margin-bottom: 10px; margin-top: -5px;">${sansthaName}</div>
            <div style="font-size: 9px; font-weight: 400; margin-top: 0px;">${schoolDataHTML}</div>
          </div>
          <div style="flex: 1;"></div>
        </div>
      `;
      
      removeLastColumnOnPrint()
      // Delay the print call to allow content rendering
      setTimeout(() => {
        window.print();
        appendLastColumnOnPrint()
        // Clean up the print title element after printing
        if (printTitleElement) {
          printTitleElement.innerHTML = ''; 
          printTitleElement.style.fontSize = ''; 
          printTitleElement.style.fontWeight = ''; 
          printTitleElement.style.paddingBottom = '14px'; 
        }
      }, 1000); // 1000 ms delay
    }
  };
  function removeLastColumnOnPrint() {
    const table = document.querySelector('.table');
    const thead = table.querySelector('thead');
    const tbody = table.querySelector('tbody');
  
    const lastHeader = thead.querySelector('tr th:last-child');
    if (lastHeader) {
      lastHeader.style.display = 'none';
    }
    tbody.querySelectorAll('tr').forEach(row => {
      const lastCell = row.querySelector('td:last-child');
      if (lastCell) {
        lastCell.style.display = 'none';
      }
    });
  }
  
  function appendLastColumnOnPrint() {
    const table = document.querySelector('.table');
    const thead = table.querySelector('thead');
    const tbody = table.querySelector('tbody');
  
    const lastHeader = thead.querySelector('tr th:last-child');
    if (lastHeader) {
      lastHeader.style.display = '';
    }
  
    tbody.querySelectorAll('tr').forEach(row => {
      const lastCell = row.querySelector('td:last-child');
      if (lastCell) {
        lastCell.style.display = '';
      }
    });
  }
  const translations = {
    English: {
      division: 'Division',
      gender: 'Gender',
      adharNo: 'Adhar No',
      country: 'Country',
      district: 'District',
      class: 'Class',
      caste: 'Caste',
      taluka: 'Taluka',
      dob: 'Dob',
      religion: 'Religion',
      mobileNo: 'MobileNo',
      SARALID : 'SARALID',
      registerNo: 'RegisterNo',
      prevSchool: 'PrevSchool',
      state: 'State',
      bookNo: 'BookNo',
    },
    Marathi: {
      division: 'तुकडी',
      gender: 'लिंग',
      adharNo: 'आधार क्र',
      country: 'देश',
      district: 'जिल्हा',
      class: 'वर्ग',
      caste: 'जात',
      taluka: 'तालुका',
      dob: 'जन्मतारीख',
      religion: 'धर्म',
      mobileNo: 'मोबाईल नं.',
      SARALID : 'सरल आयडी',
      registerNo: 'रजिस्टर क्र.',
      prevSchool: 'मागील शाळा',
      state: 'राज्य',
      bookNo: 'पुस्तक क्र.',
    },
  };
  const checkboxes = [

    {
      label: translations[language].division,
      checked: filterByDivision,
      onChange: () => setFilterByDivision(!filterByDivision),
    },

    {
      label: translations[language].gender,
      checked: filterByGender,
      onChange: () => setFilterByGender(!filterByGender),
    },
    {
      label: translations[language].caste,
      checked: filterByCaste,
      onChange: () => setFilterByCaste(!filterByCaste),
    },
    {
      label: translations[language].class,
      checked: filterByClass,
      onChange: () => setFilterByClass(!filterByClass),
    },
    {
      label: translations[language].adharNo,
      checked: showAdharNo,
      onChange: () => setShowAdharNo(!showAdharNo),
    },
    {
      label: translations[language].country,
      checked: showCountry,
      onChange: () => setShowCountry(!showCountry),
    },
    {
      label: translations[language].district,
      checked: showDistrict,
      onChange: () => setShowDistrict(!showDistrict),
    },
    {
        label: translations[language].taluka,
        checked: showTaluka,
        onChange: () => setShowTaluka(!showTaluka),
      },
      {
        label: translations[language].dob,
        checked: showDob,
        onChange: () => setShowDob(!showDob),
      },
      {
        label: translations[language].religion,
        checked: showReligion,
        onChange: () => setShowReligion(!showReligion),
      },
      {
        label: translations[language].mobileNo,
        checked: showMobileNo,
        onChange: () => setShowMobileNo(!showMobileNo),
      },
      {
        label: translations[language].SARALID,
        checked: showSARALID,
        onChange: () => setShowSARALID(!showSARALID),
      },
      {
        label: translations[language].registerNo,
        checked: showRegisterNo,
        onChange: () => setShowRegisterNo(!showRegisterNo),
      },
      {
        label: translations[language].prevSchool,
        checked: showPrevSchool,
        onChange: () => setShowPrevSchool(!showPrevSchool),
      },

      {
        label: translations[language].state,
        checked: showState,
        onChange: () => setShowState(!showState),
      },
  ];

  const [schoolName, setSchoolName] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [sansthaName, setsansthaName] = useState("");
  const [logoURL, setLogoURL] = useState(null);
  
  const fetchSchoolName = async () => {
    if (udiseNumber) {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        const schoolData = await response.json();
        if (schoolData) {
          if (schoolData.schoolName) {
            setSchoolName(schoolData.schoolName);
          }
          if (schoolData.taluka) {
            setTaluka(schoolData.taluka);
          }
          if (schoolData.jilha) {
            setJilha(schoolData.jilha);
          }
          if (schoolData.sansthaName) {
            setsansthaName(schoolData.sansthaName);
          }
          if (schoolData.schoolLogo) {
            setLogoURL(schoolData.schoolLogo);
          }
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    }
  };
  useEffect(() => {
  fetchSchoolName();
}, [udiseNumber]);
  return (
    <div>
    <Sidebar/>
    <div className='mb-5 main-content-of-page'>
    <style>
          {`
            @media print {
              .table th,
              .table td {
                background-color: transparent !important;
                -webkit-print-color-adjust: exact;
              }
              .bg-secondary {
                background-color: transparent !important;
              }
              .print-button {
                display: none;
              }
              .custom-heading-text{
                margin: -5px auto;
              }
            
            }
          `}
        </style>
        <center style={{borderBottom : '0.5px solid gray', padding:'-10px', marginBottom:'20px' }}>
          <div id="print-title" className="text-center" style={{ marginTop: '10px', fontWeight: 'bold' }}></div>
          <div className="custom-heading"  style={{paddingBottom:'-20px', marginTop:'-10px'}}>
            <h2 className="custom-heading-text text-center" style={{paddingBottom:'-20px', marginBottom:'-10px'}}>{language === "English" ? "Student Report" : "विद्यार्थी अहवाल"}</h2>
          </div>
        </center>
    
      <div className="filter-container " style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "10px", }}>
        <div onClick={handleEllipsisClick} style={{ position: "absolute", left: 0 }}>
          <button className="btn btn-primary filterbtn1" type="button" data-toggle="collapse" data-target="#multiCollapseExample2" aria-expanded="false" aria-controls="multiCollapseExample2"><FaFilter /></button>
        </div>
        {expandCheckboxes && <CustomMenu checkboxes={checkboxes} isOpen={isOpen} />}
        <div>
          <label htmlFor="classDropdown">{language === "English" ? "Select Class" : "वर्ग निवडा"}</label>
          <select id="classDropdown" value={selectedClass} onChange={handleClassChange} className="dropdown">
            <option value="" disabled>{language === "English" ? "Select" : "निवडा"}</option>
            {classOptions
  .filter(cls => cls !== null && cls !== undefined && cls !== '') // Filter out null and undefined classes
  .sort((a, b) => {
    const classA = a.split(' ')[1];
    const classB = b.split(' ')[1];
    let numA, numB;

    if (language === "English") {
      numA = romanToInt[classA] !== undefined ? romanToInt[classA] : 0; // Default to 0 if not found
      numB = romanToInt[classB] !== undefined ? romanToInt[classB] : 0; // Default to 0 if not found
    } else {
      numA = marathiToInt[classA] !== undefined ? marathiToInt[classA] : 0; // Default to 0 if not found
      numB = marathiToInt[classB] !== undefined ? marathiToInt[classB] : 0; // Default to 0 if not found
    }

    return numA - numB; // Sort based on the numerical value
  })
  .map((cls, index) => (
    <option key={index} value={cls}>
      {cls}
    </option>
  ))}
          </select>
        </div>
        <div>
  <label htmlFor="divisionDropdown">
    {language === "English" ? "Select Division" : "तुकडी"}
  </label>
  <select
    id="divisionDropdown"
    value={selectedDivision}
    onChange={handleDivisionChange}
    className="dropdown"
  >
    <option value="" disabled>
      {language === "English" ? "Select" : "निवडा"}
    </option>
    {[...new Set(divisionOptions
  .filter(option => option !== null && option !== undefined && option !== ''))] 
  .sort((a, b) => a.localeCompare(b)) 
  .map((option, index) => (
    <option key={index} value={option}>
      {option}
    </option>
  ))}
  </select>
</div>

        <div className="filter-icon" onClick={() => setShowFilters(!showFilters)}>
          <MdOutlineFilterList />
        </div>
        {showFilters && (
          <div className="filter-panel">
            <div className="filter-options">
              <label htmlFor="gender">{language === "English" ? "Select Gender" : "लिंग निवडा"}</label>
              <select id="gender" value={selectedGender} onChange={handleGenderChange}>
                <option value="" disabled>{language === "English" ? "Select" : "निवडा"}</option>
                <option value="male">{language === "English" ? "Male" : "मुलगा"}</option>
                <option value="female">{language === "English" ? "Female" : "मुलगी"}</option>
              </select>
            </div>
            <div className="filter-options">
              <label htmlFor="Caste">{language === "English" ? "Select Caste" : "जात निवडा"}</label>
              <select id="Caste" value={selectedCaste} onChange={handleCasteChange}>
                <option value="" disabled>{language === "English" ? "Select" : "निवडा"}</option>
                {casteOptions.filter(option => option !== null && option !== undefined && option !== '').map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div style={{ position: "relative" }}>
          <button onClick={handlePrint} className="btn btn-primary">Print</button>
          <button onClick={handleClearData} className="clear-button">Clear Data</button>
        </div>
      </div>
      <div className="table-responsive mt-3">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>{language === "English" ? "Sr.No" : "अनु क्र."}</th>
             {filterByDivision && <th>{language === "English" ? "Division" : "तुकडी."}</th>}
              <th>{language === "English" ? "Name" : "नाव"}</th>
              {filterByGender && <th>{language === "English" ? "Gender" : "लिंग"}</th>}
              {showAdharNo && <th>{language === "English" ? "Adhar No" : "आधार क्र."}</th>}
              {showCountry && <th>{language === "English" ? "Country" : "देश"}</th>}
              {showDistrict && <th>{language === "English" ? "District" : "जिल्हा"}</th>}
              {filterByClass && <th>{language === "English" ? "Class" : "वर्ग"}</th>}
              {filterByCaste && <th>{language === "English" ? "Caste" : "जात"}</th>}
              {showReligion && <th>{language === "English" ? "Religion " : "धर्म"}</th>}
              {showMobileNo && <th>{language === "English" ? "MobileNo " : "मोबाईल नं."}</th>}
              {showTaluka && <th>{language === "English" ? "Taluka " : "तालुका"}</th>}
              {showDob && <th>{language === "English" ? "Dob" : "जन्मतारीख"}</th>}
              {showBookNo && <th>{language === "English" ? "BookNo " : "पुस्तक क्र."}</th>}
              {showSARALID && <th>{language === "English" ? "SARALID" : "सरल आयडी"}</th>}
              {showRegisterNo && <th>{language === "English" ? "RegisterNo " : "रजिस्टर क्र."}</th>}
              {showPrevSchool && <th>{language === "English" ? "PrevSchool" : "मागील शाळा"}</th>}
              {showState && <th>{language === "English" ? "State" : "राज्य"}</th>}
              <th id='editcolumn1'>{language === "English" ? "Edit" : "सुधारणे"}</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.id}>
                <td>{index + 1}</td>
                {filterByDivision &&<td>{student.division}</td>}
                <td>{student.stdName}  {student.stdFather}  {student.stdSurname}</td>
                {filterByGender && <td>{student.gender}</td>}
                {showAdharNo && <td>{student.stdAdharNo}</td>}
                {showCountry && <td>{student.country}</td>}
                {showDistrict && <td>{student.district}</td>}
                {filterByClass && <td>{student.currentClass}</td>}
                {filterByCaste && <td>{student.caste}</td>}
                {showReligion && <td>{student.religion}</td>}
                {showMobileNo&& <td>{student.mobileNo}</td>}
                {showTaluka && <td>{student.taluka}</td>}
                {showDob && <td>{student.dob}</td>}
                {showBookNo && <td>{student.bookNo}</td>}
                {showSARALID && <td>{student.studentId}</td>}
                {showRegisterNo && <td>{student.registerNo}</td>}
                {showPrevSchool && <td>{student.prevSchool}</td>}
                {showState && <td>{student.state}</td>}
               
                <td id='editcolumn2'>
                  <Link to={`/student-register/?serialNo=${student.serialNo}`}>
                    <BiEdit className="edit-icon" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default StudentAllData;