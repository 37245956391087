import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../result/result.css";
import Sidebar from "../../components/Sidebar";

const ResultEntry = () => {
  const [academicYear, setAcademicYear] = useState("");
  const [examNames, setExamNames] = useState([
    "First Semester",
    "Second Semester",
  ]);


  const [classValue, setClassValue] = useState("");
  const [subject, setSubject] = useState("");
  const [subjects, setSubjects] = useState({});
  const [newSubject, setNewSubject] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [customOption, setCustomOption] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const udiseNumber = localStorage.getItem("udiseNumber");
  const [marksData, setMarksData] = useState({});

  const [specialEntries, setSpecialEntries] = useState({});
  const [interestsAndHobbies, setInterestsAndHobbies] = useState({});
  const [necessaryCorrections, setNecessaryCorrections] = useState({});
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);


  const [selectedExamName, setSelectedExamName] = useState(examNames[0]);

  const handleAcademicYearChange = (e) => setAcademicYear(e.target.value);
  const handleClassChange = (e) => {
    setClassValue(e.target.value);
    fetchSubjectsForClass(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);

    setSelectedSubject(e.target.value)
  };
  const handleNewSubjectChange = (e) => setNewSubject(e.target.value);
  const handleExamNameChange = (e) => setSelectedExamName(e.target.value);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const addSubject = () => setShowInput(!showInput);

  const fetchStudentData = async () => {
    try {
      const response = await fetch(
        `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Filter out null entries
      const filteredData = Object.keys(data)
        .filter(key => data[key] !== null)
        .map(key => ({ srNo: key, ...data[key] }));

      setStudentData(filteredData);

      console.log("filteredData", filteredData);

      const classSet = new Set();
      filteredData.forEach((student) => {
        if (student.currentClass) {
          classSet.add(String(student.currentClass));
          console.log("student.currentClass", student.currentClass);
        }
      });
      setClasses([...classSet]);
      console.log("classSet", classSet);

    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };



const fetchSubjectsForClass = async (classValue) => {
  try {
    if (!academicYear) {
      console.error("Academic year is not set");
      return;
    }

    const subjects = {};
    for (const student of studentData) {
      if (student.currentClass === classValue) {
        for (const examName of examNames) {
          const response = await fetch(
            `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${examName}.json`
          );
          if (!response.ok) {
            throw new Error(
              `Network response was not ok for student ${student.srNo}`
            );
          }
          const studentResult = await response.json();
          if (studentResult) {
            Object.keys(studentResult).forEach((subject) => {
              // Exclude the 'nondi' key
              if (subject !== "nondi") {
                subjects[subject] = true;
              }
            });
          }
        }
      }
    }

    setSubjects(subjects);
  } catch (error) {
    console.error(
      `Error fetching subjects for class ${classValue} and academic year ${academicYear}:`,
      error
    );
  }
};

useEffect(() => {
  fetchStudentData();
}, []);

useEffect(() => {
  if (classValue) {
    fetchSubjectsForClass(classValue);
  }
}, [classValue]);


  useEffect(() => {
    setSubject(Object.keys(subjects)[0] || "");
  }, [subjects]);
  useEffect(() => {
    if (subject && selectedExamName && classValue && academicYear) {
      fetchMarksForSelectedSubject();
    }
  }, [subject, selectedExamName, classValue, academicYear]);

  const fetchMarksForSelectedSubject = async () => {
    try {
      const selectedStudents = studentData.filter(
        (student) => student.currentClass === classValue
      );
      setSelectedStudents(selectedStudents);

      const marksData = {};
      for (const student of selectedStudents) {
        const studentMarks = await fetchMarksData(
          student.srNo,
          academicYear,
          selectedExamName,
          subject
        );
        marksData[student.srNo] = studentMarks;
      }
      setMarksData(marksData);
    } catch (error) {
      console.error("Error fetching marks data:", error);
    }
  };

  const submit = async () => {
    setShowForm(true);
    setShowModal(true);

    const selectedStudents = studentData.filter(
      (student) => student.currentClass === classValue
    );
    setSelectedStudents(selectedStudents);

    const marksData = {};

    for (const student of selectedStudents) {
      const studentMarks = await fetchMarksData(
        student.srNo,
        academicYear,
        selectedExamName,
        subject
      );
      marksData[student.srNo] = studentMarks;
    }

    setMarksData(marksData);
  };

  const submitNewSubject = async () => {
    if (newSubject.trim() !== "" && classValue && academicYear) {
      try {
        setSubjects((prevSubjects) => ({
          ...prevSubjects,
          [newSubject]: true,
        }));

        studentData.forEach(async (student) => {
          if (student.currentClass === classValue) {
            for (const examName of examNames) {
              await fetch(
                `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${examName}.json`,
                {
                  method: "PATCH",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ [newSubject]: true }),
                }
              );
            }
          }
        });

        setNewSubject("");
        setShowInput(false);
      } catch (error) {
        console.error(`Error submitting new subject:`, error);
      }
    }
  };

  const deleteSubject = async (subjectToDelete) => {
    try {
      const updatedSubjects = { ...subjects };
      delete updatedSubjects[subjectToDelete];
      setSubjects(updatedSubjects);

      // Delete subject from Firebase
      studentData.forEach(async (student) => {
        if (student.currentClass === classValue) {
          for (const examName of examNames) {
            await fetch(
              `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${examName}/${subjectToDelete}.json`,
              {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
          }
        }
      });
    } catch (error) {
      console.error(`Error deleting subject ${subjectToDelete}:`, error);
    }
  };

  const classMapping = {
    "Class I": 1,
    "Class II": 2,
    "Class III": 3,
    "Class IV": 4,
    "Class V": 5,
    "Class VI": 6,
    "Class VII": 7,
    "Class VIII": 8,

    "इयत्ता पहिली" : 1,
    "इयत्ता दुसरी" : 2,
    "इयत्ता तिसरी" : 3,
    "इयत्ता चौथी" : 4,
    "इयत्ता पाचवी" : 5,
    "इयत्ता सहावी" : 6,
    "इयत्ता सातवी" : 7,
    "इयत्ता आठवी" : 8,
  
  };

  const handleMarksChange = (srNo, category, type, value) => {
  const newValue = Number(value);

  setMarksData((prevMarksData) => {
    const updatedMarksData = {
      ...prevMarksData,
      [srNo]: {
        ...prevMarksData[srNo],
        [category]: {
          ...prevMarksData[srNo]?.[category],
          [type]: newValue,
        },
      },
    };

    let currentClass = selectedStudents.find(
      (student) => student.srNo === srNo
    )?.currentClass;

    console.log("Current Class:", currentClass);

    // Trim and normalize the class name
    currentClass = currentClass?.trim().replace(/\s+/g, " ");
    
    // Map the class name to its corresponding numeric value
    currentClass = classMapping[currentClass];

    if (!currentClass) {
      alert("Class is undefined or not found in mapping.");
      return prevMarksData; // Prevent further processing
    }

    const isDefaultSubject = defaultSubjects.some(
      (subject) => subject.name === selectedSubject
    );

    let maxTotalAkarik = 0;
    let maxTotalSanklik = 0;

    if (isDefaultSubject) {
      maxTotalAkarik = 100;
      maxTotalSanklik = 0;
    } else {
      if ([1, 2].includes(currentClass)) {
        maxTotalAkarik = 70;
        maxTotalSanklik = 30;
      } else if ([3, 4].includes(currentClass)) {
        maxTotalAkarik = 60;
        maxTotalSanklik = 40;
      } else if ([5, 6].includes(currentClass)) {
        maxTotalAkarik = 50;
        maxTotalSanklik = 50;
      } else if ([7, 8].includes(currentClass)) {
        maxTotalAkarik = 40;
        maxTotalSanklik = 60;
      }
    }

    const totalAkarik = [
      "Daily Monitoring",
      "Oral Work",
      "Demonstration",
      "Activity",
      "Project",
      "Test",
      "Homework",
      "Others",
    ].reduce(
      (acc, key) => acc + (updatedMarksData[srNo]?.Akarik?.[key] || 0),
      0
    );

    const totalSanklik = ["Orally", "Demonstration", "Writing"].reduce(
      (acc, key) => acc + (updatedMarksData[srNo]?.Sanklik?.[key] || 0),
      0
    );

    if (isDefaultSubject) {
      if (category === "Sanklik" && totalSanklik > maxTotalSanklik) {
        alert(
          `Sanklik total should not exceed ${maxTotalSanklik} for default subject ${selectedSubject}`
        );
        return prevMarksData;
      }

      if (category === "Akarik" && totalAkarik > maxTotalAkarik) {
        alert(
          `Akarik total should not exceed ${maxTotalAkarik} for default subject ${selectedSubject}`
        );
        return prevMarksData;
      }
    } else {
      if (category === "Akarik" && totalAkarik > maxTotalAkarik) {
        alert(
          `Akarik total should not exceed ${maxTotalAkarik} for class ${currentClass}`
        );
        return prevMarksData;
      }
      if (category === "Sanklik" && totalSanklik > maxTotalSanklik) {
        alert(
          `Sanklik total should not exceed ${maxTotalSanklik} for class ${currentClass}`
        );
        return prevMarksData;
      }
    }

    updatedMarksData[srNo] = {
      ...updatedMarksData[srNo],
      Akarik: {
        ...updatedMarksData[srNo]?.Akarik,
        Total: totalAkarik,
      },
      Sanklik: {
        ...updatedMarksData[srNo]?.Sanklik,
        Total: totalSanklik,
      },
    };

    return updatedMarksData;
  });
};


  const saveMarks = async () => {
    try {
      for (const student of selectedStudents) {
        const marks = marksData[student.srNo] || {};
  
        // Save subject-specific marks
        await fetch(
          `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${selectedExamName}/${subject}.json`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(marks),
          }
        );
  
        // Save additional fields under the selected exam name
          const additionalFields = {
            specialEntries: specialEntries[student.srNo] || "",
            interestsAndHobbies: interestsAndHobbies[student.srNo] || "",
            necessaryCorrections: necessaryCorrections[student.srNo] || "",
          };
    
          await fetch(
            `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${selectedExamName}/nondi.json`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(additionalFields),
            }
          );
        }
        alert("Marks and additional fields saved successfully");
      } catch (error) {
        console.error("Error saving marks and additional fields:", error);
      }
    };
 

    const handleSpecialEntriesChange = (srNo, value) => {
      setSpecialEntries(prevState => ({
        ...prevState,
        [srNo]: value
      }));
    };
    
    const handleInterestsAndHobbiesChange = (srNo, value) => {
      setInterestsAndHobbies(prevState => ({
        ...prevState,
        [srNo]: value
      }));
    };
    
    const handleNecessaryCorrectionsChange = (srNo, value) => {
      setNecessaryCorrections(prevState => ({
        ...prevState,
        [srNo]: value
      }));
    };
    

// Function to fetch additional fields for selected students
const fetchAdditionalFields = async () => {
  try {
    if (selectedStudents.length > 0) {
      // Iterate over selected students
      for (const student of selectedStudents) {
        const response = await fetch(
          `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${selectedExamName}/nondi.json`
        );
        const data = await response.json();

        // Check if data is not null and contains the expected properties
        if (data) {
          setSpecialEntries((prev) => ({ ...prev, [student.srNo]: data.specialEntries || "" }));
          setInterestsAndHobbies((prev) => ({ ...prev, [student.srNo]: data.interestsAndHobbies || "" }));
          setNecessaryCorrections((prev) => ({ ...prev, [student.srNo]: data.necessaryCorrections || "" }));
        } else {
          // Handle the case where data is null or undefined
          setSpecialEntries((prev) => ({ ...prev, [student.srNo]: "" }));
          setInterestsAndHobbies((prev) => ({ ...prev, [student.srNo]: "" }));
          setNecessaryCorrections((prev) => ({ ...prev, [student.srNo]: "" }));
        }
      }
    }
  } catch (error) {
    console.error("Error fetching additional fields:", error);
  }
};

// Call fetchAdditionalFields when component mounts or selectedStudents change
useEffect(() => {
  if (selectedStudents.length > 0) {
    fetchAdditionalFields();
  }
}, [selectedStudents, udiseNumber, academicYear, selectedExamName]);



  const fetchMarksData = async (srNo, academicYear, examName, subject) => {
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${srNo}/result/${academicYear}/${examName}/${subject}.json`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const data = await response.json();
      return data || {};
    } catch (error) {
      console.error("Error fetching marks data:", error);
      return {};
    }
  };

  const englishSubjects = [
    { name: "Arts", sanklikMarks: 100 },
    { name: "Work Experience", sanklikMarks: 100 },
    { name: "Physical Education", sanklikMarks: 100 },
  ];

  const marathiSubjects = [
    { name: "कला", sanklikMarks: 100 },
    { name: "कार्यानुभव", sanklikMarks: 100 },
    { name: "शा.शिक्षण", sanklikMarks: 100 },
  ];

  const [defaultSubjects, setDefaultSubjects] = useState(englishSubjects);

  useEffect(() => {
    if (language === 'English') {
      setDefaultSubjects(englishSubjects);
    } else {
      setDefaultSubjects(marathiSubjects);
    }
  }, [language]);

  const [showDefaultSubjects, setShowDefaultSubjects] = useState(true);
  const addDefaultSubject = (subject) => {
    setSubjects((prevSubjects) => ({ ...prevSubjects, [subject.name]: true }));
  };
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isDefaultSubject, setIsDefaultSubject] = useState(false);

  const handleDelete = () => {
    if (subject && window.confirm('Are you sure you want to delete this subject? This will delete selected subject data ')) {
      deleteSubject(subject);
    }
  };


  const handleSelectChange = (e) => {
    if (e.target.value === 'custom') {
      // Open the custom input field
    } else {
      setSelectedValue(e.target.value);
    }
  };

  const handleCustomInputChange = (e) => {
    setCustomOption(e.target.value);
  };

  const addCustomOption = () => {
    setSelectedValue(customOption);
  };

  return (
    <div>

      <Sidebar />
      <div>
        <div className=" main-content-of-page" >
          <h1 className="title"> {language === "English" ? "Marks Entry  " : "गुण नोंदणी"}</h1>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }}>
            <table className="table table-striped table-bordered" style={{ flex: '2' }}>
              <tbody>
                <tr>
                  <th>{language === "English" ? "Academic Year " : "शैक्षणिक वर्ष"}</th>
                  <td>
  <select
    id="academicYear"
    defaultValue={academicYear} // Add this prop
    value={academicYear}
    onChange={handleAcademicYearChange}
    className="form-control custom-select"
  >
    <option >{language === "English" ? "Select Year " : "वर्ष निवडा"}</option>
    <option value="2023-2024" >2023-2024</option>
    <option value="2024-2025" selected>2024-2025</option>
    <option value="2025-2026">2025-2026</option>
    <option value="2026-2027">2026-2027</option>
  </select>
</td>
                </tr>
                <tr>
                  <th>{language === "English" ? "Class " : "वर्ग"}</th>
                  <td>
                    <select
                      id="class"
                      value={classValue}
                      onChange={handleClassChange}  
                      className="form-control custom-select"
                    >
                      <option value="">{language === "English" ? "Select Class " : "वर्ग निवडा"}</option>
                      {classes.map((cls, index) => (
                        <option key={index} value={cls}>
                          {cls}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>{language === "English" ? "Subject " : "विषय"}</th>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <select
                        id="subject"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                          handleSubjectChange(e);
                        }}
                        className="form-control custom-select"
                      >
                        <option value="" selected>{language === "English" ? "Select Subject " : "विषय निवडा"}</option>
                        {Object.keys(subjects).map((sub, index) => (
                          <option key={index} value={sub}>
                            {sub}
                          </option>
                        ))}
                      </select>
                      {subject && (
                        <button
                          onClick={handleDelete}
                          style={{
                            marginLeft: '10px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                        >
                          ❌
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{language === "English" ? "Exam Name " : "परीक्षेचे नाव"}</th>
                  <td>
                    <select
                      id="examName"
                      value={selectedExamName}
                      onChange={handleExamNameChange}
                      className="form-control custom-select"
                    >
                      <option value="">{language === "English" ? "Select Exam " : "परीक्षा निवडा"}</option>
                      {examNames.map((examName, index) => (
                        <option key={index} value={examName}>
                          {examName}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <button
                      onClick={addSubject}
                      className="btn btn-primary btn-block me-3"
                    >
                      Add Subject
                    </button>

                    <button onClick={submit} className="btn btn-primary btn-block">
                     {language === "English" ? "  Enter Marks" : "गुण प्रविष्ट करा"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            {showDefaultSubjects && (
              <div style={{ flex: '1' }}>
                <h2>{language === "English" ? "  Default Subjects" : "विषय"}</h2>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>{language === "English" ? "  Subject Name" : "विषयाचे नाव"}</th>
                      <th>{language === "English" ? "  Action" : "कृती"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defaultSubjects.map((subject) => (
                      <tr key={subject.name}>
                        <td>{subject.name}</td>
                        <td>
                          <button type="button" onClick={() => addDefaultSubject(subject)}>
                            Add
                          </button>
                        </td>
                      </tr>
                    ))}

                    {showInput && (
                      <tr>
                        <td colSpan="2">
                          <input
                            type="text"
                            value={newSubject}
                            onChange={handleNewSubjectChange}
                            className="form-control"
                            placeholder="Enter new subject"
                          />
                          <button
                            onClick={submitNewSubject}
                            className="btn btn-primary btn-block mt-2"
                          >
                            {language === "English" ? "  Submit New Subject" : "नवीन विषय सबमिट करा"}
                          </button>
                        </td>
                      </tr>

                    )}
                  </tbody>
                </table>
              </div>
            )}

          </div>
        </div>

        <div class="footer-notes">
          <div class="column">
            <h2> {language === "English" ? "  Subject Weight:" : "इतर विषय भारांश"}</h2>
            <ul>
              <li>{language === "English" ? "  First and Second Class - Dimensional + Compiled = Total Marks - 70+30=100 " : "पहिली व दुसरी - आकारिक + संकलित = एकूण गुण - 70+30=100"}</li>
              <li>{language === "English" ? "  Third and Fourth Class - Dimensional + Compiled = Total Marks = 60 +40 100" : "तिसरी व चौथी वर्ग - आकारिक + संकलित = एकूण गुण = 60 +40 100"}</li>
              <li>{language === "English" ? "  Fifth & Sixth Class - Dimensional + Compiled = Total Marks = 50+50=100" : "पाचवी व सहावी वर्ग - आकारिक + संकलित = एकूण गुण = 50+50 = 100"}</li>
              <li>{language === "English" ? "  Seventh and Eighth Class- Dimensional + Compiled = Total marks&nbsp;=&nbsp;40+60=&nbsp;100" : "सातवी व आठवी वर्ग- आकारिक + संकलित = एकूण गुण=40+60=100"}</li>
            </ul>
          </div>
          <div class="column">
            <h2>{language === "English" ? " How To Register Marks:" : "मार्क नोंदणी कशी करावी"}</h2>
            <p class="separate-note">{language === "English" ? "  To register marks first select the type of examination then click on 'Enter Marks'. Then fill out special entries." : "मार्क नोंदणी करण्यासाठी प्रथम शैक्षणिक वर्ष निवडा,नंतर वर्ग निवडा, आणि विषय हे 'Add Subject' वर क्लिक करुन विषय भरून घ्या. त्यानंतर परीक्षा सत्र निवडुन गुण प्रविष्ट करा."}</p>
          </div>
          <div class="column">
            <h2>{language === "English" ? "Assessment:" : "कला,कार्यानुभव आणि शा.शिक्षण विषय भारांश"}</h2>
            <p class="separate-note">{language === "English" ? "For Arts, Work Experience and Physical Education subjects, out of 100 marks should be given for the assessment. There are no cumulative assessment points for these subjects." : "कला, कार्यानुभव आणि शारीरिक शिक्षण विषयांसाठी पहिली ते आठवी या वर्गासाठी आकारिक मूल्यमापनचेच 100 पैकी गुण द्यावेत.या विषयांसाठी संकलित मूल्यमापन गुण नाहीत. "}</p>
          </div>
        </div>

        {/* Modal for entering marks */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="modal-90w"
        >       
          <Modal.Header closeButton>
            <Modal.Title>
              Enter Marks for {selectedExamName} -{" "}
              <td>
    <select
      id="subject"
      value={subject}
      onChange={handleSubjectChange}
      className="form-control custom-select"
      defaultValue=""
    >
      <option value="" disabled>{language === "English" ? "Select Subject:" : "विषय निवडा"}</option>
      {Object.keys(subjects).map((sub, index) => (
        <option key={index} value={sub}>
          {sub}
        </option>
      ))}
    </select>
  </td>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style={{   width: "20px" }}>{language === "English" ? "Reg. No" : "र. नंबर"}</th>
                  <th>{language === "English" ? "Student Name" : "विद्यार्थ्याचे नाव"}</th>

                  <th colSpan={"9"} className="text-center">
                    {language === "English" ? "Akarik" : "आकारिक"}
                  </th>
                  <th colSpan={"4"} className="text-center">
                    {" "}
                    {language === "English" ? " sanklik" : "संकलिक"}
                  </th>
                  <th colSpan={"4"} className="text-center">
                    {" "}
                     {language === "English" ? " Nondi" : "नोंदी"}
                  </th>
                </tr>
                 <tr style={{ backgroundColor: "#f2f2f2", fontSize: "14px", textAlign: "center" }}>
      <th colSpan="2" style={{ padding: "8px", border: "1px solid #ddd" }}></th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Daily Monitoring" : "दैनंदिन निरीक्षण"}</th>

      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Oral Work" : "तोंडी काम"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Demonstration" : "प्रात्यक्षिके"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Activity" : "उपक्रम/कृती"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Project" : "प्रकल्प"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Test" : "चाचणी"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Homework" : "गृहपाठ"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Others" : "इतर"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Total" : "एकूण"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Orally" : "तोंडी"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Demonstration" : "प्रात्यक्षिके"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Writing" : "लेखन"}</th>
      <th style={{
  padding: "30px",
  border: "1px solid #ddd",
  height: "140px",
  width: "40px", // This width will now be applied correctly
  writingMode: "vertical-rl", // Rotates the text vertically
  transform: "rotate(180deg)", // Adjusts the text direction
  whiteSpace: "nowrap", // Ensures the text doesn't wrap
  overflow: "hidden", // Prevent overflow
}}>{language === "English" ? " Total" : "एकूण"}</th>
      <th style={{ padding: "8px", border: "1px solid #ddd" }}>{language === "English" ? " Special entries" : "विशेष नोंदी"}</th>
      <th style={{ padding: "8px", border: "1px solid #ddd" }}>{language === "English" ? " Interests and hobbies" : "आवडी आणि छंद"}</th>
      <th style={{ padding: "8px", border: "1px solid #ddd" }}>{language === "English" ? " Necessary corrections" : "आवश्यक दुरुस्ती"}</th>
    </tr>
              </thead>
              <tbody>
                {selectedStudents.map((student) => (
                  <tr key={student.srNo}>
                    <td>{student.srNo}</td>
                    <td>{student.stdName}</td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.[
                          "Daily Monitoring"
                          ] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Daily Monitoring",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Oral Work"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Oral Work",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Demonstration"] ||
                          ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Demonstration",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Activity"] || "  "
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Activity",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Project"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Project",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={marksData[student.srNo]?.Akarik?.["Test"] || ""}
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Test",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Homework"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Homework",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Akarik?.["Others"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Akarik",
                            "Others",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={marksData[student.srNo]?.Akarik?.["Total"] || ""}
                        readOnly
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Sanklik?.["Orally"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Sanklik",
                            "Orally",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Sanklik?.["Demonstration"] ||
                          ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Sanklik",
                            "Demonstration",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Sanklik?.["Writing"] || ""
                        }
                        onChange={(e) =>
                          handleMarksChange(
                            student.srNo,
                            "Sanklik",
                            "Writing",
                            e.target.value
                          )
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={
                          marksData[student.srNo]?.Sanklik?.["Total"] || ""
                        }
                        readOnly
                        className="form-control"
                      />
                    </td>
                   
<td>
<input
  list="special Entries"
  value={specialEntries[student.srNo] || ""}
  onChange={(e) => handleSpecialEntriesChange(student.srNo, e.target.value)}
  placeholder="Type or select an option"
/>
  <datalist id="special Entries">
    <option value="abc" />
    <option value="def" />
    <option value="ghi" />
    {/* Add more predefined options here */}
  </datalist>
</td>

<td>
  <input
    list="Interests and hobbies"
    value={interestsAndHobbies[student.srNo] || ""}
    onChange={(e) => handleInterestsAndHobbiesChange(student.srNo, e.target.value)}
    placeholder="Type or select an option"
  />
  <datalist id="Interests and hobbies">
    <option value="abc" />
    <option value="def" />
    <option value="ghi" />
    {/* Add more predefined options here */}
  </datalist>
</td>

<td>
  <input
    list="Necessary corrections"
    value={necessaryCorrections[student.srNo] || ""}
    onChange={(e) => handleNecessaryCorrectionsChange(student.srNo, e.target.value)}
    placeholder="Type or select an option"
  />
  <datalist id="Necessary corrections">
    <option value="abc" />
    <option value="def" />
    <option value="ghi" />
    {/* Add more predefined options here */}
  </datalist>
</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              {language === "English" ? " Close" : "Close करा"}
            </Button>
            <Button variant="primary" onClick={saveMarks}>
              {language === "English" ? " Save" : "Save करा"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ResultEntry;
