import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import '../../components/style.css';

function CasteWiseReport() {
  const [classData, setClassData] = useState([]);
  const [casteData, setCasteData] = useState([]);
  const [udiseNumber, setUdiseNumber] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [sansthaName, setsansthaName] = useState("");
  const [logoURL, setLogoURL] = useState(null);
  
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'English';
    setLanguage(storedLanguage);
  }, []);

  const classOrder = [
    language === 'English' ? 'Class I' : 'इयत्ता पहिली',
    language === 'English' ? 'Class II' : 'इयत्ता दुसरी',
    language === 'English' ? 'Class III' : 'इयत्ता तिसरी',
    language === 'English' ? 'Class IV' : 'इयत्ता चौथी',
    language === 'English' ? 'Class V' : 'इयत्ता पाचवी',
    language === 'English' ? 'Class VI' : 'इयत्ता सहावी',
    language === 'English' ? 'Class VII' : 'इयत्ता सातवी',
    language === 'English' ? 'Class VIII' : 'इयत्ता आठवी',
    language === 'English' ? 'Class IX' : 'इयत्ता नववी',
    language === 'English' ? 'Class X' : 'इयत्ता दहावी',
    language === 'English' ? 'Class XI' : 'इयत्ता अकरावी',
    language === 'English' ? 'Class XII' : 'इयत्ता बारावी'
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (udiseNumber) {
        try {
          const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
          const data = await response.json();
          if (data) {
            const classList = Object.values(data)
              .filter(student => student && student.currentClass)
              .map(student => student.currentClass);
            const sortedClasses = [...new Set(classList)].sort((a, b) => classOrder.indexOf(a) - classOrder.indexOf(b));
            setClassData(sortedClasses);
          }
        } catch (error) {
          console.error('Error fetching class data:', error);
        }
      }
    };
    fetchData();
  }, [udiseNumber, classOrder]);
  
  useEffect(() => {
    const fetchCasteCounts = async () => {
      if (udiseNumber) {
        try {
          const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
          const data = await response.json();
          if (data) {
            const allCastes = {};
            Object.values(data).forEach(student => {
              if (student && student.caste && student.currentClass && student.gender) {
                const caste = student.caste.toLowerCase();
                const classKey = student.currentClass;
                const genderKey = student.gender.toLowerCase();

                if (!allCastes[caste]) {
                  allCastes[caste] = { name: caste, classes: {} };
                }

                if (!allCastes[caste].classes[classKey]) {
                  allCastes[caste].classes[classKey] = { male: 0, female: 0 };
                }

                allCastes[caste].classes[classKey][genderKey] += 1;
              }
            });

            const casteArray = Object.values(allCastes);
            setCasteData(casteArray);
          }
        } catch (error) {
          console.error('Error fetching caste data:', error);
        }
      }
    };

    fetchCasteCounts();
  }, [udiseNumber]);

  const getClassTotal = (caste, className, gender) => {
    if (caste && caste.classes && caste.classes[className]) {
      return caste.classes[className][gender] || 0;
    } else {
      return 0;
    }
  };

  const getClassTotalOverall = (className, gender) => {
    return casteData.reduce((total, caste) => total + getClassTotal(caste, className, gender), 0);
  };

  const handlePrint = () => {
    const printTitleElement = document.getElementById('print-title');
    if (printTitleElement) {
      let logoHTML = '';
      if (logoURL) {
        logoHTML = `<img src="${logoURL}" alt="School Logo" style="width: 40px; height: 40px; margin-right: 10px;" />`;
      }
  
      let schoolDataHTML = `${schoolName}<br />Taluka: ${taluka}&nbsp;&nbsp;&nbsp;District: ${jilha}`;
  
      printTitleElement.innerHTML = `
        <div style="display: flex; align-items: center; padding-top: -20px;">
          <div style="flex: 1; text-align: left; margin-left: 20px;">
            ${logoHTML}
          </div>
          <div style="flex: 2; text-align: center;">
            <div style="font-size: 8px; font-weight: 300; margin-bottom: 10px; margin-top: -5px;">${sansthaName}</div>
            <div style="font-size: 9px; font-weight: 400; margin-top: 0px;">${schoolDataHTML}</div>
          </div>
          <div style="flex: 1;"></div>
        </div>
      `;
      
      // Delay the print call to allow content rendering
      setTimeout(() => {
        window.print();
        
        // Clean up the print title element after printing
        if (printTitleElement) {
          printTitleElement.innerHTML = ''; 
          printTitleElement.style.fontSize = ''; 
          printTitleElement.style.fontWeight = ''; 
          printTitleElement.style.paddingBottom = '14px'; 
        }
      }, 1000); // 1000 ms delay
    }
  };
  
  const fetchSchoolName = async () => {
    if (udiseNumber) {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        const schoolData = await response.json();
        if (schoolData) {
          if (schoolData.schoolName) {
            setSchoolName(schoolData.schoolName);
          }
          if (schoolData.taluka) {
            setTaluka(schoolData.taluka);
          }
          if (schoolData.jilha) {
            setJilha(schoolData.jilha);
          }
          if (schoolData.sansthaName) {
            setsansthaName(schoolData.sansthaName);
          }
          if (schoolData.schoolLogo) {
            setLogoURL(schoolData.schoolLogo);
          }
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    }
  };
  useEffect(() => {
  fetchSchoolName();
}, [udiseNumber]);

  return (
    <div>
      <Sidebar />
      <div className='main-content-of-page'>
      <style>
          {`
            @media print {
              .table th,
              .table td {
                background-color: transparent !important;
                -webkit-print-color-adjust: exact;
              }
              .bg-secondary {
                background-color: transparent !important;
              }
              .print-button {
                display: none;
              }
              .custom-heading-text{
                margin: -5px auto;
              }
            }
          `}
        </style>
        <center style={{borderBottom : '0.5px solid gray', padding:'-10px', marginBottom:'20px' }}>
          <div id="print-title" className="text-center" style={{ marginTop: '10px', fontWeight: 'bold' }}></div>
          <div className="custom-heading"  style={{paddingBottom:'-20px', marginTop:'-10px'}}>
            <h2 className="custom-heading-text text-center" style={{paddingBottom:'-20px', marginBottom:'-10px'}}>{language === "English" ? "Caste Wise Report" : "जातनिहाय अहवाल"}</h2>
            
          </div>
        </center>
        <div>
          <button onClick={handlePrint} className="print-button btn btn-primary" style={{ position: 'absolute', right: 20, top: 90 }}>Print</button>
        </div>
        <div className='table-responsive'>
          <table className="table mb-5 text-center">
            <thead>
              <tr>
                <th className='text-center'><b>{language === "English" ? "Caste" : "जात"}</b></th>
                {classData.map((className, index) => (
                  <React.Fragment key={index}>
                    <th className='text-center' colSpan={2}>{className}</th>
                  </React.Fragment>
                ))}
                <th className='text-center' colSpan={2}>{language === "English" ? "Total" : "एकूण"}</th>
                <th className='text-center'>{language === "English" ? "Sum" : "बेरीज"}</th>
              </tr>
              <tr>
                <th></th>
                {classData.map((_, index) => (
                  <React.Fragment key={index}>
                    <th className='text-center'>{language === "English" ? "Boy" : "मुलगा"}</th>
                    <th className='text-center'>{language === "English" ? "Girl" : "मुलगी"}</th>
                  </React.Fragment>
                ))}
                <th className='text-center'>{language === "English" ? "Boy" : "मुलगा"}</th>
                <th className='text-center'>{language === "English" ? "Girl" : "मुलगी"}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {casteData.map((caste, index) => (
                <tr key={index}>
                  <td>{caste.name}</td>
                  {classData.map(className => (
                    <React.Fragment key={className}>
                      <td>{getClassTotal(caste, className, 'male')}</td>
                      <td>{getClassTotal(caste, className, 'female')}</td>
                    </React.Fragment>
                  ))}
                  <td>
                    {Object.values(caste.classes).reduce((total, classCounts) => total + (classCounts.male || 0), 0)}
                  </td>
                  <td>
                    {Object.values(caste.classes).reduce((total, classCounts) => total + (classCounts.female || 0), 0)}
                  </td>
                  <td>
                    {Object.values(caste.classes).reduce((total, classCounts) => total + (classCounts.male || 0) + (classCounts.female || 0), 0)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className='bg-secondary'>{language === "English" ? "Total" : "एकूण"}</td>
                {classData.map((className, index) => (
                  <React.Fragment key={index}>
                    <td className='bg-secondary'>{getClassTotalOverall(className, 'male')}</td>
                    <td className='bg-secondary'>{getClassTotalOverall(className, 'female')}</td>
                  </React.Fragment>
                ))}
                <td className='bg-secondary'>
                  {classData.reduce((total, className) => total + getClassTotalOverall(className, 'male'), 0)}
                </td>
                <td className='bg-secondary'>
                  {classData.reduce((total, className) => total + getClassTotalOverall(className, 'female'), 0)}
                </td>
                <td className='bg-secondary'>
                  {classData.reduce((total, className) => total + getClassTotalOverall(className, 'male') + getClassTotalOverall(className, 'female'), 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CasteWiseReport;

