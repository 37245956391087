import React, { useRef, useState,useEffect } from 'react';
import { toWords } from 'number-to-words';
import AlertMessage from "../../AlertMessage";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  
  return (
    <div className="modal-overlay">
      <div style={{ backgroundColor: 'White' }}>
        <button
          className="close-btn1"
          onClick={onClose}
         
        >
          &times;
        </button>
        {children}
      </div>
      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;

          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 8px;
          width: 80%;
          height: 80%;
          max-height: 90vh;
          overflow-y: auto;
          position: relative;
        }

        .table-container1 {
          width: 50%;
          height: 800px;
          max-height: 120vh;
        }

        .close-btn1 {
    position: absolute;
    top: 6px;
    right: 5px;
    font-size: 18px;
    border: none;
    border-radius: 50%;
    background: none;
    cursor: pointer;
    color: white;
    background-color: red;
    width: 24px;   /* Adjust width to make it smaller */
    height: 24px;  /* Adjust height to make it smaller */
    line-height: 24px;  /* Centers text vertically if it’s a single character like "X" */
    padding: 0;    /* Removes any extra padding */
    text-align: center; /* Centers the text horizontally */
}

      `}</style>
    </div>
  );
};

const Receipt = ({
  isCreditSelected,
  selectedPavtiNos,
  pavtiDataArray,
  sansthaName,
  schoolName,
  taluka,
  jilha,
}) => {
  const printRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  const handlePrintReceipt = () => {
    const printContent = printRef.current.innerHTML; 
    const printWindow = window.open('', '', 'height=400,width=800');
    printWindow.document.write('<html><head><title>Print Receipt</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
      @page {
        size: A4; /* Set the page size to A4 */
        margin: 10mm; /* Set margins to avoid cutting content */
      }
      body {
        font-family: Arial, sans-serif;
        margin: 20px;
      }
      .receipt-container {
        width: 0%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 10px;
        page-break-inside: avoid; /* Prevent breaking within the receipt container */
      }
      .receipt {
        width: 95%;
        border: 1px solid #000;
        padding: 10px;
        margin-bottom: 20px;
        page-break-inside: avoid; /* Prevent breaking within each receipt */
      }
      .header {
        text-align: center;
        margin-bottom: 10px;
      }
      .header h2 {
        font-size: 22px;
        margin: 0;
      }
      .header h3 {
        font-size: 14px;
        margin: 0;
      }
      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .amount-box {
        border: 1px solid #000;
        padding: 5px 20px;
        display: inline-block;
        width: 150px;
        margin-left: 20px;
      }
      .signature-box {
        margin-right: 40px;
      }
    `);
    printWindow.document.write('</style></head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };
  

  const handleViewReceipt = () => {
    if (isCreditSelected && (!selectedPavtiNos || selectedPavtiNos.length === 0)) {
      setAlertMessage(language === "English" ? "No Pavti numbers selected." : "पावती क्रमांक निवडले नाहीत.");
      return;
    }

    // Ensure all pavti data is loaded before opening the modal
    if (pavtiDataArray && pavtiDataArray.length > 0) {
      setIsModalOpen(true); // Set modal open only when data is ready
    } else {
      setAlertMessage(language === "English" ? "Pavti data is not loaded yet." : "पावती डेटा अद्याप लोड केलेला नाही.");
    }
  };

  return (

    <div>
    <AlertMessage message={alertMessage} show={showAlert}/>
    <div>
      <button
        onClick={handleViewReceipt}
        style={{
          backgroundColor: '#87CEFA',
          color: 'white',
          padding: '5px 12px',
          fontSize: '16px',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease',
       
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#6CA6CD')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#87CEFA')}
      >
        View Receipt
      </button>

    
    </div>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <div ref={printRef} className={`receipt-container ${pavtiDataArray.length > 0 ? 'wide' : ''}`}>
    {Object.values(pavtiDataArray).map((pavtiData, index) => {

      const { pavtiNo, date: pavtiDate, khatePrakar: pavtiKhatePrakar, Amount: pavtiAmount } = pavtiData;

      if (!pavtiDate || !pavtiKhatePrakar || !pavtiAmount) {
        console.error(`Invalid pavti data: ${JSON.stringify(pavtiData)}`);
        return null;
      }

      const numericAmount = parseFloat(pavtiAmount) || 0;
      const formattedAmount = numericAmount.toFixed(2);
      const amountInWords = toWords(numericAmount).toUpperCase();

      return (
        <div className="receipt" key={index}>
          <div className="header-box">
            <div className="header">
              <h3>{sansthaName}</h3>
              <h2>{schoolName}</h2>
              <p>
                <b>
                  {language === "English" ? "Taluka: " : "तालुका: "} {taluka}, 
                  {language === "English" ? " District: " : " जिल्हा: "} {jilha}
                </b>
              </p>
            </div>
          </div>

          <div className="content">
            <div className="row">
              <div>
                <b>{language === "English" ? "Pavti No " : "पावती क्र. "} {pavtiNo}</b>
              </div>
              <div style={{ textAlign: 'right' }}>
                <b>{language === "English" ? "Date " : "दिनांक "} </b>{pavtiDate}
              </div>
            </div>
            <hr />
            <div>
              <p>
                <b>{language === "English" ? "Khate Prakar: " : "खाते प्रकार: "} </b> 
                {pavtiKhatePrakar}
              </p>
            </div>
            <hr />
            <div className="amount">
              <p>
                <b>{language === "English" ? "Amount in Words: " : "रक्कम अक्षरी: "} </b>
                Rs. {amountInWords} ONLY
              </p>
              <div className="amount-box" style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '14px' }}>Rs. {formattedAmount}</div>
              </div>
            </div>
            <div className="sign" style={{ textAlign: 'right' }}>
              <div className="signature-box">
                <p style={{ marginTop: '20px', fontSize: '14px' }}>
                  <b>{language === "English" ? "Sign " : "सही "}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </div>

  <style jsx>{`
    .receipt-container {
        height: 100vh; /* Set a fixed height */
        padding-top: 20px; /* Consistent start position */
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: flex-start; /* Align items at the start */
    }

    .receipt {
        min-height: 250px; /* Ensures each receipt has a consistent height */
        padding: 15px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        border: 1px solid #000;
        padding: 10px;
        margin-bottom: 20px;
    }

    .header-box {
        background-color: #f2f3f4;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
          border: 1px solid #000;
    }

    .header {
        text-align: center;
        margin-bottom: 10px;
    }

    .header h2 {
        font-size: 22px;
        margin: 0;
    }

    .header h3 {
        font-size: 14px;
        margin: 0;
    }

    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .amount-box {
        border: 1px solid #000;
        padding: 5px 20px;
        display: inline-block;
        width: 150px;
        margin-left: 20px;
    }

    .signature-box {
        margin-right: 40px;
    }
`}</style>

<button
    onClick={handlePrintReceipt}
    style={{
      position: 'absolute',
      top: 3,
      right: 70,
      backgroundColor: '#87CEFA',
      color: 'white',
      padding: '5px 5px',
      fontSize: '16px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'background-color 0.3s ease',
    }}
    onMouseEnter={(e) => (e.target.style.backgroundColor = '#6CA6CD')}
    onMouseLeave={(e) => (e.target.style.backgroundColor = '#87CEFA')}
  >
    Print Receipt
  </button>
</Modal>

    </div>
  );
};

export default Receipt;

