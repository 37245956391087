import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import AlertMessage from '../../AlertMessage';


const RollNo = () => {
  const [classValue, setClassValue] = useState('');
  const [divisionValue, setDivisionValue] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [newStudents, setNewStudents] = useState([]);
  const [originalNewStudents, setOriginalNewStudents] = useState([]); // Store original unsorted new students
  const [classes, setClasses] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [isSorted, setIsSorted] = useState(false); // State to track sorting
  const udiseNumber = localStorage.getItem("udiseNumber");
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    fetchStudentData();
  }, []);

  const romanToInt = {
    I: 1,
    II: 2,
    III: 3,
    IV: 4,
    V: 5,
    VI: 6,
    VII: 7,
    VIII: 8,
    IX: 9,
    X: 10,
    XI: 11,
    XII: 12,
  };
  
  const marathiToInt = {
    'पहिली': 1,
    'दुसरी': 2,
    'तिसरी': 3,
    'चौथी': 4,
    'पाचवी': 5,
    'सहावी': 6,
    'सातवी': 7,
    'आठवी': 8,
    'नववी': 9,
    'दहावी': 10,
    'अकरावी':11,
    'बारावी': 12
  };
  const [alertMessage, setAlertMessage] = useState("");
const [showAlert, setShowAlert] = useState(false);
useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  const fetchStudentData = async () => {
    try {
      const response = await fetch(
        `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch student data');
      }
      const data = await response.json();
      const filteredData = Object.keys(data)
        .filter(key => data[key] !== null)
        .map(key => ({ srNo: key, ...data[key] }));

      setStudentData(filteredData);
      
      const classSet = new Set();
      filteredData.forEach((student) => {
        if (student.currentClass) {
          classSet.add(String(student.currentClass));
        }
      });
      setClasses([...classSet]);
    } catch (error) {
      console.error('Error fetching student data:', error);
    }
  };

  const handleClassChange = (e) => {
    const selectedClass = e.target.value;
    setClassValue(selectedClass);

    const divisionSet = new Set();
    studentData.forEach((student) => {
      if (student.currentClass === selectedClass && student.division) {
        divisionSet.add(student.division);
      }
    });

    setDivisions([...divisionSet]);
    setDivisionValue('');
  };

  const handleDivisionChange = (e) => {
    setDivisionValue(e.target.value);
    fetchStudentsForClassAndDivision(classValue, e.target.value);
  };

  const fetchStudentsForClassAndDivision = (classValue, divisionValue) => {
    const filteredStudents = studentData.filter(
      (student) =>
        student.currentClass === classValue && student.division === divisionValue
    );
  
    // Filter students with roll numbers
    const studentsWithRollNumbers = filteredStudents.filter((student) => student.rollNo);
  
    // Sort students with roll numbers alphabetically by name
    const sortedStudentsWithRollNumbers = studentsWithRollNumbers.sort((a, b) => {
      const nameA = `${a.stdName} ${a.stdSurname}`.toUpperCase();
      const nameB = `${b.stdName} ${b.stdSurname}`.toUpperCase();
      return nameA.localeCompare(nameB);
    });
  
    // Filter students without roll numbers
    const studentsWithoutRollNumbers = filteredStudents.filter((student) => !student.rollNo);
  
    // Sort students without roll numbers alphabetically by name
    const sortedNewStudents = studentsWithoutRollNumbers.sort((a, b) => {
      const nameA = `${a.stdName} ${a.stdSurname}`.toUpperCase();
      const nameB = `${b.stdName} ${b.stdSurname}`.toUpperCase();
      return nameA.localeCompare(nameB);
    });
  
    const startingRollNo = sortedStudentsWithRollNumbers.length > 0 
      ? Math.max(...sortedStudentsWithRollNumbers.map(s => s.rollNo)) + 1 
      : 1;
  
    const newStudentsWithRollNumbers = sortedNewStudents.map((student, index) => ({
      ...student,
      rollNo: startingRollNo + index,
    }));
  
    setSelectedStudents(sortedStudentsWithRollNumbers); // Set sorted students with roll numbers
    setNewStudents(newStudentsWithRollNumbers); // Set sorted new students
    setOriginalNewStudents(newStudentsWithRollNumbers); // Store the original list of new students
  };
  

  const saveRollNumbers = async () => {
    try {
      const updates = [...selectedStudents, ...newStudents].map(async (student) => {
        const { srNo, rollNo } = student;
        return await fetch(
          `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${srNo}.json`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ rollNo }),
          }
        );
      });

      await Promise.all(updates);
      setAlertMessage('Roll numbers saved successfully!');
    } catch (error) {
      console.error('Error saving roll numbers:', error);
      setAlertMessage('Failed to save roll numbers.');
    }
  };

  const submit = () => {
    saveRollNumbers();
  };

  const toggleSorting = () => {
    if (isSorted) {
      // Revert to the original order
      setSelectedStudents(originalNewStudents.filter(student => student.rollNo)); // Revert selected students
      setNewStudents(originalNewStudents.filter(student => !student.rollNo)); // Revert new students
    } else {
      // Sort alphabetically by name
      const sortedCombinedStudents = [...selectedStudents, ...newStudents].sort((a, b) => {
        const nameA = `${a.stdName} ${a.stdSurname}`.toUpperCase();
        const nameB = `${b.stdName} ${b.stdSurname}`.toUpperCase();
        return nameA.localeCompare(nameB);
      });
  
      // Reassign roll numbers based on the new order
      const startingRollNo = 1;
      const studentsWithUpdatedRollNumbers = sortedCombinedStudents.map((student, index) => ({
        ...student,
        rollNo: startingRollNo + index,
      }));
  
      // Separate the sorted students back into two groups
      const sortedWithRollNumbers = studentsWithUpdatedRollNumbers.filter(student => student.rollNo);
      const sortedWithoutRollNumbers = studentsWithUpdatedRollNumbers.filter(student => !student.rollNo);
  
      // Store original students only once (before sorting)
      if (!isSorted) {
        setOriginalNewStudents([...selectedStudents, ...newStudents]); // Save original before sorting
      }
  
      // Update both states with sorted data
      setSelectedStudents(sortedWithRollNumbers);
      setNewStudents(sortedWithoutRollNumbers);
    }
  
    setIsSorted(!isSorted); // Toggle sorting state
  };
 
  

  return (
    <div>
      <Sidebar />
      <AlertMessage message={alertMessage} show={showAlert}/>
      <div className='main-content-of-page text-center'>
        <h1 className="title">{language === "English" ? "Students Roll No" : "विद्यार्थ्यांचे हजेरी क्रमांक"}</h1>

        <table className="table" style={{width:'50%'}}>
          <tbody>
            <tr>
              <th>{language === "English" ? "Class" : "वर्ग"}</th>
              <td>
              <select
  id="class"
  value={classValue}
  onChange={handleClassChange}
  className="form-control custom-select"
>
  <option value="">{language === "English" ? "Select Class" : "वर्ग निवडा"}</option>
  {classes
    .sort((a, b) => {
      const classA = a.split(' ')[1];
      const classB = b.split(' ')[1];
      let numA, numB;

      if (language === "English") {
        numA = romanToInt[classA] !== undefined ? romanToInt[classA] : 0; // Default to 0 if not found
        numB = romanToInt[classB] !== undefined ? romanToInt[classB] : 0; // Default to 0 if not found
      } else {
        numA = marathiToInt[classA] !== undefined ? marathiToInt[classA] : 0; // Default to 0 if not found
        numB = marathiToInt[classB] !== undefined ? marathiToInt[classB] : 0; // Default to 0 if not found
      }

      return numA - numB; // Sort based on the numerical value
    })
    .map((cls, index) => (
      <option key={index} value={cls}>
        {cls}
      </option>
    ))}
</select>
              </td>
            </tr>

            <tr>
              <th>{language === "English" ? "Division" : "तुकडी"}</th>
              <td>
                <select
                  id="division"
                  value={divisionValue}
                  onChange={handleDivisionChange}
                  className="form-control custom-select"
                >
                  <option value="">{language === "English" ? "Select Division" : "तुकडी निवडा"}</option>
                  {divisions
  .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
  .map((div, index) => (
    <option key={index} value={div}>
      {div}
    </option>
  ))}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
<div className='mt-5'>
        <button onClick={submit} className="btn btn-primary">
          {language === "English" ? "Save Roll Numbers" : "हजेरी क्रमांक जतन करा"}
        </button>

        {/* Sort toggle button */}
        {(selectedStudents.length > 0 || newStudents.length > 0) && (
          <button onClick={toggleSorting} className="btn btn-secondary ms-4">
  {isSorted
    ? (language === "English" ? "Revert to Original Order" : "मूळ क्रमांकावर  परत या")
    : (language === "English" ? "Sort Alphabetically" : "वर्णक्रमानुसार क्रमवारी लावा")}
</button>

)}
</div>


        {/* Show students who already have roll numbers */}
        {selectedStudents.length > 0 && (
          <div className="mt-4">
            <h5 style={{fontWeight:'600'}}> {language === "English" ? "Students with Roll Numbers" : "हजेरी क्रमांक असलेले विद्यार्थी"}</h5>
            <table className="table custom-table">
              <thead>
                <tr>
                  <th> {language === "English" ? "Roll No" : "हजेरी क्रमांक"}</th>
                  <th> {language === "English" ? "Register No" : "रजिस्टर क्र."}</th>
                  <th> {language === "English" ? "Student Name" : "विद्यार्थ्याचे नाव"}</th>
                </tr>
              </thead>
              <tbody>
                {selectedStudents.map((student) => (
                  <tr key={student.srNo}>
                    <td>{student.rollNo}</td>
                    <td>{student.srNo}</td>
                    <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Show newly added students */}
        {newStudents.length > 0 && (
          <div className="mt-4">
            <h5 style={{fontWeight:'600'}}> {language === "English" ? "Newly Added Students" : "नवीन जोडलेले विद्यार्थी"}</h5>
            <table className="table custom-table">
              <thead>
                <tr>
                <th> {language === "English" ? "Roll No" : "हजेरी क्रमांक"}</th>
                  <th> {language === "English" ? "Register No" : "रजिस्टर क्र."}</th>
                  <th> {language === "English" ? "Student Name" : "विद्यार्थ्याचे नाव"}</th>
                </tr>
              </thead>
              <tbody>
                {newStudents.map((student) => (
                  <tr key={student.srNo}>
                    <td>{student.rollNo}</td>
                    <td>{student.srNo}</td>
                    <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RollNo;
