import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaWhatsapp, FaEnvelope, FaInstagram, FaFacebookF, FaLink } from 'react-icons/fa';

function ReferalCompo() {
    
    const udiseNumber = localStorage.getItem("udiseNumber");
    const [show, setShow] = useState(false);
    // const referralLink = `https://digitalschool.solutions/eguruji-landingpage?referralCode=HUEWA${udiseNumber}`;
    const referralLink = `http://localhost:3000/eguruji-landingpage?referralCode=HUEWA${udiseNumber}`;

    const handleReffer = () => setShow(true);
    const handleClose = () => setShow(false);

    const copyReferralLink = () => {
        navigator.clipboard.writeText(referralLink);
        alert("Referral link copied to clipboard!");
    };

    return (
        <>
            <div style={{
                backgroundColor: '#ffebcd',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                padding: '14px 10px',
                maxWidth: '100%',
                cursor: 'pointer'
            }} onClick={handleReffer}>
                <h6 style={{
                    color: '#ff4500',
                    margin: '0'
                }}>
                    Refer & Earn Points 💰
                </h6>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Share Your Referral</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button variant="light" style={buttonStyle} onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(referralLink)}`, '_blank')}>
                            <FaWhatsapp size={24} style={iconStyle} />
                            <span>WhatsApp</span>
                        </Button>
                        <Button variant="light" style={buttonStyle} onClick={() => window.open(`mailto:?subject=Referral Link&body=${encodeURIComponent(referralLink)}`, '_blank')}>
                            <FaEnvelope size={24} style={iconStyle} />
                            <span>Email</span>
                        </Button>
                        
                        <Button variant="light" style={buttonStyle} onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`, '_blank')}>
                            <FaFacebookF size={24} style={iconStyle} />
                            <span>Facebook</span>
                        </Button>
                        <Button variant="light" style={buttonStyle} onClick={copyReferralLink}>
                            <FaLink size={24} style={iconStyle} />
                            <span>Copy Link</span>
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// Styles for buttons and icons
const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    textAlign: 'left',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};

const iconStyle = {
    marginRight: '10px',
    color: '#333'
};

export default ReferalCompo;
