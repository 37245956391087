import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import "./Bonafide.css";
// import  '../../components/style.css'
import Bonadoc from "./Bonadoc";
import AlertMessage from "../../AlertMessage";

const Bonafide = () => {
  const [formData, setFormData] = useState({
    stdName: '',
    stdMother: '',
    religion: '',
    class: '',
    educationalYear: '',
    dob: '',
    birthPlace: '',
    registerno: '',
    reason: '',
    selectedBookNo: '',
    schoolName: "",
    udiseNumber: "",
    password: "",
    mobileNumber: "",
    language: "",
    telephoneNumber: "",
    address: "",
    taluka: "",
    jilha: "",
    email: "",
    generalRegisterNumber: "",
    boardName: "",
    indexNumber: "",
    sanlagnataKramank: "",
    manyataKramank: "",
    schoolLogo: "",
    caste: "",
  });
  const [udiseNumber, setUdiseNumber]= useState('');
  const [walletPoints, setWalletPoints] = useState(0);
  

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        const data = await response.json();
        console.log("Wallet points data:", data);
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]); 

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "English";
    setLanguage(storedLanguage);
},[]);

  useEffect(()=>{
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if(schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber)
    }
  }, [])

  useEffect(() => {
    console.log("formData:", formData); 
  }, [formData]);

  useEffect(() => {
    setFormData({
    stdName: '',
      stdMother: '',
      religion: '',
      class: '',
      educationalYear: '',
      dob: '',
      birthPlace: '',
      registerno: '',
      reason: language === "English" ? "To avail of travel benefits" : "प्रवासाचे फायदे मिळावेत",
      selectedBookNo: '',
      division: '',
      caste: '',
    });
  }, [language]);

const [submitted, setSubmitted] = useState(false);
  const [duplicateStudents, setDuplicateStudents] = useState([]);
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(formData.remark === (language === "English" ? "Other" : "इतर"));
  const handleSelectStudent = (selectedStudent) => {
    setFormData((prevData) => ({
      ...prevData,
      stdName: selectedStudent.stdName || '',
      stdMother: selectedStudent.stdMother || '',
      religion: selectedStudent.religion || '',
      class: selectedStudent.currentClass || '',
      educationalYear: selectedStudent.educationalYear || '', 
      dob: selectedStudent.dob || '',
      birthPlace: selectedStudent.birthPlace || '',
      dobInWords: selectedStudent.dobInWords || '',
      studentId: selectedStudent.studentId || '',
      stdSurname: selectedStudent.stdSurname || '',
      stdFather: selectedStudent.stdFather || '',
      stdAdharNo: selectedStudent.stdAdharNo || '',
      division: selectedStudent.division || '',
      caste: selectedStudent.caste || '',


    }));
    setShowDuplicatePopup(false);
  };

  const DuplicateStudentPopup = ({ duplicateStudents, onSelectStudent }) => {
    return (
      <div className="popup">
        <h4>Select Student</h4>
        <table className="student-table">
        <thead>
          <tr>
            <th> Full Name</th>
            <th>Class</th>
          </tr>
        </thead>
        <tbody>
          {duplicateStudents.map((student, index) => (
            <tr key={index} onClick={() => onSelectStudent(student)}>
              <td>{student.stdName} {student.stdFather} {student.stdSurname}</td>
              <td>{student.currentClass}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    );
  };

 const handleWalletUpdate = async (updatedPoints) => {
        try {
          const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
            method: 'PUT', 
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedPoints),
          });
          if (!updateResponse.ok) {
            throw new Error('Failed to update wallet points');
          }
          console.log("Updated wallet points in state:", updatedPoints);
          setWalletPoints(updatedPoints);
        
        } catch (error) {
          console.error('Error updating wallet points:', error);
        } 
      };

      const handleChange = async (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    
        if (name === 'registerno') {
          setDuplicateStudents([]);
          setShowDuplicatePopup(false);
          try {
            const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`);
            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
            const data = await response.json();
    
            console.log('Fetched data:', data); 
            if (data && typeof data === 'object') {
              const matchingStudents = [];
    
              Object.values(data).forEach((serialData) => {
                if (serialData && serialData.registerNo === value) {
                  matchingStudents.push(serialData);
                }
              });
    
              if (matchingStudents.length > 1) {
                console.log('Duplicate students found:', matchingStudents); 
                setDuplicateStudents(matchingStudents);
                setShowDuplicatePopup(true);
              } else if (matchingStudents.length === 1) {
                const studentData = matchingStudents[0];
                setFormData((prevData) => ({
                  ...prevData,
                  stdName: studentData.stdName || '',
                  stdMother: studentData.stdMother || '',
                  religion: studentData.religion || '',
                  class: studentData.currentClass || '',
                  educationalYear: studentData.educationalYear || '', 
                  dob: studentData.dob || '',
                  birthPlace: studentData.birthPlace || '',
                  dobInWords: studentData.dobInWords || '',
                  studentId: studentData.studentId || '',
                  stdSurname: studentData.stdSurname || '',
                  stdFather: studentData.stdFather || '',
                  stdAdharNo: studentData.stdAdharNo || '',
                  division: studentData.division || '',
                  caste: studentData.caste || '',
    
                }));
              } else {
                console.log('No matching students found');
                setFormData((prevData) => ({
                  ...prevData,
                  stdName: '',
                  stdMother: '',
                  religion: '',
                  class: '',
                  educationalYear: '',
                  dob: '',
                  birthPlace: '',
                  dobInWords: '',
                  studentId: '',
                  stdSurname: '',
                  stdFather: '',
                  stdAdharNo: '',
                  division: '',
                  caste: '',
                }));
              }
            } else {
              console.error('Data fetched is not valid:', data);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      };
    
const handleSubmit = async (event) => {
  event.preventDefault();
  const updatedPoints = walletPoints - 2; 
  if (updatedPoints < 0) {
    setAlertMessage('Insufficient points! Please recharge your wallet.');
    return;
  }
  await handleWalletUpdate(updatedPoints);
  setSubmitted(true);
 
};

const handleReset = () => {
  setFormData({
  stdName: '',
  stdMother: '',
  religion: '',
  class: '',
  educationalYear: '',
  dob: '',
  birthPlace: '',
  registerno: '',
  reason: '',
  selectedBookNo: '',
  division: '',
  caste: '',
});
setSubmitted(false);
window.location.reload(); // Refresh the page
};

const [isReasonOther, setIsReasonOther] = useState(false);

const handleSelectChange = (event) => {
  const { value } = event.target;
  if (value === (language === "English" ? "Other" : "इतर")) {
    setIsReasonOther(true);
  } else {
    setIsReasonOther(false);
  }
  setFormData({ ...formData, reason: value });
};

const handleInputChange = (event) => {
  const { value } = event.target;
  setFormData({ ...formData, reason: value });
};

  return (
    <div>
      <Sidebar />
      <AlertMessage message={alertMessage} show={showAlert}/>
      <div
        className="main-content-of-page  Bonafide-Css"
        style={{ width: "90%" }}
      >
        <div className="bonafidecontent" style={{ width: "87%" }} >
          <h2 className="title">
            {language === "English"
              ? "Bonafide Certificate"
              : "बोनाफाईड प्रमाणपत्र"}
          </h2>
          <div className="form-output">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label style={{fontSize:'16px'}}>
                  {language === "English" ? "Register No" : "रजिस्टर क्र"}
                </label>
                <input 
                  className="input-field"
                  type="text"
                  name="registerno"
                  value={formData.registerno}
                  onChange={handleChange}
                  required
                />
              </div>

              <br />

              <div className="form-group">
                <label style={{fontSize:'16px'}}>
                  {language === "English" ? "Full Name" : "पूर्ण नाव"}
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="stdName"
                  value={formData.stdName}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />

              <div className="form-group">
                <label style={{fontSize:'16px'}}>
                  {language === "English" ? "Mother's Name" : "आईचे नाव"}
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="stdMother"
                  value={formData.stdMother}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />
              <div className="form-group">
                <label style={{fontSize:'16px'}}>{language === "English" ? "Religion" : "धर्म"}</label>
                <input
                  className="input-field"
                  type="text"
                  name="religion"
                  value={formData.religion}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />
              <div className="form-group">
                <label style={{fontSize:'16px'}}>{language === "English" ? "Class" : "वर्ग"}</label>
                <input
                  className="input-field"
                  type="text"
                  name="class"
                  value={formData.class}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />
              <div className="form-group">
                <label style={{fontSize:'16px'}}>
                  {language === "English" ? "Academic Year" : "शैक्षणिक वर्ष"}
                </label>
                <input
                  className="input-field"
                  type="text"
                  name="educationalYear"
                  value={formData.educationalYear}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />
              <div className="form-group">
                <label style={{fontSize:'16px'}}>{language === "English" ? "DOB" : "जन्मतारीख"}</label>
                <input
                  className="input-field"
                  type="text"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>
              <br />


              {/* <div className="form-group l1">
        <label>{language === "English" ? "Reason" : "कारण"}</label>
        <select className="input-field" name="reason" value={formData.reason} onChange={(e) => handleSelectChange(e, "reason")} required></select> */}

              <div className="form-group l1">
  <label>{language === "English" ? "Reason" : "कारण"}</label>
  <select className="input-field" name="reason" value={formData.reason} onChange={(e) => handleSelectChange(e, "reason")}  required>
    
    <option value={language === "English" ? "To avail of travel benefits" : "प्रवासाचे फायदे मिळावेत"}>
      {language === "English" ? "To avail of travel benefits" : "प्रवासाचे फायदे मिळावेत"}
    </option>
    <option value={language === "English" ? "For educational loans from a school or bank" : "शाळा किंवा बँकेकडून शैक्षणिक कर्जासाठी"}>
      {language === "English" ? "For educational loans from a school or bank" : "शाळा किंवा बँकेकडून शैक्षणिक कर्जासाठी"}
    </option>
    <option value={language === "English" ? "For permission to visit an industry for project work" : "प्रकल्पाच्या कामासाठी उद्योगाला भेट देण्याच्या परवानगीसाठी"}>
      {language === "English" ? "For permission to visit an industry for project work" : "प्रकल्पाच्या कामासाठी उद्योगाला भेट देण्याच्या परवानगीसाठी"}
    </option>
    <option value={language === "English" ? "To open a bank account" : "बँक खाते उघडण्यासाठी"}>
      {language === "English" ? "To open a bank account" : "बँक खाते उघडण्यासाठी"}
    </option>
    <option value={language === "English" ? "Other" : "इतर"}>{language === "English" ? "Other" : "इतर"}</option>
  </select>
  {isReasonOther && (
    <input
      className="input-field"
      name="otherReason"
      value={formData.reason}
      onChange={handleInputChange}
      placeholder={language === "English" ? "Enter other reason" : "इतर कारण निवडा"}
    />
  )}
</div>





              <div className="wallet-points">
                {language === "English" ? " Wallet Points For Each Bonafide = 2" : "प्रत्येक  बोनाफाईड साठी वॉलेट पॉइंट्स = 2"}
               
              </div>
              <div></div>
            </form>
          </div>
          
          {showDuplicatePopup && (
            <div className="popup-container">             
                <DuplicateStudentPopup
                  duplicateStudents={duplicateStudents}
                  onSelectStudent={handleSelectStudent}
                />
            </div>
          )}
        </div>
        <div >
          <Bonadoc
            formData={formData}
          />
        </div>
      </div>
    </div>
  );
};

export default Bonafide;