import {React, useState} from 'react'

function Formsdemo() {
    

  const [name, setName]=useState('')
  const handlechnage = (e)=>{
    setName(e.target.value)
  }
  const submit= (e)=>{
    e.preventDefault()
    console.log("name is ",name);
  }
// ************************************
  const [ischecked, steischeked] = useState(false);
  
  const handlecheckbox = ()=>{
    steischeked(!ischecked)
  }

// ************************************
const [items, seitems]= useState({
    item1 : false,
    item2 : false,
    item3 : false,
})


  return (
    <div className='p-5'>
<form onSubmit={submit} className='mt-5 '>
  <select name="" id="" value={name} onChange={handlechnage}>
    <option value="">select option</option>
    <option value="megha">megha</option>
    <option value="anisha">anisha</option>
    <option value="anita">anita</option>
  </select>
  <button type='submit'>submit</button>
</form>
 {/* ************************************ */}

<br/>
<label>
  <input type="checkbox" checked={ischecked} onChange={handlecheckbox}/> check me
</label>
<p>{ischecked ? 'checked': "not checked"}</p>

{/* ************************************ */}
<br/>


<h6>multiple checkboxes</h6>
<div>
    one <input type="checkbox" name='one' checked={items.item1} />
    two <input type="checkbox" name='two' />
    three <input type="checkbox" name='three' />
</div>

    </div>
  )
}

export default Formsdemo