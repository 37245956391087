import React from "react";

const AlertMessage = ({ message, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '300px',  
      height: '200px', 
      backgroundColor: '#d1ebff', 
      border: '1px solid #d1ebff',
      padding: '30px', 
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', // Add a subtle shadow for depth
      zIndex: '100000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
  }}>
   <div style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          fontSize: '12px',  // Smaller font size for the software name
          color: '#666'  // Subtle grey color
      }}>
          E-Guruji Software
      </div>
    <div className="alert-message-content" style={{
        fontSize: '18px',
      color:'black',
        textAlign: 'center' // Center the text within the square
    }}>
      {message} !
    </div>
  </div>
  
  );
};

export default AlertMessage;