
import React from 'react';
import logo from '../../assets/images/e-guruji-logo.png';
import Sidebar from '../../components/Sidebar';
import silverbg from '../../assets/images/silverbg.jpg';
import goldenbg from '../../assets/images/goldenbg.jpg';
import diamondbg from '../../assets/images/diamondbg.jpg';

const PaymentPage = () => {
  const udiseNumber = localStorage.getItem("udiseNumber");

  const handlePayment = (amount, points) => {
    // Create order on the server first
    fetch('http://localhost/backend/create_order.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ amount: amount }),
    })
    .then(response => response.json())
    .then(order => {
      // console.log("order",order);
      // console.log("order.orderId-",order.orderId);
      
      // Use the order ID generated by the server in Razorpay options
      const options = {
        key: 'rzp_live_GyXbMu1y7DNbpK', // Razorpay key
        amount: amount,            // Amount from the server (in paise)
        currency: 'INR',                 // Currency from the server
        name: 'E-Guruji',                // Merchant name
        description: 'Payment Transaction', // Payment description
        image: logo,                     // Logo image
        order_id: order.orderId,              // The order ID received from the server
        handler: function (response) {
          if (response.razorpay_payment_id) {
            window.alert('Congratulations! Your payment was successful.');
            updateWalletPoints(points); // Update wallet points after success
          }
        },
        notes: {
          address: 'Infoyashonand Technology Sangli',
        },
        theme: {
          color: '#3399cc',
        },
        modal: {
          ondismiss: function () {
            window.alert('Payment failed or was cancelled.');
          },
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    })
    .catch(error => {
      console.error('Error creating Razorpay order:', error);
      window.alert('Something went wrong while initiating the payment.');
    });
  };
  
  
  const updateWalletPoints = async (points) => {
    const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const currentWalletPoints = data?.walletPoints || 0;
      const newWalletPoints = currentWalletPoints + points;

      const updateResponse = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletPoints: newWalletPoints }),
      });

      if (updateResponse.ok) {
        window.alert(`Wallet Points Updated! New Balance: ${newWalletPoints}`);
      } else {
        window.alert('Failed to update wallet points');
      }
    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="main-content-of-page">
        <div style={styles.container} className='container-paymentInt'>
          <h4 style={styles.heading}>Select Packages for Adding Wallet Points</h4>
          <div style={styles.cardContainer} className='cardContainer-paymentInt'>
            <div style={{ 
              ...styles.card, 
              backgroundImage: `url(${silverbg})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center' 
            }}>
              <h2 style={styles.packageTitle}>Silver Package</h2>
              <p style={styles.packageDescription}>Increase wallet points by 500.</p>
              <button
                onClick={() => handlePayment(1.05, 500)}
                style={styles.payButton}
              >
                Pay ₹500
              </button>
            </div>
            <div style={{ 
              ...styles.card, 
              backgroundImage: `url(${goldenbg})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center' 
            }}>
              <h2 style={styles.packageTitle}>Gold Package</h2>
              <p style={styles.packageDescription}>Increase wallet points by 1000.</p>
              <button
                onClick={() => handlePayment(1, 1000)}
                style={styles.payButton}
              >
                Pay ₹1000
              </button>
            </div>
            <div style={{ 
              ...styles.card, 
              backgroundImage: `url(${diamondbg})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center' 
            }}>
              <h2 style={styles.packageTitle}>Diamond Package</h2>
              <p style={styles.packageDescription}>Increase wallet points by 3000.</p>
              <button
                onClick={() => handlePayment(1.3, 3000)}
                style={styles.payButton}
              >
                Pay ₹2000
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '50vh',
    backgroundColor: '#f8f9fa',
    padding: '30px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    margin: '80px auto',
    borderRadius: '8px',
    width: '70%',
    backgroundImage: 'linear-gradient(315deg, #b3ffff 0%, #fffff3 94%)'
  },

  heading: {
    textAlign: 'center',
    marginTop: '30px',
    color: '#03365e',
    fontSize: '1.8rem',
    textShadow: '2px 2px 4px rgba(7, 219, 226, 0.2)'
  },
  cardContainer: {
    display: 'flex',
    gap: '60px',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: '100px'
  },
  card: {
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    maxWidth: '300px',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#ffffff',
    backgroundBlendMode: 'overlay',
    transition: 'transform 0.3s, box-shadow 0.3s',
    boxShadow: '5px 10px 18px #888888'
  },
  packageTitle: {
    fontSize: '1.5rem',
    marginBottom: '10px',
    color: '#000000',
  },
  packageDescription: {
    fontSize: '1rem',
    marginBottom: '20px',
    color: '#000000',
  },
  payButton: {
    backgroundColor: '#3399cc',
    color: '#ffffff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
};

export default PaymentPage;
