import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const KhatavaniReport = ({ combinedData, schoolData, khateDetails,onClose}) => {
  // const location = useLocation();
  // const { combinedData, schoolData, khateDetails,onClose} = location.state || {};

console.log("combinedData",combinedData)
console.log("khateDetails",khateDetails)
  const taluka = schoolData ? schoolData.taluka : "";
  const jilha = schoolData ? schoolData.jilha : "";

  const groupByKhate = () => {
    const groupedData = {};

    Object.keys(combinedData).forEach(pavtiNo => {
      const jamaEntries = combinedData[pavtiNo].jama || [];
      const naveEntries = combinedData[pavtiNo].nave || [];
console.log("jamaEntries",jamaEntries);
console.log("naveEntries",naveEntries);

      // Group Jama entries by khate (Credit entries)
      jamaEntries.forEach(jamaEntry => {
        const khate = jamaEntry.credit1 || 'Unknown'; // Only group by credit1 for jama
console.log("khate in jamaEntries ",khate);

        if (!groupedData[khate]) {
          groupedData[khate] = [];
        }

        groupedData[khate].push({
          khatePrakar: String(jamaEntry.khatePrakar),
          jama: String(jamaEntry.amount),
          nave: '', 
          date: String(jamaEntry.date),
        });
      });

      // Group Nave entries by debit1 (Debit entries)
      naveEntries.forEach(naveEntry => {
        const khate = naveEntry.debit1 || 'Unknown'; // Only group by debit1 for nave
        console.log("khate in naveEntries ",khate);

        if (!groupedData[khate]) {
          groupedData[khate] = [];
        }

        groupedData[khate].push({
          khatePrakar: String(naveEntry.khatePrakar),
          jama: '', // Empty for nave
          nave: String(naveEntry.amount),
          date: String(naveEntry.date),
        });
      });
    });

    return groupedData;
  };

  const groupedData = groupByKhate();
  const printRef = useRef();

  
  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=1000');
    printWindow.document.write('<html><head><title>Khatavani Report</title>');
    
    // Add print styles
    printWindow.document.write(
      `<style>
        body {
          font-family: Arial, sans-serif; 
          margin: 0; /* Ensure no margin on print */
          padding: 20px; /* Optional padding to content */
        }
        table {
          width: 100%; 
          max-width: 700px; 
          border-collapse: collapse; 
          margin: 10px auto;
        }
        th, td {
          border: 1px solid #000; 
          padding: 8px;
          text-align: center;
        }
        th {
          background-color: #007bff;
          color: white;
        }
        tr:nth-child(even) {
          background-color: #f2f2f2; 
        }
        h1, h3 {
          text-align: center;
        }
        .school-info {
          background-color: #f0f0f0;
          padding: 20px;
          margin-bottom: 20px;
          text-align: center;
          border: 1px solid #000;
        }
        .header-title {
          background-color: #000;
          color: #fff;
          padding: 5px;
          display: block; 
          width: fit-content; 
          margin: 10px auto;
          border-radius: 10px;
        }
        @media print {
          body {
            margin: 0; 
            padding: 0; /* Remove all margin/padding on print to avoid extra page breaks */
          }
          table {
            width: 680px !important;
            margin: auto;
          }
          .page-break {
            page-break-before: always; 
          }
        }
      </style>`
    );
  
    printWindow.document.write('</head><body>');
    printWindow.document.write(printRef.current.innerHTML); // Get the content to print
    printWindow.document.write('</body></html>');
  
    printWindow.document.close();
    printWindow.print(); // Trigger print dialog
  };
  
  const handleClose = () => {
    if (printWindow) {
      printWindow.close();
      setPrintWindow(null);
    }
  };
  const [printWindow, setPrintWindow] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem("language") || "English");

  return (
   
     <div style={report1Overlay}>
    <div style={report1Container}>
    
      <button style={closeButtonStyle1} onClick={onClose}>Close</button>

      <button
        onClick={handlePrint}
        style={{
          margin:  '10px 0',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
       
        }}
      >
        Print Report
      </button>
     
 
          <div ref={printRef} style={{ overflowY: 'auto', maxHeight: '70vh', width: '100%' }}>
          <div className="khatavani-report">
          {/* School Info Section */}
          
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "20px",
              marginBottom: "20px",
              textAlign: "center",
              border: "1px solid #000",
            }}
          >
            <h1 style={{ margin: "0", fontSize: "24px", fontWeight: "bold" }}>
              {schoolData.schoolName}
            </h1>
            <p style={{ margin: "5px 0", fontSize: "16px" }}>
              {language === "English" ? "Taluka: " : "तालुका: "} {taluka},
              {language === "English" ? " District: " : " जिल्हा: "} {jilha}
            </p>
            <p style={{ margin: "5px 0", fontSize: "16px" }}>
              {language === "English" ? "Email: " : "ई-मेल: "} {schoolData.email}, {language === "English" ? "Mobile Number : " : "मोबाईल नंबर: "} {schoolData.mobileNumber}
            </p>
            <hr />
            <h6 className="report-title">{language === "English" ? "Khatavani Report " : "खतावणी अहवाल "}</h6>
          </div>
         

          {Object.keys(groupedData).map((khate, index) => {
    const khateAmount = parseFloat(khateDetails.find(kh => kh.khate === khate)?.amount || 0); // Opening Balance
    const khateEntries = groupedData[khate];

    let jamaTotal = 0;
    let naveTotal = 0;
    let bakiTotal = khateAmount > 0 ? khateAmount : 0; // Always initialize bakiTotal as a number

    return (
      <div key={index} className="khate-section page-break">
        <h6 className="khate-title">Khate: {khate}</h6>
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
        <table className="khate-table mb-3" style={{ width: '700px', border: "1px solid black" }}>
          <thead>
            <tr>
              <th style={{ backgroundColor: '#007bff', textAlign: 'center', color: 'white', border: '1px solid black' }}>
                {language === "English" ? "Date " : "दिनांक "}
              </th>
              <th style={{ backgroundColor: '#007bff', textAlign: 'center', color: 'white', border: '1px solid black' }}>
                {language === "English" ? "Account details" : "खात्याचे तपशील "}
              </th>
              <th style={{ backgroundColor: '#007bff', textAlign: 'center', color: 'white', border: '1px solid black' }}>
                {language === "English" ? "Credit" : "जमा "}
              </th>
              <th style={{ backgroundColor: '#007bff', textAlign: 'center', color: 'white', border: '1px solid black' }}>
                {language === "English" ? "Debit" : "नावे "}
              </th>
              <th style={{ backgroundColor: '#007bff', textAlign: 'center', color: 'white', border: '1px solid black' }}>
                {language === "English" ? "The Rest" : "बाकी"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>Open Balance</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>{bakiTotal > 0 ? bakiTotal.toFixed(2) : ''}</td>
            </tr>
            {khateEntries
  .filter(entry => {
    const entryDate = new Date(entry.date);
    const month = entryDate.getMonth(); // 0 = January, 1 = February, ..., 3 = April, ..., 5 = June
    return month >= 3 && month <= 5; // Filter for April (3), May (4), June (5)
  })
  .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sort by date in ascending order
  .map((entry, i) => {
              const jamaAmount = parseFloat(entry.jama || 0);
              const naveAmount = parseFloat(entry.nave || 0);
              jamaTotal += jamaAmount;
              naveTotal += naveAmount;
              bakiTotal += naveAmount - jamaAmount; // Calculate the new baki

              return (
                <tr key={i}>
             
                  <td style={{ textAlign: 'center', border: '1px solid black' }}>{entry.date}</td>
                  <td style={{ textAlign: 'center', border: '1px solid black' }}>{entry.khatePrakar}</td>
                  <td style={{ textAlign: 'center', border: '1px solid black' }}>{jamaAmount ? jamaAmount.toFixed(2) : ''}</td>
                  <td style={{ textAlign: 'center', border: '1px solid black' }}>{naveAmount ? naveAmount.toFixed(2) : ''}</td>
                  <td style={{ textAlign: 'center', border: '1px solid black' }}>{bakiTotal >= 0 ? bakiTotal.toFixed(2) : ''}</td>
                </tr>
              );
            })}

            {/* Closing Balance Row */}
            <tr>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>Closing Balance</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>{bakiTotal >= 0 ? bakiTotal.toFixed(2) : ''}</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
            </tr>
          </tbody>
          <tfoot>
            {/* Total Row */}
            <tr>
              <td style={{ textAlign: 'center', border: '1px solid black' }} colSpan="2">Total</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>{jamaTotal.toFixed(2)}</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}>{naveTotal.toFixed(2)}</td>
              <td style={{ textAlign: 'center', border: '1px solid black' }}></td>
            </tr>
                  </tfoot>
                </table>

              </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};

export default KhatavaniReport;

const report1Overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)', // Blur effect
  zIndex: 1000, // Ensure it appears above other content
};

const report1Container = {
  background: 'white', // White background for the report
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  maxWidth: '900px', // Set a maximum width
  width: '100%', // Full width
  position: 'relative', // Position for the close button
  color:'black',
  overflowY: 'hidden', 
  display: 'flex', // Use flexbox
  flexDirection: 'column', 
  alignItems: 'center', 
  marginTop: '0', 
  maxHeight: '80vh',
};

const closeButtonStyle1 = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color:'Red'
};