import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import './Bonafide.css';
import ID from "../../assets/images/Id.jpeg"; 
import ID2 from "../../assets/images/ID2.jpeg";
import ID3 from "../../assets/images/ID3.jpeg";



// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeRzK6wzqfH5zO7icoCKwkaE7Du998dKw",
  authDomain: "digitalschool-6ba79.firebaseapp.com",
  databaseURL: "https://digitalschool-6ba79-default-rtdb.firebaseio.com",
  projectId: "digitalschool-6ba79",
  storageBucket: "digitalschool-6ba79.appspot.com",
  messagingSenderId: "407448102746",
  appId: "1:407448102746:web:22ed42062e3f32aa3cb563",
  measurementId: "G-ZV6YZNL451",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function IdDoc({ submitted, formData, selectedStudents, logoData }) {
  const [schoolData, setSchoolData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [walletPoints, setWalletPoints] = useState(0);
  const [language, setLanguage] = useState(localStorage.getItem("language") || "English");
  const [logoURL, setLogoURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [udiseNumber, setUdiseNumber] = useState('');

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [design, setDesign] = useState('default');

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);
  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    if (schoolUdiseNumber) {
      fetchSchoolData(schoolUdiseNumber);
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);


  useEffect(() => {
    const fetchWalletPoints = async () => {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch wallet points');
        }
        const data = await response.json();
        if (data && data.walletPoints !== undefined) {
          setWalletPoints(data.walletPoints);
        } else {
          console.error('Invalid wallet points data:', data);
        }
      } catch (error) {
        console.error('Error fetching wallet points:', error);
      }
    };
    fetchWalletPoints();
  }, [udiseNumber]);

  const handleWalletUpdate = async (updatedPoints) => {
    try {
      const updateResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData/walletPoints.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPoints),
      });
      if (!updateResponse.ok) {
        throw new Error('Failed to update wallet points');
      }
      setWalletPoints(updatedPoints);
    } catch (error) {
      console.error('Error updating wallet points:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize); // Add event listener for window resize
    return () => window.removeEventListener("resize", handleResize); // Clean up event listener
  }, []);

  const fetchSchoolData = async (udiseNumber) => {
    try {
      console.log("Fetching school data...");
      const response = await fetch(
        `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("School data:", data);
      setSchoolData(data);
      if (data && data.schoolLogo) {
        const storageRef = firebase.storage().refFromURL(data.schoolLogo);
        const url = await storageRef.getDownloadURL();
        console.log("Logo URL:", url);
        setLogoURL(url);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching school data:", error);
      setLoading(false);
    }
  };


  const renderIDCard = (student) => {
    if (!schoolData || !formData) return null;
  
    console.log("Rendering content...");
    console.log("Logo URL in renderContent:", logoURL);
  

    return (
      <div>

{design === 'default' && (

          <div
        style={{
          backgroundImage: ` url(${ID3})`, // Add the image to the background
    backgroundSize: 'cover', // Cover the entire background with the image
    backgroundPosition: 'center', // Center the image
          left:'2px',
          top:'20px',
          position: 'relative',
          width: '4.4in',  // increased by 10% (4in * 1.1)
          height: '2.74in',  // increased by 10% (2.6in * 1.1)
          margin: '10px',
          border: '1px solid black',
          boxSizing: 'border-box',
          padding: '0.0in',
          fontFamily: "'NotoSansDevanagari', sans-serif",
       // Responsive adjustments
      '@media (max-width: 1200px)': {  // For screens narrower than 1200px
        right: '0in',
        width: '8in',
        height: '11in',
      },
      '@media (max-width: 992px)': {  // For screens narrower than 992px
        right: '0in',
        width: '8.5in',
        height: '11.5in',
      },
      '@media (max-width: 768px)': {  // For screens narrower than 768px
        top: '0.2in',
        right: '0in',
        width: '100%',
        height: 'auto',
        padding: '0.1in',
      },
    }}   
     
      >
        <div>
          <div
            style={{
              position: "absolute",
              top: "0.22in",
              left: "0.25in",
            }}
          >
            {logoURL && (
              <img
                src={logoURL}
                alt="School Logo"
                style={{ width: "35px", height: "35px" }}
              />
            )}
          </div>
          <div style={{width:'80%',  fontSize: '13px', fontWeight: 'bold', color: 'white',  position: 'absolute', top: '0.2in', right: '-0.1in'  }}>
            {schoolData?.schoolName}
          </div>
          {/* <div style={{ textAlign: 'center', fontSize: '10px', color: 'white' , marginBottom: '5px'}}>
            ph: {schoolData?.telephoneNumber}, {schoolData?.address}
          </div> */}
        </div>
       
        <div style={{ fontSize: '11px', color: 'black', marginTop: '125px', lineHeight: '0.1', marginLeft: '135px' }}>
          <p style={{ position: 'absolute', top: '0.75in', right: '0.4in' }}>Reg: {student.registerNo}</p>
          
          <p >Name:<b style={{marginLeft:'2px', padding:'2px'}}> {student.stdName} {student.stdFather} {student.stdSurname}</b></p>
          {/* <p>Father Name:  <b style={{marginLeft:'10px'}}>{student.stdFather} {student.stdSurname}</b></p> */}
         
          <p>D.O.B: <b style={{marginLeft:'40px'}}> {student.dob}</b></p>
          <p>Class: <b style={{marginLeft:'38px'}}>{student.currentClass} </b> </p>
          <p>Mob No:  <b style={{marginLeft:'28px'}}>{student.mobileNo}</b></p>
          <p>Address: <b style={{marginLeft:'26px'}}> {student.taluka} {student.district}</b></p>
        </div>
        <div style={{ position: 'absolute', bottom: '0.02in', right: '0.28in', fontSize: '8px', color: 'black' }}>
          PRINCIPAL
        </div>
        <div style={{ position: 'absolute', bottom: '0.1in', right: '3.2in', fontSize: '14px', color: 'White' }}>
        <b>{student.educationalYear}</b>
        </div>

        {/* <p>Academic Year: <b>{student.educationalYear}</b> </p> */}
        <div style={{ position: 'absolute', top: '0.87in', right: '3.08in' }}>
  {student.stdPhoto && (
    <img
      src={student.stdPhoto}
      alt="Student Photo"
      style={{
        width: '1.1in',
        height: '1.1in',
        borderRadius: '50%', // Makes the image round
        // border: '4px solid lightgray', // Adds a light gray border with a thickness of 2px
        transform: 'rotate(0deg)', // Rotates the image 90 degrees
      }}
    />
  )}
</div>
      </div>

    )}
 {/* Second (New) Design */}
 {design === 'new' && (


        <div
  style={{
    backgroundImage: ` url(${ID})`, // Add the image to the background
    backgroundSize: 'cover', // Cover the entire background with the image
    backgroundPosition: 'center', // Center the image
    transform: 'rotate(0deg)', // Rotate the image 180 degrees
    left: '2px',
    top: '20px',
    position: 'relative',
  width: '4.4in',  // increased by 10% (4in * 1.1)
height: '2.74in',  // increased by 10% (2.6in * 1.1)
    margin: '10px',
    border: '1px solid black',
    boxSizing: 'border-box',
    padding: '0.0in',
    fontFamily: "'NotoSansDevanagari', sans-serif",
    // Responsive adjustments
    '@media (max-width: 1200px)': {  // For screens narrower than 1200px
      right: '0in',
      width: '8in',
      height: '11in',
    },
    '@media (max-width: 992px)': {  // For screens narrower than 992px
      right: '0in',
      width: '8.5in',
      height: '11.5in',
    },
    '@media (max-width: 768px)': {  // For screens narrower than 768px
      top: '0.2in',
      right: '0in',
      width: '100%',
      height: 'auto',
      padding: '0.1in',
    },
  }}
>
        
<div
  style={{
    transform: 'rotate(-90deg)',  
    width: '2.3in',  // Set the width to the height of the box
    height: '100%',  // Set the height to 100% to fill the available space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    textAlign: 'center',
    position: 'absolute',  
    left: '22px',
    top: 0,
  }}
>
    <div style={{ textAlign: 'center', marginBottom: '5px', fontSize: '15px', fontWeight: 'bold', color: 'white' }}>
      {schoolData?.schoolName}
    </div>
    {/* <div style={{ textAlign: 'center', fontSize: '10px', color: 'white' }}>
      ph: {schoolData?.telephoneNumber}, {schoolData?.address}
    </div> */}
    {/* <div
    style={{
      backgroundColor: '#ffdabb',
      borderRadius: '5px',
      padding: '2px',
      width: '1in',
      textAlign: 'center',
      fontSize: '10px',
      marginTop: '5px',
      margin: '0 auto',
    }}
  >
      IDENTITY CARD
    </div> */}
</div>



<div style={{ transform: 'rotate(-90deg)',  fontSize: '11.5px', color: 'black', marginTop: '-9px', lineHeight: '0.15', marginLeft: '60px', width: '100%' }}>


<p style={{}}>
  Name: <b>{student.stdName} {student.stdFather} {student.stdSurname}</b>
</p>
 <p style={{ marginLeft: '0px' }}>
    Roll No: 
  </p>
  {/* <p style={{ marginLeft: '0px' }}>
    Father Name: <b>{student.stdFather} {student.stdSurname}</b>
  </p> */}
  <p style={{ marginLeft: '0px' }}>
    Academic Year: <b>{student.educationalYear}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    D.O.B: <b>{student.dob}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Class: <b>{student.currentClass}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Contact No: <b>{student.mobileNo}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Address: <b>{student.taluka} {student.district}</b>
  </p>
 
</div>
  <div  style = {{transform: 'rotate(-90deg)',  }} >
   
    <p style={{ position: 'absolute', bottom: '-2.2in', right: '1.2in', fontSize: '12px', color: 'black' }}> PRINCIPAL</p>
    {/* <p style={{ position: 'absolute', top: '-0.9in', right: '2.2in' ,  fontSize: '8px', color: 'black' }}>Reg : {student.registerNo}</p> */}
  </div>



  <div style={{ position: 'absolute', top: '0.72in', right: '2.3in' }}>
  {student.stdPhoto && (
    <img
      src={student.stdPhoto}
      alt="Student Photo"
      style={{
        width: '1.2in',
        height: '1.3in',
        borderRadius: '50%', // Makes the image round
        border: '4px solid lightgray', // Adds a light gray border with a thickness of 2px
        transform: 'rotate(-90deg)', // Rotates the image 90 degrees
      }}
    />
  )}
</div>
        </div>
      )}

      {/* Third Alternative Design */}
      {design === 'alternative' && (
        <div
  style={{
    backgroundImage: ` url(${ID2})`, // Add the image to the background
    backgroundSize: 'cover', // Cover the entire background with the image
    backgroundPosition: 'center', // Center the image
    transform: 'rotate(0deg)', // Rotate the image 180 degrees
    left: '2px',
    top: '20px',
    position: 'relative',
  width: '4.4in',  // increased by 10% (4in * 1.1)
height: '2.74in',  // increased by 10% (2.6in * 1.1)
    margin: '10px',
    border: '1px solid black',
    boxSizing: 'border-box',
    padding: '0.0in',
    fontFamily: "'NotoSansDevanagari', sans-serif",
    // Responsive adjustments
    '@media (max-width: 1200px)': {  // For screens narrower than 1200px
      right: '0in',
      width: '8in',
      height: '11in',
    },
    '@media (max-width: 992px)': {  // For screens narrower than 992px
      right: '0in',
      width: '8.5in',
      height: '11.5in',
    },
    '@media (max-width: 768px)': {  // For screens narrower than 768px
      top: '0.2in',
      right: '0in',
      width: '100%',
      height: 'auto',
      padding: '0.1in',
    },
  }}
>
        
<div
  style={{
    transform: 'rotate(-90deg)',  
    width: '2.3in',  // Set the width to the height of the box
    height: '100%',  // Set the height to 100% to fill the available space
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    textAlign: 'center',
    position: 'absolute',  
    left: '26px',
    top: 0,
  }}
>
    <div style={{ textAlign: 'center', marginBottom: '5px', fontSize: '12px', fontWeight: 'bold', color: 'white' }}>
      {schoolData?.schoolName}
    </div>
    {/* <div style={{ textAlign: 'center', fontSize: '10px', color: 'white' }}>
      ph: {schoolData?.telephoneNumber}, {schoolData?.address}
    </div> */}
    {/* <div
    style={{
      backgroundColor: '#ffdabb',
      borderRadius: '5px',
      padding: '2px',
      width: '1in',
      textAlign: 'center',
      fontSize: '10px',
      marginTop: '5px',
      margin: '0 auto',
    }}
  >
      IDENTITY CARD
    </div> */}
</div>



<div style={{ transform: 'rotate(-90deg)',  fontSize: '11.5px', color: 'black', marginTop: '-11px', lineHeight: '0.15', marginLeft: '112px', width: '100%' }}>


<p style={{}}>
  Name: <b>{student.stdName} {student.stdFather} {student.stdSurname}</b>
</p>
 <p style={{ marginLeft: '0px' }}>
    Roll No: 
  </p>
  {/* <p style={{ marginLeft: '0px' }}>
    Father Name: <b>{student.stdFather} {student.stdSurname}</b>
  </p> */}
  <p style={{ marginLeft: '0px' }}>
    Academic Year: <b>{student.educationalYear}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    D.O.B: <b>{student.dob}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Class: <b>{student.currentClass}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Contact No: <b>{student.mobileNo}</b>
  </p>
  <p style={{ marginLeft: '0px' }}>
    Address: <b>{student.taluka} {student.district}</b>
  </p>
 
</div>
  <div  style = {{transform: 'rotate(-90deg)',  }} >
   
    <p style={{ position: 'absolute', bottom: '-2.3in', right: '1.2in', fontSize: '12px', color: 'black' }}> PRINCIPAL</p>
    <p style={{ position: 'absolute', bottom: '-0.62in', right: '1.95in', fontSize: '12px', color: 'black' }}> <b>Identity Card</b></p>

    {/* <p style={{ position: 'absolute', top: '-0.9in', right: '2.2in' ,  fontSize: '8px', color: 'black' }}>Reg : {student.registerNo}</p> */}
  </div>



  <div style={{ position: 'absolute', top: '0.72in', right: '2.3in' }}>
  {student.stdPhoto && (
    <img
      src={student.stdPhoto}
      alt="Student Photo"
      style={{
        width: '1.2in',
        height: '1.3in',
        borderRadius: '50%', // Makes the image round
        border: '4px solid lightgray', // Adds a light gray border with a thickness of 2px
        transform: 'rotate(-90deg)', // Rotates the image 90 degrees
      }}
    />
  )}
</div>
        </div>
      )}
    </div>
  );
};  


  const renderPages = () => {
    if (!formData || !selectedStudents) return null;

    const pages = [];
    for (let i = 0; i < selectedStudents.length; i += 8) {
      const pageStudents = selectedStudents.slice(i, i + 8);
      pages.push(
        <div
          key={i}
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'repeat(4, auto)',
                    gridGap: '10px',
                    width: '9.5in',
                    height: '13.5in',
                    padding: '10px',
                    fontFamily: "'NotoSansDevanagari', sans-serif",
                    border: '1px solid black',
                    boxSizing: 'border-box',
                    margin: '0 0 20px 0', // Add margin here for gap between pages
                    pageBreakAfter: 'always',
             // Responsive adjustments
      '@media (max-width: 1200px)': {  // For screens narrower than 1200px
        right: '0in',
        width: '8in',
        height: '11in',
        pageBreakAfter: 'always',
      },
      '@media (max-width: 992px)': {  // For screens narrower than 992px
        right: '0in',
        width: '8.5in',
        height: '11.5in',
        pageBreakAfter: 'always',
      },
      '@media (max-width: 768px)': {  // For screens narrower than 768px
        top: '0.2in',
        right: '0in',
        width: '100%',
        height: 'auto',
        pageBreakAfter: 'always',
        padding: '0.1in',
      },
    }}
        >
          {pageStudents.map((student) => renderIDCard(student))}
         
        </div>
      );
     
    }

    return pages;
  };


  const printCertificate = async () => {
    const selectedStudentCount = selectedStudents.length;
    const pointsPerStudent = 1; // Points to deduct per student
    const totalDeduction = selectedStudentCount * pointsPerStudent;
    const updatedPoints = walletPoints - totalDeduction;
  
    if (updatedPoints < 0) {
      setAlertMessage('Insufficient points! Please recharge your wallet.');
      return;
    }
  
    await handleWalletUpdate(updatedPoints);
  
    const printContent = document.getElementById("print-content");
    const originalContent = document.body.innerHTML;
  
    // Apply CSS styles directly to the print content for positioning
    printContent.style.padding = "60px 20px"; // Shift content to the right and up
    printContent.style.transform = "translate(0, -50px)"; // Slightly move content upwards

    document.body.innerHTML = printContent.outerHTML; // Replace body with print content
    window.print(); // Print the current document
    document.body.innerHTML = originalContent; // Restore the original content
    window.location.reload()
  }  ;
  

  return (
    <>
    <div
  style={{
    display: "flex",
    justifyContent: "flex-end",
    padding: "1em",
  }}
>
  <div id="print-content">
    {loading ? <p>Loading...</p> : renderPages()}
  </div>
 
  <div style={{ marginTop: "1em" }}>
  <button
    onClick={printCertificate}
    style={{
      position: "absolute",
      top: "14.2em",
      left: "75em",
    }}
  >
    Print
  </button>
  </div>
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  <button style={{width:'auto', height:'60px', marginLeft:'10px'}} onClick={() => setDesign(design === 'default' ? 'new' : design === 'new' ? 'alternative' : 'default')}>
      Change ID Format <i class="fa-solid fa-circle-chevron-right"></i>
    </button></div>
</div>   
    </>
  );
}

export default IdDoc;
