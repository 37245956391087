import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';

function GeneralRegister() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [udiseNumber, setUdiseNumber] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [taluka, setTaluka] = useState("");
  const [jilha, setJilha] = useState("");
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem("udiseNumber");
    setUdiseNumber(schoolUdiseNumber);
  }, []);

  useEffect(() => {
    if (udiseNumber) {
      const fetchData = async () => {
        try {
          const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`;
          const response = await fetch(url);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          
          const data = await response.json();
          console.log("Fetched data:", data);

          // Check if data is an object and not null
          const filteredData = (data && typeof data === 'object') ? Object.values(data).filter(student => student !== null) : [];
          setData(filteredData);
        } catch (error) {
          // Do not set error if data is null or undefined
          if (error.message !== 'Network response was not ok') {
            console.error('Error fetching data:', error);
          }
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [udiseNumber]);

  const [sansthaName, setsansthaName] = useState("");
  const [logoURL, setLogoURL] = useState(null);
  const fetchSchoolName = async () => {
    if (udiseNumber) {
      try {
        const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`);
        const schoolData = await response.json();
        if (schoolData) {
          if (schoolData.schoolName) {
            setSchoolName(schoolData.schoolName);
          }
          if (schoolData.taluka) {
            setTaluka(schoolData.taluka);
          }
          if (schoolData.jilha) {
            setJilha(schoolData.jilha);
          }
          if (schoolData.sansthaName) {
            setsansthaName(schoolData.sansthaName);
          }
          if (schoolData.schoolLogo) {
            setLogoURL(schoolData.schoolLogo);
          }
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      }
    }
  };
  useEffect(() => {
  fetchSchoolName();
}, [udiseNumber]);

const handlePrint = () => {
  const printTitleElement = document.getElementById('print-title');
  if (printTitleElement) {
    let logoHTML = '';
    if (logoURL) {
      logoHTML = `<img src="${logoURL}" alt="School Logo" style="width: 40px; height: 40px; margin-right: 10px;" />`;
    }

    let schoolDataHTML = `${schoolName}<br />Taluka: ${taluka}&nbsp;&nbsp;&nbsp;District: ${jilha}`;

    printTitleElement.innerHTML = `
      <div style="display: flex; align-items: center; padding-top: -20px;">
        <div style="flex: 1; text-align: left; margin-left: 20px;">
          ${logoHTML}
        </div>
        <div style="flex: 2; text-align: center;">
          <div style="font-size: 8px; font-weight: 300; margin-bottom: 10px; margin-top: -5px;">${sansthaName}</div>
          <div style="font-size: 9px; font-weight: 400; margin-top: 0px;">${schoolDataHTML}</div>
        </div>
        <div style="flex: 1;"></div>
      </div>
    `;
    
    // Delay the print call to allow content rendering
    setTimeout(() => {
      window.print();
      
      // Clean up the print title element after printing
      if (printTitleElement) {
        printTitleElement.innerHTML = ''; 
        printTitleElement.style.fontSize = ''; 
        printTitleElement.style.fontWeight = ''; 
        printTitleElement.style.paddingBottom = '14px'; 
      }
    }, 1000); // 1000 ms delay
  }
};

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Sidebar />
      <div className='mb-5 main-content-of-page'>
      <style>
          {`
            @media print {
              .table th,
              .table td {
                background-color: transparent !important;
                -webkit-print-color-adjust: exact;
              }
              .bg-secondary {
                background-color: transparent !important;
              }
              .print-button {
                display: none;
              }
              .custom-heading-text{
                margin: -5px auto;
              }
            }
          `}
        </style>
        <center style={{ borderBottom: '0.5px solid gray', padding: '10px', marginBottom: '20px' }}>
          <div id="print-title" className="text-center" style={{ fontWeight: 'bold' }}></div>
          <div className="custom-heading" style={{ paddingBottom: '10px', marginTop: '-10px' }}>
            <h2 className="custom-heading-text text-center" style={{ marginBottom: '-10px' }}>
              {language === "English" ? "General Register Number 1" : "जनरल रजिस्टर क्रमांक १"}
            </h2>
          </div>
        </center>
        <button onClick={handlePrint} className="btn btn-primary float-end" style={{marginTop:'-60px', marginBottom:'5px'}}>Print</button>
        <table className='table' style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', fontSize: '13px', textAlign: 'center' }}>
          <thead>
            <tr style={{ fontWeight: 'bold', fontSize: '15px' }}>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black', width: '30px' }}>
                {language === "English" ? "Sr. No." : "अ. क्र."}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Register Number" : "रजिस्टर क्रमांक"}<br />
                {language === "English" ? "Book Number" : "बूक क्रमांक"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Student's Name" : "विद्यार्थ्याचे नाव"}<br />
                {language === "English" ? "Student ID" : "स्टुडंट आयडी"}<br />
                {language === "English" ? "Aadhar Number" : "आधार क्रमांक"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Mother's Name" : "आईचे नाव"}<br />
                {language === "English" ? "Nationality" : "राष्ट्रीयत्व"}<br />
                {language === "English" ? "Mother Tongue" : "मातृभाषा"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Religion" : "धर्म"}<br />
                {language === "English" ? "Caste" : "जात"}<br />
                {language === "English" ? "SubCaste" : "पोटजात"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Admission Date" : "प्रविष्ट दिनांक"}<br />
                {language === "English" ? "Gender" : "लिंग"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Birth Place" : "जन्मस्थळ"}<br />
                {language === "English" ? "State - Country" : "राज्य - देश"}<br />
                {language === "English" ? "Previous School" : "यापूर्वी शिकत असलेली शाळा"}
              </th>
              <th className='text-center' style={{ padding: '5px', border: '1px solid black' }}>
                {language === "English" ? "Date Of Birth In numbers and Words" : "जन्म दिनांक अंकी व अक्षरी"}<br />
                {language === "English" ? "Admission Class" : "प्रवेश घेतलेला वर्ग"}<br />
                {language === "English" ? "Current Class" : "सध्या शिकत असलेला वर्ग"}
              </th>
            </tr>

          </thead>
          <tbody>
            {data.map((student, index) => (
              <tr key={index}>
                <td style={{ padding: '5px 10px', border: '1px solid black', width: '30px' }}>{index + 1}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.registerNo || '-'}<br />{student.bookno || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.stdName || '-'} <br /> {student.studentId || '-'} <br /> {student.stdAdharNo || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.stdMother || '-'} <br /> {student.nationality || '-'} <br /> {student.motherTounge || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.religion || '-'} <br /> {student.caste || '-'} <br /> {student.subCaste || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}> {new Date(student.dateOfAdmission).toLocaleDateString('en-GB')}<br />{student.gender || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.birthPlace || '-'} <br /> {student.state || '-'} -  {student.country || '-'} <br /> {student.prevSchool || '-'}</td>
                <td style={{ padding: '5px', border: '1px solid black' }}>{student.dob || '-'} - {student.dobInWords || '-'} <br /> {student.admissionClass || '-'} <br /> {student.currentClass || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GeneralRegister;
