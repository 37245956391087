import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
function DainandinReport() {
  const location = useLocation();
  const { selectedDate, selectedClass } = location.state || {};
  const language = localStorage.getItem("language");
  const udiseNumber = localStorage.getItem("udiseNumber");
  const [schoolData, setSchoolData] = useState(null); 
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [countTotal, setCountTotal] = useState('');
  const [expenseData, setExpenseData] = useState({}); 
  const navigate = useNavigate();
  const [rowInputData, setRowInputData] = useState(
  modalData.map(() => ({
    supplementaryFood: ' ',
    beneficiaryExpenditure: ''
  }))
);
  const marathiMonths = {
    January: 'जानेवारी',
    February: 'फेब्रुवारी',
    March: 'मार्च',
    April: 'एप्रिल',
    May: 'मे',
    June: 'जून',
    July: 'जुलै',
    August: 'ऑगस्ट',
    September: 'सप्टेंबर',
    October: 'ऑक्टोबर',
    November: 'नोव्हेंबर',
    December: 'डिसेंबर'
  };
  const classMapping = {
    '1 To 5': {
      English: "1 To 5",
      Marathi: "१ ते ५"
    },
    '6 To 8': {
      English: "6 To 8",
      Marathi: "६ ते ८"
    }
  };
  


  
const dateObject = new Date(selectedDate);
const year = dateObject.getFullYear(); // Extract year
const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Extract month and format as two digits
console.log("selectedDate and selectedClass",selectedDate, selectedClass);
const englishMonthName = dateObject.toLocaleString('default', { month: 'long' });

const monthName = language === "English" ? englishMonthName:  marathiMonths[englishMonthName] ;

useEffect(() => {
  console.log("modalData",modalData);
    if (modalData && modalData.length > 0) {
        const initialRowData = modalData.map(() => ({
            supplementaryFood: '',
            beneficiaryExpenditure: ''
        }));
        console.log("initialRowData",initialRowData);
        setRowInputData(initialRowData); 
    }
}, [modalData]); 

  const fetchSchoolData = () => {
    const firebaseDatabaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;

    fetch(firebaseDatabaseUrl)
      .then(response => response.json())
      .then(data => {
        setSchoolData(data); 
      })
      .catch(error => {
        console.error('Error fetching school data from Firebase:', error);
      });
  };
  const updateFirebaseData = async (formattedDate, index) => {
    const itemToUpdate = modalData[index];
    const itemToUpdateDate = itemToUpdate.date; // This is the date string
    console.log("itemToUpdate", itemToUpdate); // Log the item being updated

    if (!itemToUpdate) {
        console.error('No item found for the given index:', index);
        return; // Exit if item is not found
    }

    // Extract dayString (the day part of the date)
    const dayString = itemToUpdateDate.split('-')[0]; // Get the day part (21 from '21-10-2024')

    // Create a Date object to calculate dayName
    const [day, month, year] = itemToUpdateDate.split('-'); // Split the date string
    const dateObject = new Date(`${year}-${month}-${day}`); // Create a Date object
    const dayName = dateObject.toLocaleString('default', { weekday: 'long' }); // Get the day name (e.g., 'Monday')

    console.log("dayName", dayName); // Log the calculated dayName
    console.log("dayString", dayString); // Log the calculated dayString

    // Construct the Firebase URL
    const fetchDataUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${year}/${month}/${dayName}/${dayString}.json`;

    // Prepare the data to update
    const dataToUpdate = {
        ...itemToUpdate.foodItems,
        SupplementaryFood: rowInputData[index]?.supplementaryFood || '',
        BeneficiaryWiseExpenditure: rowInputData[index]?.beneficiaryExpenditure || ''
    };

    console.log("fetchDataUrl", fetchDataUrl); // Log the URL being called
    console.log("dataToUpdate", dataToUpdate); // Log the data being sent to Firebase

    try {
        await axios.put(fetchDataUrl, dataToUpdate);
        console.log('Data updated successfully!');
    } catch (error) {
        console.error('Error updating data in Firebase:', error);
    }
};
const handleInputChange = (index, field, value) => {
  console.log("value", value);
  console.log(typeof(value));

  let numericValue = value; // Default to the original value

  // If the field is 'beneficiaryExpenditure', try to parse it as a number
  if (field === 'beneficiaryExpenditure') {
    numericValue = parseInt(value, 10); // Convert to number

    // If the input is not a valid number, use the original string value
    if (isNaN(numericValue)) {
      numericValue = value;
    }

    // Limit the number to 8 digits
    if (numericValue > 99999999) {
      numericValue = 0;
    }
  }

  const updatedRowInputData = [...rowInputData];
  updatedRowInputData[index][field] = numericValue; // Update with the numeric value

  console.log("updatedRowInputData", updatedRowInputData);
  setRowInputData(updatedRowInputData);
};

const handleKeyPress = (event, formattedDate, index) => {
    if (event.key === 'Enter') {
        updateFirebaseData(formattedDate, index); // Pass only formattedDate and index
    }
};
  const fetchModalData = async () => {
    const dataPromises = [];

    const days = {
        1: 'Monday',    // Monday
        2: 'Tuesday',   // Tuesday
        3: 'Wednesday', // Wednesday
        4: 'Thursday',  // Thursday
        5: 'Friday',    // Friday
        6: 'Saturday'   // Saturday
    };

    // Array to store the final combined data
    let finalData = [];

    // Iterate through each day of the week
    for (let dayOfWeek = 1; dayOfWeek <= 6; dayOfWeek++) { 
        const dayName = days[dayOfWeek];

        // Find the first occurrence of the day in the selected month
        let firstOccurrenceDate = new Date(year, dateObject.getMonth(), 1);

        // Move to the first occurrence of the current dayOfWeek
        while (firstOccurrenceDate.getDay() !== dayOfWeek) {
            firstOccurrenceDate.setDate(firstOccurrenceDate.getDate() + 1);
        }

        // Now iterate through all occurrences of that weekday in the selected month
        let currentDate = firstOccurrenceDate;
        while (currentDate.getMonth() === dateObject.getMonth() && currentDate.getFullYear() === year) {
            const dayString = String(currentDate.getDate()).padStart(2, '0'); 
            const formattedDate = `${dayString}-${month}-${year}`; 

            const fetchDataUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/dainandinnondani/${selectedClass}/${year}/${month}/${dayName}/${dayString}.json`;

            console.log("fetchDataUrl", fetchDataUrl); 
            dataPromises.push(
                fetch(fetchDataUrl).then(response => response.json()).then(data => {
                    if (data) {
                        finalData.push({ date: formattedDate, foodItems: data });
                    }
                })
            );

            // Move to the next occurrence of the same weekday (7 days later)
            currentDate.setDate(currentDate.getDate() + 7);
        }
    }

    try {
        // Wait for all data to be fetched
        await Promise.all(dataPromises);
        console.log("finalData", finalData);
        setModalData(finalData);

        const initialRowData = finalData.map(() => ({
            supplementaryFood: '',
            beneficiaryExpenditure: ''
        }));
        setRowInputData(initialRowData);
    } catch (error) {
        console.error('Error fetching modal data from Firebase:', error);
    }
};

const fetchexpense = async () => {
  const fetchpramanUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman/${selectedClass}.json`;
  
  try {
    const response = await axios.get(fetchpramanUrl);
    setExpenseData(response.data || {}); // Store the fetched expense data
  } catch (error) {
    console.error('Error fetching expense data from praman node:', error);
  }
};

const handleFetchStudentCount = async () => {
  try {
    // Construct the fetch URL
    const fetchUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`;

    // Fetch student data from Firebase
    const response = await axios.get(fetchUrl);
    const studentData = response.data || {};

    console.log("studentData", studentData);

    if (!studentData) {
      console.log("No student data found");
      return; // or throw an error if needed
    }

    // Declare class ranges
    let classRange1 = [];
    let classRange2 = [];

    // Set class ranges based on the language
    if (language === 'English') {
      classRange1 = ['Class I', 'Class II', 'Class III', 'Class IV', 'Class V'];
      classRange2 = ['Class VI', 'Class VII', 'Class VIII'];
    } else {
      classRange1 = ['इयत्ता पहिली', 'इयत्ता दुसरी', 'इयत्ता तिसरी', 'इयत्ता चौथी', 'इयत्ता पाचवी'];
      classRange2 = ['इयत्ता सहावी', 'इयत्ता सातवी', 'इयत्ता आठवी'];
    }

    let countClass1to5 = 0;
    let countClass6to8 = 0;

    // Iterate over student data
    Object.values(studentData).forEach((student) => {
      if (student !== null) { // Skip null values
        console.log("student", student);
        console.log("student.currentClass", student.currentClass);

        const trimmedCurrentClass = student.currentClass.trim(); // Trim extra spaces

        if (classRange1.includes(trimmedCurrentClass)) {
          countClass1to5++;
        } else if (classRange2.includes(trimmedCurrentClass)) {
          countClass6to8++;
        }
      }
    });

    console.log(`Count of students in Class I to Class V: ${countClass1to5}`);
    console.log(`Count of students in Class VI to Class VIII: ${countClass6to8}`);
    if (selectedClass === '1 To 5') {
      setCountTotal(countClass1to5)
    }
    else{
      setCountTotal(countClass6to8)
    }

    // Return the counts if needed
    return { countClass1to5, countClass6to8 };
  } catch (error) {
    console.error('Failed to fetch student data:', error);
  }
};

  useEffect(() => {
    fetchexpense();
    fetchSchoolData(); 
    fetchModalData(); 
    handleFetchStudentCount();
  }, [selectedDate, selectedClass]); 

  const closeModal = () => {
    navigate('/dainandin') 
  };

  const handlePrint = () => {
    const printContent = document.querySelector('.modal-content');
  
    if (printContent) {
      const cloneContent = printContent.cloneNode(true);
  
      const schoolName = cloneContent.querySelector('p'); 
      const otherDetails = cloneContent.querySelectorAll('p'); 
      
      otherDetails.forEach((p, index) => {
        if (index !== 0) { 
          p.style.fontWeight = 'normal'; 
        }
      });
          
      if (schoolName) {
        schoolName.innerHTML = `<h5 style="font-size: 16px; margin-top: 10px;">${schoolData.schoolName}</h5>`; // Increase the font size and set top margin
      }
  
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Modal Content</title>
            <style>
              @media print {
                @page {
                  size: landscape; /* Set the page size to landscape */
                  margin: 0;      /* Remove default margins */
                }
  
                body {
                  margin: 5px;  
                  margin-top: 15px;    /* Remove body margin */
                  padding: 0;     /* Remove body padding */
                }
  
                /* Ensure that the table takes full width */
                table {
                  width: 100%;    /* Make the table full width */
                  border-collapse: collapse; /* Collapse borders */
                  padding: auto 80px;
                }
  
                th, td {
                  border: 1px solid black; /* Add border to table cells */
                  padding: 8px;            /* Add padding for readability */
                  text-align: left;        /* Align text to the left */
                }
  
                th {
                  font-weight: normal !important;  /* Remove bold text from table headers */
                  font-size: 12px !important;
                }
  
.Dailyrgposhan{
margin:0 auto;
}
.last2td input {
  padding: 0;
  margin: 0;
  width: 50px;
 
}
  .last2td textarea {

   padding: -5px;
  margin:  -5px;
          border: none;
            outline: none;
            text-align: center;
            resize: none; // Prevent resizing if you want to control size
            width: '100%';  // Make textarea take full width of cell
            max-width: 'fit-content'; // Adjust max width to fit content
            min-width: '60px'; // Optional: set a minimum width
            min-height: '50px'; // Optional: set a minimum height for better usability
            overflow: 'hidden';
            font-size:9px;
 
}

                
            /* Add styles for the certificate text */

                .Certificate-dailyposhan {
                  text-align: center;
                   margin-top: 1rem;
                }


                .certificate-part-poshan{
                 display: flex;
                 justify-content: center ;
                 margin-top:-20px;
                }

                   .certificate-text {
             text-align: left;
             width: 74%;
             font-size:13px;
          }

          .signature-section {
            display: flex;
             justify-content: space-between;
              margin-top:10px;
              font-size:'13px;
          }

.principal-signature{
text-align: 'right'
}
          .schmgnt{
          margin-top: 0px;
          }

          .president-signature {
           text-align: center;
          }
  
                /* Optional: scale down table for smaller print size */
                table {
                  transform: scale(0.98);
                  transform-origin: center;
                }
    
                .no-print {
                  display: none;
                }
              }
  
            </style>
          </head>
          <body>
            ${cloneContent.outerHTML} <!-- Include the modified outer HTML of the modal content -->
          </body>
        </html>
      `);
      printWindow.document.close(); 
      printWindow.focus(); // Focus the new window
      printWindow.print(); // Trigger print
      printWindow.close(); // Close the print window after printing
    } else {
      console.error('Print content not found');
    }
  };
  return (
    <div> 
<div className='main-content-of-page'>
      {showModal && (
          <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
          }}>
            <div style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '100%',
              boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
              maxHeight: '90%',
              overflowY: 'auto',
              position: 'relative',
            }}>



              <div className="modal-content">
               
                <table style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '30px',
                  fontFamily: 'Arial, sans-serif',
                  border: '1px solid black',
                  borderRadius: '10px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                }}>
                
                  <thead>
                    {schoolData && (
                      <tr>
  <th colSpan="23" style={{
    color: 'black',
    padding: '5px',
    borderRadius: '10px 10px 0 0',
    textAlign: 'center',
    lineHeight: '0.4',
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderTop: '1px solid black',
    fontSize: '13px',
    position: 'relative' 
  }}>
    <p><strong><h6> {schoolData.schoolName}</h6></strong></p>
    <p>{schoolData.sansthaName}</p>
    <p>{language === "English" ? "Taluka:" : "तालुका:"} {schoolData.taluka} , {language === "English" ? "Jilha:" : "जिल्हा:"} {schoolData.jilha} </p>
    <p>{language === "English" ? "National Mid Day Meal Scheme" : "राष्ट्रीय मध्यान्ह भोजन योजना"}  </p>


    <p className='Dailyrgposhan' style={{ display: 'inline-block', backgroundColor: '#252729', color: 'white', padding: '10px', borderRadius: '5px', fontSize: '14px' }}>
    {language === "English" ? "Daily Register to be maintained at school level Part-II (Accounting of Cereals)" : "शालेय स्तरावर ठेवण्याची दैनंदिन नोंदवही भाग-2 (धान्यादी मालाचा हिशोब)"}
                          </p>
    {/* Centered selected class */}
    <p style={{fontSize:'16px'}}>{language === "English" ? `Class: ${classMapping[selectedClass].English}` : `वर्ग: ${classMapping[selectedClass].Marathi}`}<p style={{ position: 'absolute', right: '10px', bottom:'2px'}}>{language === "English" ? "Month: " : "महिना: "}{monthName}</p></p>

    
  </th>
</tr>


                    )}
                    <tr style={{ color: 'black' }}>
                      <th style={{ borderLeft: '1px solid black',width:'100px', ...thstyle, padding:'auto 0px' }}>{language === "English" ? "Date" : "तारीख"}</th>
                      <th style={thstyle}>{language === "English" ? "Total Student" : "एकूण विद्यार्थी"}</th>
                      <th style={thstyle}>{language === "English" ? "Daily Presence" : "दैनिक उपस्थिती"}</th>
                      <th style={thstyle}>{language === "English" ? "Plate Count" : "ताटांची संख्या"}</th>
                      <th style={thstyle}>{language === "English" ? "Rice" : "तांदूळ"}</th>
                      <th style={thstyle}>{language === "English" ? "Mugdal" : "मुगडाळ"}</th>
                      <th style={thstyle}>{language === "English" ? "Turdal" : "तूरडाळ"}</th>
                      <th style={thstyle}>{language === "English" ? "Masurdal" : "मसूरडाळ"}</th>
                      <th style={thstyle}>{language === "English" ? "Matki" : "मटकी"}</th>
                      <th style={thstyle}>{language === "English" ? "Moong" : "मूग"}</th>
                      <th style={thstyle}>{language === "English" ? "Cowpea" : "चवळी"}</th>
                      <th style={thstyle}>{language === "English" ? "Gram" : "हरभरा"}</th>
                      <th style={thstyle}>{language === "English" ? "Pease" : "वाटाणा"}</th>
                      <th style={thstyle}>{language === "English" ? "Cumin" : "जिरे"}</th>
                      <th style={thstyle}>{language === "English" ? "Mustard" : "मोहरी"}</th>
                      <th style={thstyle}>{language === "English" ? "Turmeric" : "हळद"}</th>
                      <th style={thstyle}>{language === "English" ? "Chili" : "मिरची"}</th>
                      <th style={thstyle}>{language === "English" ? "Oil" : "तेल"}</th>
                      <th style={thstyle}>{language === "English" ? "Salt" : "मीठ"}</th>
                      <th style={thstyle}>{language === "English" ? "Garam Masala" : "गरम मसाला"}</th>
                      <th style={thstyle}>{language === "English" ? "Vegetables" : "भाजी पाला"}</th>
                      <th style={thstyle}>{language === "English" ? "Supplementary food" : "पूरक आहार"}</th>
                      <th style={thstyle}>{language === "English" ? "Beneficiary wise expenditure" : "लाभार्थी नुसार होणारा खर्च"}</th>
                    </tr>
                  </thead>

                  <tbody>
  {modalData && modalData.length > 0 && modalData.map((item, index) => {
    const expenseKey = language === "English" ? "Expenses" : "खर्च";
                      const expenseValue = expenseData[expenseKey] || 0; // Get the expense value

  return (
    <tr key={index}>
      {/* Date column */}
      <td style={{...thstylebody, borderLeft: '1px solid black', padding:'15px 0'}}>{item.date}</td>
      <td style={thstylebody}>{countTotal || ''}</td>
      <td style={thstylebody}>{item.foodItems.labharthi || ''}</td>
      <td style={thstylebody}>{item.foodItems.labharthi || ''}</td>

      {/* Food Items */}
      <td style={thstylebody}>{item.foodItems.Rice || item.foodItems.तांदूळ || ''}</td>
      <td style={thstylebody}>{item.foodItems.Mugdal || item.foodItems.मुगडाळ || ''}</td>
      <td style={thstylebody}>{item.foodItems.Turdal || item.foodItems.तूरडाळ || ''}</td>
      <td style={thstylebody}>{item.foodItems.Masurdal ||item.foodItems.मसूरडाळ || ''}</td>
      <td style={thstylebody}>{item.foodItems.Matki ||item.foodItems.मटकी || ''}</td>
      <td style={thstylebody}>{item.foodItems.Moong || item.foodItems.मूग ||''}</td>
      <td style={thstylebody}>{item.foodItems.Cowpea || item.foodItems.चवळी ||''}</td>
      <td style={thstylebody}>{item.foodItems.Gram || item.foodItems.हरभरा ||''}</td>
      <td style={thstylebody}>{item.foodItems.Pease || item.foodItems.वाटाणा ||''}</td>
      <td style={thstylebody}>{item.foodItems.Cumin || item.foodItems.जिरे ||''}</td>
      <td style={thstylebody}>{item.foodItems.Mustard || item.foodItems.मोहरी ||''}</td>
      <td style={thstylebody}>{item.foodItems.Turmeric ||item.foodItems.हळद || ''}</td>
      <td style={thstylebody}>{item.foodItems.Chili ||item.foodItems.मिरची || ''}</td>
      <td style={thstylebody}>{item.foodItems.Oil || item.foodItems.तेल ||''}</td>
      <td style={thstylebody}>{item.foodItems.Salt || item.foodItems.मीठ ||''}</td>
      <td style={thstylebody}>{item.foodItems['Garam Masala'] || item.foodItems["गरम मसाला"] ||''}</td>
      <td style={thstylebody}>{item.foodItems.Vegetables || item.foodItems["भाजी पाला"] ||''}</td>
      <td style={thstylebody} className='last2td'>
      <textarea
        style={{
          padding: '-5px',
          margin:  '-5px',
            border: 'none',
            outline: 'none',
            resize: 'none', // Prevent resizing if you want to control size
            width: '100%',  // Make textarea take full width of cell
            maxWidth: 'fit-content', // Adjust max width to fit content
            minWidth: '60px', // Optional: set a minimum width
            minHeight: '50px', 
            overflow: 'hidden' // Hide scrollbar
        }}
        value={rowInputData[index]?.supplementaryFood || item.foodItems.SupplementaryFood || ''}
        onChange={(event) => handleInputChange(index, 'supplementaryFood', event.target.value)}
        onKeyPress={(event) => handleKeyPress(event, item.date, index)}
        rows={1 }
    />
                          </td>
                         

                          <td style={thstylebody} className='last2td'>{(expenseValue * (item.foodItems.labharthi || 0)) || 0}</td> {/* Display calculated expense */}
    </tr>
  );
  })}

  {/* Totals Row */}
  <tr>
    <td style={{...thstylebody, borderLeft: '1px solid black', fontWeight: 'bold', padding:'auto 0px'}}> Total</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(countTotal) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.labharthi) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.labharthi) || 0), 0)}</td>

    {/* Food Items */}
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Rice) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Mugdal) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Turdal) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Masurdal) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Matki) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Moong) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Cowpea) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Gram) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Pease) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Cumin) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Mustard) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Turmeric) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Chili) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Oil) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Salt) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.GaramMasala) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.Vegetables) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.SupplementaryFood) || 0), 0)}</td>
    <td style={{...thstylebody,  fontWeight: 'bold'}}>{modalData.reduce((acc, item) => acc + (parseFloat(item.foodItems.BeneficiaryWiseExpenditure) || 0), 0)}</td>
  </tr>
</tbody>


                </table>

                <div className='text-center mt-3 Certificate-dailyposhan'>
  <p>{language === "English" ? "Certificate" : "प्रमाणपत्र"} </p>
  <div className='certificate-part-poshan' style={{ display: 'flex', justifyContent: 'center' }}>
    <p className='certificate-text'  style={{ textAlign: 'left', width: '74%', fontSize:'13px' }}>
      {language === "English"
        ? "It is hereby certified that the amount mentioned above has been spent on the purchase of vegetables, supplementary food, fuel, and grains required for the beneficiaries of the school nutrition program. The expenditure is appropriate, and the items have been used in the daily cooking as per the prescribed guidelines of the government for the school nutrition meal scheme. I am confident that the use of these materials is accurate and correct. Therefore, this certificate is being issued."
        : "प्रमाणित करण्यात येते की, वरील समाविष्ठ असलेली रकुम शाळेतील शालेय पोषण आहार लाभार्थींसाठी लागणारा भाजीपाला, पुरक आहार, इंधन आणि धान्यादी साहीत्यासाठी मी खर्च केलेला असून तो योग्य आहे आणि शालेय पोषण आहार भोजन यासाठी शासनाने निर्देशित केलेल्या प्रमाणात वरील साहित्याचा वापर प्रत्यक्ष दैनंदिन पाककृतीमध्ये केलेला आहे व तो बरोबर आहे, याची मला खात्री आहे. करिता प्रमाणपत्र करण्यात येते."}
    </p>    
  </div>
  <div className='signature-section' style={{ display: 'flex', justifyContent: 'space-between', margin: '0 auto', padding: '0 20px', fontSize: '13px', width: '70%', alignItems: 'center', marginTop: '10px' }}>
  <p>{language === "English" ? "Signature of Principal" : "मुख्याध्यापक स्वाक्षरी"}</p>
  <div className='principal-signature' style={{ textAlign: 'right' }}>
    <p className='president-signature' style={{ textAlign: 'center', marginBottom: '0' }}>
      {language === "English" ? "President" : "अध्यक्ष"}
    </p>
    <p className='schmgnt'>{language === "English" ? "School Management Committee" : "शाळा व्यवस्थापन समिती"}</p>
  </div>
</div>

  
</div>
              </div>
             

              <div style={{ textAlign: 'right', marginTop: '20px' }}>
                <button onClick={handlePrint} style={{
          padding: '12px 20px',
          backgroundColor: '#007BFF',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontSize: '16px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.2s ease',
          marginRight: '10px',
        }}>
         {language === "English" ? "Print" : "प्रिंट करा"} 
        </button>
                <button onClick={closeModal} style={{
                  padding: '12px 20px',
                  backgroundColor: '#FF4136',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'background-color 0.2s ease',
                }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#E04A3F'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#FF4136'}
                >
               {language === "English" ? "Close" : " बंद करा"}                          
                </button>
              </div>
            </div>
          </div>
        )}

        </div>
    </div>
  );
}

export default DainandinReport;


const thstyle={
  fontSize:'14px',
  padding: '0px',
  fontWeight: '600',
  borderBottom: '1px solid black',
  textAlign: 'center',
  borderRight: '1px solid black',
  borderRight: '1px solid black',
  width:'60px'
}

const thstylebody={
  fontSize:'12px',
  padding: '7px',
  borderBottom: '1px solid black',
  textAlign: 'center',
  color: 'black',
  borderRight: '1px solid black',
}