import { React } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import SchoolRegister from './pages/School/SchoolRegister';
import LoginForm from './pages/LoginForm';
import StudentRegister from './pages/Student/StudentRegister';
import StudentAllData from './pages/Student/StudentAllData';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CastewiseReport from './pages/Student/CastewiseReport';
import Minority from './pages/Student/Minority';
import AgewiseReport from './pages/Student/AgewiseReport';
import SchoolData from './pages/SchoolData';
import Sidebar from './components/Sidebar';
import Leaving from './pages/Student/Leaving';
import Importexport from './pages/Student/Importexport';
import Bonafide from './pages/Student/Bonafide';
import IdCard from './pages/Student/IdCard';
import Sadhyachasatha from './pages/Poshanaahar/Sadhyachasatha';

import Kird from './pages/Kird/Kird';
import GunaNeendani from './pages/result/GunaNeendani';
import ProgressSheet from './pages/result/ProgressSheet';
import SubjectwiseReport from './pages/result/SubjectWiseResult';
import Avaksatha from './pages/Poshanaahar/Avaksatha';
import Magani from './pages/Poshanaahar/Magani';
import Praman from './pages/Poshanaahar/Praman';
import Menu from './pages/Poshanaahar/Menu';
import DainandiNondvahi from './pages/Poshanaahar/DainandinNondvahi';
import GeneralRegister from './pages/Student/GeneralRegister';
import WithAuth from '../src/WithAuth';
import PaymentPage from './pages/PaymentIntegration/PaymentPage';
import Collectout from './pages/result/Collectout';
import GradeWise from './pages/result/GradeWise';
import DailyRegister from './pages/result/DailyRegister';
import Failed from './pages/result/Failed';
import LandingPage from './pages/PaymentIntegration/LandingPage';
import Downloadexe from './pages/PaymentIntegration/Downloadexe';
import Formsdemo from './pages/PaymentIntegration/Formsdemo';
import RollNo from './pages/Student/RollNo';
import BoardResult from './pages/result/BoardResult';
import DainandinReport from './pages/Poshanaahar/DainandinReport';

const AuthenticatedApp = () => (
   <Routes>
    <Route path="/school-data" element={<SchoolData />} />
    <Route path="/home" element={<Home />} />
    <Route path='/student-register' element={<StudentRegister />} />
    <Route path='/student-register/?serialNo=${student.serialNo}' element={<StudentRegister />} />
    <Route path='/all-student-report' element={<StudentAllData />} />
    <Route path='/caste-report' element={<CastewiseReport />} />
    <Route path='/minority-report' element={<Minority />} />
    <Route path='/agewisereport' element={<AgewiseReport />} />
    <Route path='/sid' element={<Sidebar />} />
    <Route path='/leaving-certificate' element={<Leaving />} />
    <Route path='/importexport' element={<Importexport />} />
    <Route path='/bonafide' element={<Bonafide />} />
    <Route path='/IdCard' element={<IdCard />} />
    <Route path='/generalregister' element={<GeneralRegister />} />

    <Route path='/kird' element={<Kird />} />

    <Route path='/GunaNeendani' element={<GunaNeendani />} />
    <Route path='/progresssheet' element={<ProgressSheet />} />
    <Route path='/subjectwise' element={<SubjectwiseReport />} />
    <Route path='/collectout' element={<Collectout />} />
    <Route path='/gradewise' element={<GradeWise />} />
    <Route path='/dailyregister' element={<DailyRegister />} />
    <Route path='/failed' element={<Failed />} />
    <Route path='/boardresult' element={<BoardResult />} />
    <Route path='/rollNo' element={<RollNo />} />

    <Route path='/sadhyachasatha' element={<Sadhyachasatha />} />
    <Route path='/aavaksatha' element={<Avaksatha />} />
    <Route path='/magani' element={<Magani />} />
    <Route path='/praman' element={<Praman />} />
    <Route path='/menu' element={<Menu />} />
    <Route path='/dainandin' element={<DainandiNondvahi />} />
    <Route path='/dainandinNondreport' element={<DainandinReport />} />

  
    
    <Route path='/paymentint' element={<PaymentPage />}  />

  </Routes> 
);

const AuthenticatedRoutes = WithAuth(AuthenticatedApp);

const App = () => {
  const location = useLocation(); // Use inside the functional component

  // Paths where you don't want to show Navbar and Footer
  const hideNavbarAndFooter = location.pathname === '/eguruji-landingpage';

  return (
    <>
      {!hideNavbarAndFooter && <Navbar />} {/* Conditionally render Navbar */}
      <Routes>
        <Route path="/demo" element={<Formsdemo />} />
        <Route path="/downloadexe-landingpage" element={<Downloadexe />} />
        <Route path="/eguruji-landingpage" element={<LandingPage />} />
        <Route path="/" element={<LoginForm />} />
        <Route path="/school-register" element={<SchoolRegister />} />
        <Route path="/*" element={<AuthenticatedRoutes />} />
      </Routes>
      {!hideNavbarAndFooter && <Footer />} {/* Conditionally render Footer */}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
