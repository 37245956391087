import React, { useState, useEffect } from "react";
import "firebase/database";
import "./style1.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles for react-datepicker
import axios from "axios"; // For making HTTP requests to Firebase
import  KhatavaniReport  from './KhatavaniReport';
import { ProfitLossReport} from './ProfitLossReport';
import Receipt from './Receiptcredit';
import Receipt1 from "./Receiptdebit";
import 'jspdf-autotable'; 


import Sidebar from "../../components/Sidebar";
import KirdReport from "./KirdReport";
import TerijReport from "./TerijReport";
import AlertMessage from "../../AlertMessage";
import loadingImg from '../../assets/images/loading.jpg.gif'

const Kird = () => {
    const [guna2ComboBoxItems, setGuna2ComboBoxItems] = useState([]);
    const [newKirdPrakar, setNewKirdPrakar] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedItemName, setSelectedItemName] = useState("");
    const [khateInput, setKhateInput] = useState("");
    const [amountInput, setAmountInput] = useState("");
    const [showCardsBox, setShowCardsBox] = useState(false);
    const [showEmptyBox, setShowEmptyBox] = useState(false);
    const [todayAvailableKhate, setTodayAvailableKhate] = useState([]);
    const [todayAvailableKhateCount, setTodayAvailableKhateCount] = useState(0);
    const [showLaserInput, setShowLaserInput] = useState(false);
    const [selectedKhateAmount, setSelectedKhateAmount] = useState(""); // State for selected khate amount
    const [editItem, setEditItem] = useState(null); // State to store the item being edited
    const [label11, setLabel11] = useState("0");
    const [label1, setLabel1] = useState("0");
    const [label2, setLabel2] = useState("0");
    const [label3, setLabel3] = useState("0");
    const [label4, setLabel4] = useState("0");
    const [label5, setLabel5] = useState("0");
    const [showTable, setShowTable] = useState(false); // State to control table visibility
    const [blurBackground, setBlurBackground] = useState(false); // State to control blur effect
    const [savedValues, setSavedValues] = useState([]);
    const [showTableContainer, setShowTableContainer] = useState(false);
    const [hideOuterBox, setHideOuterBox] = useState(false);
    const [showFormInPopup, setShowFormInPopup] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [newKirdType, setNewKirdType] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [showOuterBox, setShowOuterBox] = useState(true);
    const [schoolData, setSchoolData] = useState(null);
    const [showTable1, setShowTable1] = useState(false);
    const [selectedYear1, setSelectedYear1] = useState("");
    const [selectedDate, setSelectedDate] = useState('');
    const [currentPavtiNo, setCurrentPavtiNo] = useState(1);
    const [transactionType, setTransactionType] = useState(''); // 'credit' or 'debit'
    const [khatePrakar, setKhatePrakar] = useState('');
    const [amount, setAmount] = useState('');
    const [remark, setRemark] = useState('');
    const [credit1, setCredit1] = useState('');
    const [debit1, setDebit1] = useState('');
    const [isCreditSelected, setIsCreditSelected] = useState(false);
    const [isDebitSelected, setIsDebitSelected] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
   const [savedPavtiNos, setSavedPavtiNos] = useState([]);
    const [pavtiData, setPavtiData] = useState({}); 
    const [selectedPavtiNos, setSelectedPavtiNos] = useState([]);
    const [pavtiDataArray, setPavtiDataArray] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isExistingPavtiNo, setIsExistingPavtiNo] = useState(false);
    const [date, setDate] = useState("");
    const [financialYear, setFinancialYear] = useState("");
    const [pavtiNo, setPavtiNo] = useState("");
    const [language, setLanguage] = useState(
      localStorage.getItem("language") || "English"
    );
    const [deletedPavtiNos, setDeletedPavtiNos] = useState([]); 
    const [showWarning, setShowWarning] = useState(false);
    const [ledgerType, setLedgerType] = useState("normal");
    const [showKirdReport, setShowKirdReport] = useState(false);
    const [showKhatavaniReport, setShowKhatavaniReport] = useState(false);
    const [showTerijReport, setShowTerijReport] = useState(false);
    const [showProfitLossReport, setShowProfitLossReport] = useState(false);
    const [showloadingimg, setShowloadingimg] = useState(false);

    
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');

    const udiseNumber = localStorage.getItem("udiseNumber");
    const firebaseBaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird`;

    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
      const fetchDefaultYear = async () => {
        try {
          const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/defaultSettings.json`);
          const data = await response.json();
          if (data && data.defaultYear) {
            const year = data.defaultYear;
            setSelectedYear(year); // Set the default year
            setSelectedYear1(year); // Set the default year
            const { start, end } = getYearDateRange(year);
            setStartDate(start); // Set default start date
            setEndDate(end); // Set default end date
            setSelectedDate(start); // Set selected date to start date
            setMinDate(start); // Set minimum date
            setMaxDate(end); // Set maximum date
            console.log("start", start);
          }
        } catch (error) {
          console.error("Error fetching default year:", error);
        }
      };
  
      fetchDefaultYear();
    }, []);


useEffect(() => {
      const storedLanguage = localStorage.getItem("language") || "English";
      setLanguage(storedLanguage);
    }, []);

    const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        setAlertMessage(""); 
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [alertMessage]);

    const transactionPath = transactionType === 'credit' ? 'jama' : 'nave';
    const lastPavtiNoKey = transactionType === 'credit' ? 'lastjamaNo' : 'lastnaveNo';
    const fetchData = () => {
        fetch(`${firebaseBaseUrl}.json`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then((data) => {
                if (data) {
                    const items = Object.keys(data).map((key) => ({
                        kirdtype: key,
                        entries: Object.keys(data[key]).map((entryKey) => ({
                            id: entryKey,
                            amount: data[key][entryKey].amount,
                            khate: data[key][entryKey].khate,
                        })),
                    }));
                    setGuna2ComboBoxItems(items);
                } else {
                    setGuna2ComboBoxItems([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const getYearDateRange = (year) => {
      const yearMapping = {
        '2022-2023': { defaultDate: '2022-04-01', min: '2022-04-01', max: '2023-03-31' },
        '2023-2024': { defaultDate: '2023-04-01', min: '2023-04-01', max: '2024-03-31' },
        '2024-2025': { defaultDate: '2024-04-01', start: '2024-04-01', end: '2025-03-31' },
        '2025-2026': { defaultDate: '2025-04-01', start: '2025-04-01', end: '2026-03-31' },
        '2026-2027': { defaultDate: '2026-04-01', start: '2026-04-01', end: '2027-03-31' },
        '2027-2028': { defaultDate: '2027-04-01', start: '2027-04-01', end: '2028-03-31' },
        '2028-2029': { defaultDate: '2028-04-01', start: '2028-04-01', end: '2029-03-31' },
        '2029-2030': { defaultDate: '2029-04-01', start: '2029-04-01', end: '2030-03-31' },
        '2030-2031': { defaultDate: '2030-04-01', start: '2030-04-01', end: '2031-03-31' },
      };
  
      return yearMapping[year] || { start: '', end: '' };
    };
  

    useEffect(() => {
      if (selectedYear1) {
      const { start,end} = getYearDateRange(selectedYear1);
      
      setStartDate(start);
      setEndDate(end);
      setMinDate(start);
      setMaxDate(end);
      setSelectedDate(start); // Set the date to defaultDate or min
      }
    }, [selectedYear1]);
  

const handleInfoButtonClick = () => {
        setShowTable(true);
        setBlurBackground(true);
        console.log("Info button clicked");
        console.log("showTable:", showTable); // Check if showTable state is correctly updated
        console.log("blurBackground:", blurBackground); // Check if blurBackground state is correctly updated
    };
useEffect(() => {
      const fetchLastPavtiNo = async () => {
        try {
          const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/lastPavtiNo.json`);
          const data = await response.json();
          if (data && data.lastPavtiNo) {
            setCurrentPavtiNo(data.lastPavtiNo + 1);
          }
        } catch (error) {
          console.error("Error fetching last Pavti No:", error);
        }
      };
  
      fetchLastPavtiNo();
    }, [udiseNumber]);
  
  useEffect(() => {
        fetchData();
        fetchSchool();

        if (showTableContainer) {
            // Simulate delay to show the table container
            const timer = setTimeout(() => {
                setHideOuterBox(true); // Hide the outer box after a delay
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        }
    }, [showTableContainer]);

const fetchSchool = () => {
        fetch(
            `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                console.log("School data fetched successfully:", data);
                setSchoolData(data);
                console.log("data",data);
                
            })
            .catch((error) => {
                console.error("Error fetching school data:", error);
            });
    };

    const handleAdd = async () => {
      try {
        // Check if the ledger type is "bank" and the amount input is empty
        if (ledgerType === "bank" && amountInput.trim() === "") {
          setAlertMessage(language === "English" ? "Please enter an amount before saving." : "कृपया बचत करण्यापूर्वी रक्कम प्रविष्ट करा.");
          return; // Prevent further execution
        }
    
        // Validate khateInput and selectedItemName
        if (!khateInput.trim() || !selectedItemName.trim()) {
          console.error("Khate Input or Kird Type cannot be empty.");
          return;
        }

        // Check for duplicates before adding
    const isDuplicate = todayAvailableKhate.some(item => item.khate === khateInput.trim());
    if (isDuplicate) {
      setAlertMessage(language === "English" ? "This khate already exists." : "हा खाते आधीच अस्तित्वात आहे.");
      return; // Exit the function to prevent adding a duplicate
    }

    
        // Set amountInput to "0" if it's empty or whitespace
        const amount = amountInput.trim() === "" ? "0" : amountInput.trim();
    
        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split("T")[0];
    
        // Prepare the data to save
        const data = {
          amount: amount,
          date: today,
          khate: khateInput.trim(),
        };
    
        // Fetch existing data to determine the next ID
        const existingDataResponse = await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate.json`);
        const existingData = await existingDataResponse.json();
    
        // Determine the next ID by finding the highest existing ID
        let maxId = 0;
        if (existingData) {
          const ids = Object.keys(existingData).map(key => parseInt(key, 10)).filter(id => !isNaN(id));
          maxId = ids.length > 0 ? Math.max(...ids) : 0;
        }
    
        // New ID for the entry
        const newId = maxId + 1;
    
        // Firebase URL with the new ID
        const firebaseUrl = `${firebaseBaseUrl}/${selectedItemName}/khate/${newId}.json`;
    
        // Save the data using PUT method with the new ID
        const response = await fetch(firebaseUrl, {
          method: "PUT", // Use PUT to create or replace the entry
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
    
        // Check response
        if (!response.ok) {
          throw new Error("Failed to add Khate and Amount Input.");
        }
    
        console.log("Khate and Amount Input added successfully");
        setShowLaserInput(true);
    
        // Fetch data again to update the UI
        fetchData();
    
        setAlertMessage(language === "English" ? "Khate and Amount Input added successfully" : "खाते आणि रक्कम यशस्वीरित्या जोडले");
    
        // Update the state for label11 (assuming it's used for Bank Account Remaining)
        if (setLabel11) {
          setLabel11((prevLabel11) => {
            const newAmount = parseFloat(prevLabel11) + parseFloat(amount);
            return newAmount.toFixed(2);
          });
        }
    
        // Add the new entry to the state for today's available khate
        setTodayAvailableKhate((prev) => [
          ...prev,
          { khate: khateInput.trim(), amount: amount, date: today, id: newId }, // Include ID here
        ]);
    
        // Update the count of today's available khate
        setTodayAvailableKhateCount(todayAvailableKhateCount + 1);
    
        // Clear inputs and hide fields
        setKhateInput("");
        setAmountInput("");
      } catch (error) {
        console.error("Error adding Khate and Amount Input:", error);
      }
    };
  

    const handleSelectItem = async (event) => {
      const selectedValue = event.target.value;
      setSelectedItem(selectedValue);
      // Firebase URL for the selected item
      const firebaseUrl = `${firebaseBaseUrl}/${selectedValue}/khate.json`;
  
      try {
          const response = await fetch(firebaseUrl);
  
          if (!response.ok) {
              throw new Error("Failed to fetch data for selected Kird Prakar.");
          }
        const data = await response.json();
          console.log("Fetched data:", data);
  
          // Transform the data into an array if necessary
          const entries = Object.keys(data || {}).map(key => ({
              khate: data[key].khate,
              amount: data[key].amount,
              date: data[key].date, // Include date if needed
          }));
          setSelectedItemName(selectedValue);
          setTodayAvailableKhate(entries);
          setTodayAvailableKhateCount(entries.length);
          setShowCardsBox(true);
      } catch (error) {
          console.error("Error fetching data for selected item:", error);
          setSelectedItemName("");
          setTodayAvailableKhate([]);
          setTodayAvailableKhateCount(0);
          setShowCardsBox(true);
      }
      fetchOpeningbal()
  };
  const handleCreateLaser = () => {
        setBlurBackground(true);
        setShowLaserInput(true);
    };
    const handleSaveNewKirdPrakar = async () => {
      try {
        // Check if the input value is empty
        if (!newKirdPrakar.trim()) {
          setAlertMessage(language === "English" ? "Please enter a Kird Prakar" : "कृपया किर्द प्रकार प्रविष्ट करा");
          return; // Stop the function from proceeding if the input is empty
        }
    
        // Generate a unique ID for the new item (you can use any preferred method)
        const newItem = {
          id: savedValues.length + 1, // Example: incrementing ID
          kirdtype: newKirdPrakar,
        };
    
        // Add the new item to the saved values array
        setSavedValues([...savedValues, newItem]);
    
        // Clear the input field and close the popup
        setNewKirdPrakar("");
        setShowPopup(false);
    
        // Prepare data to be saved in Firebase
        const data = {
          khate: "", // Initial value
          amount: "" // Initial value
        };
    
        // Construct Firebase URL for new Kird Prakar
        const firebaseUrl = `${firebaseBaseUrl}/${newKirdPrakar}/khate.json`;
    
        // Save data to Firebase
        const response = await fetch(firebaseUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
    
        if (!response.ok) {
          throw new Error("Failed to save new Kird Prakar.");
        }
    
        console.log("Saving new Kird Prakar:", newKirdPrakar);
    
        // Fetch data again to update the UI
        fetchData();
    
        setShowFormInPopup(true); // Show form in popup if needed
      } catch (error) {
        console.error("Error saving new Kird Prakar:", error);
      }
    };
    
  

    const handleEdit = (item) => {
      console.log("Editing item:", item);
      setEditItem(item); // Set the selected item to be edited
      setShowLaserInput(true); // Show the input fields to allow editing
      setBlurBackground(true); // Optional: Blur background for better focus
      setKhateInput(item.khate); // Set current khate value in input field
      setAmountInput(item.amount); // Set current amount value in input field
  };
    
    const handleCloseButtonClick4 = () => {
      setShowLaserInput(false);
      setShowCardsBox(true); // Assuming you want to show cards box again
      setBlurBackground(false); // Clear blur effect
      setKhateInput("");
      setAmountInput("");
      setEditItem(null);
  };

const handleDelete = async (item) => {
    console.log("Deleting item:", item.kirdtype); // Check the item structure
  
    // Firebase URL for accessing the specific collection of items
    const firebaseUrl = `${firebaseBaseUrl}/${selectedItemName}.json`;
  
    try {
      // Fetch existing data to find the key for the item to delete
      const existingDataResponse = await fetch(firebaseUrl);
      const existingData = await existingDataResponse.json();
      console.log("existingData", existingData);
  
     // Construct the URL to delete the specific item
      const deleteUrl = `${firebaseBaseUrl}/${item.kirdtype}.json`;
      console.log("deleteUrl", deleteUrl);

  // Perform the DELETE request
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        console.log("Delete successful");
        setAlertMessage(language === "English" ? "Kird Type deleted successfully"  : "किर्द प्रकार यशस्वीरित्या हटवला");
      } else {
        console.error("Error deleting the item: ", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting item in Firebase: ", error);
    }
  };


  const handleDelete2 = async (item) => {
    console.log("item", item); // Log the item to check its structure

    try {
        // Fetch all khate items to find the unique ID for the item to delete
        const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/khate.json`;
        const response = await fetch(firebaseUrl);
        const data = await response.json();

        // Find the item to delete
        const itemToDelete = Object.values(data).find(existingItem => 
            existingItem.khate === item.khate && 
            existingItem.amount === item.amount && 
            existingItem.date === item.date
        );

        // Check if the item has transactionDone: true
        if (itemToDelete && itemToDelete.transactionDone) {
            console.warn("Deletion not allowed: transaction is already done for this khate.");
            setAlertMessage(language === "English" ? "You cannot delete this khate as the transaction is already completed." : "व्यवहार आधीच पूर्ण झाल्यामुळे तुम्ही हे खाते हटवू शकत नाही.");
            return; // Exit if deletion is not allowed
        }

        // Find the ID of the item to delete
        const itemId = Object.keys(data).find(key => 
            data[key].khate === item.khate && 
            data[key].amount === item.amount && 
            data[key].date === item.date
        );

        if (!itemId) {
            console.error("Item ID not found. Cannot delete item.");
            return; // Exit the function if itemId is undefined
        }

        // Construct the URL to delete the specific khate item by its ID
        const deleteUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/khate/${itemId}.json`;
        console.log("Firebase URL for delete:", deleteUrl);

        // Make a DELETE request to remove the item
        const deleteResponse = await fetch(deleteUrl, {
            method: "DELETE",
        });

        // Check if the delete request was successful
        if (!deleteResponse.ok) {
            throw new Error("Failed to delete item in Firebase");
        }

        console.log("Item deleted successfully from Firebase");

        // Remove the item from the local state to reflect changes in the UI
        setTodayAvailableKhate((prevItems) =>
            prevItems.filter((khate) => khate.khate !== item.khate) // Filter by khate property
        );

        // Optionally, display an alert message to inform the user
        setAlertMessage(language === "English" ? "Ledger deleted successfully" : "लेजर यशस्वीरित्या हटवले.");
    } catch (error) {
        console.error("Error deleting item in Firebase:", error);
    }
};


const handleSaveEdit = async () => {

  console.log("editItem before save:", editItem); // Check the editItem state

              

  // Validate that we have an item to edit and an amount provided
  if (editItem && amountInput.trim()) {
    // Create the updated item object
    const updatedItem = {
      amount: amountInput, // Update the amount
      date: editItem.date, // Keep the existing date
      khate: khateInput || editItem.khate, // Update khate if a new value is provided
    };

    console.log("Updating item:", updatedItem); // Debugging line to check the item structure

    // Firebase URL for fetching existing items
    const firebaseUrl = `${firebaseBaseUrl}/${selectedItemName}/khate.json`;
    console.log("firebaseUrl", firebaseUrl);

    try {
      // Fetch existing data to find the key for the item to update
      const existingDataResponse = await fetch(firebaseUrl);
      const existingData = await existingDataResponse.json();
      console.log("existingData", existingData);

      // Check if the khate to be updated has transactionDone: true
      const itemToEdit = Object.values(existingData).find(item => item.khate === editItem.khate && item.amount === editItem.amount && item.date === editItem.date);

      if (itemToEdit && itemToEdit.transactionDone) {
        console.warn("Editing not allowed: transaction is already done for this khate.");
        setAlertMessage( language === "English" ? "You cannot edit this khate as the transaction is already completed."  : "व्यवहार आधीच पूर्ण झाल्यामुळे तुम्ही हे खाते संपादित करू शकत नाही.");
        return; // Exit if editing is not allowed
      }

      // Find the key of the item to update based on matching values
      const itemKeyToUpdate = Object.keys(existingData).find(key => {
        const item = existingData[key];
        return item.amount === editItem.amount && item.date === editItem.date && item.khate === editItem.khate;
      });

      if (!itemKeyToUpdate) {
        console.error("Item to update not found in Firebase.");
        return; // Exit if the item is not found
      }

      // Use the found key to update the specific item
      const updateUrl = `${firebaseBaseUrl}/${selectedItemName}/khate/${itemKeyToUpdate}.json`;
      console.log("updateUrl", updateUrl);

      const response = await fetch(updateUrl, {
        method: 'PUT', // Use PUT to replace the existing item
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedItem),
      });

      if (response.ok) {
        console.log("Update successful");

        // Update the local state
        const updatedItems = todayAvailableKhate.map((item) =>
          item.amount === editItem.amount && item.date === editItem.date && item.khate === editItem.khate ? updatedItem : item
        );
        setTodayAvailableKhate(updatedItems); // Update the local state to reflect the change

        // Reset the state after a successful update
        setEditItem(null);
        setShowLaserInput(true);
        setKhateInput(''); // Clear the khate input field if necessary
        setAmountInput(''); // Clear the amount input field
        setShowCardsBox(true); 
      } else {
        console.error("Error saving the edited item: ", response.statusText);
      }
    } catch (error) {
      console.error("Error updating in Firebase: ", error);
    }
  } else {
    console.warn("Please provide a valid amount value.");
  }
};





const handleKhateItemClick = (khate) => {
        const selectedKhate = todayAvailableKhate.find(
            (item) => item.khate === khate
        );
        if (selectedKhate) {
            setSelectedKhateAmount(selectedKhate.amount);
        }
        console.log("Khate item clicked:", khate);
    };

    const handleAdditionalFormSubmit = (e) => {
        e.preventDefault();
        // Logic to handle additional form submission
        setShowPopup(false); // Close the popup after submission
    };

    const handleEdit1 = (event, item) => {
      console.log("event",event);
      console.log("item",item.kirdtype);
      
        event.preventDefault();
        setEditingItem(item); // Set the item to edit
        setNewKirdType(item.kirdtype); // Set the new Kird Type to the current value
        setShowPopup(true); // Show the popup
        setShowFormInPopup(true); // Indicate we are in the edit mode
    };

    const handleUpdateKirdPrakar = async () => {
        if (!newKirdType.trim()) {
            console.error("Kird Prakar cannot be empty.");
            return;
        }

        if (!editingItem || !editingItem.kirdtype) {
            console.error("No item selected for update or missing Kird Prakar.");
            return;
        }

        try {
            const firebaseBaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird`;

            // URLs for old and new Kird Prakar
            const oldKirdUrl = `${firebaseBaseUrl}/${editingItem.kirdtype}.json`; // Old Kird Prakar URL
            const newKirdUrl = `${firebaseBaseUrl}/${newKirdType}.json`; // New Kird Prakar URL

            console.log("Old Kird URL:", oldKirdUrl);
            console.log("New Kird URL:", newKirdUrl);

            // Fetch old item data
            const oldItemResponse = await fetch(oldKirdUrl);
            if (!oldItemResponse.ok) {
                throw new Error("Failed to fetch old Kird Prakar.");
            }
            const oldItemData = await oldItemResponse.json();
            console.log("Old Item Data:", oldItemData);

            // Delete the old item
            const deleteResponse = await fetch(oldKirdUrl, {
                method: "DELETE",
            });
            if (!deleteResponse.ok) {
                throw new Error("Failed to delete old Kird Prakar.");
            }
            console.log("Old Kird Prakar deleted.");

            // Save new item with updated Kird Prakar
            const saveResponse = await fetch(newKirdUrl, {
                method: "PUT", // Use PUT to update or add
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(oldItemData), // Save existing data under the new key
            });
            if (!saveResponse.ok) {
                throw new Error("Failed to save updated Kird Prakar.");
            }
            console.log("Kird Prakar updated with new type:", newKirdType);

            // Update local state with new Kird Prakar
            const updatedItems = guna2ComboBoxItems.map((item) =>
                item.kirdtype === editingItem.kirdtype
                    ? { ...item, kirdtype: newKirdType }
                    : item
            );
            setGuna2ComboBoxItems(updatedItems);
            setEditingItem(null);
            setNewKirdType("");
            setAlertMessage(" updated successfully");
            // Refresh data
            fetchData();
        } catch (error) {
            console.error("Error updating Kird Prakar:", error);
        }
    };

    const handleSelectYearItem = async (event) => {
      const year = event.target.value;
      setSelectedYear(year); // Store the selected year in state
    
      if (year) {
        // Get the date range for the selected year
        const { start, end } = getYearDateRange(year);
        setStartDate(start); // Set the start date based on selected year
        setEndDate(end); // Set the end date based on selected year
        setMinDate(start); // Set the minimum date based on selected year
        setMaxDate(end); // Set the maximum date based on selected year
        setSelectedDate(start); 

        // Prepare the data to be saved to Firebase
        const academicYearData = {
          year: year,
          hyaTarkhepasun: start,
          hyaTarkheparyant: end,
        };
    
        try {
          // Save the selected year and date range to Firebase
          const response = await axios.put(
            `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/khate/${selectedItem}/academicYear.json`,
            academicYearData
          );
    
          if (response.status === 200) {
            console.log("Academic year and date range saved successfully ");
            console.log("Saved data:", academicYearData);
          } else {
            console.error("Failed to save data. Status:", response.status);
          }
        } catch (error) {
          console.error("Error saving academic year data to Firebase:", error.response ? error.response.data : error.message);
        }
      } else {
        // If no year is selected, reset the dates
        setStartDate("");
        setEndDate("");
      }
    };

  const fetchOpeningbal = async()=>{
    const urlOpening = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird.json`
    console.log("urlOpening",urlOpening);
    
  }
  
  const handleKirdReport = async () => {
    try {
        if (!startDate || !endDate || !selectedItem) {
            setAlertMessage( language === "English" ? "Please select both start and end dates, and a Kird Prakar."  : "कृपया प्रारंभ आणि समाप्ती या दोन्ही तारखा आणि किर्द प्रकार निवडा");
            return;
        }

        const [jamaResponse, naveResponse] = await Promise.all([
            axios.get(
                `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`
            ),
            axios.get(
                `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`
            ),
        ]);

        const jamaData = jamaResponse.data || {};
        const naveData = naveResponse.data || {};

        // Combine and structure data
        const combinedData = {};

        Object.keys(jamaData).forEach((pavtiNo) => {
            const pavti = jamaData[pavtiNo];
            if (pavti && pavti.date) {
                const pavtiDate = new Date(pavti.date).toISOString().split('T')[0];
                if (!combinedData[pavtiDate]) {
                    combinedData[pavtiDate] = { jama: [], nave: [] };
                }
                combinedData[pavtiDate].jama.push({
                    pavtiNo: pavtiNo.toString(),
                    credit1: (pavti.credit1 || '').toString(),
                    amount: pavti.Amount.toString(),
                    date: pavti.date || '',
                    khatePrakar: pavti.khatePrakar || '',
                });
            }
        });

        Object.keys(naveData).forEach((pavtiNo) => {
            const pavti = naveData[pavtiNo];
            if (pavti && pavti.date) {
                const pavtiDate = new Date(pavti.date).toISOString().split('T')[0];
                if (!combinedData[pavtiDate]) {
                    combinedData[pavtiDate] = { jama: [], nave: [] };
                }
                combinedData[pavtiDate].nave.push({
                    pavtiNo: pavtiNo.toString(),
                    debit1: (pavti.Debit1 || '').toString(),
                    amount: pavti.Amount.toString(),
                    date: pavti.date || '',
                    khatePrakar: pavti.khatePrakar || '',
                });
            }
        });

        const filteredData = Object.keys(combinedData)
            .filter((date) => {
                const itemDate = new Date(date);
                const start = new Date(startDate);
                const end = new Date(endDate);
                return itemDate >= start && itemDate <= end;
            })
            .reduce((result, date) => {
                result[date] = combinedData[date];
                return result;
            }, {});

        if (Object.keys(filteredData).length === 0) {
            setAlertMessage( language === "English" ? "No data found for the selected Kird Prakar and date range."  : "निवडलेल्या किर्द प्रकार आणि तारीख श्रेणीसाठी कोणताही डेटा आढळला नाही.");
            return;
        }

        // Set the filtered data and show the Kird report component
        setFilteredData(filteredData);
        setShowKirdReport(true);
        
    } catch (error) {
        console.error("Error fetching or generating report:", error);
    }
};


const[KhatavaniReportData,setKhatavaniReportData] = useState("");
const[KhatavaniReportData1,setKhatavaniReportData1] = useState("");




const handleKhatavaniReport = async () => {
  try {
    if (!startDate || !endDate || !selectedItem) {
      setAlertMessage( language === "English" ? "Please select both start and end dates, and a Kird Prakar."  : "कृपया प्रारंभ आणि समाप्ती या दोन्ही तारखा आणि किर्द प्रकार निवडा");
      return;
    }

    // Firebase URLs
    const khateUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/khate.json`;
    const jamaUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`;
    const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`;

    // Fetch khate data
    const khateResponse = await axios.get(khateUrl);
    if (khateResponse.status !== 200) {
      throw new Error('Failed to fetch khate data from Firebase');
    }
    const khateData = khateResponse.data || {};
    console.log("khateData", khateData);

    // Get khate names
    const khateNames = Object.keys(khateData).map(pavtiNo => khateData[pavtiNo]?.khate);
    const khateDetails = Object.keys(khateData).map(pavtiNo => ({
      khate: khateData[pavtiNo]?.khate, // khate name
      amount: khateData[pavtiNo]?.amount // corresponding amount
    }));
    
    console.log("khateDetails", khateDetails);
    
    // Fetch jama and nave data
    const [jamaResponse, naveResponse] = await Promise.all([
      axios.get(jamaUrl),
      axios.get(naveUrl),
    ]);

    // Check if responses are successful
    if (jamaResponse.status !== 200 || naveResponse.status !== 200) {
      throw new Error('Failed to fetch data from Firebase');
    }

    // Get data
    const jamaData = jamaResponse.data || {};
    const naveData = naveResponse.data || {};
    console.log("naveData", naveData);
    console.log("jamaData", jamaData);

    // Initialize combinedData
    const combinedData = {};
    let openBalance = 0;

    // Convert startDate and endDate to Date objects for comparison
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Process Jama Data
    await Promise.all(Object.keys(jamaData).map(async (pavtiNo) => {
      const pavti = jamaData[pavtiNo];
      if (pavti && pavti.date) {
        const pavtiDate = new Date(pavti.date);
        // Check if the date is within the specified range

        console.log("pavtiDate",pavtiDate);
        console.log("start",start);
        console.log("end",end);
        
        if (pavtiDate >= start && pavtiDate <= end) {
          const formattedDate = pavtiDate.toISOString().split('T')[0];
          if (!combinedData[formattedDate]) {
            combinedData[formattedDate] = { jama: [], nave: [], khate: [], openBalance: 0 };
          }

          // Safeguard for null/undefined values
          const khateName = pavti?.credit1 || pavti?.Debit1 || '';
          console.log("khateName jama", khateName);
          
          const khateAmount = pavti?.credit1 || pavti?.Debit1 || '';
          if (khateNames.includes(khateName)) {
            combinedData[formattedDate].jama.push({
              pavtiNo: pavtiNo,
              credit1: pavti?.credit1 || '',
              amount: parseFloat(pavti?.Amount) || 0,
              khateAmount: parseFloat(khateAmount) || 0,
              date: pavti?.date || '',
              khatePrakar: pavti?.khatePrakar || '',
            });
            openBalance += parseFloat(pavti?.Amount) || 0;
          }
        }
      }
    }));

    // Process Nave Data
    await Promise.all(Object.keys(naveData).map(async (pavtiNo) => {
      if (!pavtiNo) return; // Skip null/undefined pavtiNo

      const pavti = naveData[pavtiNo];
      if (pavti && pavti.date) {
        const pavtiDate = new Date(pavti.date);
        // Check if the date is within the specified range
        if (pavtiDate >= start && pavtiDate <= end) {
          const formattedDate = pavtiDate.toISOString().split('T')[0];
          if (!combinedData[formattedDate]) {
            combinedData[formattedDate] = { jama: [], nave: [], khate: [], openBalance: 0 };
          }

          // Safeguard for null/undefined values
          const khateName = pavti.Debit1 || pavti.credit1 || '';
          if (khateNames.includes(khateName)) {
            combinedData[formattedDate].nave.push({
              pavtiNo: pavtiNo,
              debit1: pavti.Debit1 || pavti.credit1 || '',
              amount: parseFloat(pavti.Amount) || 0,
              date: pavti.date || '',
              khatePrakar: pavti.khatePrakar || '',
            });
            openBalance -= parseFloat(pavti.Amount) || 0; // Decrease open balance for nave
          }
        }
      }
    }));

    // Process Khate Data
    Object.keys(khateData).forEach((pavtiNo) => {
      const khate = khateData[pavtiNo];
      if (khate && khate.date) {
        const khateDate = new Date(khate.date);
        // Check if the date is within the specified range
        if (khateDate >= start && khateDate <= end) {
          const formattedDate = khateDate.toISOString().split('T')[0];
          if (!combinedData[formattedDate]) {
            combinedData[formattedDate] = { jama: [], nave: [], khate: [], openBalance: 0 };
          }
          combinedData[formattedDate].khate.push({
            pavtiNo: pavtiNo,
            amount: parseFloat(khate?.amount) || 0,
            date: khate?.date || '',
            khate: khate?.khate || '',
            khatePrakar: khate?.khatePrakar || ''
          });
        }
      }
    });

    // Set open balance to each date
    Object.keys(combinedData).forEach((date) => {
      combinedData[date].openBalance = openBalance;
    });

    // Generate the report
    setKhatavaniReportData(combinedData);
    setKhatavaniReportData1(khateDetails);
    setShowKhatavaniReport(true);

  } catch (error) {
    console.error("Error fetching data:", error.response ? error.response.data : error.message);
    setAlertMessage(language === "English" ? "There was an error generating the report. Please try again."  : "अहवाल तयार करताना त्रुटी आली. कृपया पुन्हा प्रयत्न करा.");
  }
};




  const[TerijReportData,setTerijReportData] = useState("");
  const[TerijReportData1,setTerijReportData1] = useState("");
  

const handleTerijReport = async () => {
  try {
    if (!startDate || !endDate || !selectedItem) {
      setAlertMessage( language === "English" ? "Please select both start and end dates, and a Kird Prakar."  : "कृपया प्रारंभ आणि समाप्ती या दोन्ही तारखा आणि किर्द प्रकार निवडा");
      return;
    }

    // Firebase URLs for khate, jama, and nave
    const khateUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/khate.json`;
    const jamaUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`;
    const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`;

    // Fetch khate data
    const khateResponse = await axios.get(khateUrl);
    const khateData = khateResponse.data || {};
    const combinedData = {}; // Initialize combinedData

    // Process Khate Data
    Object.keys(khateData).forEach((pavtiNo) => {
      const khate = khateData[pavtiNo];
      if (khate && khate.date) {
        const khateDate = new Date(khate.date).toISOString().split('T')[0];
        
        // Check if the khateDate is within the selected date range
        if (new Date(khateDate) >= new Date(startDate) && new Date(khateDate) <= new Date(endDate)) {
          if (!combinedData[khateDate]) {
            combinedData[khateDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[khateDate].khate.push({
            khate: khate.khate || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Fetch jama data
    const jamaResponse = await axios.get(jamaUrl);
    const jamaData = jamaResponse.data || {};

    // Process Jama Data
    Object.keys(jamaData).forEach((pavtiNo) => {
      const jama = jamaData[pavtiNo];
      if (jama && jama.date) {
        const jamaDate = new Date(jama.date).toISOString().split('T')[0];

        // Check if the jamaDate is within the selected date range
        if (new Date(jamaDate) >= new Date(startDate) && new Date(jamaDate) <= new Date(endDate)) {
          if (!combinedData[jamaDate]) {
            combinedData[jamaDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[jamaDate].jama.push({
            Amount: jama.Amount || '',
            Remark: jama.Remark || '',
            credit1: jama.credit1 || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Fetch nave data
    const naveResponse = await axios.get(naveUrl);
    const naveData = naveResponse.data || {};

    // Process Nave Data
    Object.keys(naveData).forEach((pavtiNo) => {
      const nave = naveData[pavtiNo];
      if (nave && nave.date) {
        const naveDate = new Date(nave.date).toISOString().split('T')[0];

        // Check if the naveDate is within the selected date range
        if (new Date(naveDate) >= new Date(startDate) && new Date(naveDate) <= new Date(endDate)) {
          if (!combinedData[naveDate]) {
            combinedData[naveDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[naveDate].nave.push({
            Amount: nave.Amount || '',
            Remark: nave.Remark || '',
            debit1: nave.Debit1 || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Combine and total Jama amounts where credit1 matches khate
    const totalsByKhate = {};

    Object.keys(combinedData).forEach(date => {
      const { khate, jama, nave } = combinedData[date];

      jama.forEach(jamaEntry => {
        if (!totalsByKhate[jamaEntry.credit1]) {
          totalsByKhate[jamaEntry.credit1] = { credit: 0, debit: 0 };
        }
        totalsByKhate[jamaEntry.credit1].credit += parseFloat(jamaEntry.Amount || 0);
      });

      // Process Nave to Debit
      nave.forEach(naveEntry => {
        if (!totalsByKhate[naveEntry.debit1]) {
          totalsByKhate[naveEntry.debit1] = { credit: 0, debit: 0 };
        }
        totalsByKhate[naveEntry.debit1].debit += parseFloat(naveEntry.Amount || 0);
      });
    });

// Debug log for combinedData and totals
console.log("Combined Data for report:", combinedData);
console.log("Totals by Khate:", totalsByKhate);

// Generate the report and pass totalsByKhate to TerijReport function
// const pdfBlobUrl = TerijReport(combinedData, schoolData, totalsByKhate);
// window.open(pdfBlobUrl); // Open the PDF blob URL in a new tab

    // Navigate to the KirdReport page, passing filteredData and schoolData via state
    // navigate('/terij-report', { state: { combinedData, schoolData, totalsByKhate } });
    setTerijReportData(combinedData)
    setTerijReportData1(totalsByKhate)
    setShowTerijReport(true)



} catch (error) {
console.error("Error fetching data:", error.response ? error.response.data : error.message);
setAlertMessage(language === "English" ? "There was an error generating the report. Please try again."  : "अहवाल तयार करताना त्रुटी आली. कृपया पुन्हा प्रयत्न करा.");
}
};


const[ProfitLossReportData,setProfitLossReportData] = useState("");
const[ProfitLossReportData1,setProfitLossReportData1] = useState("");


const handleProfitLossReport = async () => {
  try {
    if (!startDate || !endDate || !selectedItem) {
      setAlertMessage( language === "English" ? "Please select both start and end dates, and a Kird Prakar."  : "कृपया प्रारंभ आणि समाप्ती या दोन्ही तारखा आणि किर्द प्रकार निवडा");
      return;
    }

    // Firebase URLs for khate, jama, and nave
    const khateUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/khate.json`;
    const jamaUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`;
    const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`;

    // Fetch khate data
    const khateResponse = await axios.get(khateUrl);
    const khateData = khateResponse.data || {};
    const combinedData = {}; // Initialize combinedData

    // Process Khate Data
    Object.keys(khateData).forEach((pavtiNo) => {
      const khate = khateData[pavtiNo];
      if (khate && khate.date) {
        const khateDate = new Date(khate.date).toISOString().split('T')[0];
        
        // Check if the khateDate is within the selected date range
        if (new Date(khateDate) >= new Date(startDate) && new Date(khateDate) <= new Date(endDate)) {
          if (!combinedData[khateDate]) {
            combinedData[khateDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[khateDate].khate.push({
            khate: khate.khate || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Fetch jama data
    const jamaResponse = await axios.get(jamaUrl);
    const jamaData = jamaResponse.data || {};

    // Process Jama Data
    Object.keys(jamaData).forEach((pavtiNo) => {
      const jama = jamaData[pavtiNo];
      if (jama && jama.date) {
        const jamaDate = new Date(jama.date).toISOString().split('T')[0];

        // Check if the jamaDate is within the selected date range
        if (new Date(jamaDate) >= new Date(startDate) && new Date(jamaDate) <= new Date(endDate)) {
          if (!combinedData[jamaDate]) {
            combinedData[jamaDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[jamaDate].jama.push({
            Amount: jama.Amount || '',
            Remark: jama.Remark || '',
            credit1: jama.credit1 || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Fetch nave data
    const naveResponse = await axios.get(naveUrl);
    const naveData = naveResponse.data || {};

    // Process Nave Data
    Object.keys(naveData).forEach((pavtiNo) => {
      const nave = naveData[pavtiNo];
      if (nave && nave.date) {
        const naveDate = new Date(nave.date).toISOString().split('T')[0];

        // Check if the naveDate is within the selected date range
        if (new Date(naveDate) >= new Date(startDate) && new Date(naveDate) <= new Date(endDate)) {
          if (!combinedData[naveDate]) {
            combinedData[naveDate] = { khate: [], jama: [], nave: [] };
          }

          combinedData[naveDate].nave.push({
            Amount: nave.Amount || '',
            Remark: nave.Remark || '',
            debit1: nave.Debit1 || '',
            pavtiNo: pavtiNo // Keep pavtiNo for reference
          });
        }
      }
    });

    // Combine and total Jama amounts where credit1 matches khate
    const totalsByKhate = {};

    Object.keys(combinedData).forEach(date => {
      const { khate, jama, nave } = combinedData[date];

      jama.forEach(jamaEntry => {
        if (!totalsByKhate[jamaEntry.credit1]) {
          totalsByKhate[jamaEntry.credit1] = { credit: 0, debit: 0 };
        }
        totalsByKhate[jamaEntry.credit1].credit += parseFloat(jamaEntry.Amount || 0);
      });

      // Process Nave to Debit
      nave.forEach(naveEntry => {
        if (!totalsByKhate[naveEntry.debit1]) {
          totalsByKhate[naveEntry.debit1] = { credit: 0, debit: 0 };
        }
        totalsByKhate[naveEntry.debit1].debit += parseFloat(naveEntry.Amount || 0);
      });
    });

    // Debug log for combinedData and totals
    console.log("Combined Data for report:", combinedData);
    console.log("Totals by Khate:", totalsByKhate);
    // Generate the report and pass totalsByKhate to TerijReport function
    // const pdfBlobUrl = ProfitLossReport(combinedData, schoolData, totalsByKhate);
    // window.open(pdfBlobUrl); // Open the PDF blob URL in a new tab
    // navigate('/profitloss-report', { state: { combinedData, schoolData, totalsByKhate } });

    setProfitLossReportData(combinedData)
    setProfitLossReportData1(totalsByKhate)
    setShowProfitLossReport(true)


  } catch (error) {
    console.error("Error fetching data:", error.response ? error.response.data : error.message);
    setAlertMessage( language === "English" ? "There was an error generating the report. Please try again"  : "अहवाल तयार करताना त्रुटी आली. कृपया पुन्हा प्रयत्न करा.");
  }
};

const handleWarningClick = () => {
resetForm();
  setShowTable1(true);  // This will execute first
  setTimeout(() => {
    setAlertMessage(language === "English" 
      ? "Firstly, select the transaction type (Credit Or Debit )" 
      : "कृपया व्यवहार प्रकार निवडा (जमा किंवा नावे)"); // Marathi version of the setAlertMessage
  }, 0);  // Set a delay of 0ms to ensure setAlertMessage shows after state change
  setShowCardsBox(false);
  
};


   
    const [currentPavatiForUpadte, setcurrentPavatiForUpadte] = useState('')
 // Function to fetch and update pavtiNo based on the transaction type
 const fetchAndUpdatePavtiNo = async (type) => {
  const lastPavtiNoKey = type === 'credit' ? 'lastjamaNo' : 'lastnaveNo';
  try {
    const existingShillak = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/AkherAkherSaved.json`);
    const existingShillakData = await existingShillak.json();

    if (!existingShillakData) {
      // If "31-03-Akher shillak" is not saved, include it in the dropdown
      setTodayAvailableKhate((prevKhate) => [...prevKhate, { id: prevKhate.length + 1, khate: "31-03-Akher shillak" }]);
    }

    // Fetch the last used pavtiNo
    const lastPavtiNoResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${lastPavtiNoKey}.json`);
    const lastPavtiNoData = await lastPavtiNoResponse.json();
    const newPavtiNo = (lastPavtiNoData?.[lastPavtiNoKey] || 0) + 1;

    setCurrentPavtiNo(newPavtiNo);
    setcurrentPavatiForUpadte(newPavtiNo)
  } catch (error) {
    console.error("Error fetching pavtiNo:", error);
  }
};


// Handle when the transaction type changes (credit or debit)
const handleTransactionTypeChange = (type) => {
  if (type == "credit") {
    setIsCreditSelected(true);
  } else {
    setIsCreditSelected(false);
  }

  setTransactionType(type);
  setIsCreditSelected(type === 'credit');
  setIsDebitSelected(type === 'debit');
  setSelectedPavtiNos([]);
  setShowCheckbox(true);
  setAmount("");      // Clear the amount field
  setKhatePrakar(""); // Reset account type selection
  setCredit1("");     // Reset credit account field
  setDebit1("");  
  // resetForm();
};


const [akherAkherSaved, setAkherAkherSaved] = useState(false);

const isCreditDisabled = khatePrakar === 'ByCash' &&  isDebitSelected;
const isDebitDisabled = khatePrakar === 'ByCash' && isCreditSelected;

// Fetch akherAkherSaved status from Firebase when the component mounts
useEffect(() => {
  const fetchAkherAkherSavedStatus = async () => {
    try {
      const response = await fetch(
        `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/AkherAkherSaved.json`
      );
      const data = await response.json();
      setAkherAkherSaved(data || false);  // Set true or false based on data
    } catch (error) {
      console.error("Error fetching akherAkherSaved status:", error);
    }
  };

  fetchAkherAkherSavedStatus();
}, [udiseNumber, selectedItem]);

const handleSave = async () => {
  setShowloadingimg(true)
  let missingFields = [];

  if (!selectedYear1) missingFields.push('Year');
  if (!selectedDate) missingFields.push('Date');
  if (!transactionType) missingFields.push('Transaction Type');
  if (!khatePrakar) missingFields.push('Khate Prakar');
  if (!amount) missingFields.push('Amount');
 console.log("khatePrakar",khatePrakar);
 
  if (khatePrakar === 'ByCash') {
    if (!credit1 && !debit1) missingFields.push('Credit Account Or Debit Account');
  }else{
    if (!credit1 ) missingFields.push('Credit Account');
    if (!debit1 ) missingFields.push('Debit Account');
  }
  if (missingFields.length > 0) {
    setAlertMessage(`Missing field: ${missingFields.join(', ')}`);
    setShowloadingimg(false)
    return;
  }
  

  const transactionPaths = {
    jama: `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama`,
    nave: `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave`,
  };

  const lastPavtiNoKeys = {
    jama: `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastjamaNo.json`,
    nave: `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastnaveNo.json`,
  };

  try {
    // Save "AkherAkherSaved" if "31-03-Akher shillak" is selected
    if (credit1 === "31-03-Akher shillak") {
      await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/AkherAkherSaved.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(true),
      });
      setLabel3(amount); 
      setTodayAvailableKhate((prevKhate) => prevKhate.filter((item) => item.khate !== "31-03-Akher shillak"));
       // Save "31-03-Akher shillak" with Pavti No set to 0
       const data = {
        financialYear: selectedYear1,
        date: selectedDate,
        khatePrakar: khatePrakar,
        Amount: amount,
        Remark: remark,
        credit1: "31-03-Akher shillak",
        Debit1: debit1 || null,
        pavtiNo: 0,  // Pavti No set to 0 for "31-03-Akher shillak"
        currenttime: new Date().toLocaleTimeString('en-GB', { hour12: false })
      };

      await fetch(`${transactionPaths.jama}/0.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      setAlertMessage(language === "English" ? "Saved successfully for 31-03-Akher shillak with Pavti No 0 in jama node! " : "जमा नोडमध्ये पावती क्रमांक 0 सह 31-03-अखेर शिल्लक साठी यशस्वीरित्या जतन केले!");
      setShowloadingimg(false)
      return;
    }
    

    // Fetch current lastjamaNo and lastnaveNo
    const [lastJamaResponse, lastNaveResponse] = await Promise.all([
      fetch(lastPavtiNoKeys.jama),
      fetch(lastPavtiNoKeys.nave),
    ]);

    const [lastJamaData, lastNaveData] = await Promise.all([
      lastJamaResponse.json(),
      lastNaveResponse.json(),
    ]);

    const lastJamaNo = lastJamaData?.lastjamaNo || 0;
    const lastNaveNo = lastNaveData?.lastnaveNo || 0;

    // Prepare data to save
    const data = {
      financialYear: selectedYear1,
      date: selectedDate,
      khatePrakar: khatePrakar,
      Amount: amount,
      Remark: remark,
      credit1: credit1 || null,
      Debit1: debit1 || null,
      currenttime: new Date().toLocaleTimeString('en-GB', { hour12: false })
    };

    // Check if khatePrakar is "By Cash"
    if (khatePrakar === "ByCash") {
      if (credit1) {
        // Save only to jama node
        const newJamaNo = lastJamaNo + 1;
        await fetch(`${transactionPaths.jama}/${newJamaNo}.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, pavtiNo: newJamaNo }),
        });

        // Update lastjamaNo
        await fetch(lastPavtiNoKeys.jama, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lastjamaNo: newJamaNo }),
        });
        setAlertMessage(language === "English" ? "Saved successfully " : "यशस्वीरित्या जतन केले ");
        console.log("currentPavtiNo",currentPavtiNo);
        setShowloadingimg(false)
        setCurrentPavtiNo(currentPavtiNo+1)
      }

      if (debit1) {
        // Save only to nave node
        const newNaveNo = lastNaveNo + 1;
        await fetch(`${transactionPaths.nave}/${newNaveNo}.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, pavtiNo: newNaveNo }),
        });

        // Update lastnaveNo
        await fetch(lastPavtiNoKeys.nave, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lastnaveNo: newNaveNo }),
        });
        setAlertMessage(language === "English" ? "Saved successfully " : "यशस्वीरित्या जतन केले ");
        console.log("currentPavtiNo",currentPavtiNo);
        setShowloadingimg(false)
        
        setCurrentPavtiNo(currentPavtiNo+1)
      }
      
      const khateResponse = await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate.json`);
      const khateData = await khateResponse.json();
  
      // Loop through each ID in khate
      for (const id in khateData) {
        if (khateData.hasOwnProperty(id)) {
          const khateEntry = khateData[id];
  
          // Check if khate name matches credit1 or debit1
          if (khateEntry.khate === credit1) {
            await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate/${id}.json`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ transactionDone: true }),
            });
          }
  
          if (khateEntry.khate === debit1) {
            await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate/${id}.json`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ transactionDone: true }),
            });
          }
        }
      }
    } else {
      // For other khatePrakar options, allow both credit and debit
      if (credit1) {
        const newJamaNo = lastJamaNo + 1;
        await fetch(`${transactionPaths.jama}/${newJamaNo}.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, pavtiNo: newJamaNo }),
        });

        await fetch(lastPavtiNoKeys.jama, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lastjamaNo: newJamaNo }),
        });
      }

      if (debit1) {
        const newNaveNo = lastNaveNo + 1;
        await fetch(`${transactionPaths.nave}/${newNaveNo}.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, pavtiNo: newNaveNo }),
        });

        await fetch(lastPavtiNoKeys.nave, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lastnaveNo: newNaveNo }),
        });
      }

      

      const khateResponse = await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate.json`);
      const khateData = await khateResponse.json();
      // Loop through each ID in khate
      for (const id in khateData) {
        if (khateData.hasOwnProperty(id)) {
          const khateEntry = khateData[id];
  
          // Check if khate name matches credit1 or debit1
          if (khateEntry.khate === credit1) {
            await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate/${id}.json`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ transactionDone: true }),
            });
          }
  
          if (khateEntry.khate === debit1) {
            await fetch(`${firebaseBaseUrl}/${selectedItemName}/khate/${id}.json`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ transactionDone: true }),
            });
          }
        }
      }
      setAlertMessage(language === "English" ? "Saved successfully " : "यशस्वीरित्या जतन केले ");
      console.log("currentPavtiNo",currentPavtiNo);
      setShowloadingimg(false)

      setCurrentPavtiNo(currentPavtiNo+1)
    }
   
     // Update the savedPavtiNos array with the new Pavti No
     setSavedPavtiNos((prevSavedPavtiNos) => [...prevSavedPavtiNos, newPavtiNo]);

     // Immediately show the Pavti No in the checkbox
     setShowCheckbox(true);
    const newPavtiNo = lastJamaNo + 1; 
    setAlertMessage(language === "English" ? "Save Successful! Click OK to refresh the data. " : "यशस्वीरित्या जतन केले डेटा रिफ्रेश करण्यासाठी ओके क्लिक करा. ");
    setShowloadingimg(false)
    // setCurrentPavtiNo(currentPavtiNo); 

    // setPavtiNo('');
    setKhatePrakar('');
    setAmount('');
    setRemark('');
    setCredit1('');
    setDebit1('');
    // setPavtiNo(CurrentPvtaiNoforUpdate);

    // await fetchTableData(); 

  } catch (error) {
    console.error("Error saving data:", error);
    setShowloadingimg(false)

  }
};



// Fetch Pavti data
const fetchPavtiData = async (pavtiNos) => {
  try {
    let dataPromises;

    // Determine if `pavtiNos` is a single value or an array
    if (Array.isArray(pavtiNos)) {
      // Fetch data for multiple `pavtiNos`
      dataPromises = pavtiNos.map(pavtiNo => 
        fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${transactionPath}/${pavtiNo}.json`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Failed to fetch data for Pavti No: ${pavtiNo}`);
            }
            return response.json();
          })
          .then(data => ({ pavtiNo, data }))
          
      );
    } else {
      // Fetch data for a single `pavtiNo`
      dataPromises = [
        fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${transactionPath}/${pavtiNos}.json`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`Failed to fetch data for Pavti No: ${pavtiNos}`);
            }
            return response.json();
          })
          .then(data => ({ pavtiNo: pavtiNos, data }))
      ];
    }

    const results = await Promise.all(dataPromises);

    // Debug logs to check what's being fetched
    console.log("Results:", results);

    const fetchedData = results.reduce((acc, { pavtiNo, data }) => {
      if (data) {
        acc.push(data);

        // Update individual state values
        if (Array.isArray(pavtiNos) && pavtiNos.length === 1) {
          // Update state only if there's a single pavtiNo
          setSelectedYear1(data.financialYear || "");
          setSelectedDate(data.date || "");
          setAmount(data.Amount || "");
          setRemark(data.Remark || "");
          setCredit1(data.credit1 || "");
          setDebit1(data.debit1 || "");
          setKhatePrakar(data.khatePrakar || "");
          setKhatePrakar(data.pavtiNo || "");

        }

        // Special handling if specific values need to be added
        if (data.credit1 === "31-03-Akher shillak") {
          setTodayAvailableKhate(prevKhate => {
            const akherShillakExists = prevKhate.some(item => item.khate === "31-03-Akher shillak");
            if (!akherShillakExists) {
              return [...prevKhate, { id: prevKhate.length + 1, khate: "31-03-Akher shillak" }];
            }
            return prevKhate;
          });
        }
      } else {
        console.log(`No data found for Pavti No: ${pavtiNo}`);
      }
      return acc;
    }, []);

    // Debug log to check what data is being set
    console.log("Fetched Data Array:", fetchedData);

    // Update your state with fetched data
    setPavtiDataArray(fetchedData);
    
  } catch (error) {
    console.error("Error fetching Pavti data:", error);
  }
};



const handlePavtiSelection = (pavtiNo) => {
  setSelectedPavtiNos((prevPavtiNos) => {
    if (prevPavtiNos.includes(pavtiNo)) {
      // If Pavti No is already selected, remove it
      const updatedPavtiNos = prevPavtiNos.filter((no) => no !== pavtiNo);
      // Update the fetched data accordingly
      setPavtiData((prevPavtiData) => {
        const updatedPavtiData = { ...prevPavtiData };
        delete updatedPavtiData[pavtiNo];
console.log("updatedPavtiData",updatedPavtiData);

        return updatedPavtiData;
      });
console.log("updatedPavtiNos",updatedPavtiNos);
      return updatedPavtiNos;
    } else {
      // If Pavti No is not selected, add it
      return [...prevPavtiNos, pavtiNo];
    }
  });
  setCurrentPavtiNo(pavtiNo);
};


// useEffect to fetch and update pavtiNo on page load
useEffect(() => {
  if (transactionType) {
    fetchAllPavtiNumbers(transactionType);
    fetchAndUpdatePavtiNo(transactionType)
  }
}, [transactionType]);

const fetchAllPavtiNumbers = async (type) => {
  const transactionPath = type === 'credit' ? 'jama' : 'nave'; // Determine the node based on transaction type
  const pavtiNumbers = [];

  try {
      // Fetch data from the Firebase node (jama or nave)
      const response = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${transactionPath}.json`);
      const data = await response.json();

      // Check if data exists and extract the pavti numbers
      if (data) {
          Object.keys(data).forEach((pavtiNo) => {
              pavtiNumbers.push(pavtiNo); // Store pavti numbers in the array
          });
      }

      // Update state with the fetched Pavti numbers
      setSavedPavtiNos(pavtiNumbers);
      console.log("pavtiNumbers", pavtiNumbers);
  } catch (error) {
      console.error("Error fetching Pavti numbers:", error);
  }
};

  useEffect(() => {
    if (selectedPavtiNos.length > 0) {
      fetchPavtiData(selectedPavtiNos);
    }
  }, [selectedPavtiNos]);
  
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Select all items except those with value '0'
      setSelectedPavtiNos(savedPavtiNos.filter((no) => no !== '0'));
    } else {
      // Deselect all items
      setSelectedPavtiNos([]);
    }
  };

  const handleCloseButtonClick = () => {
    setShowTable(false);
    setBlurBackground(false);
    setShowOuterBox(true);
};

const handleCloseButtonClick1 = () => {
  console.log("clicked");
  setShowCardsBox(true)
  setShowTable1(false);
  setBlurBackground(false);
  setShowOuterBox(true);
};

const handleCloseButtonClick2 = () => {
window.location.reload()
};

const handleCloseButtonClick3 = () => {
  window.location.reload()
};

const [matchingPavtiNo, setMatchingPavtiNo] = useState('');

const handleUpdate = async () => {
  console.log("Update button clicked");

  // Define the updated data structure
  const updatedData = {
    Amount: amount,
    Debit1: debit1,
    credit1: credit1,
    Remark: remark,
    khatePrakar: khatePrakar,
    date: date,
    financialYear: financialYear,
    pavtiNo: currentPavtiNo,
    currenttime: new Date().toLocaleTimeString(), // Keep the current time
  };

  // Check if the credit or debit radio button is selected
  if (isCreditSelected) {
    // Firebase URL for the 'jama' (credit) side
    const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama/${currentPavtiNo}.json`;
    console.log("firebaseUrl", firebaseUrl);

    try {
      // Fetch Nave data to get matching entries
      const naveResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`);
      const naveData = await naveResponse.json();

      // If there are matching entries, update the first one
      if (matchingPavtiNo > 0) {
        const existingNaveData = naveData[matchingPavtiNo];

        // Check if khatePrakar changed to "ByCash"
        if (existingNaveData && existingNaveData.khatePrakar !== "ByCash" && khatePrakar === "ByCash") {
          // Delete the matching entry in the nave node
          const deleteNaveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${matchingPavtiNo}.json`;
          const deleteResponse = await fetch(deleteNaveUrl, {
            method: 'DELETE',
          });

          if (deleteResponse.ok) {
            console.log("Deleted matching entry in 'nave'");
          } else {
            console.error("Failed to delete matching entry in 'nave':", deleteResponse.statusText);
          }
        } else {
          // Include existing pavtiNo in updatedData
          const updatedNaveData = {
            ...updatedData,
            pavtiNo: existingNaveData.pavtiNo // Keep the existing pavtiNo
          };

          // Send PUT request to update the corresponding 'nave' node (debit)
          const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${matchingPavtiNo}.json`;
          const updateResponse = await fetch(naveUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedNaveData), // Include pavtiNo in updatedData
          });

          if (updateResponse.ok) {
            console.log("Data updated successfully in 'nave'");
            alert("Updated Successfully in 'nave'");
          } else {
            console.error("Failed to update data in 'nave':", updateResponse.statusText);
          }
        }
      } else {
        console.log("No matching Nave entries found to update.");

        const lastnaveResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastnaveNo.json`);
        const lastnaveData = await lastnaveResponse.json();
        console.log("lastnaveData",lastnaveData);
        
        const lastnaveNo = lastnaveData?.lastnaveNo || 0;
        console.log("lastnaveNodata",lastnaveNo);
         // Fetch current jama data
      const existingJamaDataResponse = await fetch(firebaseUrl);
      const currentJamaData = await existingJamaDataResponse.json();

    if (currentJamaData.khatePrakar === "ByCash" && khatePrakar !== "ByCash") {
        // Update existing jama node with new data
        const updatedJamaData = {
          ...updatedData,
          pavtiNo: currentPavtiNo // Keep the current pavtiNo
        };

        await fetch(firebaseUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedJamaData),
        });

        console.log("Data updated successfully in 'jama'");
        alert("Updated Successfully in 'jama'");

        // Create a new entry in the nave node
        const newNaveNo = lastnaveNo + 1; // Generate new pavtiNo
        const newNaveData = {
          ...updatedData,
          pavtiNo: newNaveNo,
        };

        await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${newNaveNo}.json`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newNaveData),
        });

        console.log("Created new Nave entry:", newNaveData);

        // Update lastjamaNo
        await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastnaveNo.json`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ lastnaveNo: lastnaveNo + 1 }),
        });
      } 

      }

      // Send PUT request to update the 'jama' node (credit)
      const response = await fetch(firebaseUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData), // Do not include pavtiNo in updatedData
      });

      if (response.ok) {
        console.log("Data updated successfully in 'jama'");
        alert("Updated Successfully in 'jama'");
        setCurrentPavtiNo(currentPavatiForUpadte);
        // Reset the form after updating
        resetForm();
        console.log("updatedData", updatedData);
      } else {
        console.error("Failed to update data in 'jama':", response.statusText);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  } else if (isDebitSelected) {
    // Firebase URL for the 'jama' (credit) side
    const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${currentPavtiNo}.json`;
    console.log("firebaseUrl", firebaseUrl);

    try {
      // Fetch Nave data to get matching entries
      const naveResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`);
      const naveData = await naveResponse.json();

      // If there are matching entries, update the first one
      if (matchingPavtiNo > 0) {
        const existingNaveData = naveData[matchingPavtiNo];

        // Check if khatePrakar changed to "ByCash"
        if (existingNaveData && existingNaveData.khatePrakar !== "ByCash" && khatePrakar === "ByCash") {
          // Delete the matching entry in the nave node
          const deleteNaveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama/${matchingPavtiNo}.json`;
          const deleteResponse = await fetch(deleteNaveUrl, {
            method: 'DELETE',
          });

          if (deleteResponse.ok) {
            console.log("Deleted matching entry in 'jama'");
          } else {
            console.error("Failed to delete matching entry in 'jama':", deleteResponse.statusText);
          }
        } else {
          // Include existing pavtiNo in updatedData
          const updatedNaveData = {
            ...updatedData,
            pavtiNo: existingNaveData.pavtiNo // Keep the existing pavtiNo
          };

          // Send PUT request to update the corresponding 'nave' node (debit)
          const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama/${matchingPavtiNo}.json`;
          const updateResponse = await fetch(naveUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedNaveData), // Include pavtiNo in updatedData
          });

          if (updateResponse.ok) {
            console.log("Data updated successfully in 'jama'");
            alert("Updated Successfully in 'jama'");
          } else {
            console.error("Failed to update data in 'jama':", updateResponse.statusText);
          }
        }
      } else {
        console.log("No matching Jama entries found to update.");

        const lastjamaResponse = await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastjamaNo.json`);
        const lastjamaData = await lastjamaResponse.json();
        console.log("lastjamaData",lastjamaData);
        
        const lastjamaNo = lastjamaData?.lastjamaNo || 0;
        console.log("lastjamaNodata",lastjamaNo);
         // Fetch current jama data
      const existingnaveDataResponse = await fetch(firebaseUrl);
      const currentNaveData = await existingnaveDataResponse.json();
      
    if (currentNaveData.khatePrakar === "ByCash" && khatePrakar !== "ByCash") {
        // Update existing jama node with new data
        const updatedNaveData = {
          ...updatedData,
          pavtiNo: currentPavtiNo // Keep the current pavtiNo
        };

        await fetch(firebaseUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedNaveData),
        });

        console.log("Data updated successfully in 'nave'");
        alert("Updated Successfully in 'nave'");

        // Create a new entry in the nave node
        const newJamaNo = lastjamaNo + 1; // Generate new pavtiNo
        const newJamaData = {
          ...updatedData,
          pavtiNo: newJamaNo,
        };

        await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${newJamaNo}.json`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newJamaData),
        });

        console.log("Created new Jama entry:", newJamaData);

        // Update lastjamaNo
        await fetch(`https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/lastjamaNo.json`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ lastjamaNo: lastjamaNo + 1 }),
        });
      } 

      }

      // Send PUT request to update the 'jama' node (credit)
      const response = await fetch(firebaseUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData), // Do not include pavtiNo in updatedData
      });

      if (response.ok) {
        console.log("Data updated successfully in 'nave'");
        alert("Updated Successfully in 'nave'");
        setCurrentPavtiNo(currentPavatiForUpadte);
        // Reset the form after updating
        resetForm();
        console.log("updatedData", updatedData);
      } else {
        console.error("Failed to update data in 'nave':", response.statusText);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }
};



const handleDelete1 = async () => {
  console.log("Delete button clicked");
  console.log("currentPavtiNo", currentPavtiNo);

  // Check if a Pavti No is fetched, and if yes, delete it directly
  if (currentPavtiNo) {
    try {
      // Construct the Firebase URL for deletion based on fetched Pavti No
      const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${transactionPath}/${currentPavtiNo}.json`;

      const response = await fetch(firebaseUrl, {
        method: 'DELETE'
      });

      if (response.ok) {
        console.log(`Data for Pavti No ${currentPavtiNo} deleted successfully`);

        // Remove the deleted Pavti No from the saved list
        let updatedSavedPavtiNos = savedPavtiNos.filter(
          (num) => num !== currentPavtiNo
        );

        // Also remove the Pavti No from the selected checkboxes
        let updatedSelectedPavtiNos = selectedPavtiNos.filter(
          (num) => num !== currentPavtiNo
        );

        // Update state with the new list of saved Pavti Nos
        setSavedPavtiNos(updatedSavedPavtiNos);
        setSelectedPavtiNos(updatedSelectedPavtiNos);
        

        // Automatically set the next Pavti No (if available) after deletion
        if (updatedSavedPavtiNos.length > 0) {
          // Find the next Pavti No greater than the current one, avoiding "0"
          const sortedPavtiNos = updatedSavedPavtiNos
            .filter((num) => num !== "0") // Avoid "0" values
            .sort((a, b) => a - b); // Sort numerically

          // Find the next Pavti No after the deleted one
          const nextPavtiNo = sortedPavtiNos.find((num) => num > currentPavtiNo);

          if (nextPavtiNo) {
            setCurrentPavtiNo(nextPavtiNo);  // Set the valid next Pavti No
          } else {
            setCurrentPavtiNo(sortedPavtiNos[0]);  // If no next Pavti No, set the first one in the list
          }
        } else {
          setCurrentPavtiNo("");  // No Pavti Nos left, clear the input
        }

        // Clear selected Pavti Nos and reset the form after deletion
        setSelectedPavtiNos([]);
        setAlertMessage("Deleted successfully!");
        resetForm();
        setIsDataFetched(false);
        setIsExistingPavtiNo(false);

        // Check if khatePrakar is not "ByCash" and delete from the other node
        if (transactionType === 'credit' && khatePrakar !== "ByCash") {
          await deleteMatchingNaveEntries(matchingPavtiNo);
        } else if (transactionType === 'debit' && khatePrakar !== "ByCash") {
          await deleteMatchingJamaEntries(matchingPavtiNo);
        }

      } else {
        console.error("Failed to delete data:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  } else {
    // Handle the case where no Pavti No is fetched
    console.error("No Pavti No specified for deletion");
  }
};

// Function to delete matching entries from the Nave node
const deleteMatchingNaveEntries = async (pavtiNo) => {
  try {
    const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave/${pavtiNo}.json`;
    const response = await fetch(naveUrl, {
      method: 'DELETE'
    });

    if (response.ok) {
      console.log(`Matching Nave entry for Pavti No ${pavtiNo} deleted successfully`);
    } else {
      console.error("Failed to delete matching Nave entry:", response.statusText);
    }
  } catch (error) {
    console.error("Error deleting matching Nave entry:", error);
  }
};


// Function to delete matching entries from the Jama node
const deleteMatchingJamaEntries = async (pavtiNo) => {
  try {
    const jamaUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama/${pavtiNo}.json`;
    const response = await fetch(jamaUrl, {
      method: 'DELETE'
    });

    if (response.ok) {
      console.log(`Matching Jama entry for Pavti No ${pavtiNo} deleted successfully`);
    } else {
      console.error("Failed to delete matching Jama entry:", response.statusText);
    }
  } catch (error) {
    console.error("Error deleting matching Jama entry:", error);
  }
}; 

//************************************************************************************************************************************ */
const handlePavtiNoChange = async (e) => {
  const pavtiNo = e.target.value;
  setCurrentPavtiNo(pavtiNo);

  if (pavtiNo) {
    const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/${transactionPath}/${pavtiNo}.json`;

    try {
      console.log("Fetching data from:", firebaseUrl);
      const response = await fetch(firebaseUrl);

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched data:", data);

        if (data) {
          setPavtiNo(data.pavtiNo || "");
          setAmount(data.Amount || "");
          setDebit1(data.Debit1 || "");
          setCredit1(data.credit1 || "");
          setRemark(data.Remark || "");
          setKhatePrakar(data.khatePrakar || "");
          setSelectedDate(data.date || "");
          // setFinancialYear(data.financialYear || "");
          setSelectedYear1(data.financialYear || "")
          setIsDataFetched(true);
          setIsExistingPavtiNo(true);
console.log("transactionType",transactionType);
console.log("khatePrakar",khatePrakar);

           // Update the Jama node if the transaction type is not "ByCash"
           if (transactionType === 'debit' && khatePrakar !== "ByCash") {
            await updateJamaNode(data.date, data.currenttime);
          }


          // Check if the transaction type is not "ByCash"
          if (transactionType === 'credit' && khatePrakar !== "ByCash") {
            // Fetch Nave data with the same date and current time
            await fetchNaveData(data.date, data.currenttime);
          }
        } else {
          resetForm();
          setIsDataFetched(false);
          setIsExistingPavtiNo(false);
        }
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};

// New function to fetch Nave data based on date and current time
const fetchNaveData = async (date, currentTime) => {
  const naveUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`;
  console.log("date", date);
  console.log("currentTime", currentTime);

  try {
    const response = await fetch(naveUrl);
    if (response.ok) {
      const naveData = await response.json();
      console.log("Fetched Nave data:", naveData);
      
      // Filter Nave data to find entries with the same date and current time
      const matchingNaveEntries = Object.keys(naveData).filter((pavtiNo) => {
        const entry = naveData[pavtiNo];
        console.log("entry", entry);
        
        // Ensure entry is not null before accessing its properties
        if (entry) {
          return entry.date === date && entry.currenttime === currentTime;
        }
        return false; // Skip null entries
      });
      
      // Log the matching entries to the console
      if (matchingNaveEntries.length > 0) {
        console.log("Matching Nave entries:", matchingNaveEntries);
        // Store the first matching Pavti number in state
        setMatchingPavtiNo(matchingNaveEntries[0]); // This will store '4' if matchingNaveEntries is ['4']
      } else {
        console.log("No matching Nave entries found.");
        setMatchingPavtiNo(''); // Reset state if no matches found
      }
    } else {
      console.error("Failed to fetch Nave data:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching Nave data:", error);
  }
};

const updateJamaNode  = async (date, currentTime) => {
  const jamaUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`;
  console.log("date", date);
  console.log("currentTime", currentTime);

  try {
    const response = await fetch(jamaUrl);
    if (response.ok) {
      const jamaData = await response.json();
      console.log("Fetched Nave data:", jamaData);
      
      // Filter Nave data to find entries with the same date and current time
      const matchingNaveEntries = Object.keys(jamaData).filter((pavtiNo) => {
        const entry = jamaData[pavtiNo];
        console.log("entry", entry);
        
        // Ensure entry is not null before accessing its properties
        if (entry) {
          return entry.date === date && entry.currenttime === currentTime;
        }
        return false;
      });
      
      // Log the matching entries to the console
      if (matchingNaveEntries.length > 0) {
        console.log("Matching jama entries:", matchingNaveEntries);
        // Store the first matching Pavti number in state
        setMatchingPavtiNo(matchingNaveEntries[0]); 
      } else {
        console.log("No matching Nave entries found.");
        setMatchingPavtiNo(''); // Reset state if no matches found
      }
    } else {
      console.error("Failed to fetch Nave data:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching Nave data:", error);
  }
};

// Function to reset form fields if no data is found
const resetForm = () => {
  setAmount("");
  setDebit1("");
  setCredit1("");
  setRemark("");
  setKhatePrakar("");
  setDate("");
  setFinancialYear("");
  setIsDataFetched(false);
  setIsExistingPavtiNo(false);
  setPavtiDataArray([]);
  
  // Set `pavtiNo` to the current pavti number
  setPavtiNo(currentPavatiForUpadte);
  setCurrentPavtiNo(currentPavatiForUpadte)
  console.log("setPavtiNo",setPavtiNo);
  console.log("currentPavatiForUpadte",currentPavatiForUpadte);
  
};



// Function to fetch the latest Pavti No from Firebase
const fetchLatestPavtiNo = async () => {
  const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/jama.json`;

  try {
    const response = await fetch(firebaseUrl);
    if (response.ok) {
      const data = await response.json();
      if (data) {
        // Assuming the Pavti No is the last key or you have a logic to get the latest Pavti No
        const pavtiNos = Object.keys(data);
        const latestPavtiNo = Math.max(...pavtiNos); // Get the latest Pavti No
        
        setCurrentPavtiNo(latestPavtiNo.toString()); // Set the current Pavti No in state

        // Call the handlePavtiNoChange to fetch and display data for the latest Pavti No
        handlePavtiNoChange({ target: { value: latestPavtiNo.toString() } });
      } else {
        console.log("No Pavti Nos found");
      }
    }
  } catch (error) {
    console.error("Error fetching Pavti No:", error);
  }
};

// Function to handle radio button change
const handleRadioChange = (e) => {
  if (e.target.value === "credit") {
    fetchLatestPavtiNo(); // Automatically fetch the latest Pavti No when 'credit' is selected
  }
};


useEffect(() => {
  console.log("useEffect triggered");
  if (!selectedItem || !udiseNumber) return;

  // Format today's date as YYYY-MM-DD
  const todayDate = new Date().toISOString().slice(0, 10);
  const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/jama.json`;

  console.log("Fetching from URL:", firebaseUrl);

  // Fetch data from Firebase
  fetch(firebaseUrl)
    .then((response) => response.json())
    .then((data) => {
      console.log("Fetched data:", data); // Log fetched data
      if (data) {
        const pavtiNumbers = Object.keys(data);
        let todayCount = 0;

        pavtiNumbers.forEach((pavtiNo) => {
          const pavtiData = data[pavtiNo];
          if (pavtiData) {
            console.log("Pavti date:", pavtiData.date, "Today date:", todayDate); // Log both dates
            if (pavtiData.date === todayDate) {
              todayCount++;
            }
          } else {
            console.warn(`No data found for Pavti No: ${pavtiNo}`);
          }
        });

        setLabel1(todayCount);
        console.log("Total today's pavti:", todayCount);
      } else {
        setLabel1(0);
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}, [selectedItem, udiseNumber]);




useEffect(() => {
  if (!selectedItem || !udiseNumber) return;

  // Format today's date as YYYY/MM/DD
  const todayDate = new Date().toISOString().slice(0, 10);

  // Firebase URL
  const firebaseUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/account/kird/${selectedItem}/nave.json`;

  console.log("Fetching from URL:", firebaseUrl); // Log the URL being fetched

  // Fetch data from Firebase using fetch API
  fetch(firebaseUrl)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        // Iterate over each pavtiNo in the fetched data
        const pavtiNumbers = Object.keys(data);
        
        let todayCount = 0; // Counter for today's pavti
        pavtiNumbers.forEach((pavtiNo) => {
          const pavtiData = data[pavtiNo]; // Get the data for each pavtiNo

          // Check if pavtiData is defined before accessing its properties
          if (pavtiData) {

            // Check if the date matches today's date
            if (pavtiData.date === todayDate) {
              todayCount++; 
            }
          } else {
            console.warn(`No data found for Pavti No: ${pavtiNo}`);
          }
        });

        // Set the count of today's receipts
        setLabel5(todayCount);
        console.log("Total today's Voucher:", todayCount); // Log total count
      } else {
        setLabel5(0); // No data found, set count to 0
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
}, [selectedItem, udiseNumber]);





return (
  <>
    <Sidebar/>
    {showloadingimg && (
      <div style={{position:'fixed', top:'47%', left:'47%',justifyContent:'center', alignItems:'center', zIndex:'99999'}}>
        <img style={{width:'100px', height:'100px'}} src={loadingImg} alt="Loading..." />
      </div>
    )}

    <div
      className="main-content-of-page"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    > <AlertMessage message={alertMessage} show={showAlert}/>

      <>
        {selectedItem ? (
          <div></div>
        ) : (
          <div className={`kirdtable-con ${showPopup ? "blur" : ""}`}>
            {/* Table and dropdown container */}
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <div className="kirddropdown-container">
                      {guna2ComboBoxItems.length > 0 ? (
                        
                        <select
                          value={selectedItem}
                          onChange={handleSelectItem}
                          className="kirdselect-dropdown"
                        >
                          <option value="">{language === "English" ? "Select Kird Prakar " : "किर्द प्रकार निवडा"}</option>
{guna2ComboBoxItems.map((item, index) => {
  // Check if the item should be excluded
  if (item.kirdtype === "khate") {
    return null; // Skip rendering this option
  }
  return (
    <option key={index} value={item.kirdtype}>
      {item.kirdtype}
    </option>
  );
})}

                        </select>
                      ) : null}
                      <div style={{ marginTop: '10px' }}></div>
                      <button
                        onClick={() => setShowPopup(true)}
                        className="kirdadd-button"
                      >
                        {language === "English" ? "Add New Kird" : "नवीन किर्द जोडा"}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {/* Popup Container (outside cards-box) */}
        {showPopup && (
          <div className="kirdpopup-container">
            <div className="kirdpopup">
            {showFormInPopup ? (
    
              <div className="kirdcenter-text" style={{ textAlign: "center",marginTop: "10px", marginBottom:'20px' }}> {language === "English" ? "Edit Kird Prakar" : "किर्द प्रकार संपादित करा"}</div>
  ) : (
    <div className="kirdcenter-text" style={{ textAlign: "center",marginTop: "10px", marginBottom:'20px' }}> {language === "English" ? "Enter Kird Prakar" : "किर्द प्रकार प्रविष्ट करा"}</div>
  )}

              <div style={{width:'60%',display:"flex"}}>
                <input
                  type="text"
                  value={editingItem ? newKirdType : newKirdPrakar}
                  onChange={(e) => {
                    if (editingItem) {
                      setNewKirdType(e.target.value);
                    } else {
                      setNewKirdPrakar(e.target.value);
                    }
                  }}
                  maxLength={30} // Set the maximum length to 30 characters
                  style={{marginRight: "40px",width:"200px",marginLeft:'40px', borderRadius:'5px', border:"1px solid black" }} 
                />
                {editingItem ? (
                  <button
            onClick={handleUpdateKirdPrakar}
            style={{ backgroundColor: "green", color: "white",marginRight: "20px",borderRadius:'5px', border:"1px solid black" , marginLeft:'-20px'}}
          >
            {language === "English" ? "Update" : "Update"}
          </button>
        ) : (
          <button
            onClick={handleSaveNewKirdPrakar}
            style={{ backgroundColor: "green", color: "white",marginRight: "0px", marginleft:"0px",borderRadius:'5px', border:"1px solid 'black" }}
          >
             {language === "English" ? "Save" : "Save"}
          </button>
                )}
                
                <button
                    className="kirdclose-button3"
                    onClick={handleCloseButtonClick3}
                  >
                    <i class="fa-solid fa-circle-xmark"></i>
                  </button>
              </div>
              <br />

              <form
                className="kirdadditional-form"
                onSubmit={handleAdditionalFormSubmit}
              >
               <div style={{ maxHeight: "300px", overflowY: "auto" }}>
  <table>
    <thead>
      <tr>
        <th> {language === "English" ? "Kird Name" : "किर्दचे नाव"} </th>
        <th> {language === "English" ? "Action" : "कृती"} </th>
      </tr>
    </thead>
    <tbody>
  {guna2ComboBoxItems
    .filter(entry => !["khate"].includes(entry.kirdtype)) // Add the names you want to skip here
    .map((entry) => (
      <tr key={entry.id}>
        <td>{entry.kirdtype || "No ItemName"}</td>
        <td>
          <button
            onClick={(event) => handleEdit1(event, entry)}
            className="kirdedit-button"
          >
            <i
              className="fas fa-pen-square"
              style={{
                fontSize: "18px",
                backgroundColor: "transparent",
              }}
            ></i>
          </button>
          <button
            onClick={() => handleDelete(entry)}
            className="kirddelete-button"
          >
            <i
              className="fas fa-trash-alt"
              style={{
                fontSize: "18px",
                backgroundColor: "transparent",
              }}
            ></i>
          </button>
        </td>
      </tr>
    ))}
</tbody>
  </table>
</div>

              </form>
            </div>
          </div>
        )}

        {selectedItem && (
          <div
            className={`kirdouter-box ${blurBackground ? "blur-background" : ""}`}
          >
              <p
      style={{
        color: "black",
        position: "absolute",
        top: "183px",
        fontWeight: "bold",
        marginLeft:'34%'
      
      }}
    > {new Date().toISOString().slice(0, 10).replace(/-/g, '/')}   {new Date().toLocaleTimeString()} {/* Format: YYYY/MM/DD */}
     
    </p>
    
            <div className="kirdcontainer">
              {/* Cards Box */}
              {showCardsBox && (
                <div className="kirdcards-box">
                  <button
                    className="kirdclose-button2"
                    onClick={handleCloseButtonClick2}
                  >
                    <i class="fa-solid fa-circle-xmark"></i>
                  </button>
                  <div className="kirdselected-item-box">
                  <h4 style={{ marginBottom: "80px" }}>
                     {selectedItemName}
                  </h4>
                  </div>
                  {/* Added marginBottom */}
                  <div className="row">
                    <div className="col-sm-4 ">
                      <div className="kirdcard " >
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                              {language === "English" ? "Today Initial Value" : "आजची आरंभिक शिल्लक"}
                          </a>
                          <h5 className="kirdcard-title">{label3}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="kirdcard">
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                             {language === "English" ? "Todays Last Amount" : "आजची अंतिम शिल्लक"}
                          </a>
                          <h5 className="kirdcard-title">{label4}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="kirdcard">
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                           {language === "English" ? "Total Accounts" : "एकूण खाती"}
                          </a>
                          <h5 className="kirdcard-title" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  {todayAvailableKhateCount > 1 ? (
    <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
      <li  style={{ textAlign: "center",listStyleType: 'none', padding: 0, margin: 0 }}>{todayAvailableKhateCount - 1}</li>
    </ul>
  ) : (
    <span style={{ textAlign: "center" }}>{label2}</span>
  )}
</h5>

                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="kirdcard">
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                            {language === "English" ? "Bank Account Balance" : "बँक खातेची शिल्लक"}
                          </a>
                          <h5 className="kirdcard-title"  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          {selectedKhateAmount ? (
                            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                              <li style={{ textAlign: "center",listStyleType: 'none', padding: 0, margin: 0 }}>{selectedKhateAmount}</li>
                            </ul>
                          ) : (
                            <span style={{ textAlign: "center" }}>{label11}</span>
                          )}
                          </h5>
                       </div>                                                                                       
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="kirdcard">
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                           {language === "English" ? "Today's Total Receipts" : "आजच्या एकूण पावती"}
                          </a>
                          <h5 className="kirdcard-title">{label1}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="kirdcard">
                        <div className="kirdcard-body">
                          <a href="#" className="btn btn-primary">
                         {language === "English" ? "Todays Total Vouchers" : "आजचे एकूण व्हाउचर"}
                          </a>
                          <h5 className="kirdcard-title">{label5}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Display only khate values in the outer box */}
              {selectedItemName && (
                
                <div className="kirdouter-box-khate" style={{ marginTop: "60px" }}>
                  {" "}
                  {/* Added marginTop */}
                  {selectedItemName !== "31-03-Akher shillak" && (
    <h4>{selectedItemName}</h4>
)}

                  <ul className="kirdhorizontal-list">
                    {todayAvailableKhate
                      .filter((item) => item.khate.trim() !== ""   && item.khate !== "31-03-Akher shillak") // Filter out blank khate values
                      .map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleKhateItemClick(item.khate)}
                        >
                          {item.khate}
                        </li>
                      ))}
                  </ul>
                </div>
              )}

              {/* Button Container */}
              <div className="kirdbutton-container">
                <button
                  className="button info-button"
                  onClick={handleInfoButtonClick}
                >
                    {language === "English" ? "Report" : "अहवाल"}
                </button>

                <button
                  className="button create-button"
                  onClick={handleCreateLaser}
                >
                   {language === "English" ? " Create Ledger" : "लेजर तयार करा"}
                </button>
                <button
                  className="button warning-button"
                  onClick={handleWarningClick}
                >
                    {language === "English" ? " Create Transaction" : "व्यवहाराचा प्रकार"}
                </button>
              </div>
            </div>

            
  <div className={blurBackground ? "blur-background3" : ""}>
              {showTable1 && (
                <div className="kirdtable-containerr1">
                  <button
                    className="kirdclose-button1"
                    onClick={handleCloseButtonClick1}
                  >
                    <i class="fa-solid fa-circle-xmark"></i>
                  </button>
                  <h4 className="text-center"> {selectedItemName}</h4>

                  <div className="kirdempty-box" style={{display:'flex', width:'100%'}}>
  <label htmlFor="kirdpavti-input" className="kirdpavti-label" style={{width:'70%', marginRight:'-15%'}}>
    {language === "English" ? " Pavti No " : "पावती क्रमांक"}
    <input
      id="kirdpavti-input" style={{marginLeft:'15px'}}
      type="text"
      className="kirdpavti-input"
      value={currentPavtiNo}
      onChange={handlePavtiNoChange}
      maxLength={5}
      onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')} 
    />
  </label>
  <label style={{ display:'flex'}}> 
  <label style={{margin:'10px', color:'#333'}}> {language === "English" ? "Select Option" : "पर्याय निवडा"}</label>
<div
    style={{
      border: '1px solid #ccc', 
      borderRadius: '5px',
      padding: '10px',
      backgroundColor: '#f9f9f9', 
      height:'40px', 
      paddingTop:'8px', paddingLeft:'20px',
      width:'200px'
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}> 
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>

  <label 
    htmlFor="credit-radio" 
    style={{ margin: '0 5px 0 0', fontWeight:'600', color:'#333'}}
  >
      {language === "English" ? " Credit " : "जमा"}
  </label>
  <input
    id="credit-radio"
    type="radio"
    name="transaction-type"
    value="credit"
    style={{ margin: '0 10px 0 0' }}
    checked={isCreditSelected}
    onChange={() => handleTransactionTypeChange("credit")}
  />
  </div>
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
  <label 
    htmlFor="debit-radio" 
    style={{ margin: '0 5px 0 0', fontWeight:'600' , color:'#333'}}
  >
     {language === "English" ? " Debit " : "नावे"}
  </label>
  <input
    id="debit-radio"
    type="radio"
    name="transaction-type"
    value="debit"
    style={{ margin: '0 10px 0 0' }}
    checked={isDebitSelected}
    onChange={() => handleTransactionTypeChange("debit")}
  />
  </div>
  </div>
</div>
</label>
</div>

                  <table>
                    <tr>
                      <td className="kirdflex-container" style={{ width:'100% !important'}}>
                      
                        <label
                          htmlFor="financial-year"
                          className="kirddropdown-label1" style={{fontSize:"12" }}
                        >
                          {language === "English" ? "  Financial Year " : "आर्थिक वर्ष"}
                        
                        <select
                          id="financial-year"
                          className="kirddropdown-select1" style={{width:'200px'}}
                          value={selectedYear1}
                          onChange={(e) => setSelectedYear1(e.target.value)} >
                          <option value="">{language === "English" ? " Year" : "आर्थिक वर्ष"}</option>
                          <option value="2022-2023">2022-2023</option>
                          <option value="2023-2024">2023-2024</option>
                          <option value="2024-2025">2024-2025</option>
                          <option value="2025-2026">2025-2026</option>
                          <option value="2026-2027">2026-2027</option>
                          <option value="2027-2028">2027-2028</option>
                          <option value="2028-2029">2028-2029</option>
                          <option value="2029-2030">2029-2030</option>
                          <option value="2030-2031">2030-2031</option>
                        </select>
                        </label>
                        <label htmlFor="kirddate-input" className="kirddate-label1 " >
                           {language === "English" ? " Date " : "तारीख"}
                        <input
                          id="kirddate-input"
                          type="date"
                          className="kirddate-input me-4" style={{width:'200px'}}
                          value={selectedDate}
                          min={minDate}
                          max={maxDate}
                          onChange={(e) => setSelectedDate(e.target.value)}
                        />
 </label>


                      </td>
                    </tr>
                  </table>
                  <div className="kirdempty-box">
  <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
    <label htmlFor="kirdAmount" className="kirdAmount">
      {language === "English" ? " Amount " : "रक्कम"}
      </label>
    <input
      style={{ width: '340px',  marginLeft: '25px'}}
      id="kirdAmount-input"
      type="text"
      className="kirdAmount-input"
      value={amount}
      onChange={(e) => {
        if (!isCreditSelected && !isDebitSelected) {
          setAlertMessage(language === "English" ? "Please select the transaction type" : "कृपया व्यवहार प्रकार निवडा");
          return;
        }
        const value = e.target.value;
        if (/^\d{0,8}$/.test(value) && (value === "" || parseInt(value, 10) > 0)) {
          setAmount(value);
        }
      }}
      disabled={!isCreditSelected && !isDebitSelected} // Disable when no transaction type is selected
    />
 
    <label style={{ width: '130px', marginRight: '-40px', marginBottom:'30px' }} htmlFor="kirdkhate-input" className="kirdkhate-label">
      {language === "English" ? " Type of Account " : "खाते प्रकार"}
    </label>
    <select
      style={{ width: '350px', marginBottom:'40px', marginLeft:'10px'}}
      id="kirdkhate-input"
      className="kirdkhate-dropdown"
      value={khatePrakar}
      onChange={(e) => {
        if (!isCreditSelected && !isDebitSelected) {
          setAlertMessage(language === "English" ? "Please select the transaction type" : "कृपया व्यवहार प्रकार निवडा");
          return;
        }
        setKhatePrakar(e.target.value);
        setCredit1(''); // Reset Credit Account dropdown
        setDebit1('');  // Reset Debit Account dropdown
      }}
      disabled={!isCreditSelected && !isDebitSelected} // Disable when no transaction type is selected
    >
      <option value="">{language === "English" ? " Select an option " : "एक पर्याय निवडा"}</option>
      <option value="ByCash">By Cash</option>
      <option value="ByCheque">By Cheque (Contra Entry)</option>
      <option value="ByDD">By DD (Contra Entry)</option>
      <option value="ByOnline">By Online (Contra Entry)</option>
      <option value="ByCheque">By Cheque (Journal Entry)</option>
      <option value="ByDD">By DD (Journal Entry)</option>
      <option value="ByOnline">By Online (Journal Entry)</option>
    </select>
    </div>

    
    <div style={{ display: 'flex', alignItems: 'center', gap: '40px', marginBottom:'10px' }}>
    {(isCreditSelected && khatePrakar === "ByCash") || khatePrakar !== "ByCash" ? (
      <>
        <label 
          htmlFor="credit-input"  
          style={{ marginRight: '-30px', fontWeight:'600', marginBottom:'15px' }}
        >
          {language === "English" ? " Credit Account " : "जमा खाते"}
        </label>
        <select
          id="credit-input"
          className="kirdcredit-dropdown"
          style={{ width: '350px', padding: '5px' }}
          value={credit1}
          onChange={(e) => setCredit1(e.target.value)}
          disabled={isCreditDisabled || !khatePrakar }
        >
          <option value="">{language === "English" ? " Select an option " : "एक पर्याय निवडा"}</option>
          {todayAvailableKhate
            .filter(item => item.khate.trim() !== "")
            .filter(item => {
      // Show "31-03-Akher shillak" only when "ByCash" is selected
      if (khatePrakar === "ByCash") {
        return true; // Include all items
      }
      // Exclude "31-03-Akher shillak" for other account types
      return item.khate !== "31-03-Akher shillak";
    })
            .reduce((acc, item) => {
              if (item.khate === "31-03-Akher shillak" && acc.some(i => i.khate === "31-03-Akher shillak")) {
                return acc;
              }
              return [...acc, item];
            }, [])
            .map(item => (
              <option key={item.id} value={item.khate}>
                {item.khate}
              </option>
            ))}
        </select>
      </>
    ) : null}

    {(isDebitSelected && khatePrakar === "ByCash") || khatePrakar !== "ByCash" ? (
      <>
        <label 
          htmlFor="debit-input"
          style={{ width: '100px', marginRight: '-54px', fontWeight:'600', marginBottom:'15px' }}
        >
          {language === "English" ? " Debit Account " : "नावे खाते"}
        </label>
        <select
          id="debit-input"
          className="kirddebit-dropdown"
          style={{ width: '350px', padding: '5px' }}
          value={debit1}
          onChange={(e) => setDebit1(e.target.value)}
          disabled={isDebitDisabled || !khatePrakar}
        >
          <option value="">{language === "English" ? " Select an option " : "एक पर्याय निवडा"}</option>
          {todayAvailableKhate
            .filter(item => item.khate && item.khate.trim() !== "")
            .filter(item => item.khate !== "31-03-Akher shillak")
            .map((item) => (
              <option key={item.id} value={item.khate}>
                {item.khate}
              </option>
            ))}
        </select>
      </>
    ) : null}
  </div>


                    <label htmlFor="kirdremark-input" className="kirdremark-label1">
                      {language === "English" ? " Comment " : "टिप्पणी"}
                   
                    <input
                      id="kirdremark-input" style={{marginLeft:'54px'}}
                      type="text"
                      className="kirdremark-input"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                      maxLength={50}
                    />
                     </label>
                  <div className="kirdbox2" style={{ display: 'flex', alignItems: 'center'}}>
               
                  {!showloadingimg && (
      
   <>
                  {isExistingPavtiNo  ? (
  // Show Update button if Pavti No already exists
  <button
    className="kirdupdate-button"
    onClick={handleUpdate}
    disabled={!isDataFetched || !isExistingPavtiNo} // Update button enabled if data is fetched and Pavti No exists
  >
    {language === "English" ? " Update " : "Update"}
  </button>
) : (
  // Show Save button if Pavti No is new
  <button
    className="kirdsave-button1"
    onClick={handleSave}
    disabled={isDataFetched && !isExistingPavtiNo} // Save button enabled if data is fetched and Pavti No is new
    style={{ marginRight: "10px" }} 
  >
    {language === "English" ? " Save " : "Save"}
  </button>
)}

  {/* Delete button */}
  <button 
  className="kirddelete-button1" 
  onClick={handleDelete1}
  disabled={!currentPavtiNo}  // Disable button if no Pavti No is selected
>
     {language === "English" ? " Delete " : "Delete"}
  </button>
  </>
)}
  {/* Reload button
  <button 
    className="kirdreload-button" 
    onClick={handleReload} // Define this function to handle the reload logic
    style={{ marginLeft: '10px', backgroundColor:'rgb(8, 146, 8)',height:'35px',textAlign:'center',fontSize:'1rem',padding:'5px 12px',width:'100px' }} // Add spacing between buttons
  >
    {language === "English" ? " Reload " : "Reload"}
  </button> */}


  <div className="receipts-container" style={{marginLeft: 'auto',display: 'flex', gap: '10px'}}>
  {isCreditSelected && (
    <Receipt
      isCreditSelected={isCreditSelected}
      selectedDate={pavtiDataArray.map((data) => data.date).join(", ")}
      khatePrakar={pavtiDataArray.map((data) => data.khatePrakar).join(", ")}
      amount={pavtiDataArray.map((data) => data.Amount).join(", ")}
      remark={pavtiDataArray.map((data) => data.Remark).join(", ")}
      credit1={pavtiDataArray.map((data) => data.credit1).join(", ")}
      sansthaName={schoolData.sansthaName|| " "}
      schoolName={schoolData.schoolName || " "}
      taluka={schoolData.taluka}
      jilha={schoolData.jilha}
      type="credit"
      selectedPavtiNos={selectedPavtiNos}
      pavtiDataArray={pavtiDataArray}
    />
      )}
      {isDebitSelected && (
    <Receipt1
      isDebitSelected={isDebitSelected}
      selectedDate={pavtiDataArray.map((data) => data.date).join(", ")}
      khatePrakar={pavtiDataArray.map((data) => data.khatePrakar).join(", ")}
      amount={pavtiDataArray.map((data) => data.Amount).join(", ")}
      remark={pavtiDataArray.map((data) => data.Remark).join(", ")}
      debit1={pavtiDataArray.map((data) => data.debit1).join(", ")}
      sansthaName={schoolData.sansthaName || " "}
      schoolName={schoolData.schoolName || " "}
      taluka={schoolData.taluka}
      jilha={schoolData.jilha}
      type="debit"
      selectedPavtiNos={selectedPavtiNos}
      pavtiDataArray={pavtiDataArray}
    />  )}
  </div>
</div>

                  </div>
                 <p style={{ marginBottom: "10px" }}>
  {language === "English" ? "To See Below Print Receipt Click " : "खाली प्रिंट पावती पाहण्यासाठी क्लिक करा"}
</p>
                  {showCheckbox && (
  <div style={{ padding: "10px", overflowX: "auto" }}>
    {/* Checkbox to select or deselect all */}
    <div style={{ marginBottom: "10px",marginLeft:'0px',marginRight:'90%' }}>
      <input
        type="checkbox"
        checked={
          savedPavtiNos.length > 0 &&
          selectedPavtiNos.length === savedPavtiNos.length
        }
        onChange={handleSelectAll}
        style={{ marginRight: "10px" }}
      />
      <span> {language === "English" ? " Select All " : "Select All"}</span>
    </div>

    {/* Split savedPavtiNos into columns of 10 */}
    <div style={{ display: "flex", gap: "20px" }}>
      {savedPavtiNos.length > 0 ? (
        savedPavtiNos
          .filter((pavtiNo) => pavtiNo !== "0" && !deletedPavtiNos.includes(pavtiNo)) // Exclude "0" values
          .map((pavtiNo) => (
            <div key={pavtiNo} style={{ marginBottom: "8px" }}>
              <input
                type="checkbox"
                checked={selectedPavtiNos.includes(pavtiNo)}
                onChange={() => handlePavtiSelection(pavtiNo)}
                value={pavtiNo || ""}
                style={{ marginRight: "10px" }}
              />
              <span>{pavtiNo || ""}</span>
            </div>
          ))
      ) : (
        <p>{language === "English" ? " No Pavti numbers found " : " पावती नाहीत"}</p>
      )}
    </div>
  </div>
)}

        
                </div>
              )}
            </div>
          </div>
        )}

        
        {showLaserInput && (
          <div className="kirdlaser-amount-input">
  <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flexDirection: 'column' }}>
    <h3>{editItem ? language === "English" ? " Edit Ledger Details " : " लेजर तपशील संपादित करा" : language === "English" ? " Enter Ledger Details " : " लेजर तपशील प्रविष्ट करा"}</h3>

              {/* Radio buttons for "Bank" and "Normal" with increased gap */}
              <div style={{ 
            marginTop: '0px', 
            marginBottom: '15px', 
            border: '1px solid black', 
            padding: '3px', 
            borderRadius: '5px', 
            width: '430px', 
            textAlign: 'center', 
            paddingLeft: '23%',
            border: "1px solid #ccc",
            display: 'flex',               // Make the container a flexbox
            justifyContent: 'center',      // Center the content horizontally
            alignItems: 'center',          // Center the content vertically
            flexDirection: 'column'        // Stack the radio buttons vertically
          }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginTop: '10px' }}> {/* Increased gap here */}
        <label htmlFor="bank-radio" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px',marginRight:'200px' }}>
          <input
            id="bank-radio"
            type="radio"
            name="ledger-type"
            value="bank"
            onChange={() => setLedgerType("bank")}
            style={{ marginTop: '5px' }}
          />
         {language === "English" ? "  Bank " : " बँक"}
        </label>
        <label htmlFor="normal-radio" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px',marginRight:'100px' }}>
          <input
            id="normal-radio"
            type="radio"
            name="ledger-type"
            value="normal"
            onChange={() => {
              setLedgerType("normal");
              setAmountInput(''); // Optionally clear the amount input when switching to normal
               }}
            style={{ marginTop: '5px' }} 
          />
          {language === "English" ? "Ledger " : " लेजर"}
        </label>
        </div>
      </div>
    </div>

    <input
      type="text"
      value={khateInput}
      onChange={(e) => setKhateInput(e.target.value)}
      placeholder={language === "English" ? "Khate" :  "खाते " }
      maxLength={30}
    />

    {/* Render the amount input only if "Bank" is selected */}
    {ledgerType === "bank" && (
      <input
        type="text"
        value={amountInput}
        onChange={(e) => {
          const value = e.target.value;
          // Allow only digits and limit to 8 digits
          if (/^\d{0,8}$/.test(value)) {
            setAmountInput(value);
          }
        }}
        placeholder= {language === "English" ? "Opening Balance  " : " आरंभिक शिल्लक "}
        disabled={ledgerType !== "bank"} // Disable the input unless "Bank" is selected
        onMouseEnter={() => {
          if (ledgerType === "normal") {
            // Show warning message on hover
            setShowWarning(true);
          }
        }}
        onMouseLeave={() => {
          // Hide warning message on mouse leave
          setShowWarning(false);
        }}
      />
    )}

    {/* Conditional rendering for the warning message */}
    {ledgerType === "normal" && showWarning && (
      <div style={{ color: 'red', marginTop: '5px' }}>
        Bank is selected. Please select 'Bank' to enter amount.
      </div>
    )}


    {editItem ? (
    <div>
        <button onClick={handleSaveEdit}>Update</button> {/* Update button */}
        <button
            onClick={() => {
                setEditItem(null); // Exit edit mode
                setShowLaserInput(false); // Hide input fields
                setKhateInput(''); // Clear khate input
                setAmountInput(''); // Clear amount input
                setShowCardsBox(true); // Show cards box
                setBlurBackground(false); // Remove blur
            }}
        >
            Cancel
        </button> {/* Cancel button */}
    </div>
    ) : (
      <div>
        <button onClick={handleAdd}> Add </button>
        <button
          className="kirdclose-button4"
          onClick={handleCloseButtonClick4}
          style={{
            backgroundColor: "transparent", // Remove background color
            border: "none", // Remove any border
            boxShadow: "none", // Remove any box shadow if present
            cursor: "pointer" // Ensure it's still clickable
          }}
        >
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
    )}

    {/* Display saved items only in the laser-amount-input box */}
    {selectedItemName && todayAvailableKhate.length > 0 && (
      <div className="kirdsaved-items">
        <h3> {selectedItemName}</h3>
        {/* Wrap the table in a container with a fixed height and scrolling enabled */}
        <div className="ledger-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <table>
            <thead>
              <tr>
                <th>{language === "English" ? " Account Name " : " खाते नाव"}</th>
                <th>{language === "English" ? "Amount " : "रक्कम"}</th>
                <th>{language === "English" ? " Actions " : "कृती"}</th>
              </tr>
            </thead>
            <tbody>
    {todayAvailableKhate
        .filter((item) => item.khate && item.amount)
        .map((item) => (
            <tr key={item.id}>
                <td>{item.khate}</td>
                <td>{item.amount}</td>
                <td>
                    {/* Edit button */}
                    <button
                        onClick={() => handleEdit(item)}
                        className="kirdedit-button"
                    >
                        <i className="fas fa-pen-square" style={{}}></i>
                    </button>

                    {/* Delete button */}
                    <button
                        onClick={() => handleDelete2(item)}
                        className="kirddelete-button2"
                    >
                        <i
                            className="fas fa-trash-alt"
                            style={{
                                fontSize: "18px",
                                backgroundColor: "transparent",
                            }}
                        ></i>
                    </button>
                </td>
            </tr>
        ))}
</tbody>
          </table>
        </div>
      </div>
    )}
  </div>
)}

 {/* Apply the blur class when blurBackground is true */}
        <div className={blurBackground ? "blur-background2" : ""}></div>
        {showTable && (
          <div className="kirdtable-containerrr">
            <button className="kirdclose-button" onClick={handleCloseButtonClick}>
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
            <table>
              <thead>
                <tr>
                  <th
                    className="text-center"
                    style={{
                      padding: "8px",
                      fontSize: "20px",
                      width: "100px",
                      minWidth: "200px",
                      maxWidth: "100px",
                      backgroundColor: "blue",
                    }}
                  >
                    <span style={{ color: "white" }}>{language === "English" ? "Reporting " : "अहवाल"}</span>
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            {/* Apply the blur class when blurBackground is true */}
            <div className={blurBackground ? "blur-background" : ""}>
             <br/>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
  <label 
    htmlFor="kirdselect-dropdown1"
    style={{ textAlign: 'left', display: 'block', marginRight: '10px', width: '150px', fontWeight: '500' }}
  >
   {language === "English" ? " Financial Year " : "आर्थिक वर्ष"}
  </label>
  <select
    id="kirdselect-dropdown1"
    onChange={handleSelectYearItem}
    style={{ width: '100%', padding: '8px', border: '1px solid #6c757d', borderRadius: '4px',  fontSize: '13px', marginRight:'10px' }}  value={selectedYear}
  >
    <option value="">{language === "English" ? " Select Financial Year " : "आर्थिक वर्ष निवडा"}</option>
    <option value="2021-2022">2021-2022</option>
    <option value="2022-2023">2022-2023</option>
    <option value="2023-2024">2023-2024</option>
    <option value="2024-2025">2024-2025</option>
    <option value="2025-2026">2025-2026</option>
    <option value="2026-2027">2026-2027</option>
    <option value="2027-2028">2027-2028</option>
    <option value="2028-2029">2028-2029</option>
    <option value="2029-2030">2029-2030</option>
    <option value="2030-2031">2030-2031</option>

  </select>
</div>

<div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
  <label 
    htmlFor="kirdselect-dropdown2"
    style={{ textAlign: 'left', display: 'block', marginRight: '10px', width: '150px', fontWeight: '500' }}
  >
     {language === "English" ? " From date " : "ह्या तारखेपासून"}
  </label>
  <input
    type="date"
    id="kirdselect-dropdown2"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
    style={{ width: '100%', padding: '8px', border: '1px solid #6c757d', borderRadius: '4px', fontSize: '13px', marginRight:'10px' }}
  />
</div>

<div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom:'15px' }}>
  <label 
    htmlFor="kirdselect-dropdown3"
    style={{ textAlign: 'left', display: 'block', marginRight: '10px', width: '150px', fontWeight: '500' }}
  >
    {language === "English" ? " To Date " : "ह्या तारखेपर्यंत "}
  </label>
  <input
    type="date"
    id="kirdselect-dropdown3"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    style={{ width: '100%', padding: '8px', border: '1px solid #6c757d', borderRadius: '4px', fontSize: '13px', marginRight:'10px' }}
  />
</div>


            <div>
              <button
                onClick={handleKirdReport}
                className="kirdsmall-input"
                style={{
                  backgroundColor: "#6495ED",
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                }}
              >
                 {language === "English" ? "Kird " : "किर्द"}
              </button>

              <button
                onClick={handleKhatavaniReport}
                className="kirdsmall-input"
                style={{
                  backgroundColor: "#6495ED",
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                }}
              >
                 {language === "English" ? "Khatavani " : "खतावणी"}
              </button>

              <button
                onClick={handleTerijReport}
                className="kirdsmall-input"
                style={{
                  backgroundColor: "#6495ED",
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                }}
              >
                {language === "English" ? "Terij " : "तेरीज"}
              </button>
              <button
              onClick={handleProfitLossReport}
                className="kirdsmall-input"
                style={{
                  backgroundColor: "#6495ED",
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                }}
              >
                {language === "English" ? " Profit-Loss " : "नफा-तोटा"}
              </button>

              {/* <button
                className="small-input"
                style={{
                  backgroundColor: "#6495ED",
                  border: "none",
                  color: "white",
                  padding: "10px 20px",
                  textAlign: "center",
                  textDecoration: "none",
                  display: "inline-block",
                  fontSize: "16px",
                  margin: "4px 2px",
                  cursor: "pointer",
                }}
              >
                Taleband
              </button> */}
            </div>
            {showEmptyBox && (
              <div className="kirdempty-box">
                <h3>Empty Box Content</h3>
                <input
                  type="text"
                  value={khateInput}
                  onChange={(e) => setKhateInput(e.target.value)}
                  placeholder="Khate Input"
                />
                <input
                  type="text"
                  value={amountInput}
                  onChange={(e) => setAmountInput(e.target.value)}
                  placeholder="Amount Input"
                />
                <button onClick={handleAdd}>Add</button>
                <button onClick={() => setShowEmptyBox(false)}>Cancel</button>
              </div>
            )}
          </div>
        )}
      </>

      {showKirdReport && (
    <KirdReport
        // Pass any required props to the Kird report component
        filteredData={filteredData} // Assuming you have this data from the report fetching logic
        schoolData={schoolData}
        onClose={() => setShowKirdReport(false)} // Pass a function to close the report
    />
)}

{showKhatavaniReport && (
    <KhatavaniReport
        // Pass any required props to the Kird report component
        combinedData={KhatavaniReportData} // Assuming you have this data from the report fetching logic
        khateDetails={KhatavaniReportData1} 
        schoolData={schoolData}
        onClose={() => setShowKhatavaniReport(false)} // Pass a function to close the report
    />
)}

{showTerijReport && (
    <TerijReport
        // Pass any required props to the Kird report component
        combinedData={TerijReportData} // Assuming you have this data from the report fetching logic
        totalsByKhate={TerijReportData1}
        schoolData={schoolData}
        onClose={() => setShowTerijReport(false)} // Pass a function to close the report
    />
)}

{showProfitLossReport && (
    <ProfitLossReport
        // Pass any required props to the Kird report component
        combinedData={ProfitLossReportData} // Assuming you have this data from the report fetching logic
        totalsByKhate={ProfitLossReportData1}
        schoolData={schoolData}
        onClose={() => setShowProfitLossReport(false)} // Pass a function to close the report
    />
)}

    </div>
  </>
);
};

export default Kird;


