import React, { useState, useEffect } from 'react';
import { FaBell, FaBirthdayCake } from 'react-icons/fa';
import { WhatsappIcon } from 'react-share';
import '../pages/Home.css'
import bgImg from '../assets/images/initiallogo.png';

import img from '../assets/images/b1.jpeg';
import imgA from '../assets/images/b2.jpeg';
import imgB from '../assets/images/b3.jpeg';
import imgC from '../assets/images/b4.jpeg';
import imgD from '../assets/images/b5.jpeg';
import imgE from '../assets/images/b6.jpeg';
import imgF from '../assets/images/b7.jpeg';
import imgG from '../assets/images/b8.jpeg';
import imgH from '../assets/images/b9.jpeg';
import imgI from '../assets/images/b10.jpeg';
import spinner from '../assets/images/loading2.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../components/Sidebar';

const Home = () => {
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedStudentImages, setSelectedStudentImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [udiseNumber, setUdiseNumber] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

  useEffect(() => {
    const schoolUdiseNumber = localStorage.getItem('udiseNumber');
    if (schoolUdiseNumber) {
      setUdiseNumber(schoolUdiseNumber);
    }
  }, []);

  const fetchTodaysBirthdays = async () => {
    try {
      const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      if (data) {
        const today = new Date();
        const todayDayMonthString = `${today.getMonth() + 1}-${today.getDate()}`;
  
        const todayBirthdays = Object.values(data)
          .filter(student => student !== null)
          .filter(student => {
            const dob = new Date(student.dob);
            
            const dobDayMonthString = `${dob.getMonth() + 1}-${dob.getDate()}`;
            return dobDayMonthString === todayDayMonthString;
          });
        console.log("Today's birthdays:", todayBirthdays);
  
        setTodaysBirthdays(todayBirthdays);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchError(error.message);
    }
  };
  

  useEffect(() => {
    fetchTodaysBirthdays();
  }, [udiseNumber]);

  const handleNotificationClick = () => {
    setIsNotificationClicked(!isNotificationClicked);
  };

  const handleStudentClick = (student) => {
    const fullName = `${student.stdName} ${student.stdFather} ${student.stdSurname}`;
    if (selectedStudent === fullName) {
      setImageIndex(0);
    } else {
      setSelectedStudent(fullName);
      setImageIndex(0);
      setIsLoading(true);
      setIsNotificationClicked(false); // Hide notifications list when a student is clicked
      const studentImages = [
        img,
        imgA,
        imgB,
        imgC,
        imgD,
        imgE,
        imgF,
        imgG,
        imgH,
        imgI,
      ];
      
      Promise.all(studentImages.map((image) => getBase64Image(image, fullName)))
        .then((imagesWithNames) => {
          setSelectedStudentImages(imagesWithNames);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  const handleNextImage = () => {
    setImageIndex((imageIndex + 1) % selectedStudentImages.length);
  };

  const handlePrevImage = () => {
    setImageIndex((imageIndex - 1 + selectedStudentImages.length) % selectedStudentImages.length);
  };

  const handleShareWhatsApp = async () => {
    try {
      const imageUrl = selectedStudentImages[imageIndex];
      const studentIndex = todaysBirthdays.findIndex(student => {
        const fullName = `${student.stdName} ${student.stdFather} ${student.stdSurname}`;
        return fullName === selectedStudent;
      });
      if (studentIndex !== -1) {
        const studentName = `${todaysBirthdays[studentIndex].stdName} ${todaysBirthdays[studentIndex].stdFather} ${todaysBirthdays[studentIndex].stdSurname}`;
        const base64Image = await getBase64Image(imageUrl, studentName);
        const blob = await fetch(base64Image).then(response => response.blob());
        const filesArray = [new File([blob], 'birthday_image.jpg', { type: blob.type })];
        if (navigator.canShare && navigator.canShare({ files: filesArray })) {
          navigator
            .share({
              files: filesArray,
              text: 'Birthday image',
              title: 'Birthday Image',
            })
            .then(() => {
              console.log('Image shared successfully');
            })
            .catch(error => {
              console.error('Error sharing image:', error);
            });
        } else {
          console.error('Sharing not supported');
        }
      } else {
        console.error('Student not found in today\'s birthdays');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getBase64Image = async (url, studentName) => {
    return new Promise(async (resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = async function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = this.width;
        canvas.height = this.height;
        ctx.drawImage(this, 0, 0);

        try {
          const schoolDataUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/schoolData.json`;
          const response = await fetch(schoolDataUrl);
          if (!response.ok) {
            reject(new Error('Failed to fetch school data'));
            return;
          }

          const data = await response.json();
          console.log(data);

          if (data && data.schoolName) {
            const schoolName = data.schoolName;
            const MAX_CHARACTERS = 40;
            const FONT_SIZE = 45;
            const Y_POSITION = 1080;

            ctx.font = `bold ${FONT_SIZE}px Arial`;
            ctx.fillStyle = 'white';

            const x = canvas.width / 2;
            let y = Y_POSITION + FONT_SIZE + 10;
            let lines = [];
            let currentLine = '';

            for (let i = 0; i < schoolName.length; i++) {
              if (currentLine.length >= MAX_CHARACTERS) {
                lines.push(currentLine);
                currentLine = '';
              }

              if (schoolName[i] === ' ') {
                if (ctx.measureText(currentLine + ' ' + schoolName.slice(i + 1).split(' ')[0]).width > canvas.width) {
                  lines.push(currentLine);
                  currentLine = '';
                }
              }
              currentLine += schoolName[i];
            }

            lines.push(currentLine);

            lines.forEach((line, index) => {
              const textWidth = ctx.measureText(line).width;
              const lineY = y + (index * (FONT_SIZE + 10));
              const lineX = x - textWidth / 2;
              ctx.fillText(line, lineX, lineY);
            });

            ctx.font = 'bold 80px Arial';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const textX = canvas.width / 2;
            const textY = 320; 
            ctx.fillText(studentName, textX, textY);

            const dataURL = canvas.toDataURL('image/jpeg');
            resolve(dataURL);
          } else {
            reject(new Error('School data not available'));
          }
        } catch (error) {
          reject(new Error('Failed to process image'));
        }
      };
      img.onerror = function () {
        console.error('Image load error', url);
        reject(new Error('Could not load image'));
      };
      img.src = url;
    });
  };

  return (
    <div>
<Sidebar/>
    
    <div style={{ position: 'relative',backgroundImage: `url(${bgImg})`,  height:'90vh',    backgroundRepeat: 'no-repeat', backgroundPosition: 'center'  }} className='main-content-of-page'>
      <div style={{ position: 'absolute', right: '1%', top: '-50px', zIndex:'1111' }}>
   
        <div style={{ position: 'relative', display: 'inline-block' }} onClick={handleNotificationClick}>
          <FaBell size={24} style={{ color: 'white', cursor: 'pointer' }} />
          {!isNotificationClicked && todaysBirthdays.length > 0 && !selectedStudent && (
            <span style={{
              position: 'absolute',
              top: '0',
              right: '0',
              background: 'red',
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              fontSize: '12px'
            }}>
              {todaysBirthdays.length}
            </span>
          )}
        </div>
    
        {/* Display today's birthdays */}
       
        {fetchError && <div>Error fetching data: {fetchError}</div>}
       {/* {todaysBirthdays.length > 0 && ( */}
        {todaysBirthdays.length > 0 && isNotificationClicked && (
          <div className="birthday-list" style={{
            position: 'absolute',
            right: '10px',
            top: '30px',
            background: 'white',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            zIndex: '1000',
            padding: '10px',
            borderRadius: '15px',
            textAlign: 'left',
            width: '300px',
            height: 'auto',
            overflowY: 'auto',
          }}>
            <h3 style={{ color: 'black' }}></h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {todaysBirthdays.map((student, index) => (
                <li key={index} className="list-group-item list-group-item-info" style={{ marginBottom: '10px', color: '' }} onClick={() => handleStudentClick(student)}>
                  <FaBirthdayCake style={{ marginRight: '5px', color: 'red', borderRadius: '10px' }} />
                  Today's Birthday: {student.stdName} {student.stdFather} {student.stdSurname}
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* )} */}
      </div>
       
      {selectedStudent && (
        <div className="selected-student-images" style={{ paddingTop: '80px', textAlign: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faArrowLeft} onClick={handlePrevImage} style={{ cursor: 'pointer', marginRight: '10px' }} />
            <img src={selectedStudentImages[imageIndex]} alt="Birthday" style={{ width: '500px', height: '500px', objectFit: 'cover' }} />
            <div className="loading-animation">
              {isLoading && (
                <img src={spinner} alt="Loading animation" />
              )}
            </div>
            <FontAwesomeIcon icon={faArrowRight} onClick={handleNextImage} style={{ cursor: 'pointer', marginLeft: '10px' }} />
          </div>
          <button onClick={handleShareWhatsApp} style={{ marginTop: '20px', padding: '10px 20px', background: '#25D366', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
            <WhatsappIcon size={24} /> Share on WhatsApp
          </button>
        </div>
      )}
    </div>
    </div>
  );
};

export default Home;

