// createSchoolNodes.js
import axios from 'axios';

// Function to create poshanaahar nodes
const CreatePramanNode = (udiseNumber,language) => {
  const apiUrl = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/poshanaahar/praman.json`;

  const dataFor1To5English = {
    'Rice': '100',
    'Mugdal': '20',
    'Turdal': '20',
    'Masurdal': '20',
    'Matki': '20',
    'Moong': '20',
    'Cowpea': '20',
    'Gram': '20',
    'Pease': '20',
    'Cumin': '0.0003',
    'Mustard': '0.0003',
    'Turmeric': '0.0004',
    'Chili': '0.0006',
    'Oil': '0.005495',
    'Salt': '0.0003',
    'Garam Masala': '0.0004',
    'Vegetables': '50',
    'Expenses':'5.45'
  };

  const dataFor1To5Marathi = {
    'तांदूळ': '100',
    'मुगडाळ': '20',
    'तूरडाळ': '20',
    'मसूरडाळ': '20',
    'मटकी': '20',
    'मूग': '20',
    'चवळी': '20',
    'हरभरा': '20',
    'वाटाणा': '20',
    'जिरे': '0.0003',
    'मोहरी': '0.0003',
    'हळद': '0.0004',
    'मिरची': '0.0006',
    'तेल': '0.005495',
    'मीठ': '0.0003',
    'गरम मसाला': '0.0004',
    'भाजी पाला': '50',
    'खर्च' : '5.45'
  };

  // Predefined values for 6 To 8 (English)
  const dataFor6To8English = {
    'Rice': '150',
    'Mugdal': '30',
    'Turdal': '30',
    'Masurdal': '30',
    'Matki': '30',
    'Moong': '30',
    'Cowpea': '30',
    'Gram': '30',
    'Pease': '30',
    'Cumin': '0.0005',
    'Mustard': '0.0005',
    'Turmeric': '0.0006',
    'Chili': '0.0007',
    'Oil': '0.008242',
    'Salt': '0.0005',
    'Garam Masala': '0.0006',
    'Vegetables': '75',
    'Expenses':'8.17'
  };

  // Predefined values for 6 To 8 (Marathi)
  const dataFor6To8Marathi = {
    'तांदूळ': '150',
    'मुगडाळ': '30',
    'तूरडाळ': '30',
    'मसूरडाळ': '30',
    'मटकी': '30',
    'मूग': '30',
    'चवळी': '30',
    'हरभरा': '30',
    'वाटाणा': '30',
    'जिरे': '0.0005',
    'मोहरी': '0.0005',
    'हळद': '0.0006',
    'मिरची': '0.0007',
    'तेल': '0.008242',
    'मीठ': '0.0005',
    'गरम मसाला': '0.0006',
    'भाजी पाला': '75',
    'खर्च': '8.17'
  };
  
   const combinedData = {
    '1 To 5': language === 'English' ? dataFor1To5English : dataFor1To5Marathi,
    '6 To 8': language === 'English' ? dataFor6To8English : dataFor6To8Marathi,
  };

  console.log("Sending combinedData to API", combinedData);

  axios
    .patch(apiUrl, combinedData)
    .then((response) => {
      console.log('Data added successfully:', response.data);
    })
    .catch((error) => {
      console.error('Error adding data: ', error.response?.data || error.message);

      // Optional: Fallback to PUT request for testing if patch doesn't work
      axios
        .put(apiUrl, combinedData)
        .then((response) => {
          console.log('Fallback PUT request success:', response.data);
        })
        .catch((putError) => {
          console.error('Fallback PUT error: ', putError.response?.data || putError.message);
        });
    });
};

export default CreatePramanNode;
