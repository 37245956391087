import React, { useState, useEffect } from "react";
import "../result/result.css";
import Sidebar from "../../components/Sidebar";

function Collectout() {


        const [academicYear, setAcademicYear] = useState("");
        const [classValue, setClassValue] = useState("");
        const [subject, setSubject] = useState("");
        const [selectedExamName, setSelectedExamName] = useState("");
        const [studentData, setStudentData] = useState([]);
        const [selectedStudents, setSelectedStudents] = useState([]);
        const [marksData, setMarksData] = useState({});
        const [classes, setClasses] = useState([]);
        const [subjects, setSubjects] = useState({});
        const examNames = ["First Semester", "Second Semester"];
        
        const udiseNumber = localStorage.getItem("udiseNumber");
      
        const [language, setLanguage] = useState(localStorage.getItem('language') || 'English');

        useEffect(() => {
          const storedLanguage = localStorage.getItem('language') || 'English';
          setLanguage(storedLanguage);
        }, []);

        useEffect(() => {
            if (udiseNumber) {
                fetchStudentData();
            }
        }, [udiseNumber]);
    
        useEffect(() => {
            if (selectedExamName && classValue && academicYear) {
                fetchMarksForSelectedSubject();
            }
        }, [subject, selectedExamName, classValue, academicYear]);
    
        const handleAcademicYearChange = (e) => setAcademicYear(e.target.value);
        const handleClassChange = (e) => {
            setClassValue(e.target.value);
            fetchSubjectsForClass(e.target.value);
        };
        const handleSubjectChange = (e) => setSubject(e.target.value);
        const handleExamNameChange = (e) => setSelectedExamName(e.target.value);
    
        const fetchStudentData = async () => {
            try {
                const response = await fetch(
                    `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData.json`
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
        
                if (!data) {
                    console.error("No data received from the server");
                    return;
                }
        
                const studentsArray = Object.keys(data).map((key) => ({
                    srNo: key,
                    ...data[key]
                }));
        
                setStudentData(studentsArray);
        
                const classSet = new Set();
                studentsArray.forEach((student) => {
                    if (student && student.currentClass) {
                        classSet.add(student.currentClass);
                    }
                });
        
                setClasses([...classSet]);
            } catch (error) {
                console.error("Error fetching student data:", error);
            }
        };
    
    
        const fetchSubjectsForClass = async (classValue) => {
  try {
    if (!academicYear) {
      console.error("Academic year is not set");
      return;
    }

    const subjects = {};
    for (const student of studentData) {
      if (student.currentClass === classValue) {
        for (const examName of examNames) {
          const response = await fetch(
            `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${student.srNo}/result/${academicYear}/${examName}.json`
          );
          if (!response.ok) {
            throw new Error(
              `Network response was not ok for student ${student.srNo}`
            );
          }
          const studentResult = await response.json();
          if (studentResult) {
            Object.keys(studentResult).forEach((subject) => {
              // Exclude the 'nondi' key
              if (subject !== "nondi") {
                subjects[subject] = true;
              }
            });
          }
        }
      }
    }

    setSubjects(subjects);
  } catch (error) {
    console.error(
      `Error fetching subjects for class ${classValue} and academic year ${academicYear}:`,
      error
    );
  }
};
        
    
        const fetchMarksForSelectedSubject = async () => {
            try {
                console.log("Fetching marks for selected subject...");
    
                const selectedStudents = studentData.filter(
                    (student) => student && student.currentClass === classValue
                );
                console.log("Selected students:", selectedStudents);
                setSelectedStudents(selectedStudents);
    
                const marksDataPromises = selectedStudents.map(async (student) => {
                    const studentMarks = await fetchMarksData(
                        student.srNo,
                        academicYear,
                        selectedExamName,
                        subject
                    );
                    return { srNo: student.srNo, marks: studentMarks };
                });
    
                const marksDataArray = await Promise.all(marksDataPromises);
                console.log("Marks data array:", marksDataArray);
    
                const marksData = marksDataArray.reduce((acc, { srNo, marks }) => {
                    acc[srNo] = marks;
                    return acc;
                }, {});
    
                console.log("Final marks data:", marksData);
                setMarksData(marksData);
            } catch (error) {
                console.error("Error fetching marks data:", error);
            }
        };
    
        const fetchMarksData = async (srNo, academicYear, examName, subject) => {
            const url = `https://digischoolweb-default-rtdb.firebaseio.com/schoolRegister/${udiseNumber}/studentData/${srNo}/result/${academicYear}/${examName}/${subject}.json`;
    
            try {
                console.log(`Fetching data from URL: ${url}`);
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Error fetching data: ${response.statusText}`);
                }
                const data = await response.json();
                console.log(`Data fetched for student ${srNo}:`, data);
                return data || {};
            } catch (error) {
                console.error("Error fetching marks data:", error);
                return {}; 
            }
        };

        const getGrade = (total) => {
            if (total >= 91) return 'A1';
            if (total >= 81) return 'A2';
            if (total >= 71) return 'B1';
            if (total >= 61) return 'B2';
            if (total >= 51) return 'C1';
            if (total >= 41) return 'C2';
            if (total >= 33) return 'D1';
            if (total >= 21) return 'D2';
            return 'Fail';
          };
    
        const submit = () => {
            console.log("Submit button clicked");
            fetchMarksForSelectedSubject();
        };

        
        const printTable = () => {
    const tableElement = document.getElementById("printableTable");
    if (tableElement) {
        const tableContent = tableElement.outerHTML;
        const printWindow = window.open("", "", "height=600,width=800");
        printWindow.document.write("<html><head><title>Print</title>");
        
        // Include your styles here
        printWindow.document.write(`
            <style>
             /* General table styling */

             table {
                width: 100%;
                border-collapse: collapse;
            }
            th, td {
                border: 1px solid #ccc;
                padding: 5px;
                text-align: left;
                width: calc(100% / 7); /* Adjust width to fit the number of columns */
            }
            thead th {
                background-color: #f4f4f4;
                font-weight: normal; /* Removes bold styling */
                rotate: 90;
            }
            tbody td {
                text-align: center;
                font-weight: normal; /* Removes bold styling */
            }
            .grade-table table {
                width: 100%;
                border-collapse: collapse;
            }
            .grade-table th, .grade-table td {
                border: 1px solid #ccc;
                padding: 5px;
                text-align: center;
                width: calc(100% / 10); /* Adjust based on the number of columns */
            }
            .grade-table thead th {
                background-color: #f4f4f4;
            }
            .grade-table tbody td {
                text-align: center;
            }


            
              /* Rotated headers styling */
          .styled-table {
  font-size: 0.8em;
  border-collapse: collapse;
  width: 100%;
}

.styled-table th, .styled-table td {
  font-weight: normal;
  font-size: 0.8em;
  padding: 2px 4px;
  border: 1px solid #130606;
  text-align: left;
}

/* Specific widths for columns */
.styled-table th {
  width: 30px;
}

/* Ensures columns do not expand beyond the table width */
.styled-table {
  table-layout: fixed;
}

/* Specific styles for rotated headings */
.rotate-90 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

/* Reduce padding for smaller cells */
.styled-table th, .styled-table td {
  padding: 2px 4px;
  word-wrap: break-word;
  box-sizing: border-box;
}
            </style>
        `);
        printWindow.document.write("</head><body>");
        printWindow.document.write(tableContent);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
    } else {
        console.error("Table element with ID 'printableTable' not found.");
    }
};



// table {
//     width: 100%;
//     border-collapse: collapse;
// }
// th, td {
//     border: 1px solid #ccc;
//     padding: 5px;
//     text-align: left;
//     width: calc(100% / 7); /* Adjust width to fit the number of columns */
// }
// thead th {
//     background-color: #f4f4f4;
//     font-weight: normal; /* Removes bold styling */
//     rotate: 90;
// }
// tbody td {
//     text-align: center;
//     font-weight: normal; /* Removes bold styling */
// }
// .grade-table table {
//     width: 100%;
//     border-collapse: collapse;
// }
// .grade-table th, .grade-table td {
//     border: 1px solid #ccc;
//     padding: 5px;
//     text-align: center;
//     width: calc(100% / 10); /* Adjust based on the number of columns */
// }
// .grade-table thead th {
//     background-color: #f4f4f4;
// }
// .grade-table tbody td {
//     text-align: center;
// }
//   /* Rotated headers styling */
// .rotate-90 {
// transform: rotate(-90deg);
// text-align: center; /* Center text after rotation */
// vertical-align: middle; /* Align text vertically */
// }


        return (
            <div>
                <Sidebar />
                <div className="p-3 main-content-of-page">
                <h1 className="title"> {language === "English" ? "Collect out" : "कलेक्ट आऊट"}</h1>

                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th>{language === "English" ? "Academic Year " : "शैक्षणिक वर्ष"}</th>
                                <td>
                                    <select
                                        id="academicYear"
                                        value={academicYear}
                                        onChange={handleAcademicYearChange}
                                        className="form-control custom-select"
                                    >
                                        <option value="2023-2024" selected>2023-2024</option>
                                        <option value="2024-2025">2024-2025</option>
                                        <option value="2025-2026">2025-2026</option>
                                        <option value="2026-2027">2026-2027</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>{language === "English" ? "Class " : "वर्ग"}</th>
                                <td>
                                    <select
                                        id="class"
                                        value={classValue}
                                        onChange={handleClassChange}
                                        className="form-control custom-select"
                                    >
                                    <option value="">{language === "English" ? "Select Class " : "वर्ग निवडा"}</option>
                                        {classes.map((cls, index) => (
                                            <option key={index} value={cls}>
                                                {cls}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                       
                            <tr>
                                <th>{language === "English" ? "Exam Name " : "परीक्षेचे नाव"}</th> 
                                <td>
                                    <select
                                        id="examName"
                                        value={selectedExamName}
                                        onChange={handleExamNameChange}
                                        className="form-control custom-select"
                                    >
                                    <option value="">{language === "English" ? "Select Exam " : "परीक्षा निवडा"}</option>
                                        {examNames.map((examName, index) => (
                                            <option key={index} value={examName}>
                                                {examName}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button onClick={submit} className="btn btn-primary btn-block">
                                        {language === "English" ? "Submit " : "सबमिट करा"}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={printTable} className="btn btn-primary">
                     {language === "English" ? "Print " : "Print करा"}
                </button>
                <br/>
                    <table className="table table-striped table-bordered"  id="printableTable">
                    <thead>
  <tr>
    <th>{language === "English" ? "Reg No " : "रजिस्टर नंबर"}</th>
    <th>{language === "English" ? "Student Name " : "विद्यार्थ्याचे नाव"}</th>
    {Object.keys(subjects).map((subject, index) => (
      <th key={index} colSpan="4" className="text-center">{subject}</th>
    ))}
  </tr>
  <tr>
    <th colSpan="2" className="text-center"></th>
    {Object.keys(subjects).map((subject, index) => (
      <React.Fragment key={index}>
        <th className="rotate-90">{language === "English" ? "Akarik " : "आकारिक"}</th>
        <th className="rotate-90">{language === "English" ? "Sanklit " : "संकलित"}</th>
        <th className="rotate-90">{language === "English" ? "Total " : "एकूण"}</th>
        <th className="rotate-90">{language === "English" ? "Grade " : "श्रेणी"}</th>
      </React.Fragment>
    ))}
  </tr>
</thead>

        <tbody>
  {selectedStudents.map((student) => (
    <tr key={student.srNo}>
      <td>{student.srNo}</td>
      <td>{student.stdName}</td>
      {Object.keys(subjects).map((subject, index) => (
        <React.Fragment key={index}>
          <td>{marksData[student.srNo]?.[subject]?.Akarik?.Total ?? 'N/A'}</td>
          <td>{marksData[student.srNo]?.[subject]?.Sanklik?.Total ?? 'N/A'}</td>
          <td>{(marksData[student.srNo]?.[subject]?.Akarik?.Total ?? 0) + (marksData[student.srNo]?.[subject]?.Sanklik?.Total ?? 0) ?? 'N/A'}</td>
          <td>{getGrade((marksData[student.srNo]?.[subject]?.Akarik?.Total ?? 0) + (marksData[student.srNo]?.[subject]?.Sanklik?.Total ?? 0))}</td>
        </React.Fragment>
      ))}
    </tr>
  ))}
</tbody>
    </table>
     
                </div>
            </div>
        );
    };
    
  

export default Collectout;