import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export const TerijReport = ( { combinedData, schoolData, totalsByKhate , onClose} ) => {
  // const location = useLocation();
  // const { combinedData, schoolData, totalsByKhate , onClose} = location.state || {};

  const taluka = schoolData ? schoolData.taluka : "";
  const jilha = schoolData ? schoolData.jilha : "";

  // Helper function to format numbers
  const formatNumber = (num) => (num ? num.toFixed(2) : "");

  // Total Calculation
  let totalCredit = 0;
  let totalDebit = 0;

  // Store the opening balance separately
  const openingBalanceCredit = totalsByKhate["31-03-Akher shillak"]
    ? totalsByKhate["31-03-Akher shillak"].credit
    : 0;
  const openingBalanceDebit = totalsByKhate["31-03-Akher shillak"]
    ? totalsByKhate["31-03-Akher shillak"].debit
    : 0;

  // Print function
  const handlePrint = () => {
    const printContent = document.getElementById("printableContent");
    const printWindow = window.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
              margin: 0;
            }
            h1, h3 {
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
            }
            th, td {
              padding: 10px;
              text-align: center;
              border: 1px solid #000;
            }
            th {
              background-color: #6495ED;
              color: #fff;
            }
            .opening-balance {
              background-color: #C5C6C7;
            }
            .closing-balance {
              background-color: #C5C6C7;
            }
            .total-row {
              background-color: #ffeb3b;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );

  return (
    <div style={report2Overlay}>
    <div style={report2Container}>
    
      <button style={closeButtonStyle2} onClick={onClose}>Close</button>

      <button onClick={handlePrint} style={{ margin: "20px" }}>
        Print Report
      </button>
    


      <div
        id="printableContent"
        style={{
          padding: "20px",
          maxWidth: "1200px",
          margin: "0 auto",
          fontFamily: "Arial, sans-serif",
          border: "1px solid #000",
          boxSizing: "border-box",  overflowY: "auto", // Enable vertical scrolling
          maxHeight: "70vh",
        }}
      >
        {/* School Info Section */}
        <div
          style={{
            backgroundColor: "#f0f0f0",
            padding: "20px",
            marginBottom: "20px",
            textAlign: "center",
            border: "1px solid #000",
          }}
        >
          <h1
            style={{
              margin: "0",
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {schoolData.schoolName}
          </h1>
          <p
            style={{
              margin: "5px 0",
              fontSize: "16px",
            }}
          >
            {language === "English" ? "Taluka: " : "तालुका: "} {taluka},{" "}
            {language === "English" ? " District: " : " जिल्हा: "} {jilha}
          </p>
          <p
            style={{
              margin: "5px 0",
              fontSize: "16px",
            }}
          >
            {language === "English" ? "Email: " : "ई-मेल: "} {schoolData.email},{" "}
            {language === "English" ? "Mobile Number: " : "मोबाईल नंबर "}{" "}
            {schoolData.mobileNumber}
          </p>
        </div>

        {/* Title */}
        <h3
          style={{
            textAlign: "center",
            backgroundColor: "#000",
            color: "#fff",
            padding: "4px",
            display: "block",
            width: "fit-content",
            margin: "0 auto",
            borderRadius: "10px",
            marginBottom: "10px",
            fontSize: "20px",
          }}
        >
          {language === "English" ? "Terij Report " : "तेरीज पत्र "}
        </h3>

        {/* Table Header */}
        <table
          style={{
            width: "1000px",
            maxWidth: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
            margin: "0 auto",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "1px solid #000",
                  backgroundColor: "#6495ED",
                  color: "#fff",
                }}
              >
                <b>{language === "English" ? "Account Name  " : "खात्याचे नाव "}</b>
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "1px solid #000",
                  backgroundColor: "#6495ED",
                  color: "#fff",
                }}
              >
                <b>{language === "English" ? "Credit Amount " : "जमा रक्कम"}</b>
              </th>
              <th
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "1px solid #000",
                  backgroundColor: "#6495ED",
                  color: "#fff",
                }}
              >
                <b>{language === "English" ? "Debit Amount " : "नावे रक्कम"}</b>
              </th>
            </tr>
          </thead>

          <tbody>
            {/* Opening Balance Row */}
            {totalsByKhate["31-03-Akher shillak"] && (
              <>
                <tr
                  style={{
                    backgroundColor: "#C5C6C7",
                  }}
                >
                  <td
                    style={{
                      fontWeight: "bold", border: "1px solid #000",
                    }}
                  >
                    <b>{language === "English" ? "Opening Balance" : "आरंभिक "}</b>
                  </td>
                  <td style={{ border: "1px solid #000"}} >{formatNumber(openingBalanceCredit)}</td>
                  <td style={{ border: "1px solid #000"}}>{formatNumber(openingBalanceDebit)}</td>
                </tr>
                {/* Removing the entry */}
                {delete totalsByKhate["31-03-Akher shillak"]}
              </>
            )}

            {/* Loop Through Khates */}
            {Object.keys(totalsByKhate).map((khateName) => {
              const { credit, debit } = totalsByKhate[khateName];
              totalCredit += credit || 0;
              totalDebit += debit || 0;

              return (
                <tr key={khateName}>
                  <td style={{ border: "1px solid #000"}}>{khateName}</td>
                  <td style={{ border: "1px solid #000"}}>{formatNumber(credit)}</td>
                  <td style={{ border: "1px solid #000"}}>{formatNumber(debit)}</td>
                </tr>
              );
            })}

            {/* Akher Shillak */}
            <tr
              style={{
                backgroundColor: "#C5C6C7",
              }}
            >
              <td
                style={{
                  fontWeight: "bold",border: "1px solid #000"
                }}
              >
                <b>{language === "English" ? "Finally balance" : "अखेर शिल्लक"}</b>
              </td>
              <td style={{ border: "1px solid #000"}}>{""}</td> {/* Leave the Credit column empty */}
              <td style={{ border: "1px solid #000"}}>{formatNumber((totalCredit + openingBalanceCredit) - totalDebit)}</td> 
            </tr>

            {/* Total Row */}
            <tr>
              <td
                style={{
                  fontWeight: "bold",border: "1px solid #000"
                }}
              >
                {language === "English" ? "Total " : "एकूण "}
              </td>
              <td style={{ border: "1px solid #000"}}>
                {formatNumber(
                  totalCredit + openingBalanceCredit
                )}
              </td>
              <td style={{ border: "1px solid #000"}}>{formatNumber(totalDebit + (totalCredit + openingBalanceCredit) - totalDebit)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};
export default TerijReport;

const report2Overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)', // Blur effect
  zIndex: 1000, // Ensure it appears above other content
};

const report2Container = {
  background: 'white', // White background for the report
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  maxWidth: '900px', // Set a maximum width
  width: '100%', // Full width
  position: 'relative', // Position for the close button
  color: 'black',
  overflowY: 'hidden', 
  maxHeight: '90vh',
};

const closeButtonStyle2 = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: 'Red'
};
