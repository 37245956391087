import React, { useRef,useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ProfitLossReport = ({ combinedData, schoolData, totalsByKhate,onClose }) => {
  // const location = useLocation();
  // const = location.state || {};

  const taluka = schoolData ? schoolData.taluka : "";
  const jilha = schoolData ? schoolData.jilha : "";

  const printRef = useRef();

  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=1000');
    printWindow.document.write('<html><head><title>Profit-Loss Report</title>');

    // Add styles for the print window directly
    printWindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif; 
          margin: 20px; 
        }
        table {
          width: 100%; /* Set to 100% to ensure it uses full width */
          max-width: 700px; /* Limit max width */
          border-collapse: collapse; /* Remove gaps between borders */
          margin: 10px auto; /* Center the table */
        }
        th, td {
          border: 1px solid #000; /* Ensure borders are consistent */
          padding: 8px;
          text-align: center;
        }
        th {
          background-color: #007bff;
          color: white;
        }
        tr:nth-child(even) {
          background-color: #f2f2f2; /* Zebra striping for even rows */
        }
        h1, h3 {
          text-align: center;
        }
        .school-info {
          background-color: #f0f0f0;
          padding: 20px;
          margin-bottom: 20px;
          text-align: center;
          border: 1px solid #000;
        }
        .header-title {
          background-color: #000;
          color: #fff;
          padding: 5px;
          display: block; 
          width: fit-content; 
          margin: 10px auto;
          border-radius: 10px;
        }
        @media print {
          body {
            margin: 0; /* Remove default body margin for print */
          }
          table {
            width: 680px !important; /* Enforce width */
            margin: auto; /* Center table on page */
          }
          /* Hide scrollbar on print */
          ::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
          }
          body {
            overflow: hidden; /* Prevent scrolling */
          }
        }
      </style>
    `);

    printWindow.document.write('</head><body>');
    printWindow.document.write(printRef.current.innerHTML); // Get the HTML of the div you want to print
    printWindow.document.write('</body></html>');

    printWindow.document.close(); // Close the document to start rendering
    printWindow.print(); // Open the print dialog
  };
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "English"
  );

   // Prepare data for the table
   const tableData = [];
   let totalCredit = 0;
   let totalDebit = 0;
 
   Object.keys(totalsByKhate).forEach(khateName => {
     if (khateName.trim().toLowerCase() === "31-03-akher shillak".toLowerCase()) {
       return; // Skip this iteration
     }
 
     const { credit, debit } = totalsByKhate[khateName] || { credit: 0, debit: 0 };
 
     // Add row data to the table
     tableData.push([khateName, debit || "", khateName, credit || ""]);
 
     // Add to grand total
     totalCredit += credit || 0;
     totalDebit += debit || 0;
   });
  const profit = totalCredit - totalDebit;
  const profitValue = profit > 0 ? profit : 0;
  const lossValue = profit < 0 ? Math.abs(profit) : 0;

  tableData.push(["Profit", profitValue, "Loss", lossValue]);

  // Add total row to the table
  tableData.push(["Total", totalDebit + profitValue, "Total", totalCredit + lossValue]);


  return (
    <div style={report2Overlay}>
    <div style={report2Container}>
    
      <button style={closeButtonStyle2} onClick={onClose}>Close</button>

      <button
        onClick={handlePrint}
        style={{
          margin: '10px 0',
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Print Report
      </button>
    
          
      <div ref={printRef}>
        <div
          style={{
            maxWidth: '800px',
            margin: '0 auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            border: '1px solid #000',    overflowY: "auto", // Enable vertical scrolling
            maxHeight: "70vh"
          }}
        >
          {/* School Info Section */}
          <div
            style={{
              backgroundColor: '#f0f0f0',
              padding: '20px',
              marginBottom: '20px',
              textAlign: 'center',
              border: '1px solid #000',
            }}
          >
            <h1
              style={{
                margin: '0',
                fontSize: '24px',
                fontWeight: 'bold',
              }}
            >
              {schoolData.schoolName}
            </h1>
            <p
              style={{
                margin: '5px 0',
                fontSize: '16px',
              }}
            >
              {language === "English" ? "Taluka: " : "तालुका: "} {taluka},
            {language === "English" ? " District: " : " जिल्हा: "} {jilha}
            </p>
            <p
              style={{
                margin: '5px 0',
                fontSize: '16px',
              }}
            >
                {language === "English" ? "Email: " : "ई-मेल: "} {schoolData.email},   {language === "English" ? "Mobile Number: " : "मोबाईल नंबर: "} {schoolData.mobileNumber}
            </p>
          </div>

          <h3
            style={{
              textAlign: 'center',
              // marginBottom: '20px',
              backgroundColor: '#000',
              color: '#fff',
              padding: '5px',
              display: 'block', // Change display to block
              width: 'fit-content', // Add width to fit the content
              margin: '0 auto', // Add margin to center the element
              borderRadius: '10px',
              marginBottom: '10px',
              fontSize: '20px',
            }}
          >
            {language === "English" ? " Profit-Loss Report " : "नफा-तोटा पत्र "} 
          </h3>

          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <table
            style={{
              width: '700px',
              borderCollapse: 'collapse',
              marginTop: '10px',
              margin: 'auto',
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: '1px solid #000',
                  }}
                >
                  <h5> {language === "English" ? " Expenses " : "खर्च"} </h5>
                </th>
                <th
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: '1px solid #000',
                  }}
                >
                  <h5>{language === "English" ? " Amount " : "रक्कम"}</h5>
                </th>
                <th
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: '1px solid #000',
                  }}
                >
                  <h5>{language === "English" ? " Income " : "उत्पन्न"}</h5>
                </th>
                <th
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: '1px solid #000',
                  }}
                >
                  <h5>{language === "English" ? " Amount " : "रक्कम"}</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(totalsByKhate).map((khateName, index) => {
                const { credit, debit } = totalsByKhate[khateName] || { credit: 0, debit: 0 };
                  // Skip rendering if khateName is '31-03-akher shillak'
    if (khateName.trim().toLowerCase() === '31-03-akher shillak') {
      return null; // Skip this row
    }
                return (
                  <tr key={khateName} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '' }}>
                    <td style={{ border: "1px solid #000",textAlign: 'center'}}>{khateName.trim().toLowerCase() === '31-03-akher shillak' ? '' : khateName}</td>
                    <td style={{ border: "1px solid #000",textAlign: 'center'}}>{debit || ''}</td>
                    <td style={{ border: "1px solid #000",textAlign: 'center'}}>{khateName.trim().toLowerCase() === '31-03-akher shillak' ? '' : khateName}</td>
                    <td style={{ border: "1px solid #000",textAlign: 'center'}}>{credit || ''}</td>
                  </tr>
                );
              })}

              {/* Profit and Loss Calculation */}
              <tr>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                  {language === "English" ? " Profit " : "नफा"}
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                  {profitValue}
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                   {language === "English" ? " Loss " : "तोटा"}
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                  {lossValue}
                </td>
              </tr>

              {/* Total Calculation */}
              <tr>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                   {language === "English" ? " Total " : "एकूण"}
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                  {totalDebit + profitValue} 
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                    {language === "English" ? " Total " : "एकूण"}
                </td>
                <td
                  style={{
                    border: '1px solid #ddd',
                    padding: '8px',
                    textAlign: 'center',
                    border: '1px solid #000',
                  }}
                >
                  {totalCredit + lossValue}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};
export default ProfitLossReport;

const report2Overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(3px)', // Blur effect
  zIndex: 1000, // Ensure it appears above other content
};

const report2Container = {
  background: 'white', // White background for the report
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  maxWidth: '900px', // Set a maximum width
  width: '100%', // Full width
  position: 'relative', // Position for the close button
  color:'black',
  overflowY: 'hidden', maxHeight: '90vh',
};

const closeButtonStyle2 = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color:'Red'
};

