import React from 'react'

import './style.css'

function Footer() {
  return (
    <div className='footer fixed-bottom mt-5 text-start'>
       <p className='me-2'> Copyright © 2024 E-Guruji Software</p>

    </div>
      )
}

export default Footer